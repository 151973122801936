import { shallowEqual, useSelector } from "react-redux";
import { APP_ENVS, getMarvinUIEnabled } from "../utils/marvin-utils";
import config from "../config";

const useFeatureEnabled = () => {
  const {
    MTEnabled,
    storageS3PathEnabled,
    fbpEnabled,
    MAPEnabled,
    terraformEnabled,
    calendlyEnabled,
    marvinEnabled,
    federatedAccessEnabled,
    azureEnabled,
    isInitialFeatureFetchInProgress,
  } = useSelector(({ auth }) => {
    const { features = {} } = auth;
    const {
      MTEnabled = false,
      StorageS3PathEnable = false,
      FBPEnabled = false,
      MAPEnabled = false,
      TerraformEnabled = false,
      CalendlyEnabled = false,
      FAA = false,
      Azure = false,
    } = features;
    const isMarvinEnabled = getMarvinUIEnabled(auth);
    return {
      MTEnabled: MTEnabled,
      storageS3PathEnabled: StorageS3PathEnable,
      fbpEnabled: FBPEnabled,
      MAPEnabled: MAPEnabled,
      terraformEnabled: TerraformEnabled,
      calendlyEnabled: CalendlyEnabled,
      marvinEnabled: isMarvinEnabled,
      federatedAccessEnabled: FAA,
      azureEnabled: Azure,
      isInitialFeatureFetchInProgress:
        auth?.isFetchingUserDetails || Object.keys(features).length === 0,
    };
  }, shallowEqual);

  return {
    MTEnabled,
    storageS3PathEnabled,
    fbpEnabled,
    MAPEnabled,
    terraformEnabled,
    calendlyEnabled,
    marvinEnabled,
    federatedAccessEnabled,
    azureEnabled,
    isInitialFeatureFetchInProgress,
  };
};

export const useMarvinFeatureEnabled = () => {
  const { marvinEnabled } = useFeatureEnabled();
  const env = config.environment;
  if ([APP_ENVS.PRODUCTION, APP_ENVS.INTERNAL].includes(env)) {
    return marvinEnabled;
  }
  return true;
};

export default useFeatureEnabled;
