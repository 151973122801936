import {
  GET_PATCH_POLICIES_REQUEST, GET_PATCH_POLICIES_SUCCESS, GET_PATCH_POLICIES_FAILURE
} from '../actionTypes/patch-policies';

const initialState = {
  isFetching: false,
  patchPolicyList: null,
  showPatchPolicyList: true
};

const patchPolicies = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATCH_POLICIES_REQUEST:
      return { 
        ...state, 
        isFetching: true,
        patchPolicyList: initialState.patchPolicyList
      }
    case GET_PATCH_POLICIES_SUCCESS:
      return { 
        ...state, 
        isFetching: false,
        patchPolicyList: action.payload,
        showPatchPolicyList: true
      }
    case GET_PATCH_POLICIES_FAILURE:
      return { 
        ...state, 
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state;
  }
}

export default patchPolicies;
