import {
  SET_PATCH_POLICY_CONFIG,
  PATCH_POLICY_NAME_CHANGE,
  PATCH_POLCIY_DESCRIPTION_CHANGE,
  PATCH_POLCY_OPERATIONSYSTEM_CHANGE,
  PATCH_POLICY_ACCOUNTS_CHANGE,
  PATCH_POLICY_REGIONS_CHANGE,
  APPROVAL_RULES_AUTO_APPROVALDAYS_CHANGE,
  APPROVAL_RULES_CLASSIFICATION_SELECT,
  APPROVAL_RULES_COMPLIANCE_SELECT,
  APPROVAL_RULES_INCLUDE_NON_SECURITY_CHANGE,
  APPROVAL_RULES_PRIORITY_SELECT,
  APPROVAL_RULES_PRODUCT_SELECT,
  APPROVAL_RULES_SECTION_SELECT,
  APPROVAL_RULES_SEVERITY_SELECT,
  ADD_APPROVAL_RULE,
  REMOVE_APPROVAL_RULE,
  PATCH_EXCEPTIONS_APPROVED_PATCHES_CHANGE,
  PATCH_EXCEPTIONS_COMPLIANCE_SELECT,
  PATCH_EXCEPTIONS_REJECTED_PATCHES_CHANGE,
  PATCH_EXCEPTIONS_REJECTED_PATCHES_ACTION_CHANGE,
  PATCH_EXCEPTIONS_INCLUDE_NON_SECURITY_CHANGE,
  PATCH_SOURCES_CONFIGURATION_CHANGE,
  PATCH_SOURCES_NAME_CHANGE,
  PATCH_SOURCES_PRODUCT_SELECT,
  PATCH_SOURCES_ACCOUNTS_CHANGE,
  PATCH_SOURCES_REGIONS_CHANGE,
  ADD_PATCH_SOURCE,
  REMOVE_PATCH_SOURCE,
  SAVE_PATCH_POLICY_REQUEST,
  SAVE_PATCH_POLICY_SUCCESS,
  SAVE_PATCH_POLICY_FAILURE,
  DELETE_PATCH_POLICY_REQUEST,
  DELETE_PATCH_POLICY_SUCCESS,
  DELETE_PATCH_POLICY_FAILURE,
  SET_PATCH_SOURCE,
  PATCH_POLICY_RESET_FORM_FIELDS,
  PATCH_POLICY_RESET_SAVE_DATA,
  GET_PATCH_POLICY_DETAIL_REQUEST,
  GET_PATCH_POLICY_DETAIL_SUCCESS,
  GET_PATCH_POLICY_DETAIL_FAILURE,
  SET_NEXT_STEP_STATE
} from "../actionTypes/patch-policy";

const initialState = {
  editPatchPolicy: false,
  patchPolicyConfig: null,
  accounts: [],
  regions: [],
  patchPolicyName: "",
  patchPolicyDescription: "",
  patchPolicyOperatingSystem: "WINDOWS",
  // patchPolicyAccounts: [],
  // patchPolicyRegions:[],
  approvalRules: [
    // {
    //   autoApproval: "0",
    //   classification: [{ value: "All", label: "All" }],
    //   compliance: "UNSPECIFIED",
    //   includeNonSecurity: false,
    //   priority: [{ value: "All", label: "All" }],
    //   product: [{ value: "All", label: "All" }],
    //   section: [{ value: "All", label: "All" }],
    //   severity: [{ value: "All", label: "All" }]
    // }
  ],
  patchExceptionsApprovalPatches: "",
  patchExceptionsRejectedPatches: "",
  patchExceptionsRejectedPatchesAction: "ALLOW_AS_DEPENDENCY",
  patchExceptionsCompliance: "UNSPECIFIED",
  patchExceptionsIncludeNonSecurity: false,
  patchSources: [],
  isCreatingPolicy: null,
  createdPolicy: null,
  disableNextStep: true,
  isDeletingPolicy: null,
  deletedPolicy: null
};

const patchPolicy = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATCH_POLICY_CONFIG:
      return {
        ...state,
        patchPolicyConfig: action.payload,
        approvalRules: [...initialState.approvalRules],
        patchPolicyName: initialState.patchPolicyName,
        patchPolicyDescription: initialState.patchPolicyDescription,
        patchExceptionsApprovalPatches:
          initialState.patchExceptionsApprovalPatches,
        patchExceptionsRejectedPatches:
          initialState.patchExceptionsRejectedPatches,
        patchExceptionsCompliance: initialState.patchExceptionsCompliance,
        patchExceptionsIncludeNonSecurity:
          initialState.patchExceptionsIncludeNonSecurity,
        patchSources: initialState.patchSources
      };
    case PATCH_POLICY_NAME_CHANGE:
      return {
        ...state,
        patchPolicyName: action.payload
      };
    case PATCH_POLCIY_DESCRIPTION_CHANGE:
      return {
        ...state,
        patchPolicyDescription: action.payload
      };
    case PATCH_POLICY_ACCOUNTS_CHANGE:
      return {
        ...state,
        patchPolicyAccounts: action.payload
      };
    case PATCH_POLICY_REGIONS_CHANGE:
      return {
        ...state,
        patchPolicyRegions: action.payload
      };
    case PATCH_POLCY_OPERATIONSYSTEM_CHANGE:
      return {
        ...state,
        patchPolicyOperatingSystem: action.payload,
        approvalRules: initialState.approvalRules
        //  patchSources: initialState.patchSources
      };
    case APPROVAL_RULES_AUTO_APPROVALDAYS_CHANGE:
      var newRules = [...state.approvalRules];
      newRules[action.payload[0].index].autoApproval =
        action.payload[0].approvalRulesAutoApproval;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_CLASSIFICATION_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].classification =
        action.payload[0].approvalRulesClassification;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_COMPLIANCE_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].compliance =
        action.payload[0].approvalRulesCompliance;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_INCLUDE_NON_SECURITY_CHANGE:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].includeNonSecurity =
        action.payload[0].approvalRulesIncludeNonSecurity;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_PRIORITY_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].priority =
        action.payload[0].approvalRulesPriority;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_PRODUCT_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].product =
        action.payload[0].approvalRulesProduct;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_SECTION_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].section =
        action.payload[0].approvalRulesSection;
      return {
        ...state,
        approvalRules: newRules
      };
    case APPROVAL_RULES_SEVERITY_SELECT:
      newRules = [...state.approvalRules];
      newRules[action.payload[0].index].severity =
        action.payload[0].approvalRulesSeverity;
      return {
        ...state,
        approvalRules: newRules
      };
    case ADD_APPROVAL_RULE: {
      newRules = [...state.approvalRules];
      newRules.push({
        autoApproval: "0",
        classification: [{ value: "All", label: "All" }],
        compliance: ["UNSPECIFIED"],
        includeNonSecurity: false,
        priority: [{ value: "All", label: "All" }],
        product: [{ value: "All", label: "All" }],
        section: [{ value: "All", label: "All" }],
        severity: [{ value: "All", label: "All" }]
      });
      return {
        ...state,
        approvalRules: newRules
      };
    }
    case REMOVE_APPROVAL_RULE:
      newRules = [...state.approvalRules];
      newRules = newRules.filter((item, index) => {
        if (action.payload !== index) {
          return item;
        } else return null;
      });
      return {
        ...state,
        approvalRules: newRules
      };
    case PATCH_EXCEPTIONS_APPROVED_PATCHES_CHANGE:
      return {
        ...state,
        patchExceptionsApprovalPatches: action.payload
      };
    case PATCH_EXCEPTIONS_COMPLIANCE_SELECT:
      return {
        ...state,
        patchExceptionsCompliance: action.payload
      };
    case PATCH_EXCEPTIONS_REJECTED_PATCHES_CHANGE:
      return {
        ...state,
        patchExceptionsRejectedPatches: action.payload
      };
    case PATCH_EXCEPTIONS_REJECTED_PATCHES_ACTION_CHANGE:
      return {
        ...state,
        patchExceptionsRejectedPatchesAction: action.payload
      };
    case PATCH_EXCEPTIONS_INCLUDE_NON_SECURITY_CHANGE:
      return {
        ...state,
        patchExceptionsIncludeNonSecurity: action.payload
      };
    case PATCH_SOURCES_CONFIGURATION_CHANGE:
      var newSources = [...state.patchSources];
      newSources[action.payload[0].index].configuration =
        action.payload[0].patchSourcesConfiguration;
      return {
        ...state,
        patchSources: newSources
      };
    case PATCH_SOURCES_NAME_CHANGE:
      newSources = [...state.patchSources];
      newSources[action.payload[0].index].name =
        action.payload[0].patchSourcesName;
      return {
        ...state,
        patchSources: newSources
      };
    case PATCH_SOURCES_PRODUCT_SELECT:
      newSources = [...state.patchSources];
      newSources[action.payload[0].index].product =
        action.payload[0].patchSourcesProduct;
      return {
        ...state,
        patchSources: newSources
      };
    case PATCH_SOURCES_ACCOUNTS_CHANGE:
      newSources = [...state.patchSources];
      newSources[action.payload[0].index].accounts =
        action.payload[0].patchSourcesAccount;
      return {
        ...state,
        patchSources: newSources
      };
    case PATCH_SOURCES_REGIONS_CHANGE:
      newSources = [...state.patchSources];
      newSources[action.payload[0].index].regions =
        action.payload[0].patchSourcesRegion;
      return {
        ...state,
        patchSources: newSources
      };
    case SET_PATCH_SOURCE:
      return {
        ...state,
        patchSources: action.payload
      };
    case ADD_PATCH_SOURCE: {
      newSources = [...state.patchSources];
      newSources.push({
        configuration: null,
        name: null,
        product: null
      });
      return {
        ...state,
        patchSources: newSources
      };
    }
    case REMOVE_PATCH_SOURCE: {
      newSources = [...state.patchSources];
      newSources = newSources.filter((item, index) => {
        if (action.payload !== index) {
          return item;
        } else return null;
      });
      return {
        ...state,
        patchSources: newSources
      };
    }
    case SAVE_PATCH_POLICY_REQUEST:
      return {
        ...state,
        isCreatingPolicy: true
      };
    case SAVE_PATCH_POLICY_SUCCESS:
      return {
        ...state,
        createdPolicy: true,
        isCreatingPolicy: false
      };
    case SAVE_PATCH_POLICY_FAILURE:
      return {
        ...state,
        createdPolicy: false,
        isCreatingPolicy: false
      };
    case DELETE_PATCH_POLICY_REQUEST:
      return {
        ...state,
        isDeletingPolicy: true
      };
    case DELETE_PATCH_POLICY_SUCCESS:
      return {
        ...state,
        deletedPolicy: true,
        isDeletingPolicy: false
      };
    case DELETE_PATCH_POLICY_FAILURE:
      return {
        ...state,
        deletedPolicy: false,
        isDeletingPolicy: false
      };
    case PATCH_POLICY_RESET_FORM_FIELDS:
      return {
        ...state,
        ...initialState
      };
    case PATCH_POLICY_RESET_SAVE_DATA:
      return {
        ...state,
        ...initialState
      };
    case GET_PATCH_POLICY_DETAIL_REQUEST:
      return {
        ...state,
        isFetchingDetails: true
      };
    case GET_PATCH_POLICY_DETAIL_SUCCESS:
      const { patchPolicyDetails, processedDetails } = action.payload;
      return {
        ...state,
        isFetchingDetails: false,
        patchPolicyDetails: patchPolicyDetails,
        editPatchPolicy: true,
        policyId: processedDetails.policyId,
        patchPolicyDescription: processedDetails.patchPolicyDescription,
        patchPolicyName: processedDetails.patchPolicyName,
        patchPolicyOperatingSystem: processedDetails.patchPolicyOperatingSystem,
        approvalRules: processedDetails.approvalRules,
        patchExceptionsApprovalPatches:
          processedDetails.patchExceptionsApprovalPatches,
        patchExceptionsRejectedPatches:
          processedDetails.patchExceptionsRejectedPatches,
        patchExceptionsRejectedPatchesAction:
          processedDetails.patchExceptionsRejectedPatchesAction,
        patchExceptionsCompliance: processedDetails.patchExceptionsCompliance,
        patchExceptionsIncludeNonSecurity:
          processedDetails.patchExceptionsIncludeNonSecurity,
        patchSources: processedDetails.patchSources
      };
    case GET_PATCH_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload
      };
    case SET_NEXT_STEP_STATE:
      return {
        ...state,
        disableNextStep: action.payload
      };
    default:
      return state;
  }
};

export default patchPolicy;
