import {
  SAVE_CREATE_USER_USERNAME,
  SAVE_CREATE_USER_DESCRIPTION,
  SAVE_CREATE_USER_EMAIL,
  SAVE_CREATE_USER_SELECTED_ROLES,
  SAVE_CREATE_USER_SELECTED_CHARACTER,
  SAVE_CREATE_USER_SELECTED_DEVICE,
  SAVE_CREATE_USER_SELECTED_OPERATOR,
  CREATE_ADMIN_USER_REQUEST,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILURE,
  EDIT_ADMIN_USER_REQUEST,
  EDIT_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER_FAILURE,
  CLEAR_CREATE_ADMIN_USER_DATA,
  SAVE_CREATE_USER_DEVICE_PRODUCT_ID,
  SAVE_CREATE_USER_DEVICE_PRODUCT_NUMBER,
  SAVE_CREATE_USER_CHARACTER_NAME,
  SAVE_CREATE_USER_CHARACTER_DESCRIPTION,
  SAVE_CREATE_USER_CHARACTER,
  CLEAR_CREATE_CHARACTER_DATA,
  SAVE_CREATE_USER_ADDED_ROLES,
  CLEAR_CREATE_ADMIN_USER_ROLES,
  SAVE_CREATE_USER_PASSWORD,
  SAVE_ADMIN_USER_FOR_EDITING,
  IS_USER_ROLE_MAPPING_DIRTY,
  SAVE_CREATE_USER_CALENDLY,
  ADD_ORG_TO_ACCESS_LIST,
  ADD_ORG_ACCESS_LIST_LOADING,
  ADD_ORG_ACCESS_LIST_LOADED,
  ADD_ORG_REMOVE_ACCESS_LIST,
  CLEAR_ORG_ACCESS_LIST,
} from "../actionTypes/user";

const initialState = {
  userName: "",
  userPassword: "",
  userDescription: "",
  userEmail: "",
  isDay2IdentityType: false,
  userRoles: null,
  addedRoles: [],
  operator: null,
  device: null,
  deviceProductId: "",
  deviceSerialNumber: "",
  userCharacter: null,
  characterName: "",
  characterDescription: "",
  isCreatingUser: false,
  createdUser: false,
  isEditing: false,
  editingUser: false,
  editedUser: false,
  editingUserFailed: false,
  isRoleMappingDirty: false,
  organizationAccess: [],
  organizationAccessRemoveList: [],
  organizationAccessReference: [],
  isAccessListLoading: false,
  userRoleList: [
    {
      ID: "Department Admin",
      value: "Department Admin",
      isSelected: false,
    },
    {
      ID: "Provisioning Approver",
      value: "Provisioning Approver",
      isSelected: false,
    },
    {
      ID: "Provisioning Requester",
      value: "Provisioning Requester",
      isSelected: false,
    },
    {
      ID: "Infrastructure Access",
      value: "Infrastructure Access",
      isSelected: false,
    },
    {
      ID: "Operations Access",
      value: "Operations Access",
      isSelected: false,
    },
    {
      ID: "Reports Access",
      value: "Reports Access",
      isSelected: false,
    },
  ],
  userCharacterList: [
    {
      id: "bot-1",
      value: "Marvin",
      isSelected: false,
      description: "MontyCloud's AI Representative",
    },
    {
      id: "bot-2",
      value: "CL.AI.RE",
      isSelected: false,
      description: "Cloud AI Representative",
    },
    {
      id: "bot-3",
      value: "AI.D.EN",
      isSelected: false,
      description: "AI Devops Engineer",
    },

    {
      id: "bot-5",
      value: "D.AI.SY",
      isSelected: false,
      description: "Devops AI Systems Engineer",
    },
    {
      id: "bot-4",
      value: "Pepper",
      isSelected: false,
      description: "The Humanoid Robot",
    },
  ],
  deviceList: [
    {
      id: "alexa",
      value: "Alexa",
      isSelected: false,
    },
    {
      id: "google-home",
      value: "Google Home",
      isSelected: false,
    },
    {
      id: "other",
      value: "Other",
      isSelected: false,
    },
  ],
  operatorList: [
    {
      id: "L1",
      value: "Perform operational tasks within a department",
      isSelected: false,
    },
    {
      id: "L2",
      value: "Level 1 + Perform provisioning tasks within a department",
      isSelected: false,
    },
    {
      id: "L3",
      value: "Level 2 + Department level approval access",
      isSelected: false,
    },
  ],
  calendlyUrl: null,
};

const createUser = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CREATE_USER_USERNAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SAVE_CREATE_USER_PASSWORD:
      return {
        ...state,
        userPassword: action.payload,
      };
    case SAVE_CREATE_USER_DESCRIPTION:
      return {
        ...state,
        userDescription: action.payload,
      };
    case SAVE_CREATE_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case SAVE_CREATE_USER_SELECTED_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case SAVE_CREATE_USER_SELECTED_CHARACTER:
      return {
        ...state,
        userCharacter: action.payload,
      };
    case SAVE_CREATE_USER_SELECTED_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case SAVE_CREATE_USER_SELECTED_OPERATOR:
      return {
        ...state,
        operator: action.payload,
      };
    case SAVE_CREATE_USER_DEVICE_PRODUCT_ID:
      return {
        ...state,
        deviceProductId: action.payload,
      };
    case SAVE_CREATE_USER_DEVICE_PRODUCT_NUMBER:
      return {
        ...state,
        deviceSerialNumber: action.payload,
      };
    case SAVE_CREATE_USER_CHARACTER_NAME:
      return {
        ...state,
        characterName: action.payload,
      };
    case SAVE_CREATE_USER_CHARACTER_DESCRIPTION:
      return {
        ...state,
        characterDescription: action.payload,
      };
    case SAVE_ADMIN_USER_FOR_EDITING:
      return {
        ...state,
        ...action.payload,
        isEditing: true,
      };
    case IS_USER_ROLE_MAPPING_DIRTY:
      return {
        ...state,
        isRoleMappingDirty: action.payload,
      };
    case CLEAR_CREATE_CHARACTER_DATA:
      return {
        ...state,
        characterName: initialState.characterName,
        characterDescription: initialState.characterDescription,
      };
    case SAVE_CREATE_USER_CHARACTER:
      let data = action.payload;
      let characters = state.userCharacterList;
      let character = {
        id: `bot-${characters.length + 1}`,
        value: data.name,
        isSelected: false,
      };
      let allCharacters = characters.concat(character);
      return {
        ...state,
        userCharacterList: allCharacters,
      };
    case CREATE_ADMIN_USER_REQUEST:
      return {
        ...state,
        isCreatingUser: true,
      };
    case CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        isCreatingUser: false,
        createdUser: true,
        organizationAccess: [],
      };
    case CREATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        isCreatingUser: false,
        createdUser: false,
      };
    case EDIT_ADMIN_USER_REQUEST:
      return {
        ...state,
        editingUser: true,
        editedUser: initialState.editedUser,
        editingUserFailed: initialState.editingUserFailed,
      };
    case EDIT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        editingUser: false,
        editedUser: true,
        ...(action.clearAccessList
          ? {
              organizationAccess: [],
            }
          : {}),
      };
    case EDIT_ADMIN_USER_FAILURE:
      return {
        ...state,
        editingUser: false,
        editingUserFailed: true,
      };
    case CLEAR_CREATE_ADMIN_USER_DATA:
      return {
        ...state,
        ...initialState,
        addedRoles: [],
        organizationAccess: [],
      };
    case CLEAR_CREATE_ADMIN_USER_ROLES:
      return {
        ...state,
        userRoles: initialState.userRoles,
      };
    case SAVE_CREATE_USER_ADDED_ROLES:
      return {
        ...state,
        addedRoles: action.payload,
      };
    case SAVE_CREATE_USER_CALENDLY:
      return {
        ...state,
        calendlyUrl: action.payload,
      };

    case ADD_ORG_TO_ACCESS_LIST:
      return {
        ...state,
        organizationAccess: [...action.payload],
      };
    case CLEAR_ORG_ACCESS_LIST:
      return {
        ...state,
        organizationAccessRemoveList: [],
      };
    case ADD_ORG_ACCESS_LIST_LOADING:
      return {
        ...state,
        isAccessListLoading: true,
      };

    case ADD_ORG_ACCESS_LIST_LOADED:
      return {
        ...state,
        isAccessListLoading: false,
        organizationAccess: action.payload,
        // used when editing
        organizationAccessReference: [...action.payload],
      };

    case ADD_ORG_REMOVE_ACCESS_LIST:
      return {
        ...state,
        organizationAccessRemoveList: action.payload,
      };

    default:
      return state;
  }
};

export default createUser;
