import {
  CLEAR_SELECTED_ACCOUNTS_REGIONS,
  CLEAR_SELECTED_PROJECT_ENVIRONMENTS,
  CLEAR_WORKLOAD_SCOPE,
  CREATE_ASSESSMENT_FAILURE,
  CREATE_ASSESSMENT_REQUEST,
  CREATE_ASSESSMENT_RESET,
  CREATE_ASSESSMENT_SUCCESS,
  DISABLE_NEXT_STEP,
  EDIT_ASSESSMENT_FAILURE,
  EDIT_ASSESSMENT_REQUEST,
  EDIT_ASSESSMENT_SUCCESS,
  FETCHING_INDUSTRY_DATA_FAILURE,
  FETCHING_INDUSTRY_DATA_SUCCESS,
  SAVE_HOSTED_REGION,
  SAVE_REVIEW_ARCHITECTURAL_DESIGN_URL,
  SAVE_REVIEW_DESCRIPTION,
  SAVE_REVIEW_ENV_TYPE,
  SAVE_REVIEW_INDUSTRY,
  SAVE_REVIEW_INDUSTRY_TYPE,
  SAVE_REVIEW_NAME,
  SAVE_REVIEW_OWNER,
  SAVE_SELECTED_ACCOUNTS,
  SAVE_SELECTED_DEPARTMENT,
  SAVE_SELECTED_SCOPE_MODE,
  SELECT_ALL_ACCOUNTS_REGIONS,
  SELECT_ALL_PROJECTS_ENVS,
  SET_ACCOUNTS,
  SET_ASSESSMENT_DETAILS,
  SET_DEPARTMENTS,
  SET_ENVIRONMENTS,
  SET_TOGGLE,
  SET_WORKLOAD_ACCOUNTS,
  SHOW_ENVIRONMENTS,
  TOGGLE_WORKLOAD,
  SET_IS_ASSESSMENT_SCOPE_VALID,
} from "../actionTypes/details";
import { SCOPE_TYPE } from "../constants";

const initialState = {
  reviewName: "",
  reviewDescription: "",
  reviewHostedRegion: "",
  reviewOwner: "",
  reviewArchitecturalDesignUrl: "",
  reviewEnvType: "",
  environments: [],
  departments: [],
  accounts: [],
  selectedAccounts: [],
  isMontyCloudWorkload: false,
  allAccountsRegionsSelected: false,
  allProjectsEnvsSelected: false,
  toggles: {
    accountsRegions: true,
    projectEnvs: false,
  },
  selectedDepartment: {
    ID: "",
    Name: "",
    environments: [],
  },
  disableNextStep: false,
  industryTypes: {},
  industries: {},
  fetchingIndustryTypesError: null,
  selectedIndustryType: null,
  selectedIndustry: null,
  showEnvironments: false,
  isCreating: false,
  isEditing: false,
  createdAssessment: false,
  editedAssessment: false,
  errorMessage: null,
  createdAssessmentId: null,
  selectedScopeMode: SCOPE_TYPE.ACCOUNT,
  isAssessmentScopeValid: true,
};

const detailsReducers = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REVIEW_NAME: {
      return {
        ...state,
        reviewName: action.payload,
      };
    }
    case SAVE_REVIEW_DESCRIPTION: {
      return {
        ...state,
        reviewDescription: action.payload,
      };
    }
    case SAVE_HOSTED_REGION: {
      return {
        ...state,
        reviewHostedRegion: action.payload,
      };
    }
    case SAVE_REVIEW_OWNER: {
      return {
        ...state,
        reviewOwner: action.payload,
      };
    }
    case SAVE_REVIEW_ARCHITECTURAL_DESIGN_URL: {
      return {
        ...state,
        reviewArchitecturalDesignUrl: action.payload,
      };
    }
    case SAVE_REVIEW_ENV_TYPE: {
      return {
        ...state,
        reviewEnvType: action.payload,
      };
    }
    case SAVE_SELECTED_ACCOUNTS: {
      return {
        ...state,
        selectedAccounts: action.payload,
      };
    }
    case SET_WORKLOAD_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload,
      };
    }
    case SAVE_SELECTED_DEPARTMENT: {
      return {
        ...state,
        selectedDepartment: action.payload,
      };
    }
    case TOGGLE_WORKLOAD: {
      return {
        ...state,
        isMontyCloudWorkload: action.payload,
      };
    }
    case SAVE_SELECTED_SCOPE_MODE: {
      return {
        ...state,
        selectedScopeMode: action.payload,
      };
    }
    case DISABLE_NEXT_STEP: {
      return {
        ...state,
        disableNextStep: action.payload,
      };
    }
    case FETCHING_INDUSTRY_DATA_SUCCESS: {
      const { industryTypes = {}, industries = {} } = action.payload;
      return {
        ...state,
        fetchingIndustryTypesError: null,
        industryTypes,
        industries,
      };
    }
    case FETCHING_INDUSTRY_DATA_FAILURE: {
      return {
        ...state,
        fetchingIndustryTypesError: action.payload.errorMsg,
        industryTypes: initialState.industryTypes,
        industries: initialState.industries,
      };
    }
    case SAVE_REVIEW_INDUSTRY_TYPE: {
      return {
        ...state,
        selectedIndustryType: action.payload,
      };
    }
    case SAVE_REVIEW_INDUSTRY: {
      return {
        ...state,
        selectedIndustry: action.payload,
      };
    }
    case SHOW_ENVIRONMENTS: {
      return {
        ...state,
        showEnvironments: action.payload,
      };
    }
    case SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload,
      };
    }
    case SELECT_ALL_ACCOUNTS_REGIONS: {
      return {
        ...state,
        allAccountsRegionsSelected: action.payload,
      };
    }
    case SELECT_ALL_PROJECTS_ENVS: {
      return {
        ...state,
        allProjectsEnvsSelected: action.payload,
      };
    }
    case CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdAssessment: true,
        createdAssessmentId: action.payload,
      };
    case CREATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };
    case CREATE_ASSESSMENT_RESET: {
      return {
        ...initialState,
      };
    }
    case EDIT_ASSESSMENT_REQUEST: {
      return {
        ...state,
        isEditing: true,
      };
    }
    case EDIT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        isEditing: false,
        editedAssessment: true,
      };
    }

    case EDIT_ASSESSMENT_FAILURE: {
      return {
        ...state,
        isEditing: false,
        errorMessage: action.payload,
      };
    }
    case SET_DEPARTMENTS: {
      return {
        ...state,
        departments: action.payload,
      };
    }

    case SET_ENVIRONMENTS: {
      return {
        ...state,
        environments: action.payload,
      };
    }
    case SET_TOGGLE: {
      let montyWorkload = false;
      if (action.payload.projectEnvs) {
        montyWorkload = true;
      }
      return {
        ...state,
        toggles: action.payload,
        isMontyCloudWorkload: montyWorkload,
      };
    }
    case SET_ASSESSMENT_DETAILS: {
      return {
        ...state,
        ...action.payload,
        reviewName: action.payload.reviewName,
        reviewDescription: action.payload.reviewDescription,
        reviewOwner: action.payload.reviewOwner,
        reviewArchitecturalDesignUrl:
          action.payload.reviewArchitecturalDesignUrl,
        reviewEnvType: action.payload.reviewEnvType,
        selectedIndustryType: action.payload.selectedIndustryType,
        selectedIndustry: action.payload.selectedIndustry,
      };
    }
    case CLEAR_SELECTED_ACCOUNTS_REGIONS: {
      return {
        ...state,
        accounts: action.payload,
        selectedAccounts: [],
      };
    }
    case CLEAR_SELECTED_PROJECT_ENVIRONMENTS: {
      return {
        ...state,
        departments: action.payload,
        selectedDepartment: initialState.selectedDepartment,
        environments: initialState.environments,
      };
    }
    case CLEAR_WORKLOAD_SCOPE: {
      return {
        ...state,
        departments: initialState.departments,
        selectedDepartment: initialState.selectedDepartment,
        environments: initialState.environments,
        accounts: initialState.accounts,
        selectedScopeMode: initialState.selectedScopeMode,
      };
    }
    case SET_IS_ASSESSMENT_SCOPE_VALID:
      return {
        ...state,
        isAssessmentScopeValid: action.payload,
      };

    default: {
      return { ...state };
    }
  }
};

export default detailsReducers;
