import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CONFIGURATION_ISSUES,
  COUNT_OF_CONFIGURATION_FAILED_ACCOUNTS,
  POLICY_BASE_URL,
} from "../../../config/service";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiService";

export const healthEventConfigurationApi = createApi({
  reducerPath: "healthEventConfigurations",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createHealthEventConfiguration: builder.mutation({
      query: (data) => {
        const { body } = data;
        return {
          url: POLICY_BASE_URL,
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
    }),
    getSinglePolicyDetails: builder.query({
      query: (policyId) => {
        return {
          url: `${POLICY_BASE_URL}/${policyId}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    getCountOfConfigurationFailedAccounts: builder.query({
      query: (policyId) => {
        return {
          url: `${POLICY_BASE_URL}${CONFIGURATION_ISSUES.replace("{policyId}", policyId)}${COUNT_OF_CONFIGURATION_FAILED_ACCOUNTS}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const {
  useCreateHealthEventConfigurationMutation,
  useGetSinglePolicyDetailsQuery,
  useGetCountOfConfigurationFailedAccountsQuery,
} = healthEventConfigurationApi;
