import {
  GET_ACCOUNT_TAGS_REQUEST,
  GET_ACCOUNT_TAGS_SUCCESS,
  GET_ACCOUNT_TAGS_FAILURE,
  DISABLE_NEXT_STEP,
  HIDE_PREV_STEP,
  SET_ACCOUNT_NAME,
  CLEAR_ACCOUNT_DATA,
  GET_CHECK_API_PERMISSION_REQUEST,
  GET_CHECK_API_PERMISSION_SUCCESS,
  GET_CHECK_API_PERMISSION_FAILURE,
  SET_ADD_ACCOUNT_MODAL,
  CUSTOMER_ACCOUNT_REQUESTED,
  CUSTOMER_ACCOUNT_SUCCESS,
  CUSTOMER_ACCOUNT_FAILED,
  CUSTOMER_ACCOUNT_STATUS_REQUESTED,
  CUSTOMER_ACCOUNT_STATUS_SUCCESS,
  CUSTOMER_ACCOUNT_STATUS_FAILED,
  RESET_CONFIGURE_ACCOUNT,
  SAVE_ACCOUNT_NAME_REQUESTED,
  SAVE_ACCOUNT_NAME_SUCESS,
  SAVE_ACCOUNT_NAME_FAILED,
  CONTINUE_ACCOUNT_ONBOARDING,
  SAVE_SELECTED_REGIONS,
  GET_ACCOUNT_DETAILS_REQUEST,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  SET_SELECTED_ACCOUNT_ID,
  SET_CONFIGURE_ACCESS_BTN_CLICKED,
  CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED,
  SET_AZURE_ACCOUNT_MODAL,
  SET_SELECTED_CUSTOMER_ACCOUNT_ID,
  CLEAR_ACCOUNT_DETAILS,
  RESET_ONBOARIDNG,
  SET_CONFIGURE_AWS_ACCOUNT_STATE,
  CLEAR_CONFIGURE_AWS_ACCOUNT_STATE,
} from "../actionTypes/account";
import { GET_ACCOUNTS_SUCCESS } from "../actionTypes/accounts";
import { UPDATE } from "../../../utils/action-types.json";
import { CRUDAction, clearDataAction } from "../../../actions";
import { REMOVE_ACCOUNT } from "../../../utils/action-names.json";
import {
  removeAccountApi,
  getAccountActivationDetailsApi,
  checkApiPermissionApi,
  customerAccountApi,
  customerAccountStatusApi,
  updateCustomerAccountApi,
  continueCustomerAccountApi,
  getAccountDetailsApi,
} from "../services/account";
import { getSelectedOrgsAndAllDepts } from "../../../utils/org-params";
import { postNotification } from "../../../utils/notification-message";
import i18n from "../../../config/locale/i18n";
import resourceTypeCodes from "../../../utils/resourceTypeCodes.json";
import { showErrorNotification } from "../../../utils/error-notification";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";
import { getAccounts } from "./accounts";

export function toggleAddAccountModal(payload) {
  return {
    type: SET_ADD_ACCOUNT_MODAL,
    payload,
  };
}

export const removeAccount = (params) => async (dispatch) => {
  return await CRUDAction({
    type: UPDATE,
    name: REMOVE_ACCOUNT,
    dispatch,
    service: removeAccountApi,
    params: params.customerAccountId,
    showToaster: true,
  });
};

export const clearRemoveAccountData = () => (dispatch) => {
  return clearDataAction({
    name: REMOVE_ACCOUNT,
    dispatch,
  });
};

///   Get Account Tags   ////

function requestGetAccountTags() {
  return {
    type: GET_ACCOUNT_TAGS_REQUEST,
  };
}

export function receiveGetAccountTags(payload) {
  return {
    type: GET_ACCOUNT_TAGS_SUCCESS,
    payload,
  };
}

function getAccountTagsError(payload) {
  return {
    type: GET_ACCOUNT_TAGS_FAILURE,
    payload,
  };
}

export const getAccountTags = (customerId) => (dispatch) => {
  dispatch(requestGetAccountTags());
  getAccountActivationDetailsApi(customerId)
    .then((data) => {
      dispatch(receiveGetAccountTags(data));
    })
    .catch((err) => {
      dispatch(getAccountTagsError(err.body));
    });
};

export const setDisableNextStep = (disableNextStep) => ({
  type: DISABLE_NEXT_STEP,
  payload: disableNextStep,
});

export const setHidePrevStep = (hidePrevStep) => ({
  type: HIDE_PREV_STEP,
  payload: hidePrevStep,
});

export const setAccountName = (accountName) => ({
  type: SET_ACCOUNT_NAME,
  payload: accountName,
});

export const clearAccountData = () => ({
  type: CLEAR_ACCOUNT_DATA,
});

export const checkApiPermissionToAddAccount = () => (dispatch, getState) => {
  const { globalConfig } = getState();
  var filters = getSelectedOrgsAndAllDepts(globalConfig.filteredUserData);
  filters.ResourceType = resourceTypeCodes.CUSTOMER_ACCOUNT;
  filters.Action = "add";
  dispatch({
    type: GET_CHECK_API_PERMISSION_REQUEST,
  });
  if (!filters.Orgs.length || !filters.Depts.length) {
    dispatch({
      type: GET_CHECK_API_PERMISSION_SUCCESS,
      payload: true,
    });
  } else {
    checkApiPermissionApi(filters)
      .then((result) => {
        dispatch({
          type: GET_CHECK_API_PERMISSION_SUCCESS,
          payload: result.Access,
        });
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        const message =
          (error && error.data && error.data.ErrorMessage) ||
          i18n.t("common:errorMessage");
        dispatch({
          type: GET_CHECK_API_PERMISSION_FAILURE,
          payload: message,
        });
      });
  }
};

function requestCustomerAccount() {
  return {
    type: CUSTOMER_ACCOUNT_REQUESTED,
  };
}

export function receiveCustomerAccount(payload) {
  return {
    type: CUSTOMER_ACCOUNT_SUCCESS,
    payload,
  };
}

function customerAccountError(payload) {
  return {
    type: CUSTOMER_ACCOUNT_FAILED,
    payload,
  };
}

export const getOnboardingTemplate = () => (dispatch, getState) => {
  const { firstRun } = getState();
  const {
    selectedOrgId,
    selectedDept,
    selectedCloudProvider,
    selectedAccountType,
  } = firstRun;
  let payload = {};

  if (selectedOrgId) {
    payload.OrganizationId = selectedOrgId;
  }

  if (selectedDept) {
    if (Array.isArray(selectedDept)) {
      payload.Departments = selectedDept.map((dept) => dept.ID);
    } else {
      payload.Departments = [selectedDept.ID];
    }
  }

  payload.CloudProvider = selectedCloudProvider
    ? selectedCloudProvider
    : CLOUDPROVIDERS.AWS;

  payload.AccountType = selectedAccountType;

  dispatch(requestCustomerAccount());
  customerAccountApi(payload)
    .then((data) => {
      dispatch(receiveCustomerAccount(data));
      dispatch(setHidePrevStep(true));
      dispatch({
        type: SET_CONFIGURE_ACCESS_BTN_CLICKED,
      });
    })
    .catch((err) => {
      const error = JSON.parse(err.message);
      const message =
        (error && error.data && error.data.ErrorMessage) ||
        i18n.t("common:errorMessage");
      dispatch(customerAccountError(message));
    });
};

function requestCustomerAccountStatus() {
  return {
    type: CUSTOMER_ACCOUNT_STATUS_REQUESTED,
  };
}

function receiveCustomerAccountStatus(payload) {
  return {
    type: CUSTOMER_ACCOUNT_STATUS_SUCCESS,
    payload,
  };
}

function customerAccountStatusError(payload) {
  return {
    type: CUSTOMER_ACCOUNT_STATUS_FAILED,
    payload,
  };
}

export const getCustomerAccountStatus =
  (customerAccountId) => (dispatch, getState) => {
    var payload = {
      CustomerAccountId: customerAccountId,
      Details: true,
    };
    const params = "Filters=" + encodeURI(JSON.stringify(payload));
    dispatch(requestCustomerAccountStatus());
    customerAccountStatusApi(params)
      .then((data) => {
        const {
          addAccount: { isConfigureAccessBtnClicked, customerAccountStatus },
        } = getState();
        if (
          data.Status &&
          customerAccountStatus !== data.Status &&
          isConfigureAccessBtnClicked
        ) {
          data.CustomerAccountId = customerAccountId;
          dispatch(receiveCustomerAccountStatus(data));
        }
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        const message =
          (error && error.data && error.data.ErrorMessage) ||
          i18n.t("common:errorMessage");
        dispatch(customerAccountStatusError(message));
      });
  };

export function resetConfigureAccount(isFRE) {
  return {
    type: RESET_CONFIGURE_ACCOUNT,
    isFRE: isFRE,
  };
}

export function saveSelectedRegions(payload) {
  return {
    type: SAVE_SELECTED_REGIONS,
    payload,
  };
}

function requestSaveAccountName() {
  return {
    type: SAVE_ACCOUNT_NAME_REQUESTED,
  };
}

function receiveSaveAccountName(payload) {
  return {
    type: SAVE_ACCOUNT_NAME_SUCESS,
    payload,
  };
}

function saveAccountNameError() {
  return {
    type: SAVE_ACCOUNT_NAME_FAILED,
  };
}

export const saveCustomerAccountName =
  (accountId, params, isFirstRunView) => (dispatch) => {
    if (isFirstRunView) {
      params.IsFirstRunView = true;
    }
    dispatch(requestSaveAccountName());
    updateCustomerAccountApi(accountId, params)
      .then((data) => {
        dispatch(receiveSaveAccountName(data));
        dispatch(getAccounts());
      })
      .catch((err) => {
        showErrorNotification(dispatch, err);
        dispatch(saveAccountNameError());
      });
  };

export function ContinueAddAccountModal(payload) {
  return {
    type: CONTINUE_ACCOUNT_ONBOARDING,
    payload,
  };
}
export const continueOnboarding = (params) => (dispatch, getState) => {
  dispatch(ContinueAddAccountModal(params));
  const { firstRun } = getState();
  const { selectedOrgId, selectedDept } = firstRun;
  var payload = {
    DepartmentId: selectedDept.ID,
    OrganizationId: selectedOrgId,
  };
  var newParams = "CustomerAccountId=" + params;
  dispatch(requestCustomerAccount());
  continueCustomerAccountApi(newParams, payload)
    .then((data) => {
      dispatch(receiveCustomerAccount(data));
    })
    .catch((err) => {
      const error = JSON.parse(err.message);
      const message =
        (error && error.data && error.data.ErrorMessage) ||
        i18n.t("common:errorMessage");
      dispatch(customerAccountError(message));
    });
};

// getAccountDetails
const getAccountDetailsRequest = () => ({
  type: GET_ACCOUNT_DETAILS_REQUEST,
});

const getAccountDetailsSuccess = (payload) => ({
  type: GET_ACCOUNT_DETAILS_SUCCESS,
  payload,
});

const getAccountDetailsFailure = (payload) => ({
  type: GET_ACCOUNT_DETAILS_FAILURE,
  payload,
});

const updateAccounts = (accountDetails) => (dispatch, getState) => {
  const {
    accounts: { accountList },
  } = getState();
  const accountNumber = accountDetails && accountDetails.AccountNumber;
  const payload = accountList.map((account) => {
    if (account.accountNumber === accountNumber) {
      return {
        ...account,
        accountStatus: accountDetails.Status,
        offBoardingLink:
          account.offBoardingLink || accountDetails.OffBoardingLink,
      };
    }
    return account;
  });
  dispatch({
    type: GET_ACCOUNTS_SUCCESS,
    payload,
  });
};

export const getAccountDetails =
  (accountId, updateAccountList = true) =>
  async (dispatch) => {
    dispatch(getAccountDetailsRequest());
    try {
      const data = await getAccountDetailsApi(accountId);
      if (updateAccountList) {
        dispatch(updateAccounts(data));
      }
      dispatch(getAccountDetailsSuccess(data));
    } catch (err) {
      const error = err.message ? JSON.parse(err.message) : "";
      const errorMessage =
        (error && error.data && error.data.ErrorMessage) ||
        i18n.t("addAccount:accountDetailsErrorMessage");
      if (error && error.status !== 403) {
        dispatch(postNotification(errorMessage, "danger"));
      }
      dispatch(getAccountDetailsFailure(errorMessage));
    }
  };

export function setSelectedAccountId(accountId) {
  return {
    type: SET_SELECTED_ACCOUNT_ID,
    accountId,
  };
}

export const clearCustomerAccountNameSaved = () => ({
  type: CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED,
});

export function toggleAzureAccountModal(payload) {
  return {
    type: SET_AZURE_ACCOUNT_MODAL,
    payload,
  };
}

export function setSelectedCustomerAccountId(customerAccountId) {
  return {
    type: SET_SELECTED_CUSTOMER_ACCOUNT_ID,
    payload: customerAccountId,
  };
}

export const clearAccountDetails = () => ({
  type: CLEAR_ACCOUNT_DETAILS,
});

export const resetAccountOnboarding = () => ({
  type: RESET_ONBOARIDNG,
});

export const setConfigureAWSAccountState = (configureAccountState) => {
  return {
    type: SET_CONFIGURE_AWS_ACCOUNT_STATE,
    payload: configureAccountState,
  };
};

export const clearConfigureAWSAccountState = () => ({
  type: CLEAR_CONFIGURE_AWS_ACCOUNT_STATE,
});
