import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const tenantCategoryAPI = createApi({
  tagTypes: ["TenantCategories"],
  reducerPath: "tenantCategoryAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    getTenantCategoryList: builder.query({
      query: () => ({ url: "/org/categories", method: WEB_REQUEST_TYPE.GET }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "TenantCategories", id })),
            { type: "TenantCategories", id: "LIST" },
          ]
          : [{ type: "TenantCategories", id: "LIST" }],
    }),
    createTenantCategory: builder.mutation({
      query: (body) => {
        return {
          url: "/org/category",
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
      invalidatesTags: [{ type: "TenantCategories", id: "LIST" }],
    }),
    updateTenantCategory: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/org/category/${id}`,
          method: WEB_REQUEST_TYPE.PUT,
          body,
        };
      },
      invalidatesTags: [{ type: "TenantCategories", id: "LIST" }],
    }),
    deleteTenantCategory: builder.mutation({
      query(id) {
        return {
          url: `/org/categories/${id}`,
          method: WEB_REQUEST_TYPE.DELETE,
        };
      },
      invalidatesTags: [{ type: "TenantCategories", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTenantCategoryListQuery,
  useCreateTenantCategoryMutation,
  useUpdateTenantCategoryMutation,
  useDeleteTenantCategoryMutation,
} = tenantCategoryAPI;
