import {
  GET_APP_FILE_OBJECTS_REQUEST,
  GET_APP_FILE_OBJECTS_SUCCESS,
  GET_APP_FILE_OBJECTS_FAILURE,
  DELETE_APP_FILE_OBJECTS_REQUEST,
  DELETE_APP_FILE_OBJECTS_SUCCESS,
  DELETE_APP_FILE_OBJECTS_FAILURE,
  CLEAR_DELETE_APP_FILE_OBJECTS_DATA,
  SAVE_SELECTED_FILES_STATUS,
  CLEAR_SELECTED_FILES_STATUS,
  CREATE_EDIT_OBJECT_REQUEST,
  CREATE_EDIT_OBJECT_SUCCESS,
  CREATE_EDIT_OBJECT_FAILURE,
  CLEAR_CREATE_EDIT_OBJECT_DATA,
  SAVE_SELECTED_FILES_PERCENTAGE,
  SAVE_SELECTED_PATH,
  CLEAR_STORAGE_DATA,
  UPDATE_FILE_UPLOAD_BY_INSTANCE_STATUS,
  DOWNLOAD_FILE_TO_AN_INSTANCE_REQUEST,
  DOWNLOAD_FILE_TO_AN_INSTANCE_SUCCESS,
  DOWNLOAD_FILE_TO_AN_INSTANCE_FAILURE,
  CLEAR_DOWNLOAD_FILE_TO_AN_INSTANCE_DATA,
  CANCEL_UPLOAD_SUCCESS,
  CANCEL_UPLOAD_FAILURE,
  CLEAR_CANCELLED_FILE_OBJECTS_DATA,
} from "../actionTypes/storage";

const initialState = {
  fetchingFileObjects: true,
  fileObjects: [],
  fetchingFileObjectsFailed: false,
  deletingFileObjects: false,
  deletedFileObjects: false,
  selectedFilesStatus: [],
  selectedFilesPercentage: [],
  creatingOrEditingObject: false,
  createdOrEditedObject: false,
  createOrEditObjectFailed: false,
  offset: null,
  fetchingFileObjecsErrorMsg: "",
  selectedPath: "/",
  fileUploadByInstanceStatus: [],
  isDownloadingFileToAnInstance: false,
  downloadingFileToAnInstanceSuccess: false,
  downloadingFileToAnInstanceFailed: false,
  objectIds: {},
  cancelledUploads: [],
  failedCancellations: [],
};

const appStorage = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_FILE_OBJECTS_REQUEST:
      return {
        ...state,
        fetchingFileObjects: initialState.fetchingFileObjects,
        fileObjects: action.isRefresh ? state.fileObjects : null,
        fetchingFileObjectsFailed: initialState.fetchingFileObjectsFailed,
        fetchingFileObjecsErrorMsg: initialState.fetchingFileObjecsErrorMsg,
      };
    case GET_APP_FILE_OBJECTS_SUCCESS:
      return {
        ...state,
        fetchingFileObjects: false,
        fileObjects: action.payload,
        offset: action.offset,
      };
    case GET_APP_FILE_OBJECTS_FAILURE:
      return {
        ...state,
        fetchingFileObjects: false,
        fetchingFileObjectsFailed: true,
        fetchingFileObjecsErrorMsg: action.payload,
      };
    case DOWNLOAD_FILE_TO_AN_INSTANCE_REQUEST:
      return {
        ...state,
        isDownloadingFileToAnInstance: true,
        downloadingFileToAnInstanceSuccess:
          initialState.downloadingFileToAnInstanceSuccess,
        downloadingFileToAnInstanceFailed:
          initialState.downloadingFileToAnInstanceFailed,
      };
    case DOWNLOAD_FILE_TO_AN_INSTANCE_SUCCESS:
      return {
        ...state,
        isDownloadingFileToAnInstance: false,
        downloadingFileToAnInstanceSuccess: true,
      };
    case DOWNLOAD_FILE_TO_AN_INSTANCE_FAILURE:
      return {
        ...state,
        isDownloadingFileToAnInstance: false,
        downloadingFileToAnInstanceFailed: true,
      };
    case CLEAR_DOWNLOAD_FILE_TO_AN_INSTANCE_DATA:
      return {
        ...state,
        isDownloadingFileToAnInstance:
          initialState.isDownloadingFileToAnInstance,
        downloadingFileToAnInstanceSuccess:
          initialState.downloadingFileToAnInstanceSuccess,
        downloadingFileToAnInstanceFailed:
          initialState.downloadingFileToAnInstanceFailed,
      };
    case DELETE_APP_FILE_OBJECTS_REQUEST:
      return {
        ...state,
        deletingFileObjects: true,
        deletedFileObjects: initialState.deletedFileObjects,
      };
    case DELETE_APP_FILE_OBJECTS_SUCCESS:
      return {
        ...state,
        deletingFileObjects: initialState.deletingFileObjects,
        deletedFileObjects: true,
      };
    case DELETE_APP_FILE_OBJECTS_FAILURE:
      return {
        ...state,
        deletingFileObjects: initialState.deletingFileObjects,
      };
    case CLEAR_DELETE_APP_FILE_OBJECTS_DATA:
      return {
        ...state,
        deletingFileObjects: initialState.deletingFileObjects,
        deletedFileObjects: initialState.deletedFileObjects,
      };
    case SAVE_SELECTED_FILES_STATUS:
      return {
        ...state,
        selectedFilesStatus: action.payload,
      };
    case CLEAR_SELECTED_FILES_STATUS:
      return {
        ...state,
        selectedFilesStatus: initialState.selectedFilesStatus,
        selectedFilesPercentage: initialState.selectedFilesPercentage,
      };
    case CREATE_EDIT_OBJECT_REQUEST:
      return {
        ...state,
        creatingOrEditingObject: true,
        createdOrEditedObject: initialState.createdOrEditedObject,
        createOrEditObjectFailed: initialState.createOrEditObjectFailed,
      };
    case CREATE_EDIT_OBJECT_SUCCESS:
      return {
        ...state,
        creatingOrEditingObject: false,
        createdOrEditedObject: true,
      };
    case CREATE_EDIT_OBJECT_FAILURE:
      return {
        ...state,
        creatingOrEditingObject: initialState.creatingOrEditingObject,
        createOrEditObjectFailed: true,
      };
    case CLEAR_CREATE_EDIT_OBJECT_DATA:
      return {
        ...state,
        creatingOrEditingObject: initialState.creatingOrEditingObject,
        createdOrEditedObject: initialState.createdOrEditedObject,
        createOrEditObjectFailed: initialState.createOrEditObjectFailed,
      };
    case UPDATE_FILE_UPLOAD_BY_INSTANCE_STATUS:
      return {
        ...state,
        fileUploadByInstanceStatus: action.payload,
      };
    case SAVE_SELECTED_FILES_PERCENTAGE:
      return {
        ...state,
        selectedFilesPercentage: action.payload,
      };
    case SAVE_SELECTED_PATH:
      return {
        ...state,
        selectedPath: action.payload,
      };
    case CLEAR_STORAGE_DATA:
      return {
        ...initialState,
      };
    case CANCEL_UPLOAD_SUCCESS: {
      const { objectId } = action.payload || {};
      if (!objectId) return state;

      if (!Array.isArray(state.selectedFilesStatus)) {
        return state;
      }
      return {
        ...state,
        cancelledUploads: [...state.cancelledUploads, objectId],
        selectedFilesStatus: state.selectedFilesStatus.map((file) =>
          file.objectId === objectId ? { ...file, isCancelled: true } : file
        ),
      };
    }
    case CANCEL_UPLOAD_FAILURE: {
      const { objectId, error } = action.payload || {};
      if (!objectId || !error) return state;

      if (!Array.isArray(state.selectedFilesStatus)) {
        return state;
      }
      return {
        ...state,
        failedCancellations: [...state.failedCancellations, objectId],
        selectedFilesStatus: state.selectedFilesStatus.map((file) =>
          file.objectId === objectId ? { ...file, cancelError: error } : file
        ),
      };
    }
    case CLEAR_CANCELLED_FILE_OBJECTS_DATA:
      return {
        ...state,
        cancelledUploads: initialState.cancelledUploads,
        failedCancellations: initialState.failedCancellations,
      };

    default:
      return {
        ...state,
      };
  }
};

export default appStorage;
