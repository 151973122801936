import axios from "axios";
import { API_BASE_PATH } from "../config/service";
import { getLoggedInUserAccessToken } from "./account";

export const WEB_REQUEST_TYPE = {
    POST: "POST",
    GET: "GET",
    DELETE: "DELETE",
    PUT: "PUT",
    PATCH: "PATCH",
};

export const getAxiosInstance = ({
    method,
    url,
    basePath = API_BASE_PATH,
    params,
    isWithCredentialsEnabled,
    cancelSignal,
    body,
    headers = {},
}) => {
    const mergedHeaders = {
        ...headers,
        Authorization: getLoggedInUserAccessToken(),
      };

    return axios({
        method: method,
        url: url,
        baseURL: basePath,
        data: body ? body : params,
        withCredentials: isWithCredentialsEnabled,
        headers: mergedHeaders,
        signal: cancelSignal,
    });
};