import {
  GET_LAUNCH_CONFIG_REQUEST,
  GET_LAUNCH_CONFIG_SUCCESS,
  GET_LAUNCH_CONFIG_FAILURE,
  SAVE_LAUNCH_CONFIG_NAME,
  SAVE_LAUNCH_CONFIG_DESCRIPTION,
  CREATE_LAUNCH_CONFIG_REQUEST,
  CREATE_LAUNCH_CONFIG_SUCCESS,
  CREATE_LAUNCH_CONFIG_FAILURE,
  SAVE_SELECTED_CONFIG_FOR_BP_DEPLOY,
  SAVE_SELECTED_LAUNCH_CONFIG_PARAMETERS,
  RESET_SAVED_LAUNCH_CONFIG_CHANGES,
  RESET_LAUNCH_CONFIG_DATA,
  SAVE_LAUNCH_CONFIG_CUSTOMIZE_OPTION,
  SAVE_LAUNCH_CONFIG_DATA_FOR_MODIFICATION,
  UPDATE_LAUNCH_CONFIG_REQUEST,
  UPDATE_LAUNCH_CONFIG_SUCCESS,
  UPDATE_LAUNCH_CONFIG_FAILURE,
  ENABLE_LAUNCH_CONFIG_REQUEST,
  ENABLE_LAUNCH_CONFIG_SUCCESS,
  ENABLE_LAUNCH_CONFIG_FAILURE,
  DELETE_LAUNCH_CONFIG_REQUEST,
  DELETE_LAUNCH_CONFIG_SUCCESS,
  DELETE_LAUNCH_CONFIG_FAILURE,
} from "../actionTypes/configurations";

const initialState = {
  fetchingConfigurations: false,
  launchConfigurations: [],
  fetchingConfigurationsSuccess: false,
  fetchingConfigurationsFailed: false,
  name: "",
  description: "",
  creatingLaunchConfig: false,
  creatingLaunchConfigSuccess: false,
  creatingLaunchConfigFailure: false,
  selectedConfigForBPLaunch: null,
  selectedLaunchConfigParameters: [],
  isCustomizable: true,
  updatingLaunchConfig: false,
  updatingLaunchConfigSuccess: false,
  updatingLaunchConfigFailure: false,
  enablingLaunchConfig: false,
  enablingLaunchConfigSuccess: false,
  enablingLaunchConfigFailure: false,
  deletingLaunchConfig: false,
  deletingLaunchConfigSuccess: false,
  deletingLaunchConfigFailure: false,
};

const launchConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LAUNCH_CONFIG_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SAVE_LAUNCH_CONFIG_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case SAVE_SELECTED_CONFIG_FOR_BP_DEPLOY:
      return {
        ...state,
        selectedConfigForBPLaunch: action.payload,
      };
    case SAVE_SELECTED_LAUNCH_CONFIG_PARAMETERS:
      return {
        ...state,
        selectedLaunchConfigParameters: action.payload,
      };
    case SAVE_LAUNCH_CONFIG_CUSTOMIZE_OPTION:
      return {
        ...state,
        isCustomizable: action.payload,
      };
    case SAVE_LAUNCH_CONFIG_DATA_FOR_MODIFICATION:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_SAVED_LAUNCH_CONFIG_CHANGES:
      return {
        ...state,
        ...initialState,
        launchConfigurations: state.launchConfigurations,
        fetchingConfigurations: state.fetchingConfigurations,
        fetchingConfigurationsFailed: state.fetchingConfigurationsFailed,
        fetchingConfigurationsSuccess: state.fetchingConfigurationsSuccess,
      };
    case RESET_LAUNCH_CONFIG_DATA:
      return {
        ...state,
        ...initialState,
      };
    case GET_LAUNCH_CONFIG_REQUEST:
      return {
        ...state,
        fetchingConfigurations: true,
        fetchingConfigurationsFailed: initialState.fetchingConfigurationsFailed,
      };
    case GET_LAUNCH_CONFIG_SUCCESS:
      return {
        ...state,
        fetchingConfigurations: false,
        fetchingConfigurationsSuccess: true,
        launchConfigurations: action.payload,
      };
    case GET_LAUNCH_CONFIG_FAILURE:
      return {
        ...state,
        fetchingConfigurations: false,
        fetchingConfigurationsFailed: true,
      };
    case CREATE_LAUNCH_CONFIG_REQUEST:
      return {
        ...state,
        creatingLaunchConfig: true,
        creatingLaunchConfigSuccess: initialState.creatingLaunchConfigSuccess,
        creatingLaunchConfigFailure: initialState.creatingLaunchConfigFailure,
      };
    case CREATE_LAUNCH_CONFIG_SUCCESS:
      return {
        ...state,
        creatingLaunchConfig: false,
        creatingLaunchConfigSuccess: true,
      };
    case CREATE_LAUNCH_CONFIG_FAILURE:
      return {
        ...state,
        creatingLaunchConfig: false,
        creatingLaunchConfigFailure: true,
      };
    case UPDATE_LAUNCH_CONFIG_REQUEST:
      return {
        ...state,
        updatingLaunchConfig: true,
        updatingLaunchConfigSuccess: initialState.updatingLaunchConfigSuccess,
        updatingLaunchConfigFailure: initialState.updatingLaunchConfigFailure,
      };
    case UPDATE_LAUNCH_CONFIG_SUCCESS:
      return {
        ...state,
        updatingLaunchConfig: false,
        updatingLaunchConfigSuccess: true,
      };
    case UPDATE_LAUNCH_CONFIG_FAILURE:
      return {
        ...state,
        updatingLaunchConfig: false,
        updatingLaunchConfigFailure: true,
      };
    case ENABLE_LAUNCH_CONFIG_REQUEST:
      return {
        ...state,
        enablingLaunchConfig: true,
        enablingLaunchConfigSuccess: initialState.enablingLaunchConfigSuccess,
        enablingLaunchConfigFailure: initialState.enablingLaunchConfigFailure,
      };
    case ENABLE_LAUNCH_CONFIG_SUCCESS:
      return {
        ...state,
        enablingLaunchConfig: false,
        enablingLaunchConfigSuccess: true,
      };
    case ENABLE_LAUNCH_CONFIG_FAILURE:
      return {
        ...state,
        enablingLaunchConfig: false,
        enablingLaunchConfigFailure: true,
      };
    case DELETE_LAUNCH_CONFIG_REQUEST:
      return {
        ...state,
        deletingLaunchConfig: true,
        deletingLaunchConfigSuccess: initialState.deletingLaunchConfigSuccess,
        deletingLaunchConfigFailure: initialState.deletingLaunchConfigFailure,
      };
    case DELETE_LAUNCH_CONFIG_SUCCESS:
      return {
        ...state,
        deletingLaunchConfig: false,
        deletingLaunchConfigSuccess: true,
      };
    case DELETE_LAUNCH_CONFIG_FAILURE:
      return {
        ...state,
        deletingLaunchConfig: false,
        deletingLaunchConfigFailure: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default launchConfiguration;
