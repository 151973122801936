import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ASSIGN_SUPPORT_CHARGE,
  BULK_ASSIGN_SUPPORT_CHARGES,
  BULK_UNASSIGN_SUPPORT_CHARGE,
  MARKETPLACE_EDIT_CREDIT,
  SUPPORT_CHARGES_LIST,
  SUPPORT_CHARGES_SUMMARY,
  TOTAL_SUPPORT_CHARGES_RECEIVED,
  UNASSIGN_SUPPORT_CHARGE
} from "../../../../../../config/service";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../../../helpers/apiService";
import { axiosBaseQuery } from "../../../../../../config/customBaseQuery";
import { isArrayWithLength } from "../../../../../../utils/array-methods";

export const fetchSupportChargesSummary = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  Limit,
  Offset,
  SortBy,
  SortOrder,
}) => {
  let url = SUPPORT_CHARGES_SUMMARY.replace("{orgId}", OrganizationId);
  let filters = {};

  filters["BillingPeriod"] = BillingPeriod;
  if (Dimension) {
    filters["Dimension"] = Dimension;
  }

  url += `?Filters=${encodeURIComponent(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;

  if (SortBy) {
    url += `&SortBy=${SortBy}&SortOrder=${SortOrder}`;
  }
  return getApiService(WEB_REQUEST_TYPE.GET, url);
};

export const fetchSupportCharges = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  groupKey,
  Limit,
  Offset,
  SortBy,
  SortOrder,
  FilterModel,
  Status,
}) => {
  let url = SUPPORT_CHARGES_LIST.replace("{orgId}", OrganizationId);
  let filters = {};
  filters["BillingPeriod"] = BillingPeriod;

  if (Status) {
    filters.Status = Status;
  }
  if (isArrayWithLength(FilterModel)) {
    filters.KeywordSearch = [...FilterModel];
  }
  if (Dimension) {
    filters[Dimension] = groupKey;
  }

  url += `?Filters=${encodeURIComponent(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;
  if (SortBy) {
    url += `&SortBy=${SortBy}&SortOrder=${SortOrder}`;
  }

  return getApiService(WEB_REQUEST_TYPE.GET, url);
};

export const supportChargesAPI = createApi({
  reducerPath: "supportCharges",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    assignSupportCharge: builder.mutation({
      query({ supportChargeId, organizationId, body }) {
        let url = ASSIGN_SUPPORT_CHARGE.replace("{orgId}", organizationId);
        url = url.replace("{supportChargeId}", supportChargeId);

        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
    }),
    editSupportCharge: builder.mutation({
      query({ customLineItemId, orgId, body }) {
        return {
          url: MARKETPLACE_EDIT_CREDIT.replace("{orgId}", orgId).replace(
            "{customLineItemId}",
            customLineItemId
          ),
          method: WEB_REQUEST_TYPE.PATCH,
          body,
        };
      },
    }),
    getTotalSupportCharges: builder.query({
      query({ orgId, BillingPeriod }) {
        return {
          url: `${TOTAL_SUPPORT_CHARGES_RECEIVED.replace(
            "{orgId}",
            orgId
          )}?billingPeriod=${BillingPeriod}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
    bulkAssignSupportCharges: builder.mutation({
      query({ selectedSupportCharges, organizationId }) {
        let url = BULK_ASSIGN_SUPPORT_CHARGES.replace("{orgId}", organizationId);
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: selectedSupportCharges,
        };
      },
    }),
    bulkUnassignSupportCharges: builder.mutation({
      query({ selectedSupportCharge, organizationId }) {
        let url = BULK_UNASSIGN_SUPPORT_CHARGE.replace(
          "{orgId}",
          organizationId
        );
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: selectedSupportCharge,
        };
      },
    }),
    unAssignSupportCharge: builder.mutation({
      query({ supportChargeId, orgId }) {
        return {
          url: UNASSIGN_SUPPORT_CHARGE.replace("{orgId}", orgId).replace(
            "{supportChargeId}",
            supportChargeId
          ),
          method: WEB_REQUEST_TYPE.PUT,
        };
      },
    }),
  }),
});

export const {
  useAssignSupportChargeMutation,
  useEditSupportChargeMutation,
  useGetTotalSupportChargesQuery,
  useBulkAssignSupportChargesMutation,
  useBulkUnassignSupportChargesMutation,
  useUnAssignSupportChargeMutation,
} = supportChargesAPI;
