import {
    SET_SELECTED_REPORT_FILTERS,
    RESET_REPORT_FILTERS
} from "../actionTypes/reportFilter";

const initialState = {
    selectedFilters : []
}


const reportFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_REPORT_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload
            }
        case RESET_REPORT_FILTERS:
            return {
                ...state,
                selectedFilters: initialState.selectedFilters
            } 
        default:
            return state;
    }
};

export default reportFilterReducer;