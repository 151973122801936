export const GET_ACCOUNT_TAGS_REQUEST = "GET_ACCOUNT_TAGS_REQUEST";
export const GET_ACCOUNT_TAGS_SUCCESS = "GET_ACCOUNT_TAGS_SUCCESS";
export const GET_ACCOUNT_TAGS_FAILURE = "GET_ACCOUNT_TAGS_FAILURE";
export const SET_ACCOUNT_NAME = "SET_ACCOUNT_NAME";
export const DISABLE_NEXT_STEP = "DISABLE_NEXT_STEP";
export const HIDE_PREV_STEP = "HIDE_PREV_STEP";
export const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA";
export const GET_CHECK_API_PERMISSION_REQUEST =
  "GET_CHECK_API_PERMISSION_REQUEST";
export const GET_CHECK_API_PERMISSION_SUCCESS =
  "GET_CHECK_API_PERMISSION_SUCCESS";
export const GET_CHECK_API_PERMISSION_FAILURE =
  "GET_CHECK_API_PERMISSION_FAILURE";
export const SET_ADD_ACCOUNT_MODAL = "SET_ADD_ACCOUNT_MODAL";
export const CUSTOMER_ACCOUNT_REQUESTED = "CUSTOMER_ACCOUNT_REQUESTED";
export const CUSTOMER_ACCOUNT_SUCCESS = "CUSTOMER_ACCOUNT_SUCCESS";
export const CUSTOMER_ACCOUNT_FAILED = "CUSTOMER_ACCOUNT_FAILED";

export const CUSTOMER_ACCOUNT_STATUS_REQUESTED =
  "CUSTOMER_ACCOUNT_STATUS_REQUESTED";
export const CUSTOMER_ACCOUNT_STATUS_SUCCESS =
  "CUSTOMER_ACCOUNT_STATUS_SUCCESS";
export const CUSTOMER_ACCOUNT_STATUS_FAILED = "CUSTOMER_ACCOUNT_STATUS_FAILED";
export const RESET_CONFIGURE_ACCOUNT = "RESET_CONFIGURE_ACCOUNT";

export const SAVE_ACCOUNT_NAME_REQUESTED = "SAVE_ACCOUNT_NAME_REQUESTED";
export const SAVE_ACCOUNT_NAME_SUCESS = "SAVE_ACCOUNT_NAME_SUCESS";
export const SAVE_ACCOUNT_NAME_FAILED = "SAVE_ACCOUNT_NAME_FAILED";
export const CONTINUE_ACCOUNT_ONBOARDING = "CONTINUE_ACCOUNT_ONBOARDING";
export const SAVE_SELECTED_REGIONS = "SAVE_SELECTED_REGIONS";
export const GET_ACCOUNT_DETAILS_REQUEST = "GET_ACCOUNT_DETAILS_REQUEST";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_FAILURE = "GET_ACCOUNT_DETAILS_FAILURE";
export const SET_SELECTED_ACCOUNT_ID = "SET_SELECTED_ACCOUNT_ID";
export const SET_CONFIGURE_ACCESS_BTN_CLICKED =
  "SET_CONFIGURE_ACCESS_BTN_CLICKED";

export const CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED =
  "CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED";

export const SET_AZURE_ACCOUNT_MODAL = "SET_AZURE_ACCOUNT_MODAL";
export const SET_SELECTED_CUSTOMER_ACCOUNT_ID =
  "SET_SELECTED_CUSTOMER_ACCOUNT_ID";
export const CLEAR_ACCOUNT_DETAILS = "CLEAR_ACCOUNT_DETAILS";
export const RESET_ONBOARIDNG = "RESET_ONBOARIDNG";
export const SET_CONFIGURE_AWS_ACCOUNT_STATE =
  "SET_CONFIGURE_AWS_ACCOUNT_STATE";
export const CLEAR_CONFIGURE_AWS_ACCOUNT_STATE =
  "CLEAR_CONFIGURE_AWS_ACCOUNT_STATE";
