import {
  DELETE_ASSESSMENT_FAILURE,
  DELETE_ASSESSMENT_REQUEST,
  DELETE_ASSESSMENT_RESET,
  DELETE_ASSESSMENT_SUCCESS,
  FETCHING_ASSESSMENTS_FAILURE,
  FETCHING_ASSESSMENTS_START,
  FETCHING_ASSESSMENTS_SUCCESS,
  FETCHING_COMPLETED_ASSESSMENTS_SUCCESS,
  FETCHING_PENDING_ASSESSMENTS_SUCCESS,
  FETCH_ALL_ASSESSMENTS,
  MARK_COMPLETE_FAILURE,
  MARK_COMPLETE_REQUEST,
  MARK_COMPLETE_RESET,
  MARK_COMPLETE_SUCCESS,
  SHARE_ASSESSMENT_FAILURE,
  SHARE_ASSESSMENT_REQUEST,
  SHARE_ASSESSMENT_RESET,
  SHARE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_STATS,
} from "../actionTypes/assessments";

const initialState = {
  fetchingAssessments: false,
  fetchingAssessmentsError: null,
  assessments: [],
  pendingAssessments: [],
  completedAssessments: [],
  markingComplete: false,
  markedComplete: false,
  deletingAssessment: false,
  deletedAssessment: false,
  sharingAssessment: false,
  sharedAssessment: false,
  totalPendingAssessments: 0,
  totalCompletedAssessments: 0,
  pendingAssessmentsFetched: false,
};

const assessmentsReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ASSESSMENTS_START: {
      return {
        ...state,
        fetchingAssessments: true,
        fetchingAssessmentError: null,
        assessments: initialState.assessments,
        pendingAssessments: initialState.pendingAssessments,
        completedAssessments: initialState.completedAssessments,
      };
    }
    case FETCH_ALL_ASSESSMENTS: {
      return {
        ...state,
        fetchingAssessments: true,
      };
    }
    case FETCHING_ASSESSMENTS_SUCCESS: {
      const { assessment = [] } = action.payload;
      const uniqueAssessments = assessment.filter(
        (newWorkload) =>
          !state.assessments.some(
            (workload) => workload.WorkloadId === newWorkload.WorkloadId
          )
      );
      return {
        ...state,
        fetchingAssessments: false,
        assessments: [...state.assessments, ...uniqueAssessments],
      };
    }
    case FETCHING_PENDING_ASSESSMENTS_SUCCESS: {
      const pendingAssessments = action.payload.assessments;
      return {
        ...state,
        fetchingAssessments: false,
        fetchingAssessmentError: null,
        pendingAssessments,
        pendingAssessmentsFetched: true,
      };
    }
    case FETCHING_COMPLETED_ASSESSMENTS_SUCCESS: {
      const completedAssessments = action.payload.assessments;
      return {
        ...state,
        fetchingAssessments: false,
        fetchingAssessmentError: null,
        completedAssessments,
      };
    }
    case FETCHING_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        fetchingAssessments: false,
        fetchingAssessmentError: action.payload.errorMsg,
        assessments: initialState.assessments,
        pendingAssessments: initialState.pendingAssessments,
        completedAssessments: initialState.completedAssessments,
      };
    }
    case MARK_COMPLETE_REQUEST: {
      return {
        ...state,
        markingComplete: true,
      };
    }
    case MARK_COMPLETE_SUCCESS: {
      return {
        ...state,
        markingComplete: false,
        markedComplete: true,
      };
    }
    case MARK_COMPLETE_FAILURE: {
      return {
        ...state,
      };
    }
    case MARK_COMPLETE_RESET: {
      return {
        ...state,
        markedComplete: false,
        markingComplete: false,
      };
    }
    case DELETE_ASSESSMENT_REQUEST: {
      return {
        ...state,
        deletingAssessment: true,
      };
    }
    case DELETE_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        deletingAssessment: false,
        deletedAssessment: true,
      };
    }
    case DELETE_ASSESSMENT_FAILURE: {
      return {
        ...state,
        deletingAssessment: false,
      };
    }
    case DELETE_ASSESSMENT_RESET: {
      return {
        ...state,
        deletedAssessment: false,
        deletingAssessment: false,
      };
    }
    case SHARE_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        sharingAssessment: false,
        sharedAssessment: true,
      };
    }
    case SHARE_ASSESSMENT_FAILURE: {
      return {
        ...state,
        sharingAssessment: false,
        sharedAssessment: false,
      };
    }
    case SHARE_ASSESSMENT_REQUEST: {
      return {
        ...state,
        sharingAssessment: true,
        sharedAssessment: false,
      };
    }
    case SHARE_ASSESSMENT_RESET: {
      return {
        ...state,
        sharedAssessment: false,
        sharingAssessment: false,
      };
    }
    case UPDATE_ASSESSMENT_STATS: {
      return {
        ...state,
        totalPendingAssessments: action.payload.totalPendingCount,
        totalCompletedAssessments: action.payload.totalCompletedCount,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assessmentsReducers;
