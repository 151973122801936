import {
  GET_COST_POLICIES_REQUEST,
  GET_COST_POLICIES_SUCCESS,
  GET_COST_POLICIES_FAILURE
} from "../actionTypes/cost-policies";

const initialState = {
  isFetching: false,
  costPolicyList: null,
  showCostPolicyList: true
};

const costPolicies = (state = initialState, action) => {
  switch (action.type) {
    case GET_COST_POLICIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        costPolicyList: initialState.costPolicyList
      };
    case GET_COST_POLICIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        costPolicyList: action.payload,
        showCostPolicyList: true
      };
    case GET_COST_POLICIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default costPolicies;
