import { metricsData } from "./metric-data";
import moment from "moment";

export const getMetrics = (type) => {
  const metric = metricsData.find((m) => m.type.label === type);
  if (metric) {
    return metric.metrics;
  }
};

const filteredList = (values) => {
  return values
    .filter((v, i, self) => self.indexOf(v) === i && v)
    .map((m) => m.type.label)
    .flat();
};

export const getResourceTypesByManifest = (resources) => {
  const values = Object.values(resources);
  const metrics = values.map((v) => {
    return metricsData.find((m) => {
      if (v.Type === "AWS::ElasticLoadBalancingV2::LoadBalancer") {
        return (
          m.type.value === v.Type &&
          (v.Properties.Type === m.metricType ||
            (!v.Properties.Type && m.metricType === "application"))
        );
      }
      return m.type.value === v.Type;
    });
  });
  return filteredList(metrics);
};

export const getResourceTypesByApplication = (resources) => {
  const metrics = resources.map((r) =>
    metricsData.find((m) => m.type.label === r.resourceType)
  );
  return filteredList(metrics);
};

export const setMetricsData = (data, metric) => {
  let metrics = [...data];
  const foundItem =
    metrics && metrics.find((d) => d.metric.id === metric.metric.id);
  if (foundItem) {
    const updatedItems = [];
    metrics.forEach((d) => {
      let item = d;
      if (d.metric.id === metric.metric.id) {
        item = metric;
      }
      updatedItems.push(item);
    });
    metrics = updatedItems;
  } else {
    metrics.push(metric);
  }
  return metrics;
};

export const getRangeData = (range) => {
  let format = "%H";
  let count = 12;
  let period = 1;
  switch (range) {
    case "1 Hour":
      format = "%H:%M";
      count = 20;
      period = 1;
      break;
    case "3 Hours":
      format = "%H:%M";
      count = 3;
      period = 1;
      break;
    case "5 Hours":
      format = "%H:%M";
      count = 5;
      period = 5;
      break;
    case "8 Hours":
      format = "%H:%M";
      count = 8;
      period = 5;
      break;
    case "12 Hours":
      format = "%H:%M";
      count = 12;
      period = 5;
      break;
    case "24 Hours":
      format = "%H:%M";
      count = 12;
      period = 5;
      break;
    case "1 Week":
      format = "%d";
      count = 7;
      period = 8;
      break;
    case "2 Weeks":
      format = "%d";
      count = 14;
      period = 8;
      break;
    case "4 Weeks":
      format = "%d";
      count = 24;
      period = 8;
      break;
    default:
      format = "%H";
      count = 12;
      period = 1;
      break;
  }
  return {
    format,
    count,
    period,
  };
};

export const getResourceIdByType = (type, id) => {
  let resourceId = id;
  switch (type) {
    case "Amazon Aurora":
      resourceId = resourceId.toLocaleLowerCase();
      break;
    default:
      break;
  }
  return resourceId;
};

export const getResourceId = (resource, type) => {
  return getResourceIdByType(type, resource.ResourceId);
};

export const metricsChartTooltip = (title, content, color) => {
  return `
  <div class="ag-chart-tooltip-title d-flex align-items-center justify-content-center" style="background-color: ${color}">
      ${title}
  </div>
  <div class="ag-chart-tooltip-content d-flex align-items-center justify-content-center mc-text-base-bold" style="background-color: #FFF">
      ${content}
  </div>`;
};

export const formatDateByRange = (dateString, range) => {
  const date = moment(dateString);

  if (range.split(" ")[1] === "Hour" || range.split(" ")[1] === "Hours") {
    return date.format("H:mm");
  } else if (
    range.split(" ")[1] === "Week" ||
    range.split(" ")[1] === "Weeks"
  ) {
    return date.format("DD");
  } else {
    return dateString;
  }
};

export const formatDateByRangeForTooltip = (dateString, range) => {
  const date = moment(dateString);
  return date.format("Do MMM YY, hh:mm A");
};
