export const GET_BOTS_REQUEST = "GET_BOTS_REQUEST";
export const GET_BOTS_SUCCESS = "GET_BOTS_SUCCESS";
export const GET_BOTS_FAILURE = "GET_BOTS_FAILURE";

export const GET_BOT_MANIFEST_REQUEST = "GET_BOT_MANIFEST_REQUEST";
export const GET_BOT_MANIFEST_SUCCESS = "GET_BOT_MANIFEST_SUCCESS";
export const GET_BOT_MANIFEST_FAILURE = "GET_BOT_MANIFEST_FAILURE";

export const GET_BOT_CONFIG_REQUEST = "GET_BOT_CONFIG_REQUEST";
export const GET_BOT_CONFIG_SUCCESS = "GET_BOT_CONFIG_SUCCESS";
export const GET_BOT_CONFIG_FAILURE = "GET_BOT_CONFIG_FAILURE";

export const GET_BOT_DETAILS_REQUEST = "GET_BOT_DETAILS_REQUEST";
export const GET_BOT_DETAILS_SUCCESS = "GET_BOT_DETAILS_SUCCESS";
export const GET_BOT_DETAILS_FAILURE = "GET_BOT_DETAILS_FAILURE";

export const GET_PREREQUISITES_REQUEST = "GET_PREREQUISITES_REQUEST";
export const GET_PREREQUISITES_SUCCESS = "GET_PREREQUISITES_SUCCESS";
export const GET_PREREQUISITES_FAILURE = "GET_PREREQUISITES_FAILURE";

export const LAUNCH_BOT_CONFIGURATION_REQUEST =
  "LAUNCH_BOT_CONFIGURATION_REQUEST";
export const LAUNCH_BOT_CONFIGURATION_SUCCESS =
  "LAUNCH_BOT_CONFIGURATION_SUCCESS";
export const LAUNCH_BOT_CONFIGURATION_FAILURE =
  "LAUNCH_BOT_CONFIGURATION_FAILURE";

export const UPDATE_DAY2_BOTS_SEARCH_KEY = "UPDATE_DAY2_BOTS_SEARCH_KEY";
export const SAVE_SELECTED_BOT_POLICIES = "SAVE_SELECTED_BOT_POLICIES";
export const SAVE_RUN_TIME_INTERVAL = "SAVE_RUN_TIME_INTERVAL";
export const CLEAR_BOT_CONFIGURATION = "CLEAR_BOT_CONFIGURATION";

export const OFFBOARD_BOT_REQUEST = "OFFBOARD_BOT_REQUEST";
export const OFFBOARD_BOT_SUCCESS = "OFFBOARD_BOT_SUCCESS";
export const OFFBOARD_BOT_FAILURE = "OFFBOARD_BOT_FAILURE";
export const SAVE_ALL_POLICIES = "SAVE_ALL_POLICIES";
export const SAVE_SELECTED_OFFBOARDING_TYPE = "SAVE_SELECTED_OFFBOARDING_TYPE";
export const CLEAR_SELECTED_OFFBOARDING_TYPE =
  "CLEAR_SELECTED_OFFBOARDING_TYPE";
export const CLEAR_BOT_DETAILS = "CLEAR_BOT_DETAILS";
export const SAVE_BOT_EXECUTE_NOW = "SAVE_BOT_EXECUTE_NOW";
export const SAVE_BOT_PARAMETERS_DATA = "SAVE_BOT_PARAMETERS_DATA";
export const SAVE_ACCOUNTS_THAT_NEED_VPC = "SAVE_ACCOUNTS_THAT_NEED_VPC";
