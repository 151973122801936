import {
  GET_FREE_TRIAL_SESSION_REQUEST,
  GET_FREE_TRIAL_SESSION_SUCCESS,
  GET_FREE_TRIAL_SESSION_FAILURE,
  GET_STANDARD_PLAN_SESSION_REQUEST,
  GET_STANDARD_PLAN_SESSION_SUCCESS,
  GET_STANDARD_PLAN_SESSION_FAILURE,
  GET_PREMIUM_PLAN_SESSION_REQUEST,
  GET_PREMIUM_PLAN_SESSION_SUCCESS,
  GET_PREMIUM_PLAN_SESSION_FAILURE,
  GET_TRANSACTION_DETAILS_REQUEST,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_FAILURE,
  RESET_PAYMENT_DATA
} from "../actionTypes/payment";

const initialState = {
  isFetchingFreeTrialSessionId: false,
  isFetchingStandardSessionId: false,
  isFetchingPremiumSessionId: false,
  freeTrialMetadata: null,
  freeTrialUpgradeMessage: null,
  standardSessionId: '',
  standardUpgradeMessage: null,
  premiumSessionId: '',
  premiumUpgradeMessage: null,
  isFetchingTransactionDetails: false,
  transactionDetails: {},
  planUpgradedToPremium: false,
  customerDetails: null
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREE_TRIAL_SESSION_REQUEST:
      return {
        ...state,
        isFetchingFreeTrialSessionId: true
      };
    case GET_FREE_TRIAL_SESSION_SUCCESS:
      return {
        ...state,
        isFetchingFreeTrialSessionId: false,
        freeTrialUpgradeMessage: action.payload.Message,
        freeTrialMetadata: action.payload.SubscriptionMetadata,
        customerDetails: {
          CustomerType: action.payload.CustomerType,
          SubscriptionMetadata: action.payload.SubscriptionMetadata
        }
      };
    case GET_FREE_TRIAL_SESSION_FAILURE:
      return {
        ...state,
        isFetchingFreeTrialSessionId: false,
        errorMessage: action.payload
      };
    case GET_STANDARD_PLAN_SESSION_REQUEST:
      return {
        ...state,
        isFetchingStandardSessionId: true
      };
    case GET_STANDARD_PLAN_SESSION_SUCCESS:
      return {
        ...state,
        isFetchingStandardSessionId: false,
        standardSessionId: action.payload.SessionId,
        standardUpgradeMessage: action.payload.Message
      };
    case GET_STANDARD_PLAN_SESSION_FAILURE:
      return {
        ...state,
        isFetchingStandardSessionId: false,
        errorMessage: action.payload
      };
    case GET_PREMIUM_PLAN_SESSION_REQUEST:
      return {
        ...state,
        isFetchingPremiumSessionId: true
      };
    case GET_PREMIUM_PLAN_SESSION_SUCCESS:
      return {
        ...state,
        isFetchingPremiumSessionId: false,
        premiumSessionId: action.payload.SessionId,
        premiumUpgradeMessage: action.payload.Message,
        customerDetails: {
          CustomerType: action.payload.CustomerType,
          SubscriptionMetadata: action.payload.SubscriptionMetadata
        }
      };
    case GET_PREMIUM_PLAN_SESSION_FAILURE:
      return {
        ...state,
        isFetchingPremiumSessionId: false,
        errorMessage: action.payload
      };
    case GET_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingTransactionDetails: true
      };
    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingTransactionDetails: false,
        transactionDetails: action.payload,
        planUpgradedToPremium: true
      };
    case GET_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingTransactionDetails: false,
        errorMessage: action.payload
      };
    case RESET_PAYMENT_DATA:
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
};

export default payment;
