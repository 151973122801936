import {
  GET_RESOURCE_SUMMARY_DETAILS,
  GET_APP_RESOURCES_SUMMARY_REQUEST,
  GET_APP_RESOURCES_SUMMARY_SUCCESS,
  GET_APP_RESOURCES_SUMMARY_FAILED,
  GET_ALL_RESOURCES_SUMMARY_REQUEST,
  GET_ALL_RESOURCES_SUMMARY_SUCCESS,
  GET_ALL_RESOURCES_SUMMARY_FAILED,
  GET_RESOURCE_DETAILS,
  GET_ALL_RESOURCES_REQUEST,
  GET_ALL_RESOURCES_SUCCESS,
  GET_ALL_RESOURCES_FAILED,
  CLEAR_RESOURCE_LIST,
  SAVE_RESOURCE_TYPES,
  RESET_RESOURCELIST_IS_FETCHED,
  UPDATE_RESOURCE_SEARCH_KEY,
  UPDATE_SELECTED_DEPARTMENTS,
  UPDATE_SELECTED_APPLICATIONS,
  UPDATE_SELECTED_ACCOUNTS,
  UPDATE_SELECTED_REGIONS,
  RESOURCE_EXIST_REQUEST,
  RESOURCE_EXIST_SUCCESS,
  RESOURCE_EXIST_FAILURE,
  UPDATE_ORGANIZATION_TAGS_REQUEST,
  UPDATE_ORGANIZATION_TAGS_SUCCESS,
  UPDATE_ORGANIZATION_TAGS_FAILURE,
  CLEAR_RESOURCE_EXISTS_DATA,
  CLEAR_ALL_RESOURCE_LISTS,
  CLEAR_MANAGE_TAGS_DATA,
  GET_ALL_RESOURCES_AZURE_SUCCESS,
  UPDATE_SELECTED_AZURE_SUBSCRIPTIONS,
  UPDATE_SELECTED_AZURE_REGIONS,
  CLEAR_AZURE_RESOURCE_LIST,
  SHOW_TAG_RESOURCE_ALERT,
  HIDE_TAG_RESOURCE_ALERT,
  GET_SNS_TOPIC_RESOURCES_REQUEST,
  GET_SNS_TOPIC_RESOURCES_SUCCESS,
  GET_SNS_TOPIC_RESOURCES_FAILED,
} from "../actionTypes/resource";

const initialState = {
  resourceDetails: null,
  isResourceLoading: false,
  resourceSummaryList: null,
  appResourceSummaryList: null,
  failedFetchingResourceSummary: false,
  isResourceListFetched: false,
  resourceSearchKey: "",
  filteredResourceList: null,
  fetchingFilteredResourceList: false,
  filteredResourceListFetched: false,
  fetchingFilteredResourceListFailed: false,
  selectedFilterDepartments: null,
  selectedFilterApplications: null,
  selectedFilterAccounts: null,
  selectedFilterRegions: null,
  isLoadingResourceExists: false,
  resourceExists: false,
  loadedResourceExists: false,
  failedToLoadResourceExists: false,
  updatingTags: false,
  tagsUpdated: false,
  updateTagsFailed: false,
  fetchResourcesFailed: false,
  selectedAzureFilterAccounts: null,
  selectedAzureFilterRegions: null,
  resourceListOffset: null,
  azureResourceListOffset: null,
  showResourceTagAlert: false,
  snsTopicResources: null,
  isFetchingSNSTopic: false,
  isFetchedSNSTopic: false,
  isFetchingSNSTopicFailed: false,
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCE_SUMMARY_DETAILS:
      return {
        ...state,
        resourceDetails: action.payload,
      };
    case GET_ALL_RESOURCES_SUMMARY_REQUEST:
      return {
        ...state,
        isResourceLoading: true,
        failedFetchingResourceSummary:
          initialState.failedFetchingResourceSummary,
      };
    case GET_APP_RESOURCES_SUMMARY_REQUEST:
      return {
        ...state,
        isResourceLoading: true,
        appResourceSummaryList: initialState.appResourceSummaryList,
        failedFetchingResourceSummary:
          initialState.failedFetchingResourceSummary,
      };
    case GET_ALL_RESOURCES_SUMMARY_SUCCESS:
      return {
        ...state,
        isResourceLoading: false,
        resourceSummaryList: action.payload,
      };
    case GET_APP_RESOURCES_SUMMARY_SUCCESS:
      return {
        ...state,
        isResourceLoading: false,
        appResourceSummaryList: action.payload,
      };
    case GET_ALL_RESOURCES_SUMMARY_FAILED:
      return {
        ...state,
        isResourceLoading: false,
        failedFetchingResourceSummary: true,
      };
    case GET_APP_RESOURCES_SUMMARY_FAILED:
      return {
        ...state,
        isResourceLoading: false,
        failedFetchingResourceSummary: true,
      };
    case GET_RESOURCE_DETAILS:
      return {
        ...state,
        resourceDetails: action.payload,
      };
    case GET_ALL_RESOURCES_REQUEST:
      return {
        ...state,
        isResourceLoading: true,
        fetchResourcesFailed: initialState.fetchResourcesFailed,
      };
    case GET_ALL_RESOURCES_SUCCESS:
      return {
        ...state,
        isResourceLoading: false,
        resourceList: action.payload.data,
        isResourceListFetched: true,
        resourceListOffset: action.payload.offset,
      };
    case GET_ALL_RESOURCES_FAILED:
      return {
        ...state,
        isResourceLoading: false,
        isResourceListFetched: false,
        fetchResourcesFailed: true,
      };
    case CLEAR_RESOURCE_LIST:
      return {
        ...state,
        resourceList: null,
        isResourceListFetched: false,
        resourceListOffset: initialState.resourceListOffset,
      };
    case CLEAR_AZURE_RESOURCE_LIST:
      return {
        ...state,
        azureResourceList: null,
        isResourceListFetched: false,
        azureResourceListOffset: initialState.azureResourceListOffset,
      };
    case SAVE_RESOURCE_TYPES:
      return {
        ...state,
        savedResourceTypes: action.payload,
      };
    case RESET_RESOURCELIST_IS_FETCHED:
      return {
        ...state,
        isResourceListFetched: false,
      };
    case UPDATE_RESOURCE_SEARCH_KEY:
      return {
        ...state,
        resourceSearchKey: action.payload,
      };
    case UPDATE_SELECTED_DEPARTMENTS:
      return {
        ...state,
        selectedFilterDepartments: action.payload,
      };
    case UPDATE_SELECTED_APPLICATIONS:
      return {
        ...state,
        selectedFilterApplications: action.payload,
      };
    case UPDATE_SELECTED_ACCOUNTS:
      return {
        ...state,
        selectedFilterAccounts: action.payload,
      };
    case UPDATE_SELECTED_REGIONS:
      return {
        ...state,
        selectedFilterRegions: action.payload,
      };
    case RESOURCE_EXIST_REQUEST:
      return {
        ...state,
        isLoadingResourceExists: true,
        resourceExists: initialState.resourceExists,
        loadedResourceExists: initialState.loadedResourceExists,
        failedToLoadResourceExists: initialState.loadedResourceExists,
      };
    case RESOURCE_EXIST_SUCCESS:
      return {
        ...state,
        isLoadingResourceExists: false,
        resourceExists: action.payload,
        loadedResourceExists: true,
        failedToLoadResourceExists: false,
      };
    case RESOURCE_EXIST_FAILURE: {
      return {
        ...state,
        isLoadingResourceExists: false,
        resourceExists: initialState.resourceExists,
        loadedResourceExists: true,
        failedToLoadResourceExists: true,
      };
    }
    case CLEAR_RESOURCE_EXISTS_DATA:
      return {
        ...state,
        isLoadingResourceExists: initialState.resourceExists,
        resourceExists: initialState.resourceExists,
        loadedResourceExists: initialState.loadedResourceExists,
        failedToLoadResourceExists: initialState.loadedResourceExists,
      };
    case UPDATE_ORGANIZATION_TAGS_REQUEST:
      return {
        ...state,
        updatingTags: true,
        tagsUpdated: false,
        updateTagsFailed: false,
      };
    case UPDATE_ORGANIZATION_TAGS_SUCCESS:
      return {
        ...state,
        updatingTags: false,
        tagsUpdated: true,
      };
    case UPDATE_ORGANIZATION_TAGS_FAILURE: {
      return {
        ...state,
        updatingTags: false,
        updateTagsFailed: true,
      };
    }
    case CLEAR_MANAGE_TAGS_DATA: {
      return {
        ...state,
        updatingTags: initialState.updatingTags,
        updateTagsFailed: initialState.updateTagsFailed,
        tagsUpdated: initialState.tagsUpdated,
      };
    }
    case CLEAR_ALL_RESOURCE_LISTS:
      return {
        ...state,
        resourceSummaryList: initialState.resourceSummaryList,
        filteredResourceList: initialState.filteredResourceList,
        appResourceSummaryList: initialState.appResourceSummaryList,
      };
    case GET_ALL_RESOURCES_AZURE_SUCCESS:
      return {
        ...state,
        isResourceLoading: false,
        azureResourceList: action.payload.data,
        isResourceListFetched: true,
        azureResourceListOffset: action.payload.offset,
      };
    case UPDATE_SELECTED_AZURE_SUBSCRIPTIONS:
      return {
        ...state,
        selectedAzureFilterAccounts: action.payload,
      };
    case UPDATE_SELECTED_AZURE_REGIONS:
      return {
        ...state,
        selectedAzureFilterRegions: action.payload,
      };
    case SHOW_TAG_RESOURCE_ALERT:
      return {
        ...state,
        showResourceTagAlert: true,
      };
    case HIDE_TAG_RESOURCE_ALERT:
      return {
        ...state,
        showResourceTagAlert: false,
      };
    case GET_SNS_TOPIC_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingSNSTopic: true,
        isFetchedSNSTopic: false,
        isFetchingSNSTopicFailed: false,
      };
    case GET_SNS_TOPIC_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingSNSTopic: false,
        isFetchedSNSTopic: true,
        snsTopicResources: action.payload,
      };
    case GET_SNS_TOPIC_RESOURCES_FAILED:
      return {
        ...state,
        isFetchingSNSTopicFailed: true,
        isFetchingSNSTopic: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default resourcesReducer;
