export const GET_RULES_REQUEST = "GET_RULES_REQUEST";
export const GET_RULES_SUCCESS = "GET_RULES_SUCCESS";
export const GET_RULES_FAILED = "GET_RULES_FAILED";
export const GET_EVENTS_METADATA_REQUEST = "GET_EVENTS_METADATA_REQUEST";
export const GET_EVENTS_METADATA_SUCCESS = "GET_EVENTS_METADATA_SUCCESS";
export const GET_EVENTS_METADATA_FAILED = "GET_EVENTS_METADATA_FAILED";
export const GET_RULE_CONDITION_METADATA_REQUEST =
  "GET_RULE_CONDITION_METADATA_REQUEST";
export const GET_RULE_CONDITION_METADATA_SUCCESS =
  "GET_RULE_CONDITION_METADATA_SUCCESS";
export const GET_RULE_CONDITION_METADATA_FAILED =
  "GET_RULE_CONDITION_METADATA_FAILED";
export const SAVE_RULE_REQUEST = "SAVE_RULE_REQUEST";
export const SAVE_RULE_SUCCESS = "SAVE_RULE_SUCCESS";
export const SAVE_RULE_FAILED = "SAVE_RULE_FAILED";
export const DELETE_RULE_REQUEST = "DELETE_RULE_REQUEST";
export const DELETE_RULE_SUCCESS = "DELETE_RULE_SUCCESS";
export const DELETE_RULE_FAILED = "DELETE_RULE_FAILED";
export const EDITING_RULE = "EDITING_RULE";
export const CLEAR_RULES_DATA = "CLEAR_RULES_DATA";
export const EXECUTION_HISTORY_REQUEST = "EXECUTION_HISTORY_REQUEST";
export const EXECUTION_HISTORY_SUCCESS = "EXECUTION_HISTORY_SUCCESS";
export const EXECUTION_HISTORY_FAILED = "EXECUTION_HISTORY_FAILED";

