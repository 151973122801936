import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AFFECTED_RESOURCES,
  EVENT_TIMELINES,
  EVENTS,
  HEALTH_EVENTS_BASE_URL,
} from "../../../../config/service";
import { axiosBaseQuery } from "../../../../config/customBaseQuery";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../helpers/apiService";

export const fetchAffectedResources = ({
  eventId,
  limit = 20,
  offset = 0,
  searchKey,
}) => {
  let params;
  params = searchKey ? `?EntityValue=${searchKey}` : "?";
  params = params + `${searchKey ? "&" : ""}Offset=${offset}`;
  params = params + `&Limit=${limit}`;

  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${HEALTH_EVENTS_BASE_URL}${AFFECTED_RESOURCES?.replace("{eventId}", eventId)}${params}`
  );
};

export const fetchEventTimelines = ({ eventId, limit = 20, offset = 0 }) => {
  let params = `?Offset=${offset}&Limit=${limit}`;
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${HEALTH_EVENTS_BASE_URL}${EVENT_TIMELINES?.replace("{eventId}", eventId)}${params}`
  );
};

export const healthEventDetailsApi = createApi({
  reducerPath: "healthEventDetails",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getHealthEventDetails: builder.query({
      query: (eventId) => {
        return {
          url: `${HEALTH_EVENTS_BASE_URL}${EVENTS}/${eventId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetHealthEventDetailsQuery } = healthEventDetailsApi;
