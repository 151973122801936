import i18n from "../../../config/locale/i18n";
import { CFN, TERRAFORM } from "../../../utils/app-constants.json";
import { isArrayWithLength } from "../../../utils/array-methods";

export const blueprintsUrl = "/app/services/catalog/";
export const sharedBlueprintsUrl = "/app/services/shared-catalog/";
export const bundledTaskUpdateMsg = "Task changes saved.";
export const bundledTaskUpdateFailed = "Task changes failed.";
export const deletingBundledTask = "Deleting task.";
export const bundledTaskDeleted = "Task deleted.";
export const deleteBundledTaskFailed = "Delete task failed.";
export const bundledTaskAdded = "Task added.";
export const addBundledTaskFailed = "Add task failed.";
export const elasticSearchBlueprintIDs = [
  "10d9a58e-3cc8-51ee-bfd9-8d39da171138",
  "7a8e592d-5e37-47ac-877d-c44a817dafc4",
];
export const bringYourOwnAppBlueprintIDs = [
  "08ba970e-c190-5bd5-8576-df5ac27bdf0b",
];
export const supportedFileFormats = {
  blueprint: ".json,.yaml,.yml",
  automationDocument: ".json,.yaml,.yml",
  pythonScript: ".py",
  saml: ".xml",
};

export const blueprintTypes = {
  CLOUDFORMATION: CFN,
  TERRAFORM: TERRAFORM,
};

export const blueprintTypesList = [
  {
    label: i18n.t("blueprints:blueprintType.cloudformation"),
    value: blueprintTypes.CLOUDFORMATION,
  },
  {
    label: i18n.t("blueprints:blueprintType.terraform"),
    value: blueprintTypes.TERRAFORM,
  },
];

export const terraformVersions = {
  VERSION_1_3: "1.3",
};

export const terraformVersionsList = [
  {
    label: i18n.t("blueprints:terraform.version.1.3"),
    value: terraformVersions.VERSION_1_3,
  },
];

export const BLUEPRINT_TYPE_OPTIONS = [
  { label: i18n.t("blueprints:cfnLabel"), value: CFN, checked: false },
  { label: TERRAFORM, value: TERRAFORM, checked: false },
];

export const BLUEPRINT_FILTER_TYPES = {
  CATEGORY: i18n.t("blueprints:categoryLabel"),
  TYPE: i18n.t("common:typeLabel"),
};

export const BLUEPRINT_FILTERS = [
  {
    label: BLUEPRINT_FILTER_TYPES.CATEGORY,
    value: BLUEPRINT_FILTER_TYPES.CATEGORY,
    isOpen: true,
    collapsible: false,
    options: [],
  },
  {
    label: BLUEPRINT_FILTER_TYPES.TYPE,
    value: BLUEPRINT_FILTER_TYPES.TYPE,
    isOpen: true,
    collapsible: false,
    options: BLUEPRINT_TYPE_OPTIONS,
  },
];

export const BLUEPRINT_DATA = {
  customService: i18n.t("blueprints:customServices"),
  customer: i18n.t("blueprints:customerLabel"),
};

export const DEFAULT_TERRAFORM_PARAMETER_GROUP = "Default Configuration";
export const DEFAULT_FILE_SELECTED_ID = "defaultFileSelectedId";
export const DEFAULT_FOLDER_SELECTED_ID = "defaultFolderSelectedId";

export const SINGLE_BLUEPRINT = "singleBlueprint";

export const getFirstFile = (folderStrucuture) => {
  let firstFile;
  for (let i = 0; i < folderStrucuture.length; i++) {
    if (!folderStrucuture[i].isFolder) {
      firstFile = folderStrucuture[i];
      firstFile.id = DEFAULT_FILE_SELECTED_ID;
      return firstFile;
    } else {
      const folder = folderStrucuture[i];
      folder.id = `${DEFAULT_FOLDER_SELECTED_ID}-${folder.Name}`;
      firstFile = getFirstFile(folderStrucuture[i].Children);
    }
  }
  return firstFile;
};

export const getFolderIds = (folderStrucuture) => {
  if (!isArrayWithLength(folderStrucuture)) return;
  let arr = [];
  for (let i = 0; i < folderStrucuture.length; i++) {
    if (!folderStrucuture[i].isFolder) {
      return arr;
    } else {
      arr.push(`${DEFAULT_FOLDER_SELECTED_ID}-${folderStrucuture[i].Name}`);
      arr = [...arr, ...getFolderIds(folderStrucuture[i].Children)];
    }
  }
  return arr;
};
