import {
  FETCHING_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_RESOURCES,
  ORGANIZATION_RESOURCES_LIST,
} from "../actionTypes/organization";

const initialState = {
  isFetching: false,
  organizationDetails: null,
  organizationResources: null,
  listOrganizationResources: null,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ORGANIZATION_DETAILS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ORGANIZATION_DETAILS:
      return {
        ...state,
        isFetching: false,
        organizationDetails: action.payload,
      };
    case GET_ORGANIZATION_RESOURCES:
      return {
        ...state,
        organizationResources: action.payload,
      };
    case ORGANIZATION_RESOURCES_LIST:
      return {
        ...state,
        listOrganizationResources: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default organizationReducer;
