import {
  GET_SCAN_SCHEDULED_TASKS_REQUEST,
  GET_SCAN_SCHEDULED_TASKS_SUCCESS,
  GET_SCAN_SCHEDULED_TASKS_FAILURE,
  GET_SCAN_JOBS_REQUEST,
  GET_SCAN_JOBS_SUCCESS,
  GET_SCAN_JOBS_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  CLEAR_DELETE_SCHEDULE_DATA,
} from "../actionTypes/vulnerabilityScanning";

const initialState = {
  isFetchingScanScheduledTasks: false,
  scanScheduleTaskList: null,
  isFetchingScanJobs: false,
  scanExecutionHistoryList: null,
  deletingSchedule: false,
  deletedSchedule: false,
  deleteScheduleMessage: null,
  scanScheduleTaskOffset: null,
  scanExecutionHistoryOffset: null,
};

const vulnerabilityScanning = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCAN_SCHEDULED_TASKS_REQUEST:
      return {
        ...state,
        isFetchingScanScheduledTasks: true,
      };
    case GET_SCAN_SCHEDULED_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingScanScheduledTasks: false,
        scanScheduleTaskList: action.payload.tasks,
        scanScheduleTaskOffset: action.payload.offset,
      };
    case GET_SCAN_SCHEDULED_TASKS_FAILURE:
      return {
        ...state,
        isFetchingScanScheduledTasks: false,
        scanScheduleTaskList: action.payload,
      };
    case GET_SCAN_JOBS_REQUEST:
      return {
        ...state,
        isFetchingScanJobs: true,
      };
    case GET_SCAN_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingScanJobs: false,
        scanExecutionHistoryList: action.payload.tasks,
        scanExecutionHistoryOffset: action.payload.offset,
      };
    case GET_SCAN_JOBS_FAILURE:
      return {
        ...state,
        isFetchingScanJobs: false,
        scanExecutionHistoryList: action.payload,
      };
    case DELETE_SCHEDULE_REQUEST:
      return {
        deletingSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        deletingSchedule: false,
        deletedSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_FAILURE:
      return {
        deletingSchedule: false,
        deletedSchedule: false,
        deleteScheduleMessage: action.payload,
      };
    case CLEAR_DELETE_SCHEDULE_DATA:
      return {
        ...state,
        deletingSchedule: initialState.deletedSchedule,
        deletedSchedule: initialState.deletedSchedule,
        deleteScheduleMessage: initialState.deleteScheduleMessage,
      };
    default:
      return {
        ...state,
      };
  }
};

export default vulnerabilityScanning;
