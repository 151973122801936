import {
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  SAVE_USER_DATA_REQUEST,
  SAVE_USER_DATA_SUCCESS,
  SAVE_USER_DATA_FAILURE,
  RESET_SAVE_USER_DATA,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
} from "../actionTypes/user-data";

const initialState = {
  isFetching: false,
  isSaving: false,
  userDataSaved: false,
  userDataLoaded: false,
  userData: {
    Accounts: [],
    Regions: [],
  },
  savingUsers: false,
  userSaved: false,
};

export default function userData(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case SAVE_USER_DATA_REQUEST:
      return {
        ...state,
        isSaving: true,
        userDataSaved: false,
      };
    case SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        isSaving: false,
        userDataSaved: true,
      };
    case SAVE_USER_DATA_FAILURE:
      return {
        ...state,
        isSaving: false,
        errorMessage: action.payload,
        userDataSaved: false,
      };
    case RESET_SAVE_USER_DATA:
      return {
        ...state,
        isSaving: initialState.isSaving,
        userDataSaved: initialState.userDataSaved,
        errorMessage: initialState.errorMessage,
      };

    case SAVE_USER_REQUEST:
      return {
        ...state,
        savingUsers: true,
        userSaved: initialState.userSaved,
      };

    case SAVE_USER_SUCCESS:
      return {
        ...state,
        savingUsers: false,
        userSaved: true,
      };

    case SAVE_USER_FAILURE:
      return {
        ...state,
        savingUsers: false,
      };
    default:
      return state;
  }
}
