export const GET_FREE_TRIAL_SESSION_REQUEST = "GET_FREE_TRIAL_SESSION_REQUEST";
export const GET_FREE_TRIAL_SESSION_SUCCESS = "GET_FREE_TRIAL_SESSION_SUCCESS";
export const GET_FREE_TRIAL_SESSION_FAILURE =
  "GET_PREMIUM_PLAN_SESSION_FAILURE";
export const GET_STANDARD_PLAN_SESSION_REQUEST =
  "GET_STANDARD_PLAN_SESSION_REQUEST";
export const GET_STANDARD_PLAN_SESSION_SUCCESS =
  "GET_STANDARD_PLAN_SESSION_SUCCESS";
export const GET_STANDARD_PLAN_SESSION_FAILURE =
  "GET_STANDARD_PLAN_SESSION_FAILURE";
export const GET_PREMIUM_PLAN_SESSION_REQUEST =
  "GET_PREMIUM_PLAN_SESSION_REQUEST";
export const GET_PREMIUM_PLAN_SESSION_SUCCESS =
  "GET_PREMIUM_PLAN_SESSION_SUCCESS";
export const GET_PREMIUM_PLAN_SESSION_FAILURE =
  "GET_PREMIUM_PLAN_SESSION_FAILURE";
export const GET_TRANSACTION_DETAILS_REQUEST =
  "GET_TRANSACTION_DETAILS_REQUEST";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_FAILURE =
  "GET_TRANSACTION_DETAILS_FAILURE";
export const RESET_PAYMENT_DATA = "RESET_PAYMENT_DATA";
