import { isArrayWithLength } from "../utils/array-methods";

export const checkAdminPermission = (account, allAccounts) => {
  let hasAdminPermission = false;
  const accountNumber = (account && account.accountNumber) || account;
  if (allAccounts) {
    const foundAccount =
      isArrayWithLength(allAccounts) &&
      allAccounts.find((acc) => acc.accountNumber === accountNumber);
    hasAdminPermission = foundAccount && foundAccount.adminPrivilege;
  }
  return allAccounts ? hasAdminPermission : account && account.adminPrivilege;
};
