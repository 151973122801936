export const SAVE_CREATE_BLUEPRINT_NAME = "SAVE_CREATE_BLUEPRINT_NAME";
export const SAVE_CREATE_BLUEPRINT_DESCRIPTION =
  "SAVE_CREATE_BLUEPRINT_DESCRIPTION";
export const SAVE_CREATE_BLUEPRINT_TYPE = "SAVE_CREATE_BLUEPRINT_TYPE";
export const SAVE_CREATE_BLUEPRINT_CATEGORY = "SAVE_CREATE_BLUEPRINT_CATEGORY";
export const SAVE_CREATE_BLUEPRINT_DEPARTMENTS =
  "SAVE_CREATE_BLUEPRINT_DEPARTMENTS";
export const SAVE_UPLOAD_TEMPLATE_BY_EDITOR = "SAVE_UPLOAD_TEMPLATE_BY_EDITOR";
export const SAVE_UPLOAD_TEMPLATE_FILES = "SAVE_UPLOAD_TEMPLATE_FILES";
export const SAVE_UPLOAD_TEMPLATE_TEXT = "SAVE_UPLOAD_TEMPLATE_TEXT";
export const SAVE_UPLOAD_TEMPLATE_FILES_PARENT =
  "SAVE_UPLOAD_TEMPLATE_FILES_PARENT";
export const SAVE_PARAMETER_MANIFEST = "SAVE_PARAMETER_MANIFEST";
export const CLEAR_MANIFEST_EDITED = "CLEAR_MANIFEST_EDITED";
export const SAVE_ALL_PARAMETERS = "SAVE_ALL_PARAMETERS";
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const VALIDATE_TEMPLATE_FILE_UPLOAD_REQUEST =
  "VALIDATE_TEMPLATE_FILE_UPLOAD_REQUEST";
export const VALIDATE_TEMPLATE_FILE_UPLOAD_SUCCESS =
  "VALIDATE_TEMPLATE_FILE_UPLOAD_SUCCESS";
export const VALIDATE_TEMPLATE_FILE_UPLOAD_FAILURE =
  "VALIDATE_TEMPLATE_FILE_UPLOAD_FAILURE";
export const VALIDATE_TEMPLATE_TEXT_EDITOR_REQUEST =
  "VALIDATE_TEMPLATE_TEXT_EDITOR_REQUEST";
export const VALIDATE_TEMPLATE_TEXT_EDITOR_SUCCESS =
  "VALIDATE_TEMPLATE_TEXT_EDITOR_SUCCESS";
export const VALIDATE_TEMPLATE_TEXT_EDITOR_FAILURE =
  "VALIDATE_TEMPLATE_TEXT_EDITOR_FAILURE";
export const SAVE_VALIDATION_REQUEST = "SAVE_VALIDATION_REQUEST";
//CONFIGURE PERMISSIONS
export const SAVE_SELECTED_ROLE = "SAVE_SELECTED_ROLE";
export const GET_SELECTED_ROLE_DETAILS_REQUEST =
  "GET_SELECTED_ROLE_DETAILS_REQUEST";
export const GET_SELECTED_ROLE_DETAILS_SUCCESS =
  "GET_SELECTED_ROLE_DETAILS_SUCCESS";
export const GET_SELECTED_ROLE_DETAILS_FAILURE =
  "GET_SELECTED_ROLE_DETAILS_FAILURE";
export const SAVE_ROLE_TEMPLATE = "SAVE_ROLE_TEMPLATE";
export const SAVE_NEW_ROLE_TEMPLATE = "SAVE_NEW_ROLE_TEMPLATE";
export const GET_APPENDED_ROLE_TEMPLATE_REQUEST =
  "GET_APPENDED_ROLE_TEMPLATE_REQUEST";
export const GET_APPENDED_ROLE_TEMPLATE_SUCCESS =
  "GET_APPENDED_ROLE_TEMPLATE_SUCCESS";
export const GET_APPENDED_ROLE_TEMPLATE_FAILURE =
  "GET_APPENDED_ROLE_TEMPLATE_FAILURE";
export const SKIP_PERMISSIONS = "SKIP_PERMISSIONS"
//CREATE BLUEPRINT
export const CREATE_BLUEPRINT_REQUEST = "CREATE_BLUEPRINT_REQUEST";
export const CREATE_BLUEPRINT_SUCCESS = "CREATE_BLUEPRINT_SUCCESS";
export const CREATE_BLUEPRINT_FAILURE = "CREATE_BLUEPRINT_FAILURE";
export const EDIT_BLUEPRINT_REQUEST = "EDIT_BLUEPRINT_REQUEST";
export const EDIT_BLUEPRINT_SUCCESS = "EDIT_BLUEPRINT_SUCCESS";
export const EDIT_BLUEPRINT_FAILURE = "EDIT_BLUEPRINT_FAILURE";
export const CREATE_BLUEPRINT_ID_REQUEST = "CREATE_BLUEPRINT_ID_REQUEST";
export const CREATE_BLUEPRINT_ID_SUCCESS = "CREATE_BLUEPRINT_ID_SUCCESS";
export const CREATE_BLUEPRINT_ID_FAILURE = "CREATE_BLUEPRINT_ID_FAILURE";
export const SET_BLUEPRINT_ID = "SET_BLUEPRINT_ID";
export const RESET_BLUEPRINT_ID = "RESET_BLUEPRINT_ID";
export const TEMPLATE_UPDATED = "TEMPLATE_UPDATED";
export const RESET_CREATE_BLUEPRINT_DATA = "RESET_CREATE_BLUEPRINT_DATA";
export const SAVE_BLUEPRINT_DETAILS = "SAVE_BLUEPRINT_DETAILS";
export const HIDE_APPENDED_ROLE_TEXT = "HIDE_APPENDED_ROLE_TEXT";
export const SET_ROLE_SELECTION = "SET_ROLE_SELECTION";
export const PERMISSION_HAS_ERRORS = "PERMISSION_HAS_ERRORS";
export const UPDATE_BLUEPRINT_MONITORING_METRICS_REQUEST =
  "UPDATE_BLUEPRINT_MONITORING_METRICS_REQUEST";
export const UPDATE_BLUEPRINT_MONITORING_METRICS_SUCCESS =
  "UPDATE_BLUEPRINT_MONITORING_METRICS_SUCCESS";
export const UPDATE_BLUEPRINT_MONITORING_METRICS_FAILURE =
  "UPDATE_BLUEPRINT_MONITORING_METRICS_FAILURE";
export const GET_SELECTED_BLUEPRINT_ROLE_DETAILS_REQUEST =
  "GET_SELECTED_BLUEPRINT_ROLE_DETAILS_REQUEST";
export const GET_SELECTED_BLUEPRINT_ROLE_DETAILS_SUCCESS =
  "GET_SELECTED_BLUEPRINT_ROLE_DETAILS_SUCCESS";
export const GET_SELECTED_BLUEPRINT_ROLE_DETAILS_FAILURE =
  "GET_SELECTED_BLUEPRINT_ROLE_DETAILS_FAILURE";
export const SET_BLUEPRINT_ROLE_EDITOR_DATA = "SET_BLUEPRINT_ROLE_EDITOR_DATA";
export const UPDATE_BLUEPRINT_PERMISSIONS_REQUEST =
  "UPDATE_BLUEPRINT_PERMISSIONS_REQUEST";
export const UPDATE_BLUEPRINT_PERMISSIONS_SUCCESS =
  "UPDATE_BLUEPRINT_PERMISSIONS_SUCCESS";
export const UPDATE_BLUEPRINT_PERMISSIONS_FAILURE =
  "UPDATE_BLUEPRINT_PERMISSIONS_FAILURE";
export const SET_BLUEPRINT_DETAILS = "SET_BLUEPRINT_DETAILS";
export const SET_BLUEPRINT_TEMPLATES = "SET_BLUEPRINT_TEMPLATES";
export const GET_UPLOADED_TEMPLATE_REQUEST = "GET_UPLOADED_TEMPLATE_REQUEST";
export const GET_UPLOADED_TEMPLATE_SUCCESS = "GET_UPLOADED_TEMPLATE_SUCCESS";
export const GET_UPLOADED_TEMPLATE_FAILURE = "GET_UPLOADED_TEMPLATE_FAILURE";
export const GET_BLUEPRINT_COST_REQUEST = "GET_BLUEPRINT_COST_REQUEST";
export const GET_BLUEPRINT_COST_SUCCESS = "GET_BLUEPRINT_COST_SUCCESS";
export const GET_BLUEPRINT_COST_FAILURE = "GET_BLUEPRINT_COST_FAILURE";
export const SET_BLUEPRINT_COST_ID = "SET_BLUEPRINT_COST_ID";
export const CLEAR_BLUEPRINT_COST_DATA = "CLEAR_BLUEPRINT_COST_DATA";
export const SET_COST_CALCULATE_POPUP = "SET_COST_CALCULATE_POPUP";
export const UPDATE_BLUEPRINT_PARAMETERS_REQUEST =
  "UPDATE_BLUEPRINT_PARAMETERS_REQUEST";
export const UPDATE_BLUEPRINT_PARAMETERS_SUCCESS =
  "UPDATE_BLUEPRINT_PARAMETERS_SUCCESS";
export const UPDATE_BLUEPRINT_PARAMETERS_FAILURE =
  "UPDATE_BLUEPRINT_PARAMETERS_FAILURE";
export const RESET_CREATE_CATEGORY_DATA = "RESET_CREATE_CATEGORY_DATA";

// terraform
export const SAVE_TERRAFORM_FILES_PERCENTAGE =
  "SAVE_TERRAFORM_FILES_PERCENTAGE";
export const SAVE_TERRAFORM_FILES_STATUS = "SAVE_TERRAFORM_FILES_STATUS";
export const CLEAR_TERRAFORM_FILE_UPLOAD = "CLEAR_TERRAFORM_FILE_UPLOAD";
export const CLEAR_BLUEPRINT_MANIFEST = "CLEAR_BLUEPRINT_MANIFEST";

// code view
export const TF_CODE_FILE_FETCHING = "TF_CODE_FILE_FETCHING";
export const TF_CODE_FILE_LOADING = "TF_CODE_FILE_LOADING";
export const TF_CODE_FILE_FETCHED = "TF_CODE_FILE_FETCHED";
export const TF_CODE_FILE_FETCH_FAILED = "TF_CODE_FILE_FETCH_FAILED";

// terraform
export const TF_SAVE_UPLOADED_FILE = "TF_SAVE_UPLOADED_FILE";
export const SAVE_CREATE_BLUEPRINT_TERRAFORM_VERSION =
  "SAVE_CREATE_BLUEPRINT_TERRAFORM_VERSION";
export const GET_TERRAFORM_PRESIGNED_URL_REQUEST =
  "GET_TERRAFORM_PRESIGNED_URL_REQUEST";
export const GET_TERRAFORM_PRESIGNED_URL_SUCCESS =
  "GET_TERRAFORM_PRESIGNED_URL_SUCCESS";
export const GET_TERRAFORM_PRESIGNED_URL_FAILURE =
  "GET_TERRAFORM_PRESIGNED_URL_FAILURE";
export const GET_INFRA_SETUP_INFO_REQUEST = "GET_INFRA_SETUP_INFO_REQUEST";
export const GET_INFRA_SETUP_INFO_SUCCESS = "GET_INFRA_SETUP_INFO_SUCCESS";
export const GET_INFRA_SETUP_INFO_FAILURE = "GET_INFRA_SETUP_INFO_FAILURE";
export const CLEAR_INFRA_SETUP_INFO = "CLEAR_INFRA_SETUP_INFO";
