import {
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  GET_DEPT_ACCOUNTS_REQUEST,
  GET_DEPT_ACCOUNTS_SUCCESS,
  GET_DEPT_ACCOUNTS_FAILURE,
  GET_STANDBY_ACCOUNTS_REQUEST,
  GET_STANDBY_ACCOUNTS_SUCCESS,
  GET_STANDBY_ACCOUNTS_FAILURE,
  GET_AZURE_SUBSCRIPTIONS_SUCCESS,
  GET_AZURE_SUBSCRIPTIONS_REQUEST,
  GET_AZURE_SUBSCRIPTIONS_FAILURE,
} from "../actionTypes/accounts";

const initialState = {
  isFetching: false,
  hasAccountsFetchApiBeenCalled: false,
  accountList: null,
  accountsFetchingFailed: false,
  errorMessageAccounts: null,
  isFetchingDeptAccs: false,
  deptAccountList: null,
  deptAccountsFailed: false,
  isFetchingStandbyAccs: false,
  standbyAccountList: null,
  standbyAccountFailed: false,
  isFetchingAzureSubscriptions: false,
  azureSubscriptionsList: null,
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_REQUEST:
      return {
        ...state,
        hasAccountsFetchApiBeenCalled: true,
        isFetching: true,
        accountsFetchingFailed: initialState.accountsFetchingFailed,
        errorMessageAccounts: initialState.errorMessageAccounts,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountList: action.payload,
      };
    case GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        accountsFetchingFailed: true,
        errorMessageAccounts: action.payload,
      };
    case GET_DEPT_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetchingDeptAccs: true,
        deptAccountList: initialState.deptAccountList,
        deptAccountsFailed: initialState.deptAccountsFailed,
      };

    case GET_DEPT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetchingDeptAccs: false,
        deptAccountList: action.payload,
      };
    case GET_DEPT_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetchingDeptAccs: false,
        errorMessageDeptAcc: action.payload,
        deptAccountsFailed: true,
      };
    case GET_STANDBY_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetchingStandbyAccs: true,
      };
    case GET_STANDBY_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetchingStandbyAccs: false,
        standbyAccountList: action.payload,
      };
    case GET_STANDBY_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetchingStandbyAccs: false,
        standbyAccountFailed: true,
      };
    case GET_AZURE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isFetchingAzureSubscriptions: true,
      };
    case GET_AZURE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingAzureSubscriptions: false,
        azureSubscriptionsList: action.payload,
      };
    case GET_AZURE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isFetchingAzureSubscriptions: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default accounts;
