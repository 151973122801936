import i18n from "../../../config/locale/i18n";

export const featureList = [
  {
    stepNumber: "1",
    id: "Visibility",
    name: i18n.t("firstRun:continuousVisibility"),
    heading: i18n.t("firstRun:visibilityHeading"),
    subHeading: i18n.t("firstRun:visibilitySubHeading"),
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("common:next"),
    isFree: true,
    image: "/images/dashboard/resource-overview.png",
    featureName: i18n.t("firstRun:continuousVisibility"),
  },
  {
    stepNumber: "2",
    id: "WellArchitectedReview",
    name: i18n.t("firstRun:warReview"),
    heading: i18n.t("firstRun:warReviewHeading"),
    subHeading: i18n.t("firstRun:warReviewSubHeading"),
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: true,
    options: [
      {
        id: "WellArchitectedReviewRemediation",
        text: i18n.t("firstRun:remediateIssues"),
        isFree: false,
        selected: true,
        featureName: i18n.t("common:warReviewRemediation"),
      },
    ],
    image: "/images/dashboard/assessment-details.png",
    freeFeatureText: i18n.t("firstRun:assessmentText"),
    premiumFeatureText: i18n.t("firstRun:remediationText"),
    premiumFeatureText2: i18n.t("firstRun:noCardRequired"),
    featureName: i18n.t("common:warReview"),
  },
  {
    stepNumber: "3",
    id: "SecurityBot",
    name: i18n.t("firstRun:security"),
    heading: i18n.t("firstRun:securityHeading"),
    subHeading: i18n.t("firstRun:securitySubHeading"),
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: true,
    options: [
      {
        id: "SecurityBotRemediation",
        text: i18n.t("firstRun:remediateIssues"),
        isFree: false,
        selected: true,
        featureName: i18n.t("tasks:securityRemediations"),
      },
    ],
    image: "/images/dashboard/security-posture.png",
    freeFeatureText: i18n.t("firstRun:assessmentText"),
    premiumFeatureText: i18n.t("firstRun:remediationText"),
    premiumFeatureText2: i18n.t("firstRun:noCardRequired"),
    featureName: i18n.t("common:securityAssessment"),
  },
  {
    stepNumber: "4",
    id: "ComplianceBot",
    name: i18n.t("firstRun:compliance"),
    heading: i18n.t("firstRun:complianceHeading"),
    subHeading: i18n.t("firstRun:complianceSubHeading"),
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: true,
    options: [
      {
        id: "ComplianceBotRemediation",
        text: i18n.t("firstRun:remediateIssues"),
        isFree: false,
        selected: true,
        featureName: i18n.t("common:complianceRemediations"),
      },
    ],
    image: "/images/dashboard/compliance-assessment.png",
    freeFeatureText: i18n.t("firstRun:assessmentText"),
    premiumFeatureText: i18n.t("firstRun:remediationText"),
    premiumFeatureText2: i18n.t("firstRun:noCardRequired"),
    featureName: i18n.t("common:complianceAssessment"),
  },
  {
    stepNumber: "5",
    id: "CloudGorvernance",
    name: i18n.t("firstRun:cloudGovernanceStepName"),
    heading: i18n.t("firstRun:cloudGovernanceHeading"),
    subHeading: i18n.t("firstRun:cloudGovernanceSubHeading"),
    stepLists: [
      i18n.t("firstRun:cloudGovernanceList1"),
      i18n.t("firstRun:cloudGovernanceList2"),
      i18n.t("firstRun:cloudGovernanceList3"),
      i18n.t("firstRun:cloudGovernanceList4"),
    ],

    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: false,
    image: "/images/dashboard/governance.png",
    featureName: i18n.t("firstRun:cloudGovernance"),
  },
  {
    stepNumber: "6",
    id: "ServerManagement",
    name: i18n.t("firstRun:serverManagement"),
    heading: i18n.t("firstRun:serverManagementHeading"),
    subHeading: i18n.t("firstRun:serverManagementSubHeading"),
    stepLists: [
      i18n.t("firstRun:serverManagementList1"),
      i18n.t("firstRun:serverManagementList2"),
      i18n.t("firstRun:serverManagementList3"),
      i18n.t("firstRun:serverManagementList4"),
    ],
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: false,
    image: "/images/dashboard/server-management.png",
    featureName: i18n.t("firstRun:serverManagement"),
  },

  {
    stepNumber: "7",
    id: "ApplicationManagement",
    name: i18n.t("firstRun:applicationManagement"),
    heading: i18n.t("firstRun:applicationManagementHeading"),
    subHeading: i18n.t("firstRun:applicationManagementSubHeading"),
    stepLists: [
      i18n.t("firstRun:applicationManagementList1"),
      i18n.t("firstRun:applicationManagementList2"),
      i18n.t("firstRun:applicationManagementList3"),
      i18n.t("firstRun:applicationManagementList4"),
    ],
    buttonName: i18n.t("common:next"),
    skipThisName: i18n.t("firstRun:skipThis"),
    isFree: false,
    image: "/images/dashboard/application.png",
    featureName: i18n.t("firstRun:applicationManagement"),
  },
  {
    stepNumber: "8",
    name: i18n.t("firstRun:review"),
    heading: i18n.t("firstRun:reviewHeading"),
    lastStep: true,
    image: "/images/dashboard/server-management.png",
  },
];
