import {
  clearDataAction,
  CRUDAction,
  DATA_TYPES,
  saveDataAction,
} from "../../../actions";
import { UPDATE, CREATE, GET } from "../../../utils/action-types.json";
import {
  updateMFAConfigApi,
  initiateMFASetupApi,
  registerMFADeviceApi,
  resetMFAConfigApi,
  verifyMFAApi,
} from "../services/mfa";
import {
  ORG_MFA_CONFIG,
  INITIATE_MFA_SETUP,
  REGISTER_MFA_DEVICE,
  USER_MFA_CONFIG,
  VERIFY_MFA,
} from "../../../utils/action-names.json";
import i18n from "../../../config/locale/i18n";
import { preProcessMFADetails } from "../utils";
import {
  handleDispatchLogin,
  updateMFAConfigData,
} from "../../authentication/actions/auth";

export const updateMFAConfig = (params) => async (dispatch) => {
  const updatedParams = {
    MFAEnforcement: params.mfaEnforcementType,
    MFAEnabled: params.isMFAEnabled,
  };
  return await CRUDAction({
    type: UPDATE,
    name: ORG_MFA_CONFIG,
    dispatch,
    service: updateMFAConfigApi,
    params: updatedParams,
    preProcessFunction: () => updateMFAConfigData(params),
    dispatchPreprocess: true,
  });
};

export const resetUpdateMFAConfigData = () => async (dispatch) => {
  return clearDataAction({
    name: ORG_MFA_CONFIG,
    dispatch,
  });
};

export const dispatchMFAConfigured = (error) => (dispatch) => {
  if (error.status === 400) {
    saveDataAction({
      name: INITIATE_MFA_SETUP,
      dispatch,
      payload: { mfaConfigured: true },
    });
  }
};

export const initiateMFASetup = () => async (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const params = { AccessToken: accessToken };
  return await CRUDAction({
    type: GET,
    name: INITIATE_MFA_SETUP,
    dispatch,
    service: initiateMFASetupApi,
    showToaster: false,
    preProcessFunction: preProcessMFADetails,
    dataType: DATA_TYPES.OBJECT,
    dispatchOnError: dispatchMFAConfigured,
    params,
  });
};

export const registerMFADevice = (params) => async (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const updatedParams = { ...params, AccessToken: accessToken };
  return await CRUDAction({
    type: CREATE,
    name: REGISTER_MFA_DEVICE,
    dispatch,
    service: registerMFADeviceApi,
    params: updatedParams,
  });
};

export const clearResetRegisterMFAData = () => (dispatch) => {
  return clearDataAction({
    name: REGISTER_MFA_DEVICE,
    dispatch,
  });
};

export const verifyMFA = (params, username) => async (dispatch) => {
  return await CRUDAction({
    type: CREATE,
    name: VERIFY_MFA,
    dispatch,
    service: verifyMFAApi,
    params,
    dispatchPreprocess: true,
    preProcessFunction: (result) => handleDispatchLogin(result, username),
  });
};

export const resetMFAConfig =
  ({ ID, Name }) =>
  async (dispatch) => {
    return await CRUDAction({
      type: UPDATE,
      name: USER_MFA_CONFIG,
      dispatch,
      service: resetMFAConfigApi,
      successMessage: i18n.t("mfa:successfullyReset", { name: Name }),
      params: ID,
    });
  };

export const clearResetMFAConfigData = () => (dispatch) => {
  return clearDataAction({
    name: USER_MFA_CONFIG,
    dispatch,
  });
};
