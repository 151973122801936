import {
  CREATE_SERVER_GROUP_REQUEST,
  CREATE_SERVER_GROUP_SUCCESS,
  CREATE_SERVER_GROUP_FAILURE,
  SAVE_SERVER_GROUP_DETAILS,
  RESET_SERVER_GROUP_DETAILS,
  GET_SERVER_GROUP_DETAILS_REQUEST,
  GET_SERVER_GROUP_DETAILS_SUCCESS,
  GET_SERVER_GROUP_DETAILS_FAILURE,
  EDIT_SERVER_GROUP_REQUEST,
  EDIT_SERVER_GROUP_SUCCESS,
  EDIT_SERVER_GROUP_FAILURE,
  DELETE_SERVER_GROUP_REQUEST,
  DELETE_SERVER_GROUP_SUCCESS,
  DELETE_SERVER_GROUP_FAILURE,
} from "../actionTypes/server-group";

const initialState = {
  isCreating: false,
  isDeleting: false,
  savedServerGroupDetails: {
    serverGroupName: "",
    serverGroupDescription: "",
  },
  serverGroupCreated: false,
  serverGroupDeleted: false,
  isFetchingDetails: false,
  serverGroupDetails: null,
  editServerGroupDetails: {},
};

export default function serverGroup(state = initialState, action) {
  switch (action.type) {
    case RESET_SERVER_GROUP_DETAILS:
      return {
        isCreating: initialState.isCreating,
        serverGroupCreated: initialState.serverGroupCreated,
        savedServerGroupDetails: initialState.savedServerGroupDetails,
        serverGroupDetails: initialState.serverGroupDetails,
      };
    case CREATE_SERVER_GROUP_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SERVER_GROUP_SUCCESS:
      return {
        ...state,
        isCreating: false,
        serverGroupCreated: true,
      };
    case CREATE_SERVER_GROUP_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };
    case GET_SERVER_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
      };
    case GET_SERVER_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        serverGroupDetails: action.payload,
      };
    case GET_SERVER_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload,
      };
    case SAVE_SERVER_GROUP_DETAILS:
      return {
        ...state,
        savedServerGroupDetails: action.payload,
      };
    case EDIT_SERVER_GROUP_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_SERVER_GROUP_SUCCESS:
      return {
        ...state,
        isCreating: false,
        serverGroupCreated: true,
        editServerGroupDetails: action.payload,
      };
    case EDIT_SERVER_GROUP_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };
    case DELETE_SERVER_GROUP_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_SERVER_GROUP_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        serverGroupDeleted: true,
      };
    case DELETE_SERVER_GROUP_FAILURE:
      return {
        ...state,
        isDeleting: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
