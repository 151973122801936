import React from "react";

import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

import { deserializeClientMessage } from "./deserialize";
import {
  getHandShakeMessage,
  getAcknowledgementMessage,
  getColRowInfo,
  getUserIputMessage,
  getColRowInput,
} from "./message";
import "./style.css";
import * as log from "./logs";
import "../../../node_modules/xterm/css/xterm.css";

class TerminalApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.store = {
      Term: null,
      Socket: null,
      LastMessageId: null,
      IncomingMessageList: [],
      OutgoingMessageList: [0],
    };
  }

  socket_error_handler = (error) => {
    log.error("Socket error", error);
  };

  send_ping = () => {
    this.store.Socket.send("__ping__");
  };

  socket_open_hanlder = () => {
    log.debug("Socket open");
    const { TokenValue } = this.props;
    const handShakeMessage = getHandShakeMessage(TokenValue);
    this.store.Socket.send(handShakeMessage);

    // Ping server every 5 mins
    setInterval(this.send_ping, 1000 * 5 * 60);
  };

  printableKey = (ev) => {
    const keyCode = ev.keyCode;
    const controlKeys = [
      9, // Tab
      13, // Enter
      27, // Escape
      8, // Backspace
      32, // Space
      37, // ArrowLeft
      38, // ArrowUp
      39, // ArrowRigth
      40, // ArrowDown,
      46, // Delete
      35, // End
      36, // Home
      45, // Insert
    ];
    return (
      controlKeys.indexOf(keyCode) === -1 &&
      !ev.altKey &&
      !ev.altGraphKey &&
      !ev.ctrlKey &&
      !ev.metaKey
    );
  };

  getNonPrintableKey = (ev) => {
    const keyCode = ev.keyCode;
    if (ev.ctrlKey) {
      switch (ev.code) {
        case "KeyC":
          return "\u0003";
        case "KeyR":
          return "\u0012";
        case "KeyL":
          return "\f";
        case "KeyX":
          return "\u0018";
        case "KeyD":
          return "\u0004";
        default:
          return String.fromCharCode(keyCode);
      }
    } else {
      switch (keyCode) {
        case 8:
          return "\u007f"; // BackSpace or Delete
        case 9:
          return "\u0009"; // Tab
        case 27:
          return "\u001b";
        case 37:
          return "\u001b[D";
        case 38:
          return "\u001b[A";
        case 39:
          return "\u001b[C";
        case 40:
          return "\u001b[B";
        case 46:
          return "\u001b[3~";
        case 35:
          return "\u001b[F";
        case 36:
          return "\u001b[H";
        case 45:
          return "\u001b[2~";
        default:
          return String.fromCharCode(keyCode);
      }
    }
  };

  getPrintableKey = (ev) => {
    log.debug("getPrintableKey", ev.ctrlKey, ev.key);
    return ev.key;
  };

  getInputKey = (ev) => {
    return this.printableKey(ev)
      ? this.getPrintableKey(ev)
      : this.getNonPrintableKey(ev);
  };

  userInputHandler = ({ domEvent, key }) => {
    const ev = domEvent;
    if (ev) {
      key = this.getInputKey(ev);
      log.debug("user input", ev.keyCode, ev);
    } else {
      log.debug("paste input", key, key);
    }
    let sequneceNumber = Math.max(...this.store.OutgoingMessageList) + 1;

    log.info("user input: sequneceNumber", sequneceNumber);
    let inputData = getUserIputMessage(sequneceNumber, key);
    this.store.OutgoingMessageList.push(sequneceNumber);
    this.store.Socket.send(inputData);
  };

  acknowledgement_message_handler = (messageParsed) => {
    log.debug("acknowledgement_message_handler", messageParsed);
  };

  output_stream_message_handler = (messageParsed) => {
    log.debug("output_stream_message_handler", messageParsed["Payload"]);

    const ack_message = getAcknowledgementMessage(messageParsed);
    this.store.Socket.send(ack_message);

    const sequenceNumber = messageParsed["SequenceNumber"];
    if (this.store.IncomingMessageList.indexOf(sequenceNumber) !== -1) {
      return;
    }
    this.store.IncomingMessageList.push(sequenceNumber);
    this.store.Term.write(messageParsed["Payload"]);
    if (sequenceNumber === 0) {
      const colRowInfo = getColRowInfo(this.store.Term);
      this.store.Socket.send(colRowInfo);
    }
  };

  channel_closed_message_handler = (messageParsed) => {
    log.warn("channel_closed_message_handler", messageParsed["Payload"]);
  };

  socket_message_receive_handler = (e) => {
    const messageParsed = deserializeClientMessage(e.data);
    const messageType = messageParsed["MessageType"];

    switch (messageType.trim()) {
      case "acknowledge":
        this.acknowledgement_message_handler(messageParsed);
        break;
      case "output_stream_data":
        this.output_stream_message_handler(messageParsed);
        break;
      case "channel_closed":
        this.channel_closed_message_handler(messageParsed);
        break;
      default:
        break;
    }
  };

  /**
   * Handles the terminal resize.
   * Sends updated size to server so that the response will be formatted accordingly.
   */
  onTerminalResize = (cols, rows) => {
    log.debug("onTerminalResize", cols, rows);
    if (this.store.Socket) {
      const colRowMessage = getColRowInput(cols, rows);
      this.store.Socket.send(colRowMessage);
    }
  };

  /**
   * Capture socket close event
   */
  socket_on_close_handler = () => {
    log.warn("socket_on_close_handler");
  };

  setupSocket = () => {
    log.debug("Setting up scoket and terminal");

    const term = new Terminal({
      cursorBlink: true,
    });
    term.onResize(this.onTerminalResize);

    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    term.open(document.getElementById("xterm"));
    fitAddon.fit();

    this.store["Term"] = term;

    const { StreamUrl } = this.props;

    var socket = new WebSocket(StreamUrl);

    socket.binaryType = "arraybuffer";
    socket.onopen = this.socket_open_hanlder;
    socket.onmessage = this.socket_message_receive_handler;
    socket.onerror = this.socket_error_handler;
    socket.onclose = this.socket_on_close_handler;

    this.store["Socket"] = socket;
    // term.onData(this.userInputTestHandler);
    term.onKey(this.userInputHandler);

    term.attachCustomKeyEventHandler(this.copy);
    const xterm = document.querySelector("textarea.xterm-helper-textarea");
    xterm.addEventListener("paste", this.paste);
  };

  paste = async () => {
    const text = await navigator.clipboard.readText();
    [...text].forEach((ele) => {
      this.userInputHandler({ key: ele });
    });
  };

  copy = (e) => {
    if (
      (e.ctrlKey && e.shiftKey && e.keyCode === 67) ||
      (e.ctrlKey && e.keyCode === 45)
    ) {
      document.execCommand("copy");
    }
  };

  componentWillUnmount() {
    const xterm = document.querySelector("textarea.xterm-helper-textarea");
    xterm.removeEventListener("paste", this.paste);
  }

  componentDidMount() {
    this.setupSocket();
  }

  render() {
    return (
      <div className="App">
        <div id="xterm"></div>
      </div>
    );
  }
}

export default TerminalApp;
