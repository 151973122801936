import {
  ACCOUNT_REMOVE_URL,
  USER_CHECK_API_PERMISSION,
  CUSTOMER_ACCOUNT,
  CUSTOMER_ACCOUNT_STATUS,
  CUSTOMER_ACCOUNT_CONFIGURE_REGIONS_URL,
  ACCOUNTS_TEMPLATES,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";


export const getAccountActivationDetailsApi = (param) => {
  return getApiService(WEB_REQUEST_TYPE.GET, CUSTOMER_ACCOUNT + param + "/tags")
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};

export const removeAccountApi = (accountId) => {
  const url = `${CUSTOMER_ACCOUNT}${accountId}/${ACCOUNT_REMOVE_URL}`;
  return getApiService(WEB_REQUEST_TYPE.PUT, url);
};

export const checkApiPermissionApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_CHECK_API_PERMISSION, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const customerAccountApi = (param) => {
  const url = CUSTOMER_ACCOUNT + ACCOUNTS_TEMPLATES;
  return getApiService(WEB_REQUEST_TYPE.POST, url, param)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const continueCustomerAccountApi = (param, payload) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    CUSTOMER_ACCOUNT + "?" + param,
    payload
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const customerAccountStatusApi = (params) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    CUSTOMER_ACCOUNT_STATUS + "?" + params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const updateCustomerAccountApi = (accountId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    CUSTOMER_ACCOUNT + accountId,
    params
  );
};

export const getAccountDetailsApi = (accountId) => {
  return getApiService(WEB_REQUEST_TYPE.GET, CUSTOMER_ACCOUNT + accountId)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const configureRegionsApi = (param) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    CUSTOMER_ACCOUNT_CONFIGURE_REGIONS_URL,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};
