import {
  DETECT_CLOUD_WATCH_AGENT_REQUEST,
  DETECT_CLOUD_WATCH_AGENT_SUCCESS,
  DETECT_CLOUD_WATCH_AGENT_FAILURE,
  CHECK_CLOUD_WATCH_USER_EXIST_REQUEST,
  CHECK_CLOUD_WATCH_USER_EXIST_SUCCESS,
  CHECK_CLOUD_WATCH_USER_EXIST_FAILURE,
  CLEAR_INSTANCE_METRIC_DETAILS_DATA,
  GET_INSTANCE_MONITORING_METRICS_REQUEST,
  GET_INSTANCE_MONITORING_METRICS_SUCCESS,
  GET_INSTANCE_MONITORING_METRICS_FAILURE,
  GET_INSTANCE_MONITORING_METRICS_DATA_REQUEST,
  GET_INSTANCE_MONITORING_METRICS_DATA_SUCCESS,
  GET_INSTANCE_MONITORING_METRICS_DATA_FAILURE,
  CLEAR_CLOUD_WATCH_DATA,
} from "../actionTypes/configure-cloud-watch";
import { setMetricsData } from "../../../utils/monitoring";

const initialState = {
  isFetching: false,
  isInstalled: null,
  installationStatus: null,
  failedToDetect: false,
  isFetched: false,
  isLoadingCloudWatchAgentExists: false,
  loadedCloudWatchAgentExists: false,
  cloudWatchAgentExists: null,
  failedToLoadCloudWatchAgentExists: false,
  isFetchingMonitoringMetric: false,
  monitoringMetric: [],
  monitoringMetricFetched: false,
  failedToFetchMonitoringMetric: false,
  failedMonitoringError: null,
  monitoringMetricData: [],
};

const configureCloudWatch = (state = initialState, action) => {
  switch (action.type) {
    case DETECT_CLOUD_WATCH_AGENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isInstalled: initialState.isInstalled,
        failedToDetect: initialState.failedToDetect,
        isFetched: initialState.isFetched,
      };
    case DETECT_CLOUD_WATCH_AGENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isInstalled: action.payload.isInstalled,
        installationStatus: action.payload.installationStatus,
        isFetched: true,
      };
    case DETECT_CLOUD_WATCH_AGENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        failedToDetect: true,
      };
    case CHECK_CLOUD_WATCH_USER_EXIST_REQUEST:
      return {
        ...state,
        isLoadingCloudWatchAgentExists: true,
        cloudWatchAgentExists: initialState.cloudWatchAgentExists,
        loadedCloudWatchAgentExists: initialState.loadedCloudWatchAgentExists,
        failedToLoadCloudWatchAgentExists:
          initialState.failedToLoadCloudWatchAgentExists,
      };
    case CHECK_CLOUD_WATCH_USER_EXIST_SUCCESS:
      return {
        ...state,
        isLoadingCloudWatchAgentExists: false,
        cloudWatchAgentExists: action.payload,
        loadedCloudWatchAgentExists: true,
      };
    case CHECK_CLOUD_WATCH_USER_EXIST_FAILURE:
      return {
        ...state,
        isLoadingCloudWatchAgentExists: false,
        loadedCloudWatchAgentExists: true,
        failedToLoadCloudWatchAgentExists: true,
      };
    case CLEAR_INSTANCE_METRIC_DETAILS_DATA:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_CLOUD_WATCH_DATA:
      return {
        ...state,
        isFetching: initialState.isFetching,
        isInstalled: initialState.isInstalled,
        failedToDetect: initialState.failedToDetect,
        installationStatus: initialState.installationStatus,
        isFetched: initialState.isFetched,
      };
    case GET_INSTANCE_MONITORING_METRICS_REQUEST:
      return {
        ...state,
        isFetchingMonitoringMetric: true,
        monitoringMetric: initialState.monitoringMetric,
        monitoringMetricFetched: initialState.monitoringMetricFetched,
        failedToFetchMonitoringMetric:
          initialState.failedToFetchMonitoringMetric,
        failedMonitoringError: initialState.failedMonitoringError,
      };
    case GET_INSTANCE_MONITORING_METRICS_SUCCESS:
      return {
        ...state,
        isFetchingMonitoringMetric: false,
        monitoringMetric: action.payload,
        monitoringMetricFetched: true,
      };
    case GET_INSTANCE_MONITORING_METRICS_FAILURE:
      return {
        ...state,
        isFetchingMonitoringMetric: false,
        monitoringMetricFetched: true,
        failedToFetchMonitoringMetric: true,
        failedMonitoringError: action.payload,
      };
    case GET_INSTANCE_MONITORING_METRICS_DATA_REQUEST:
      return {
        ...state,
        monitoringMetricData: setMetricsData(
          state.monitoringMetricData,
          action.payload
        ),
      };
    case GET_INSTANCE_MONITORING_METRICS_DATA_SUCCESS:
      return {
        ...state,
        monitoringMetricData: setMetricsData(
          state.monitoringMetricData,
          action.payload
        ),
      };
    case GET_INSTANCE_MONITORING_METRICS_DATA_FAILURE:
      return {
        ...state,
        monitoringMetricData: setMetricsData(
          state.monitoringMetricData,
          action.payload
        ),
      };
    default:
      return {
        ...state,
      };
  }
};

export default configureCloudWatch;
