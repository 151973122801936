export const GET_SCHEDULE_DETAILS_REQUEST = "GET_SCHEDULE_DETAILS_REQUEST";
export const GET_SCHEDULE_DETAILS_SUCCESS = "GET_SCHEDULE_DETAILS_SUCCESS";
export const GET_SCHEDULE_DETAILS_FAILURE = "GET_SCHEDULE_DETAILS_FAILURE";
export const GET_SCHEDULE_JOBS_REQUEST = "GET_SCHEDULE_JOBS_REQUEST";
export const GET_SCHEDULE_JOBS_SUCCESS = "GET_SCHEDULE_JOBS_SUCCESS";
export const GET_SCHEDULE_JOBS_FAILURE = "GET_SCHEDULE_JOBS_FAILURE";
export const GET_JOB_DETAILS_REQUEST = "GET_JOB_DETAILS_REQUEST";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAILURE = "GET_JOB_DETAILS_FAILURE";
export const CLEAR_TASK_DETAILS = "CLEAR_TASK_DETAILS";
export const GET_TASK_OUTPUT_REQUEST = "GET_TASK_OUTPUT_REQUEST";
export const GET_TASK_OUTPUT_SUCCESS = "GET_TASK_OUTPUT_SUCCESS";
export const GET_TASK_OUTPUT_FAILURE = "GET_TASK_OUTPUT_FAILURE";
export const CLEAR_TASK_OUTPUT = "CLEAR_TASK_OUTPUT";
export const CLEAR_TASK_SCHEDULE_DETAILS = "CLEAR_TASK_SCHEDULE_DETAILS";
