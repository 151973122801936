import i18n from "../config/locale/i18n";

// Task type needs to be alphabetically ordered
export const allTaskTypes = [
  {
    label: i18n.t("tasks:automationDocument"),
    value: "AutomationDocument",
    text: "automationDocumentText",
    link: "https://docs.aws.amazon.com/systems-manager/latest/userguide/automation-document-script.html",
  },
  {
    label: i18n.t("tasks:changeConfiguration"),
    value: "CloudFormationUpdate",
    text: "changeConfigurationText",
    isBlueprintTask: true,
  },
  {
    label: i18n.t("tasks:pythonScript"),
    value: "PythonScript",
    text: "pythonScriptText",
  },
  {
    label: i18n.t("common:runCommand"),
    value: "RunCommand",
    text: "runCommandText",
    link: "https://docs.aws.amazon.com/systems-manager/latest/userguide/execute-remote-commands.html",
  },
];

export const nonBlueprintTaskTypes = allTaskTypes.filter(
  (task) => !task.isBlueprintTask
);

export const categoryFilter = {
  label: i18n.t("common:all"),
  value: "All",
};

const day2Option = {
  label: "DAY2",
  value: "DAY2",
};

export const taskTypeFilters = (showBPTasks) => {
  const taskTypeOptions = [
    ...(showBPTasks ? allTaskTypes : nonBlueprintTaskTypes),
  ];
  taskTypeOptions.unshift(categoryFilter, day2Option);
  return taskTypeOptions;
};

export const getTaskTypes = (parentType, appDimension) => {
  return parentType === "blueprint" ||
    (parentType === "application" && appDimension === "Blueprint")
    ? allTaskTypes
    : nonBlueprintTaskTypes;
};
