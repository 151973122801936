import React from "react";
import s from "./CustomSelect.module.scss";
import Select, { components } from "react-select";
import SingleCheckbox from "../SingleCheckbox";
import classnames from "classnames";
import Label from "../Label";
import { isArrayWithLength } from "../../utils/array-methods";

const { Option, Placeholder, Input } = components;

const IconOption = (props) => {
  const onClick = (e) => {
    props.selectOption({ ...props.data });
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <Option {...props}>
      <div
        className={classnames(
          "multi-select-option",
          props.data.isDisabled && s.DisabledOption,
          s.checkBox
        )}
        onClick={onClick}
      >
        {props.isMulti ? (
          <SingleCheckbox
            checked={props.isSelected}
            text={props.data.label}
            disabled={props.data.isDisabled}
          />
        ) : (
          props.data.label
        )}
      </div>
    </Option>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  let values = getValue();
  let valueLabel = "";
  if (values.length) {
    valueLabel = `(${values.length})`;
  }
  const maxAllowedCharacters =
    props.selectProps && props.selectProps.maxAllowedCharacters;

  let childsToRender = React.Children.toArray(children).filter(
    (child) => [Input, Placeholder].indexOf(child.type) >= 0
  );

  return (
    <components.ValueContainer {...props} className="d-flex">
      {props.isMulti
        ? valueLabel && `${props.selectProps.placeholder} ${valueLabel}`
        : isArrayWithLength(values) &&
          values
            .map((v) =>
              maxAllowedCharacters && v.label.length > maxAllowedCharacters
                ? `${v.label.substring(0, maxAllowedCharacters)}...`
                : v.label
            )
            .join(", ")}
      {childsToRender}
    </components.ValueContainer>
  );
};

export const colourStyles = (
  extraStyles,
  isMultiFilter,
  isMulti,
  placeholderStyles,
  menuStyles,
  selectMenuStyles,
  menuPortalStyles,
  isSmallScreenSize,
  isFormField = false,
  disableMultiSelectHighlight = false
) => {
  const styles = {
    dropdownIndicator: (styles, state) => ({
      ...styles,
      padding: "8px",
      transition: "transform 0.3s",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      ...(isMultiFilter && { color: "#000" }),
      "& svg": {
        height: !isSmallScreenSize ? 12 : 20,
        width: !isSmallScreenSize ? 12 : 20,
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "0px",
      ...(placeholderStyles && { ...placeholderStyles }),
    }),
    placeholder: (styles) => ({
      ...styles,
      ...(placeholderStyles && { ...placeholderStyles }),
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      ...extraStyles,
      minHeight: isMultiFilter ? "30px" : "38px",
      height: isMultiFilter ? "30px" : "38px",
      padding: "0 1px 0 8px",
      backgroundColor: "#fff",
      borderColor: isFormField && isFocused ? "#4d90fe !important" : "#eee",
      boxShadow: isFormField && isFocused && "0 0 0 1px #4d90fe !important",
      cursor: "pointer",
      ...(isMultiFilter && {
        width: "100%",
        ...(isMulti && { minWidth: extraStyles.minWidth || "100px" }),
      }),
    }),
    option: (styles, { isFocused, isSelected }) => {
      const showOptionHighlight =
        ((isMultiFilter && !isMulti) || !isMultiFilter) &&
        !disableMultiSelectHighlight;
      return {
        ...styles,
        textAlign: "left",
        ...(showOptionHighlight
          ? {
              backgroundColor: isSelected
                ? "#2684FF"
                : isFocused
                  ? "#DEEBFF"
                  : "#FFF",
            }
          : {
              backgroundColor: isSelected
                ? "#fff"
                : isFocused
                  ? "#DEEBFF"
                  : null,
            }),
      };
    },
    menuPortal: (styles) => ({ ...styles, ...menuPortalStyles }),
    menuList: (styles) => {
      return {
        ...styles,
        ...selectMenuStyles,
      };
    },
  };
  if (isMultiFilter) {
    styles.menu = (styles) => {
      return {
        ...styles,
        ...menuStyles,
      };
    };
  }

  return styles;
};

const defaultStyles = {
  width: "220px",
};

const defaultMenuStyles = {
  zIndex: 1000,
  borderWidth: "0 1px 1px 1px!important",
  borderStyle: "solid!important",
  borderColor: "#eee!important",
  boxShadow:
    "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)important",
};

const CustomSelect = ({
  value,
  onChange,
  onInputChange,
  onMenuScrollToBottom,
  options,
  isMulti,
  placeholder,
  isDisabled,
  isClearable = true,
  extraStyles = defaultStyles,
  filterMenuStyles = {},
  selectMenuStyles = {},
  showLabel,
  isLoading,
  required,
  isMultiFilter = false,
  placeholderStyles,
  noBackgroundColor = false,
  className,
  maxAllowedCharacters,
  defaultValue,
  onBlur = () => {},
  menuPortalStyles = {},
  menuPortalTarget,
  isFormField = false,
  id,
  disableMultiSelectHighlight = false,
}) => {
  // Using window.innerWidth directly because the useScreenSize hook categorizes all widths greater than 1366 pixels as XL, and here we specifically need to check for resolutions less than or equal to 1366 pixels.
  const isSmallScreenSize = window.innerWidth <= 1366;

  const sizeStyle = {
    minWidth: isSmallScreenSize ? "170px" : "200px",
  };

  let _value = value ? value : [];
  let _options = options ? options : [];
  let sortedOptions = _value
    .concat(
      _options.filter(
        (option) =>
          !(
            _value.length > 0 &&
            _value.filter((ele) => ele.label === option.label).length
          )
      )
    )
    .filter((value) => value);

  const menuStyles = {
    ...sizeStyle,
    ...defaultMenuStyles,
    ...filterMenuStyles,
  };

  let extraProps = {};

  if (isMulti) {
    extraProps = { closeMenuOnSelect: false };
  }

  const disabled = isDisabled || isLoading;

  return (
    <>
      {showLabel && (
        <Label
          htmlFor={placeholder}
          required={required}
          className="mc-text-base-bold"
        >
          {placeholder}
        </Label>
      )}
      <Select
        {...extraProps}
        value={value}
        maxAllowedCharacters={maxAllowedCharacters}
        onChange={(value) => onChange(value)}
        onInputChange={onInputChange}
        onMenuScrollToBottom={onMenuScrollToBottom}
        options={sortedOptions}
        isMulti={isMulti}
        styles={colourStyles(
          extraStyles,
          isMultiFilter,
          isMulti,
          placeholderStyles,
          menuStyles,
          selectMenuStyles,
          menuPortalStyles,
          isSmallScreenSize,
          isFormField,
          disableMultiSelectHighlight
        )}
        hideSelectedOptions={false}
        isLoading={isLoading}
        placeholder={placeholder}
        isClearable={isClearable}
        defaultValue={defaultValue}
        className={classnames(
          s.CustomSelect,
          isMultiFilter ? s.MultiFilters : "",
          noBackgroundColor ? s.MultiFiltersBackground : "",
          disabled & !isMultiFilter ? s.backgroundDisabled : "",
          className
        )}
        components={{
          ValueContainer,
          Option: IconOption,
          ...(isMultiFilter && { IndicatorSeparator: () => null }),
        }}
        isDisabled={disabled}
        onBlur={onBlur}
        classNamePrefix="custom_select"
        menuPortalTarget={menuPortalTarget}
        id={id}
        aria-label={id}
      />
    </>
  );
};

export default CustomSelect;
