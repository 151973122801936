export const SET_FEATURES_LIST = "SET_FEATURES";
export const START_PLAN_REQUEST = "START_PLAN_REQUEST";
export const START_PLAN_SUCCESS = "START_PLAN_SUCCESS";
export const START_PLAN_FAILURE = "START_PLAN_FAILURE";
export const SAVE_FEATURES_REQUEST = "SAVE_FEATURES_REQUEST";
export const SAVE_FEATURES_SUCCESS = "SAVE_FEATURES_SUCCESS";
export const SAVE_FEATURES_FAILURE = "SAVE_FEATURES_FAILURE";
export const SAVE_EMAILS_REQUEST = "SAVE_EMAILS_REQUEST";
export const SAVE_EMAILS_SUCCESS = "SAVE_EMAILS_SUCCESS";
export const SAVE_EMAILS_FAILURE = "SAVE_EMAILS_FAILURE";
export const CLEAR_START_PLAN = "CLEAR_START_PLAN";
export const GET_CUSTOMER_PREFERENCE_REQUEST =
  "GET_CUSTOMER_PREFERENCE_REQUEST";
export const GET_CUSTOMER_PREFERENCE_SUCCESS =
  "GET_CUSTOMER_PREFERENCE_SUCCESS";
export const GET_CUSTOMER_PREFERENCE_FAILURE =
  "GET_CUSTOMER_PREFERENCE_FAILURE";
