import {
  CUSTOMER_ACCOUNT_BASE,
  CUSTOMER_ACCOUNT_MEMBER_OFFBOARDING,
  CUSTOMER_ACCOUNT_MEMBER_ONBOARDING,
  CUSTOMER_ACCOUNT_ORGANIZATION,
  HIERARCHY_SUFFIX,
  ORGANIZATION_SUMMARY_URL,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getDiscoverySummaryApi = (param) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    ORGANIZATION_SUMMARY_URL + "?" + param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getOrgHierarchyApi = async (customerAccountId) => {
  try {
    const response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      `${CUSTOMER_ACCOUNT_ORGANIZATION}/${customerAccountId}${HIERARCHY_SUFFIX}`
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const onboardMemberAccountApi = (payload) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    `${CUSTOMER_ACCOUNT_MEMBER_ONBOARDING}`,
    payload
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const offboardMemberAccountApi = (customerAccountId) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    `${CUSTOMER_ACCOUNT_BASE}${customerAccountId}${CUSTOMER_ACCOUNT_MEMBER_OFFBOARDING}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};
