export const metricsData = [
  {
    type: {
      value: "AWS::EC2::Instance",
      label: "Compute Instance",
    },
    dimensionName: "InstanceId",
    otherDimensions: ["ImageId", "InstanceType"],
    namespace: "AWS/EC2",
    metrics: [
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "Disk Read Ops",
        value: "DiskReadOps",
      },
      {
        label: "Disk Write Ops",
        value: "DiskWriteOps",
      },
      {
        label: "EBS Read Ops",
        value: "EBSReadOps",
      },
      {
        label: "EBS Write Ops",
        value: "EBSWriteOps",
      },
      {
        label: "Status Check Failed",
        value: "StatusCheckFailed",
      },
      {
        label: "Network In",
        value: "NetworkIn",
      },
      {
        label: "Network Out",
        value: "NetworkOut",
      },
    ],
  },
  {
    type: {
      value: "AWS::EC2::NatGateway",
      label: "NAT Gateway",
    },
    dimensionName: "NatGatewayId",
    namespace: "AWS/NATGateway",
    metrics: [
      {
        label: "Active Connection Count",
        value: "ActiveConnectionCount",
      },
      {
        label: "Bytes In From Destination",
        value: "BytesInFromDestination",
      },
      {
        label: "Bytes In From Source",
        value: "BytesInFromSource",
      },
      {
        label: "Bytes Out To Destination",
        value: "BytesOutToDestination",
      },
      {
        label: "Bytes Out To Source",
        value: "BytesOutToSource",
      },
      {
        label: "Connection Established Count",
        value: "ConnectionEstablishedCount",
      },
      {
        label: "Error Port Allocation",
        value: "ErrorPortAllocation",
      },
      {
        label: "Idle Timeout Count",
        value: "IdleTimeoutCount",
      },
      {
        label: "Packets Drop Count",
        value: "PacketsDropCount",
      },
      {
        label: "Packets In From Destination",
        value: "PacketsInFromDestination",
      },
      {
        label: "Packets In From Source",
        value: "PacketsInFromSource",
      },
      {
        label: "Packets Out To Destination",
        value: "PacketsOutToDestination",
      },
      {
        label: "Packets Out To Source",
        value: "PacketsOutToSource",
      },
    ],
  },
  {
    type: {
      value: "AWS::EC2::TransitGateway",
      label: "Transit Gateway",
    },
    dimensionName: "TransitGateway",
    namespace: "AWS/TransitGateway",
    metrics: [
      {
        label: "Bytes In",
        value: "BytesIn",
      },
      {
        label: "Bytes Out",
        value: "BytesOut",
      },
      {
        label: "Packets In",
        value: "PacketsIn",
      },
      {
        label: "Packets Out",
        value: "PacketsOut",
      },
      {
        label: "Packet Drop Count Blackhole",
        value: "PacketDropCountBlackhole",
      },
      {
        label: "Packet Drop Count No Route",
        value: "PacketDropCountNoRoute",
      },
    ],
  },
  {
    type: {
      value: "AWS::EC2::VPNConnection",
      label: "VPN Connection",
    },
    dimensionName: "VpnId",
    otherDimensions: ["TunnelIpAddress"],
    namespace: "AWS/VPN",
    metrics: [
      {
        label: "Tunnel State",
        value: "TunnelState",
      },
      {
        label: "Tunnel Data In",
        value: "TunnelDataIn",
      },
      {
        label: "Tunnel Data Out",
        value: "TunnelDataOut",
      },
    ],
  },
  {
    type: {
      value: "AWS::Lambda::Function",
      label: "Lambda",
    },
    dimensionName: "FunctionName",
    otherDimensions: ["Resource", "ExecutedVersion"],
    namespace: "AWS/Lambda",
    metrics: [
      {
        label: "Invocations",
        value: "Invocations",
      },
      {
        label: "Errors",
        value: "Errors",
      },
      {
        label: "Dead Letter Errors",
        value: "DeadLetterErrors",
      },
      {
        label: "Destination Delivery Failures",
        value: "DestinationDeliveryFailures",
      },
      {
        label: "Throttles",
        value: "Throttles",
      },
      {
        label: "Provisioned Concurrency Invocations",
        value: "ProvisionedConcurrencyInvocations",
      },
      {
        label: "Provisioned Concurrency Spillover Invocations",
        value: "ProvisionedConcurrencySpilloverInvocations",
      },
      {
        label: "Duration",
        value: "Duration",
      },
    ],
  },
  {
    type: {
      value: "AWS::Logs::LogGroup",
      label: "CloudWatch Logs",
    },
    dimensionName: "LogGroupName",
    otherDimensions: ["DestinationType", "FilterName"],
    namespace: "AWS/Logs",
    metrics: [
      {
        label: "Incoming Bytes",
        value: "IncomingBytes",
      },
      {
        label: "Incoming Log Events",
        value: "IncomingLogEvents",
      },
      {
        label: "Forwarded Bytes",
        value: "ForwardedBytes",
      },
      {
        label: "Forwarded Log Events",
        value: "ForwardedLogEvents",
      },
      {
        label: "Delivery Errors",
        value: "DeliveryErrors",
      },
      {
        label: "Delivery Throttling",
        value: "DeliveryThrottling",
      },
    ],
  },
  {
    type: {
      value: "AWS::Route53::HostedZone",
      label: "Hosted Zone",
    },
    dimensionName: "HostedZoneId",
    namespace: "AWS/Route53",
    metrics: [
      {
        label: "DNS Queries",
        value: "DNSQueries",
      },
    ],
  },
  {
    type: {
      value: "AWS::AutoScaling::AutoScalingGroup",
      label: "Auto Scaling Group",
    },
    dimensionName: "AutoScalingGroupName",
    namespace: "AWS/EC2",
    metrics: [
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "Disk Read Ops",
        value: "DiskReadOps",
      },
      {
        label: "Disk Write Ops",
        value: "DiskWriteOps",
      },
      {
        label: "EBS Read Ops",
        value: "EBSReadOps",
      },
      {
        label: "EBS Write Ops",
        value: "EBSWriteOps",
      },
      {
        label: "Status Check Failed",
        value: "StatusCheckFailed",
      },
      {
        label: "Network In",
        value: "NetworkIn",
      },
      {
        label: "Network Out",
        value: "NetworkOut",
      },
    ],
  },
  {
    type: {
      value: "AWS::ECS::Cluster",
      label: "Elastic Container Service",
    },
    dimensionName: "ClusterName",
    namespace: "AWS/ECS",
    metrics: [
      {
        label: "CPU Reservation",
        value: "CPUReservation",
      },
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "Memory Reservation",
        value: "MemoryReservation",
      },
      {
        label: "Memory Utilization",
        value: "MemoryUtilization",
      },
    ],
  },
  {
    type: {
      value: "AWS::EMR::Cluster",
      label: "EMR",
    },
    dimensionName: "JobFlowId",
    otherDimensions: ["JobId"],
    namespace: "AWS/ElasticMapReduce",
    metrics: [
      {
        label: "Is Idle",
        value: "IsIdle",
      },
      {
        label: "Container Allocated",
        value: "ContainerAllocated",
      },
      {
        label: "Container Reserved",
        value: "ContainerReserved",
      },
      {
        label: "Container Pending",
        value: "ContainerPending",
      },
      {
        label: "Core Nodes Running",
        value: "CoreNodesRunning",
      },
      {
        label: "HDFS Utilization",
        value: "HDFSUtilization",
      },
      {
        label: "Memory Total in MB",
        value: "MemoryTotalMB",
      },
      {
        label: "Memory Reserved in MB",
        value: "MemoryReservedMB",
      },
      {
        label: "Memory Available in MB",
        value: "MemoryAvailableMB",
      },
      {
        label: "YARN Memory Available Percentage",
        value: "YARNMemoryAvailablePercentage",
      },
      {
        label: "Hbase Backup Failed",
        value: "HbaseBackupFailed",
      },
      {
        label: "Apps Running",
        value: "AppsRunning",
      },
      {
        label: "Apps Pending",
        value: "AppsPending",
      },
      {
        label: "Apps Completed",
        value: "AppsCompleted",
      },
    ],
  },
  {
    type: {
      value: "AWS::DynamoDB::Table",
      label: "DynamoDB Table",
    },
    dimensionName: "TableName",
    otherDimensions: [
      "GlobalSecondaryIndexName",
      "Operation",
      "ReceivingRegion",
      "StreamLabel",
    ],
    namespace: "AWS/DynamoDB",
    metrics: [
      {
        label: "System Errors",
        value: "SystemErrors",
      },
      {
        label: "Consumed Read CapacityUnits",
        value: "ConsumedReadCapacityUnits",
      },
      {
        label: "Consumed Write Capacity Units",
        value: "ConsumedWriteCapacityUnits",
      },
      {
        label: "Read Throttle Events",
        value: "ReadThrottleEvents",
      },
      {
        label: "Write Throttle Events",
        value: "WriteThrottleEvents",
      },
      {
        label: "Throttled Requests",
        value: "ThrottledRequests",
      },
      {
        label: "User Errors",
        value: "UserErrors",
      },
    ],
  },
  {
    type: {
      value: "AWS::KinesisFirehose::DeliveryStream",
      label: "Kinesis Data Firehose",
    },
    dimensionName: "Delivery Stream Name",
    otherDimensions: ["Service", "Class", "Type", "Resource"],
    namespace: "AWS/Firehose",
    metrics: [
      {
        label: "Resource Count",
        value: "ResourceCount",
      },
    ],
  },
  {
    type: {
      value: "AWS::Kinesis::Stream",
      label: "Kinesis Data Streams",
    },
    dimensionName: "StreamName",
    namespace: "AWS/Kinesis",
    metrics: [
      {
        label: "Incoming Bytes",
        value: "IncomingBytes",
      },
      {
        label: "Incoming Records",
        value: "IncomingRecords",
      },
      {
        label: "Outgoing Bytes",
        value: "OutgoingBytes",
      },
      {
        label: "Outgoing Records",
        value: "OutgoingRecords",
      },
      {
        label: "Read Provisioned Throughput Exceeded",
        value: "ReadProvisionedThroughputExceeded",
      },
      {
        label: "Write Provisioned Throughput Exceeded",
        value: "WriteProvisionedThroughputExceeded",
      },
    ],
  },
  {
    type: {
      value: "AWS::S3::Bucket",
      label: "S3 Bucket",
    },
    dimensionName: "BucketName",
    otherDimensions: ["StorageType", "FilterId"],
    namespace: "AWS/S3",
    metrics: [
      {
        label: "Bucket Size Bytes",
        value: "BucketSizeBytes",
      },
      {
        label: "Number Of Objects",
        value: "NumberOfObjects",
      },
      {
        label: "All Requests",
        value: "AllRequests",
      },
      {
        label: "Get Requests",
        value: "GetRequests",
      },
      {
        label: "Put Requests",
        value: "PutRequests",
      },
      {
        label: "Delete Requests",
        value: "DeleteRequests",
      },
      {
        label: "Head Requests",
        value: "HeadRequests",
      },
      {
        label: "Post Requests",
        value: "PostRequests",
      },
      {
        label: "List Requests",
        value: "ListRequests",
      },
      {
        label: "4xx Errors",
        value: "4xxErrors",
      },
      {
        label: "5xx Errors",
        value: "5xxErrors",
      },
      {
        label: "Total Request Latency",
        value: "TotalRequestLatency",
      },
    ],
  },
  {
    type: {
      value: "AWS::SNS::Topic",
      label: "SNS Topic",
    },
    dimensionName: "TopicName",
    otherDimensions: ["Platform", "SMSType", "Country", "Application"],
    namespace: "AWS/SNS",
    metrics: [
      {
        label: "Number Of Messages Published",
        value: "NumberOfMessagesPublished",
      },
      {
        label: "Number Of Notifications Delivered",
        value: "NumberOfNotificationsDelivered",
      },
      {
        label: "Number Of Notifications Failed",
        value: "NumberOfNotificationsFailed",
      },
      {
        label: "Number Of Notifications Filtered Out",
        value: "NumberOfNotificationsFilteredOut",
      },
      {
        label: "Publish Size",
        value: "PublishSize",
      },
      {
        label: "SMS Success Rate",
        value: "SMSSuccessRate",
      },
      {
        label: "SMS Month To Date Spent USD",
        value: "SMSMonthToDateSpentUSD",
      },
    ],
  },
  {
    type: {
      value: "AWS::RDS::DBCluster",
      label: "Amazon Aurora",
    },
    dimensionName: "DBClusterIdentifier",
    otherDimensions: [
      "AuroraBinlogReplicaLag",
      "DeleteLatency",
      "DeleteThroughput",
      "EngineUptime",
      "InsertLatency",
      "InsertThroughput",
      "NetworkThroughput",
      "Queries",
      "UpdateLatency",
      "UpdateThroughput",
    ],
    namespace: "AWS/RDS",
    metrics: [
      {
        label: "Volume Bytes Used",
        value: "VolumeBytesUsed",
      },
      {
        label: "Volume Read IOPs",
        value: "VolumeReadIOPs",
      },
      {
        label: "Volume Write IOPs",
        value: "VolumeWriteIOPs",
      },
      {
        label: "Commit Latency",
        value: "CommitLatency",
      },
      {
        label: "Commit Throughput",
        value: "CommitThroughput",
      },
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "Database Connections",
        value: "DatabaseConnections",
      },
      {
        label: "Freeable Memory",
        value: "FreeableMemory",
      },
      {
        label: "Free Local Storage",
        value: "FreeLocalStorage",
      },
      {
        label: "Network Throughput",
        value: "NetworkThroughput",
      },
      {
        label: "Deadlocks",
        value: "Deadlocks",
      },
      {
        label: "Aurora Replica Lag",
        value: "AuroraReplicaLag",
      },
      {
        label: "Aurora Replica Lag Maximum",
        value: "AuroraReplicaLagMaximum",
      },
      {
        label: "Backup Retention Period Storage Used",
        value: "BackupRetentionPeriodStorageUsed",
      },
      {
        label: "Aurora Replica Lag Minimum",
        value: "AuroraReplicaLagMaximum",
      },
      {
        label: "Engine Uptime",
        value: "EngineUptime",
      },
      {
        label: "Network Receive Throughput",
        value: "NetworkReceiveThroughput",
      },
      {
        label: "Network Transmit Throughput",
        value: "NetworkTransmitThroughput",
      },
      {
        label: "Snapshot Storage Used",
        value: "SnapshotStorageUsed",
      },
      {
        label: "Total Backup Storae Billed",
        value: "TotalBackupStorageBilled",
      },
    ],
  },
  {
    type: {
      value: "AWS::Redshift::Cluster",
      label: "Redshift",
    },
    dimensionName: "ClusterIdentifier",
    otherDimensions: [
      "Latency",
      "NodeID",
      "Service",
      "Class",
      "Stage",
      "wlmid",
      "QueryPriority",
      "QueueName",
    ],
    namespace: "AWS/Redshift",
    metrics: [
      {
        label: "Commit Queue Length",
        value: "CommitQueueLength",
      },
      {
        label: "Concurrency Scaling Active Clusters",
        value: "ConcurrencyScalingActiveClusters",
      },
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "Database Connections",
        value: "DatabaseConnections",
      },
      {
        label: "Health Status",
        value: "HealthStatus",
      },
      {
        label: "Maintenance Mode",
        value: "MaintenanceMode",
      },
      {
        label: "Network Receive Throughput",
        value: "NetworkReceiveThroughput",
      },
      {
        label: "Network Transmit Throughput",
        value: "NetworkTransmitThroughput",
      },
      {
        label: "Percentage Disk Space Used",
        value: "PercentageDiskSpaceUsed",
      },
      {
        label: "Queries Completed Per Second",
        value: "QueriesCompletedPerSecond",
      },
      {
        label: "Query Duration",
        value: "QueryDuration",
      },
      {
        label: "Read Throughput",
        value: "ReadThroughput",
      },
      {
        label: "Write Throughput",
        value: "WriteThroughput",
      },
      {
        label: "Write IOPS",
        value: "WriteIOPS",
      },
      {
        label: "Write Latency",
        value: "WriteLatency",
      },
      {
        label: "Read IOPS",
        value: "ReadIOPS",
      },
      {
        label: "Read Latency",
        value: "ReadLatency",
      },
      {
        label: "WLM Running Queries",
        value: "WLMRunningQueries",
      },
      {
        label: "WLM Query Duration",
        value: "WLMQueryDuration",
      },
      {
        label: "WLM Queue Wait Time",
        value: "WLMQueueWaitTime",
      },
      {
        label: "WLM Queue Length",
        value: "WLMQueueLength",
      },
      {
        label: "Total Table Count",
        value: "TotalTableCount",
      },
    ],
  },
  {
    type: {
      value: "AWS::KMS::Key",
      label: "KMS Key",
    },
    dimensionName: "KeyId",
    namespace: "AWS/KMS",
    metrics: [
      {
        label: "Seconds Until Key Material Expiration",
        value: "SecondsUntilKeyMaterialExpiration",
      },
    ],
  },
  {
    type: {
      value: "AWS::EFS::FileSystem",
      label: "File System",
    },
    dimensionName: "FileSystemId",
    namespace: "AWS/EFS",
    metrics: [
      {
        label: "Burst Credit Balance",
        value: "BurstCreditBalance",
      },
      {
        label: "Client Connections",
        value: "ClientConnections",
      },
      {
        label: "Data Read IO Bytes",
        value: "DataReadIOBytes",
      },
      {
        label: "Data Write IO Bytes",
        value: "DataWriteIOBytes",
      },
      {
        label: "Metadata IO Bytes",
        value: "MetadataIOBytes",
      },
      {
        label: "Percent IO Limit",
        value: "PercentIOLimit",
      },
      {
        label: "Permitted Throughput",
        value: "PermittedThroughput",
      },
      {
        label: "Total IO Bytes",
        value: "TotalIOBytes",
      },
    ],
  },
  {
    type: {
      value: "AWS::EC2::Volume",
      label: "EBS Volume",
    },
    dimensionName: "VolumeId",
    otherDimensions: ["SnapshotId", "AvailabilityZone"],
    namespace: "AWS/EBS",
    metrics: [
      {
        label: "Volume Read Bytes",
        value: "Volume Read Bytes",
      },
      {
        label: "Volume Write Bytes",
        value: "VolumeWriteBytes",
      },
      {
        label: "Volume Read Ops",
        value: "VolumeReadOps",
      },
      {
        label: "Volume Write Ops",
        value: "VolumeWriteOps",
      },
      {
        label: "Volume Idle Time",
        value: "VolumeIdleTime",
      },
      {
        label: "Volume Queue Length",
        value: "VolumeQueueLength",
      },
      {
        label: "Volume Throughput Percentage",
        value: "VolumeThroughputPercentage",
      },
      {
        label: "Volume Consumed Read Write Ops",
        value: "VolumeConsumedReadWriteOps",
      },
      {
        label: "Burst Balance",
        value: "BurstBalance",
      },
      {
        label: "Fast Snapshot Restore Credits Bucket Size",
        value: "FastSnapshotRestoreCreditsBucketSize",
      },
      {
        label: "Fast Snapshot Restore Credits Balance",
        value: "FastSnapshotRestoreCreditsBalance",
      },
    ],
  },
  {
    type: {
      value: "AWS::ElasticLoadBalancingV2::LoadBalancer",
      label: "Application Load Balancer",
    },
    metricType: "application",
    dimensionName: "LoadBalancer",
    otherDimensions: ["AvailabilityZone", "TargetGroup"],
    namespace: "AWS/ApplicationELB",
    metrics: [
      {
        label: "Active Connection Count",
        value: "ActiveConnectionCount",
      },
      {
        label: "HTTP Fixed Response Count",
        value: "HTTP_Fixed_Response_Count",
      },
      {
        label: "HTTP Redirect Count",
        value: "HTTP_Redirect_Count",
      },
      {
        label: "HTTP Redirect Url Limit Exceeded Count",
        value: "HTTP_Redirect_Url_Limit_Exceeded_Count",
      },
      {
        label: "HTTPCode ELB 3XX Count",
        value: "HTTPCode_ELB_3XX_Count",
      },
      {
        label: "HTTPCode ELB 5XX Count",
        value: "HTTPCode_ELB_5XX_Count",
      },
      {
        label: "HTTPCode ELB 4XX Count",
        value: "HTTPCode_ELB_4XX_Count",
      },
      {
        label: "HTTPCode ELB 500 Count",
        value: "HTTPCode_ELB_500_Count",
      },
      {
        label: "HTTPCode ELB 502 Count",
        value: "HTTPCode_ELB_502_Count",
      },
      {
        label: "HTTPCode ELB 503 Count",
        value: "HTTPCode_ELB_503_Count",
      },
      {
        label: "HTTPCode ELB 504 Count",
        value: "HTTPCode_ELB_504_Count",
      },
      {
        label: "New Connection Count",
        value: "NewConnectionCount",
      },
      {
        label: "Request Count",
        value: "RequestCount",
      },
      {
        label: "HTTPCode Target 4XX Count",
        value: "HTTPCode_Target_4XX_Count",
      },
      {
        label: "HTTPCode Target 5XX Count",
        value: "HTTPCode_Target_5XX_Count",
      },
      {
        label: "HTTPCode Target 2XX Count",
        value: "HTTPCode_Target_2XX_Count",
      },
    ],
  },
  {
    type: {
      value: "AWS::ElasticLoadBalancing::LoadBalancer",
      label: "Classic Load Balancer",
    },
    dimensionName: "LoadBalancerName",
    otherDimensions: ["AvailabilityZone"],
    namespace: "AWS/ELB",
    metrics: [
      {
        label: "BackendConnectionErrors",
        value: "BackendConnectionErrors",
      },
      {
        label: "HealthyHostCount",
        value: "HealthyHostCount",
      },
      {
        label: "HTTPCode_Backend_2XX",
        value: "HTTPCode_Backend_2XX",
      },
      {
        label: "HTTPCode_Backend_3XX",
        value: "HTTPCode_Backend_3XX",
      },
      {
        label: "HTTPCode_Backend_4XX",
        value: "HTTPCode_Backend_4XX",
      },
      {
        label: "HTTPCode_Backend_5XX",
        value: "HTTPCode_Backend_5XX",
      },
      {
        label: "Latency",
        value: "Latency",
      },
      {
        label: "RequestCount",
        value: "RequestCount",
      },
      {
        label: "SpilloverCount",
        value: "SpilloverCount",
      },
      {
        label: "UnHealthyHostCount",
        value: "UnHealthyHostCount",
      },
    ],
  },
  {
    type: {
      value: "AWS::ElasticLoadBalancingV2::LoadBalancer",
      label: "Network Load Balancer",
    },
    metricType: "network",
    dimensionName: "LoadBalancer",
    otherDimensions: ["AvailabilityZone", "TargetGroup"],
    namespace: "AWS/NetworkELB",
    metrics: [
      {
        label: "Active Flow Count",
        value: "ActiveFlowCount",
      },
      {
        label: "Active FlowCount - TCP",
        value: "ActiveFlowCount_TCP",
      },
      {
        label: "Active FlowCount - TLS",
        value: "ActiveFlowCount_TLS",
      },
      {
        label: "Active FlowCount - UDP",
        value: "ActiveFlowCount_UDP",
      },
      {
        label: "Client TLS Negotiation Error Count",
        value: "ClientTLSNegotiationErrorCount",
      },
      {
        label: "Consumed LCUs",
        value: "ConsumedLCUs",
      },
      {
        label: "Consumed LCUs - TCP",
        value: "ConsumedLCUs_TCP",
      },
      {
        label: "Consumed LCUs - TLS",
        value: "ConsumedLCUs_TLS",
      },
      {
        label: "Consumed LCUs - UDP",
        value: "ConsumedLCUs_UDP",
      },
      {
        label: "New Flow Count",
        value: "NewFlowCount",
      },
      {
        label: "New Flow Count - TCP",
        value: "NewFlowCount_TCP",
      },
      {
        label: "New Flow Count - TLS",
        value: "NewFlowCount_TLS",
      },
      {
        label: "New Flow Count - UDP",
        value: "NewFlowCount_UDP",
      },
      {
        label: "Processed Bytes",
        value: "ProcessedBytes",
      },
      {
        label: "Processed Bytes - TCP",
        value: "ProcessedBytes_TCP",
      },
      {
        label: "Processed Bytes - TLS",
        value: "ProcessedBytes_TLS",
      },
      {
        label: "ProcessedBytes - UDP",
        value: "ProcessedBytes_UDP",
      },
      {
        label: "TCP Client Reset Count",
        value: "TCP_Client_Reset_Count",
      },
      {
        label: "TCP ELB Reset Count",
        value: "TCP_ELB_Reset_Count",
      },
      {
        label: "TCP Target Reset Count",
        value: "TCP_Target_Reset_Count",
      },
      {
        label: "UnHealthy Host Count",
        value: "UnHealthyHostCount",
      },
    ],
  },
  {
    type: {
      value: "AWS::EKS::Cluster",
      label: "Elastic Kubernetes service",
    },
    dimensionName: "ClusterName",
    namespace: "ContainerInsights",
    metrics: [
      {
        label: "Node Number of Running Containers",
        value: "node_number_of_running_containers",
      },
      {
        label: "Node Number of Running Pods",
        value: "node_number_of_running_pods",
      },
      {
        label: "Cluster Node Count",
        value: "cluster_node_count",
      },
      {
        label: "Node CPU Limit",
        value: "node_cpu_limit",
      },
      {
        label: "Node CPU Utilization",
        value: "node_cpu_utilization",
      },
      {
        label: "Node Memory Utilization",
        value: "node_memory_utilization",
      },
      {
        label: "Pod CPU Utilization",
        value: "pod_cpu_utilization",
      },
      {
        label: "Pod Memory Utilization",
        value: "pod_memory_utilization",
      },
      {
        label: "Service Number of Running Pods",
        value: "service_number_of_running_pods",
      },
      {
        label: "Cluster Failed Node Count",
        value: "cluster_failed_node_count",
      },
    ],
  },
  {
    type: {
      value: "AWS::RDS::DBInstance",
      label: "Relational Database Service",
    },
    otherDimensions: [
      "DBClusterIdentifier",
      "DatabaseClass",
      "DatabaseClass",
      "EngineName",
      "SourceRegion",
      "DBClusterIdentifier",
      "Role",
    ],
    dimensionName: "DBInstanceIdentifier",
    namespace: "AWS/RDS",
    metrics: [
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "CPU Credit Usage",
        value: "CPUCreditUsage",
      },
      {
        label: "CPU Credit Balance",
        value: "CPUCreditBalance",
      },
      {
        label: "Database Connections",
        value: "DatabaseConnections",
      },
      {
        label: "Disk Queue Depth",
        value: "DiskQueueDepth",
      },
      {
        label: "Freeable Memory",
        value: "FreeableMemory",
      },
      {
        label: "Free Storage Space",
        value: "FreeStorageSpace",
      },
      {
        label: "Network Transmit Throughput",
        value: "NetworkTransmitThroughput",
      },
      {
        label: "Read Throughput",
        value: "ReadThroughput",
      },
      {
        label: "Write Throughput",
        value: "WriteThroughput",
      },
      {
        label: "Write IOPS",
        value: "WriteIOPS",
      },
      {
        label: "Write Latency",
        value: "WriteLatency",
      },
      {
        label: "Read IOPS",
        value: "ReadIOPS",
      },
      {
        label: "Read Latency",
        value: "ReadLatency",
      },
    ],
  },
  {
    type: {
      value: "AWS::EC2::ClientVpnEndpoint",
      label: "Client VPN Endpoint",
    },
    otherDimensions: [],
    dimensionName: "Endpoint",
    namespace: "AWS/ClientVPN",
    metrics: [
      {
        label: "Active Connections Count",
        value: "ActiveConnectionsCount",
      },
      {
        label: "Authentication Failures",
        value: "AuthenticationFailures",
      },
      {
        label: "Control Days To Expiry",
        value: "CrlDaysToExpiry",
      },
      {
        label: "Egress Bytes",
        value: "EgressBytes",
      },
      {
        label: "Egress Packets",
        value: "EgressPackets",
      },
      {
        label: "Ingress Bytes",
        value: "IngressBytes",
      },
      {
        label: "Ingress Packets",
        value: "IngressPackets",
      },
      {
        label: "Self Service Portal Client Configuration Downloads",
        value: "SelfServicePortalClientConfigurationDownloads",
      },
      {
        label: "Client Connect Handler Timeouts",
        value: "ClientConnectHandlerTimeouts",
      },
      {
        label: "Client Connect Handler Invalid Responses",
        value: "ClientConnectHandlerInvalidResponses",
      },
      {
        label: "Client Connect Handler Other Execution Errors",
        value: "ClientConnectHandlerOtherExecutionErrors",
      },
      {
        label: "Client Connect Handler Throttling Errors",
        value: "ClientConnectHandlerThrottlingErrors",
      },
      {
        label: "Client Connect Handler Denied Connections",
        value: "ClientConnectHandlerDeniedConnections",
      },
      {
        label: "Client Connect Handler Failed Service Errors",
        value: "ClientConnectHandlerFailedServiceErrors",
      },
    ],
  },
  {
    type: {
      value: "AWS::ElastiCache::ReplicationGroup",
      label: "ElastiCache for Redis",
    },
    dimensionName: "CacheClusterId",
    otherDimensions: ["CacheNodeId"],
    namespace: "AWS/ElastiCache",
    metrics: [
      {
        label: "Active Defrag Hits",
        value: "ActiveDefragHits",
      },
      {
        label: "Authentication Failures",
        value: "AuthenticationFailures",
      },
      {
        label: "Bytes Used For Cache",
        value: "BytesUsedForCache",
      },
      {
        label: "Cache Hits",
        value: "CacheHits",
      },
      {
        label: "Cache Misses",
        value: "CacheMisses",
      },
      {
        label: "Command Authorization Failures",
        value: "CommandAuthorizationFailures",
      },
      {
        label: "Cache Hit Rate",
        value: "CacheHitRate",
      },
      {
        label: "Client Connections",
        value: "CurrConnections",
      },
      {
        label: "Database Memory Usage Percentage",
        value: "DatabaseMemoryUsagePercentage",
      },
      {
        label: "DB0 Average TTL",
        value: "DB0AverageTTL",
      },
      {
        label: "Engine CPU Utilization",
        value: "EngineCPUUtilization",
      },
      {
        label: "Evictions",
        value: "Evictions",
      },
      {
        label: "Global Datastore Replication Lag",
        value: "GlobalDatastoreReplicationLag",
      },
      {
        label: "Is Master",
        value: "IsMaster",
      },
      {
        label: "Key Authorization Failures",
        value: "KeyAuthorizationFailures",
      },
      {
        label: "Keys Tracked",
        value: "KeysTracked",
      },
      {
        label: "Master Link Health Status",
        value: "MasterLinkHealthStatus",
      },
      {
        label: "Memory Fragmentation Ratio",
        value: "MemoryFragmentationRatio",
      },
      {
        label: "New Connections",
        value: "NewConnections",
      },
      {
        label: "Reclaimed",
        value: "Reclaimed",
      },
      {
        label: "Replication Bytes",
        value: "ReplicationBytes",
      },
      {
        label: "Replication Lag",
        value: "ReplicationLag",
      },
      {
        label: "Save In Progress",
        value: "SaveInProgress",
      },
      {
        label: "CPU Utilization",
        value: "CPUUtilization",
      },
      {
        label: "CPU Credit Balance",
        value: "CPUCreditBalance",
      },
      {
        label: "CPU Credit Usage",
        value: "CPUCreditUsage",
      },
      {
        label: "Freeable Memory",
        value: "FreeableMemory",
      },
      {
        label: "Network Bytes In",
        value: "NetworkBytesIn",
      },
      {
        label: "Network Bytes Out",
        value: "NetworkBytesOut",
      },
      {
        label: "Network Packets In",
        value: "NetworkPacketsIn",
      },
      {
        label: "Network Packets Out",
        value: "NetworkPacketsOut",
      },
      {
        label: "Swap Usage",
        value: "SwapUsage",
      },
    ],
  },
  {
    type: {
      value: "AWS::CloudFront::Distribution",
      label: "CloudFront Distribution",
    },
    dimensionName: "DistributionId",
    otherDimensions: ["FunctionName", "Region"],
    namespace: "AWS/CloudFront",
    metrics: [
      {
        label: "Requests",
        value: "Requests",
      },
      {
        label: "4xx Error Rate",
        value: "4xxErrorRate",
      },

      {
        label: "5xx Error Rate",
        value: "5xxErrorRate",
      },

      {
        label: "Cache Hit Rate",
        value: "CacheHitRate",
      },

      {
        label: "Origin Latency",
        value: "OriginLatency",
      },
    ],
  },
  {
    type: {
      value: "AWS::CodeBuild::Project",
      label: "CodeBuild Project",
    },
    dimensionName: "BuildId",
    otherDimensions: ["BuildNumber", "ProjectName"],
    namespace: "AWS/CodeBuild",
    metrics: [
      {
        label: "Builds",
        value: "Builds",
      },
      {
        label: "Duration",
        value: "Duration",
      },
      {
        label: "Failed Builds",
        value: "FailedBuilds",
      },
      {
        label: "Provisioning Duration",
        value: "ProvisioningDuration",
      },
      {
        label: "Succeeded Builds",
        value: "SucceededBuilds",
      },
    ],
  },
  {
    type: {
      value: "AWS::ApiGateway::Account",
      label: "API",
    },
    dimensionName: "ApiName",
    otherDimensions: ["Method", "Resource", "Stage"],
    namespace: "AWS/CodeBuild",
    metrics: [
      {
        label: "4XX Error",
        value: "4XXError",
      },
      {
        label: "5XX Error",
        value: "5XXError",
      },
      {
        label: "Cache Hit Count",
        value: "CacheHitCount",
      },
      {
        label: "Cache Miss Count",
        value: "CacheMissCount",
      },
      {
        label: "Integration Latency",
        value: "IntegrationLatency",
      },
      {
        label: "Latency",
        value: "Latency",
      },
      {
        label: "Count",
        value: "Count",
      },
    ],
  },
  {
    type: {
      value: "AWS::SQS::Queue",
      label: "SQS",
    },
    dimensionName: "QueueName",
    otherDimensions: [],
    namespace: "AWS/SQS",
    metrics: [
      {
        label: "Number Of Messages Sent",
        value: "NumberOfMessagesSent",
      },
      {
        label: "Number Of Messages Received",
        value: "NumberOfMessagesReceived",
      },
      {
        label: "Number Of Empty Receives",
        value: "NumberOfEmptyReceives",
      },
      {
        label: "Approximate Number Of Messages Visible",
        value: "ApproximateNumberOfMessagesVisible",
      },
      {
        label: "Approximate Number Of Messages Delayed",
        value: "ApproximateNumberOfMessagesDelayed",
      },
    ],
  },
  {
    type: {
      value: "AWS::WorkSpaces::Workspace",
      label: "WorkSpace",
    },
    dimensionName: "WorkspaceId",
    otherDimensions: ["DirectoryId", "CertificateId"],
    namespace: "AWS/WorkSpaces",
    metrics: [
      { label: "Available", value: "Available" },
      { label: "Unhealthy", value: "Unhealthy" },
      { label: "Connection Attempt", value: "ConnectionAttempt" },
      { label: "Connection Success", value: "ConnectionSuccess" },
      { label: "Connection Failure", value: "ConnectionFailure" },
      { label: "Session Launch Time", value: "SessionLaunchTime" },
      { label: "In Session Latency", value: "InSessionLatency" },
      { label: "Session Disconnect", value: "SessionDisconnect" },
      { label: "User Connected", value: "UserConnected" },
      { label: "Stopped", value: "Stopped" },
      { label: "Maintenance", value: "Maintenance" },
    ],
  },
  {
    type: {
      value: "AWS::DocDB::DBCluster",
      label: "DocumentDBCluster",
    },
    dimensionName: "DBClusterIdentifier",
    otherDimensions: ["DBInstanceIdentifier", "Role"],
    namespace: "AWS/DocDB",
    metrics: [
      { label: "Free Local Storage", value: "FreeLocalStorage" }, //rds 
      { label: "CPU Utilization ", value: "CPUUtilization" },
      { label: "Database Connections", value: "DatabaseConnections" },
      { label: "Freeable Memory", value: "FreeableMemory" },
      { label: "Transactions Open", value: "TransactionsOpen" },
    ],
  },
];
