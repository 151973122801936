export const SAVE_CUSTOM_TASK_NAME = "SAVE_CUSTOM_TASK_NAME";
export const SAVE_CUSTOM_TASK_TYPE = "SAVE_CUSTOM_TASK_TYPE";
export const SAVE_CUSTOM_TASK_DESCRIPTION = "SAVE_CUSTOM_TASK_DESCRIPTION";
export const SAVE_RUNTIME_VERSION = "SAVE_RUNTIME_VERSION";
export const SAVE_CUSTOM_TASK_NEXT_STEP_STATE =
  "SAVE_CUSTOM_TASK_NEXT_STEP_STATE";
export const CLEAR_CUSTOM_TASK_DATA = "CLEAR_CUSTOM_TASK_DATA";
export const UPDATE_TASK_SEARCH_KEY = "UPDATE_TASK_SEARCH_KEY";
export const UPDATE_TASK_CATEGORY_FILTER = "UPDATE_TASK_CATEGORY_FILTER";
export const ENABLE_ADMIN_TASK_REQUEST = "ENABLE_ADMIN_TASK_REQUEST";
export const ENABLE_ADMIN_TASK_SUCCESS = "ENABLE_ADMIN_TASK_SUCCESS";
export const ENABLE_ADMIN_TASK_FAILURE = "ENABLE_ADMIN_TASK_FAILURE";
export const GET_CUSTOM_TASK_MANIFEST_REQUEST =
  "GET_CUSTOM_TASK_MANIFEST_REQUEST";
export const GET_CUSTOM_TASK_MANIFEST_SUCCESS =
  "GET_CUSTOM_TASK_MANIFEST_SUCCESS";
export const GET_CUSTOM_TASK_MANIFEST_FAILURE =
  "GET_CUSTOM_TASK_MANIFEST_FAILURE";
export const CREATE_CUSTOM_TASK_REQUEST = "CREATE_CUSTOM_TASK_REQUEST";
export const CREATE_CUSTOM_TASK_SUCCESS = "CREATE_CUSTOM_TASK_SUCCESS";
export const CREATE_CUSTOM_TASK_FAILURE = "CREATE_CUSTOM_TASK_FAILURE";
export const DELETE_CUSTOM_TASK_REQUEST = "DELETE_CUSTOM_TASK_REQUEST";
export const DELETE_CUSTOM_TASK_SUCCESS = "DELETE_CUSTOM_TASK_SUCCESS";
export const DELETE_CUSTOM_TASK_FAILURE = "DELETE_CUSTOM_TASK_FAILURE";
export const SAVE_IMPORTED_TASKS = "SAVE_IMPORTED_TASKS";
export const IMPORT_TASKS_REQUEST = "IMPORT_TASKS_REQUEST";
export const IMPORT_TASKS_SUCCESS = "IMPORT_TASKS_SUCCESS";
export const IMPORT_TASKS_FAILURE = "IMPORT_TASKS_FAILURE";
export const GET_TASK_EXECUTION_HISTORY_REQUEST =
  "GET_TASK_EXECUTION_HISTORY_REQUEST";
export const GET_TASK_EXECUTION_HISTORY_SUCCESS =
  "GET_TASK_EXECUTION_HISTORY_SUCCESS";
export const GET_TASK_EXECUTION_HISTORY_FAILURE =
  "GET_TASK_EXECUTION_HISTORY_FAILURE";
export const GET_TASK_EXECUTION_DETAILS_REQUEST =
  "GET_TASK_EXECUTION_DETAILS_REQUEST";
export const GET_TASK_EXECUTION_DETAILS_SUCCESS =
  "GET_TASK_EXECUTION_DETAILS_SUCCESS";
export const GET_TASK_EXECUTION_DETAILS_FAILURE =
  "GET_TASK_EXECUTION_DETAILS_FAILURE";
export const UPDATE_CUSTOM_TASK_REQUEST = "UPDATE_CUSTOM_TASK_REQUEST";
export const UPDATE_CUSTOM_TASK_SUCCESS = "UPDATE_CUSTOM_TASK_SUCCESS";
export const UPDATE_CUSTOM_TASK_FAILURE = "UPDATE_CUSTOM_TASK_FAILURE";
export const SAVE_TASK_DETAILS = "SAVE_TASK_DETAILS";
export const CLEAR_DELETE_TASK_DATA = "CLEAR_DELETE_TASK_DATA";
export const CLEAR_IMPORT_TASKS_DATA = "CLEAR_IMPORT_TASKS_DATA";
export const DISABLE_TASK_SUCCESS = "DISABLE_TASK_SUCCESS";
export const DISABLE_TASK_REQUEST = "DISABLE_TASK_REQUEST";
export const DISABLE_TASK_FAILURE = "DISABLE_TASK_FAILURE";
export const UPDATE_TASK_PERMISSIONS_REQUEST =
  "UPDATE_TASK_PERMISSIONS_REQUEST";
export const UPDATE_TASK_PERMISSIONS_SUCCESS =
  "UPDATE_TASK_PERMISSIONS_SUCCESS";
export const UPDATE_TASK_PERMISSIONS_FAILURE =
  "UPDATE_TASK_PERMISSIONS_FAILURE";
export const RESET_TASK_PERMISSION_STATUS = "RESET_TASK_PERMISSION_STATUS";

export const SAVE_SELECTED_PARAMETERS = "SAVE_SELECTED_PARAMETERS";
