export const getClassNames = (color) => {
  let _classNames = "";
  switch (color) {
    case "mc-orange":
      _classNames = "btn-mc-orange";
      break;
    case "mc-blue":
      _classNames = "btn-mc-blue";
      break;
    case "primary":
      _classNames = "btn-primary";
      break;
    case "danger":
      _classNames = "btn-danger";
      break;
    case "success":
      _classNames = "btn-success";
      break;
    case "white":
      _classNames = "btn-white";
      break;
    default:
      _classNames = "btn-default";
      break;
  }
  return _classNames;
};
