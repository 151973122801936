//Run Checks
export const START_CHECKS_EXECUTION_REQUEST = "START_CHECKS_EXECUTION_REQUEST";
export const START_CHECKS_EXECUTION_SUCCESS = "START_CHECKS_EXECUTION_SUCCESS";
export const START_CHECKS_EXECUTION_FAILURE = "START_CHECKS_EXECUTION_FAILURE";

//Get Check Summary
export const SUPPRESS_FINDING_REQUEST = "SUPPRESS_FINDING_REQUEST";
export const SUPPRESS_FINDING_SUCCESS = "SUPPRESS_FINDING_SUCCESS";
export const SUPPRESS_FINDING_FAILURE = "SUPPRESS_FINDING_FAILURE";
export const CLEAR_SUPPRESS_FINDING = "CLEAR_SUPPRESS_FINDING";

//Re-run checks
export const SHOW_RE_RUN_CHECKS_CONFIRMATION_MODAL =
  "SHOW_RE_RERUN_CHECKS_CONFIRMATION_MODAL";
export const HIDE_RE_RUN_CHECKS_CONFIRMATION_MODAL =
  "HIDE_RE_RUN_CHECKS_CONFIRMATION_MODAL";
