import config from "../../config";
import { CURRENT_ORG } from "../../utils/app-constants.json";
import { isArrayWithLength } from "../../utils/array-methods";
import { getCloudProvider } from "../../utils/common";
import { parseValidJsonOrFallback } from "../../utils/json";

const getCommonAnalyticsPayload = ({ userId, organizations }) => {
  const source = window.location.href;
  const environment = config.environment;
  let organizationId = null;
  let organizationName = null;
  let isTenant = false;
  if (isArrayWithLength(organizations)) {
    organizationId = organizations[0].ID;
    organizationName = organizations[0].Name;
    isTenant = organizations[0].ParentOrganizationId === null ? false : true;
  }

  return {
    userId: userId,
    environment: environment,
    organizationId: organizationId,
    organizationName: organizationName,
    isTenant: isTenant,
    source: source,
  };
};
export const getBasicAnalyticsPayload = () => {
  const source = window.location.href;
  const environment = config.environment;
  const email = localStorage.getItem("email");
  const currentOrg = parseValidJsonOrFallback(localStorage.getItem(CURRENT_ORG), null);
  const tenantId = currentOrg?.ID ?? "";
  const tenantName = currentOrg?.Name ?? "";
  const cloudProviderType = getCloudProvider();
  return {
    source,
    environment,
    email,
    tenantId,
    tenantName,
    cloudProviderType,
  };
};

export default getCommonAnalyticsPayload;
