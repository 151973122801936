import { withRouter } from "@hocs/withRouter";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loader from "../../../../components/Loader/Loader";
import { CUSTOMER_ROLE_TYPE } from "../../../../utils/app-constants.json";
import { SETUP_MFA_PATH } from "../../../../utils/app-paths.json";
import { getInitialPage } from "../../../../utils/menu-items";

const VerifyMFA = () => {
  const accessType = localStorage.getItem(CUSTOMER_ROLE_TYPE);
  const initialPage = getInitialPage(accessType);
  const { isMFAEnabled, isMFAConfigured } = useSelector(({ auth }) => {
    return {
      isMFAEnabled: auth.isMFAEnabled,
      isMFAConfigured: auth.isMFAConfigured,
    };
  }, shallowEqual);
  const navigate = useNavigate();

  useEffect(() => {
    if (isMFAEnabled && !isMFAConfigured) {
      navigate(SETUP_MFA_PATH);
    } else {
      navigate(initialPage);
    }
  }, [isMFAEnabled, isMFAConfigured, navigate, initialPage]);

  return <Loader />;
};

export default withRouter(VerifyMFA);
