import React from "react";
import classnames from "classnames";

const Loader = ({ className = "" }) => {
  return (
    <div
      className={classnames("loading", className)}
      data-testid={"spinning-loader"}
    >
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  );
};

export default Loader;
