import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import SelectableCard from "../../../components/SelectableCard/SelectableCard";
import ConfigureAzureSubscription from "./ConfigureAzureSubscription/ConfigureAzureSubscription";
import ConnectAccount from "./ConfigureDay2Context";

import Loader from "../../../components/Loader/Loader";
import MCButton from "../../../components/MCButton/MCButton";
import awsImg from "../../../images/resources/AWS.png";
import azureImg from "../../../images/resources/Azure/AzureNameLogo.png";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";
import { isArrayWithLength } from "../../../utils/array-methods";
import {
  getOnboardingTemplate,
  resetAccountOnboarding,
} from "../../account/actions/account";
import {
  getAccounts,
  getAzureSubscriptions,
} from "../../account/actions/accounts";
import { getLoggedInUserDetails } from "../../authentication/actions/auth";
import {
  getAllOrganizations,
  saveOrganization,
  setSelectedCloudProvider,
} from "../../first-run/actions/firstRun";
import { getUserData } from "../../user-data/actions/user-data";
import s from "./NewFirstRun.module.scss";
import ConfigureAccountType from "./ConfigureAccountType";
import { featuresList, isFeatureEnabled } from "../../../config/featureflags";
import config from "../../../config";
import useFeatureEnabled from "../../../hooks/useFeatureEnabled";

const AWS_STEPS = {
  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  CONFIGURE_ACCOUNT: "CONFIGURE_ACCOUNT",
};

const ConfigureCloudProvider = ({ parentNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun"]);
  const { azureEnabled } = useFeatureEnabled();

  const [awsSteps, setAwsSteps] = useState(null);
  const [orgName, setOrgName] = useState("Default");

  const isAzureEnabled = useMemo(() => {
    return config.demoApp ? isFeatureEnabled(featuresList.AZURE) : azureEnabled;
  }, [azureEnabled]);

  const {
    customerAccountStatusSuccess,
    savedOrg,
    companyName,
    filteredUserData,
    organizationsLoaded,
    selectedCloudProvider,
    onboardingScript,
    onboardingUrl,
    isFetchingOnboardingTemplate,
    isSavingOrg,
  } = useSelector(({ addAccount, firstRun, auth, globalConfig }) => {
    return {
      customerAccountStatusSuccess: addAccount.customerAccountStatusSuccess,
      savedOrg: firstRun.savedOrg,
      companyName: auth.companyName,
      filteredUserData: globalConfig.filteredUserData,
      organizationsLoaded: globalConfig.organizationsLoaded,
      selectedCloudProvider: firstRun.selectedCloudProvider,
      onboardingScript: addAccount.onboardingScript,
      onboardingUrl: addAccount.onboardingUrl,
      isFetchingOnboardingTemplate: addAccount.isFetchingOnboardingTemplate,
      isSavingOrg: firstRun.isSavingOrg,
    };
  }, shallowEqual);

  const _organizations = useMemo(
    () =>
      filteredUserData && isArrayWithLength(filteredUserData.Organizations)
        ? filteredUserData.Organizations
        : [],
    [filteredUserData]
  );

  const orgExists = isArrayWithLength(_organizations) || savedOrg;

  const isAzure =
    selectedCloudProvider === CLOUDPROVIDERS.AZURE &&
    orgExists &&
    onboardingScript
      ? true
      : false;

  const saveOrg = useCallback(
    (onSuccess) => {
      dispatch(saveOrganization({ Name: companyName || orgName }, onSuccess));
    },
    [companyName, orgName, dispatch]
  );

  const handleChangeCloudProvider = useCallback(
    (cloudProvider) => {
      dispatch(setSelectedCloudProvider(cloudProvider));
    },
    [dispatch]
  );

  const handleProceedToConnectAccount = useCallback(() => {
    if (orgExists) {
      dispatch(getOnboardingTemplate());
    } else {
      const onSuccess = () => {
        dispatch(getOnboardingTemplate());
      };
      saveOrg(onSuccess);
    }
  }, [orgExists, saveOrg, dispatch]);

  const handleCloudProviderNextStep = useCallback(() => {
    if (selectedCloudProvider === CLOUDPROVIDERS.AWS) {
      setAwsSteps(AWS_STEPS.ACCOUNT_TYPE);
    } else {
      handleProceedToConnectAccount();
    }
  }, [handleProceedToConnectAccount, selectedCloudProvider]);

  const awsContent = (
    <img
      loading="lazy"
      height={50}
      src={awsImg}
      alt="monty-cloud-aws"
      className={s.imgAwsCard}
    />
  );
  const azureContent = (
    <img
      loading="lazy"
      height={30}
      src={azureImg}
      alt="monty-cloud-azure"
      className={s.imgAzureCard}
    />
  );

  const isLoading =
    (isSavingOrg ||
      (isFetchingOnboardingTemplate &&
        selectedCloudProvider === CLOUDPROVIDERS.AZURE)) &&
    !(onboardingUrl || onboardingScript);

  useEffect(() => {
    if (customerAccountStatusSuccess) {
      dispatch(getAllOrganizations());
      dispatch(getAccounts());
      dispatch(getAzureSubscriptions());
      dispatch(getLoggedInUserDetails());
      dispatch(getUserData());
    }
  }, [dispatch, customerAccountStatusSuccess]);

  useEffect(() => {
    if (isArrayWithLength(_organizations)) {
      const org = _organizations[0].Name || "";
      if (org) {
        setOrgName(org);
      }
    }
  }, [dispatch, _organizations]);

  useEffect(() => {
    if (!organizationsLoaded) {
      dispatch(getAllOrganizations());
    }
  }, [dispatch, organizationsLoaded]);

  useEffect(() => {
    return () => {
      dispatch(resetAccountOnboarding());
      dispatch(setSelectedCloudProvider(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isAzureEnabled) {
      handleChangeCloudProvider(CLOUDPROVIDERS.AWS);
      handleCloudProviderNextStep();
    }
  }, [handleChangeCloudProvider, handleCloudProviderNextStep, isAzureEnabled]);

  if (isLoading) {
    return (
      <div
        className={classnames(
          s.welcomeDivLarge,
          s.ContentBackground,
          s.ConnectAccountContainer
        )}
      >
        <Loader />
      </div>
    );
  } else if (awsSteps || isAzure) {
    if (isAzure) {
      return <ConfigureAzureSubscription parentNext={parentNext} />;
    }

    switch (awsSteps) {
      case AWS_STEPS.ACCOUNT_TYPE:
        return (
          <ConfigureAccountType
            previousStep={() => {
              handleChangeCloudProvider(null);
              setAwsSteps(null);
            }}
            nextStep={() => {
              setAwsSteps(AWS_STEPS.CONFIGURE_ACCOUNT);
              handleProceedToConnectAccount();
            }}
            disablePreviousStep={!isAzureEnabled}
          />
        );
      case AWS_STEPS.CONFIGURE_ACCOUNT:
        return (
          <ConnectAccount
            parentNext={parentNext}
            frePreviousStep={() => {
              dispatch(resetAccountOnboarding());
              setAwsSteps(AWS_STEPS.ACCOUNT_TYPE);
            }}
          />
        );
      default:
        return <div>Default</div>;
    }
  }

  return (
    <div
      className={classnames(
        s.welcomeDivLarge,
        s.ContentBackground,
        s.ConnectAccountContainer
      )}
    >
      <Row className="bs4-px-4 h-100">
        <Col lg={7} className="bs4-text-center">
          <div className="bs4-text-center">
            <img
              loading="lazy"
              src={"/images/dashboard/securely-connect-account.png"}
              alt="day2"
              className={s.ConnectAccountImage}
            />
          </div>
        </Col>
        <Col lg={5}>
          <div className="d-flex flex-column h-100 align-items-center">
            <div
              className={classnames(
                s.MCHeading3,
                s.Bold600,
                "bs4-mb-4",
                s.selectCloudProviderHeading
              )}
            >
              {t("selectCloudProvider")}
            </div>
            <div className="bs4-mt-4 d-flex justify-content-around">
              <div className="bs4-mr-3">
                <SelectableCard
                  content={awsContent}
                  selected={selectedCloudProvider === CLOUDPROVIDERS.AWS}
                  handleSelect={() =>
                    handleChangeCloudProvider(CLOUDPROVIDERS.AWS)
                  }
                />
              </div>
              <div className="bs4-ml-4">
                <SelectableCard
                  content={azureContent}
                  selected={selectedCloudProvider === CLOUDPROVIDERS.AZURE}
                  handleSelect={() =>
                    handleChangeCloudProvider(CLOUDPROVIDERS.AZURE)
                  }
                />
              </div>
            </div>
            <div
              className={classnames(s.selectCloudProviderNextContainer, "w-75")}
            >
              <MCButton
                size="lg"
                disabled={!selectedCloudProvider}
                text={
                  <>
                    {t("common:next")}
                    <i className="fa fa-caret-right bs4-ml-2" />
                  </>
                }
                color="mc-blue"
                onClick={handleCloudProviderNextStep}
                className={classnames(
                  s.robotoFont,
                  s.selectCloudProviderNextButton
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConfigureCloudProvider;
