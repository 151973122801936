import {
  CREATE_MAP_PROJECT_FAILURE,
  CREATE_MAP_PROJECT_REQUEST,
  CREATE_MAP_PROJECT_SUCCESS,
  DISABLE_NEXT_STEP,
  MAP_PROJECT_SAVE_DETAILS_FORM,
  RESET_MAP_PROJECT_FORM,
  SET_TARGET_ACCOUNTS,
  SET_MANAGEMENT_ACCOUNT,
  SET_MIGRATION_TAGS,
} from "../actionTypes/details";

const generateTags = (existingTags = []) => {
  return existingTags.map((tag, index) => ({ ...tag, id: index }));
};

const initialState = {
  name: "",
  description: "",
  mpeNumber: "",
  fromDate: "",
  toDate: "",
  migrationTags: generateTags([
    {
      Key: "",
      Value: "",
    },
  ]),
  disableNextStep: false,
  errorMessage: null,
  createdProject: false,
  isCreating: false,
  creatingFailed: false,
  isProjectScopeValid: true,
  managementAccount: null,
  targetAccounts: [],
};

const mapProjectReviewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAP_PROJECT_SAVE_DETAILS_FORM: {
      const { field, value } = payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case CREATE_MAP_PROJECT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };

    case CREATE_MAP_PROJECT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdProject: true,
      };

    case CREATE_MAP_PROJECT_FAILURE:
      return {
        ...state,
        isCreating: false,
        creatingFailed: true,
        errorMessage: payload,
      };

    case DISABLE_NEXT_STEP: {
      return {
        ...state,
        disableNextStep: payload,
      };
    }
    case RESET_MAP_PROJECT_FORM: {
      return {
        ...initialState,
      };
    }
    case SET_MIGRATION_TAGS: {
      return {
        ...state,
        migrationTags: payload,
      };
    }
    case SET_MANAGEMENT_ACCOUNT: {
      return {
        ...state,
        managementAccount: payload,
      };
    }
    case SET_TARGET_ACCOUNTS: {
      return {
        ...state,
        targetAccounts: payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default mapProjectReviewReducer;
