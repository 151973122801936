import {
  ConvertByteArrayToString,
  bytesToLong,
  bytesToUuidString,
  bytesToInt32,
  ab2str
} from "./deserialize_utility";

const ClientMessageHLLength = 4;
const ClientMessageMessageTypeLength = 32;
const ClientMessageSchemaVersionLength = 4;
const ClientMessageCreatedDateLength = 8;
const ClientMessageSequenceNumberLength = 8;
const ClientMessageFlagsLength = 8;
const ClientMessageMessageIdLength = 16;
const ClientMessagePayloadDigestLength = 32;
const ClientMessagePayloadTypeLength = 4;
const ClientMessagePayloadLengthLength = 4;

const ClientMessageHLOffset = 0;
const ClientMessageMessageTypeOffset =
  ClientMessageHLOffset + ClientMessageHLLength;
const ClientMessageSchemaVersionOffset =
  ClientMessageMessageTypeOffset + ClientMessageMessageTypeLength;
const ClientMessageCreatedDateOffset =
  ClientMessageSchemaVersionOffset + ClientMessageSchemaVersionLength;
const ClientMessageSequenceNumberOffset =
  ClientMessageCreatedDateOffset + ClientMessageCreatedDateLength;
const ClientMessageFlagsOffset =
  ClientMessageSequenceNumberOffset + ClientMessageSequenceNumberLength;
const ClientMessageMessageIdOffset =
  ClientMessageFlagsOffset + ClientMessageFlagsLength;
const ClientMessagePayloadDigestOffset =
  ClientMessageMessageIdOffset + ClientMessageMessageIdLength;
const ClientMessagePayloadTypeOffset =
  ClientMessagePayloadDigestOffset + ClientMessagePayloadDigestLength;

export function deserializeClientMessage(serializedClientMessage) {
  // console.log("serializedClientMessage", serializedClientMessage);
  const headerLength = deserializeClientMessageHeaderLength(
    serializedClientMessage
  );

  // console.log("headerLength", headerLength);
  return {
    MessageType: ConvertByteArrayToString(
      serializedClientMessage,
      ClientMessageMessageTypeOffset,
      ClientMessageMessageTypeOffset + ClientMessageMessageTypeLength
    ),
    SequenceNumber: bytesToLong(
      serializedClientMessage,
      ClientMessageSequenceNumberOffset,
      ClientMessageSequenceNumberOffset + ClientMessageSequenceNumberLength
    ),
    MessageId: bytesToUuidString(
      serializedClientMessage,
      ClientMessageMessageIdOffset
    ),
    PayloadType: bytesToInt32(
      serializedClientMessage.slice(
        ClientMessagePayloadTypeOffset,
        ClientMessagePayloadTypeOffset + ClientMessagePayloadTypeLength
      )
      // serializedClientMessage,
      // ClientMessagePayloadTypeOffset,
    ),
    Payload: ab2str(
      serializedClientMessage.slice(
        headerLength + ClientMessagePayloadLengthLength
      )
    )
  };
}

/**
 * Get header length
 * @param {*} serializedClientMessage
 */
function deserializeClientMessageHeaderLength(serializedClientMessage) {
  const headerLength = bytesToLong(
    serializedClientMessage,
    ClientMessageHLOffset,
    ClientMessageHLOffset + ClientMessageHLLength
  );
  return headerLength;
}

/**
 * Get header length
 * @param {*} serializedClientMessage
 */
export function deserializeClientMessageHeaderLengthNew(
  serializedClientMessage
) {
  const serializedClientMessageHeaderPart = serializedClientMessage.slice(
    ClientMessageHLOffset,
    ClientMessageHLOffset + ClientMessageHLLength
  );
  const headerLength = bytesToInt32(serializedClientMessageHeaderPart);
  return headerLength;
}

export function getDigest(serializedMessage) {
  const startIndex = ClientMessagePayloadDigestOffset;
  const endIndex =
    ClientMessagePayloadDigestOffset + ClientMessagePayloadDigestLength;
  const digestByteArray = serializedMessage.slice(startIndex, endIndex);
  return ab2str(digestByteArray);
}
