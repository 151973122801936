import {
  SUBSCRIPTION,
  CUSTOMER_PREFERENCE,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const subscribeToPlan = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, SUBSCRIPTION, params);
};

export const subscribeToTrialPlan = (id, params) => {
  // this is for old freemium users
  return getApiService(WEB_REQUEST_TYPE.PUT, `${SUBSCRIPTION}/${id}`, params);
};

export const saveCustomerPreference = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, CUSTOMER_PREFERENCE, params);
};

export const getCustomerPreferenceApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, CUSTOMER_PREFERENCE);
};
