import {
  SAVE_CUSTOM_TASK_TYPE,
  SAVE_CUSTOM_TASK_NAME,
  SAVE_CUSTOM_TASK_DESCRIPTION,
  SAVE_CUSTOM_TASK_NEXT_STEP_STATE,
  SAVE_RUNTIME_VERSION,
  CLEAR_CUSTOM_TASK_DATA,
  UPDATE_TASK_SEARCH_KEY,
  UPDATE_TASK_CATEGORY_FILTER,
  ENABLE_ADMIN_TASK_REQUEST,
  ENABLE_ADMIN_TASK_SUCCESS,
  ENABLE_ADMIN_TASK_FAILURE,
  GET_CUSTOM_TASK_MANIFEST_REQUEST,
  GET_CUSTOM_TASK_MANIFEST_SUCCESS,
  GET_CUSTOM_TASK_MANIFEST_FAILURE,
  CREATE_CUSTOM_TASK_REQUEST,
  CREATE_CUSTOM_TASK_SUCCESS,
  CREATE_CUSTOM_TASK_FAILURE,
  DELETE_CUSTOM_TASK_REQUEST,
  DELETE_CUSTOM_TASK_SUCCESS,
  DELETE_CUSTOM_TASK_FAILURE,
  SAVE_IMPORTED_TASKS,
  IMPORT_TASKS_REQUEST,
  IMPORT_TASKS_SUCCESS,
  IMPORT_TASKS_FAILURE,
  GET_TASK_EXECUTION_HISTORY_REQUEST,
  GET_TASK_EXECUTION_HISTORY_SUCCESS,
  GET_TASK_EXECUTION_HISTORY_FAILURE,
  GET_TASK_EXECUTION_DETAILS_REQUEST,
  GET_TASK_EXECUTION_DETAILS_SUCCESS,
  GET_TASK_EXECUTION_DETAILS_FAILURE,
  UPDATE_CUSTOM_TASK_REQUEST,
  UPDATE_CUSTOM_TASK_SUCCESS,
  UPDATE_CUSTOM_TASK_FAILURE,
  SAVE_TASK_DETAILS,
  CLEAR_DELETE_TASK_DATA,
  CLEAR_IMPORT_TASKS_DATA,
  DISABLE_TASK_SUCCESS,
  DISABLE_TASK_REQUEST,
  DISABLE_TASK_FAILURE,
  UPDATE_TASK_PERMISSIONS_REQUEST,
  UPDATE_TASK_PERMISSIONS_SUCCESS,
  UPDATE_TASK_PERMISSIONS_FAILURE,
  RESET_TASK_PERMISSION_STATUS,
  SAVE_SELECTED_PARAMETERS,
} from "../actionTypes/task";

import { categoryFilter } from "../../../utils/task-types";

const pythonVersions = [
  {
    label: "Python 3.6",
    value: "Python 3.6",
  },
  // {
  //   label: "Python 3.7",
  //   value: "Python 3.7",
  // },
];

const powershellVersions = [
  {
    label: "PowerShell Core 6.0",
    value: "PowerShell Core 6.0",
  },
];

const initialState = {
  taskType: "",
  taskName: null,
  taskDescription: "",
  disableTaskNextStep: true,
  runtimeVersion: "",
  runtimeVersions: "",
  showRuntimeVersion: false,
  taskSearchKey: "",
  categoryFilter: categoryFilter,
  settingEnableStatus: false,
  settingEnableStatusSuccess: false,
  settingEnableStatusFailure: false,
  loadingManifest: false,
  customTaskManifest: {},
  manifestLoadFailed: false,
  creatingCustomTask: false,
  customTaskCreated: false,
  customTaskCreateFailed: false,
  deletingCustomTask: false,
  deletedCustomTask: false,
  deleteCustomTaskFailed: false,
  selectedImportedTasks: [],
  importingTasks: false,
  tasksImported: false,
  importTaskFailed: false,
  fetchingTaskJobs: false,
  taskJobs: [],
  fetchTaskJobsFailed: false,
  fetchingTaskExecutionDetails: false,
  jobTaskDetails: null,
  fetchingTaskExecutionDetailsFailed: false,
  updatingCustomTask: false,
  customTaskUpdated: false,
  updateTaskCreateFailed: false,
  disablingTask: false,
  disabledTask: false,
  errorDisablingTask: false,
  savingTaskPermissions: false,
  savingTaskPermissionsSuccess: false,
  savingTaskPermissionsFailure: false,
  selectedParameters: [],
};

const adminTask = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOM_TASK_TYPE:
      const pythonTask = action.payload.value.includes("PythonScript");
      const powershellTask = action.payload.value.includes("Powershell");
      const showRuntimeVersion = pythonTask || powershellTask;
      return {
        ...state,
        taskType: action.payload,
        showRuntimeVersion: showRuntimeVersion,
        runtimeVersion: "",
        runtimeVersions:
          showRuntimeVersion &&
          (pythonTask ? pythonVersions : powershellVersions),
      };
    case UPDATE_TASK_SEARCH_KEY:
      return {
        ...state,
        taskSearchKey: action.payload,
      };
    case UPDATE_TASK_CATEGORY_FILTER:
      return {
        ...state,
        categoryFilter: action.payload,
      };
    case SAVE_IMPORTED_TASKS:
      return {
        ...state,
        selectedImportedTasks: action.payload,
      };
    case SAVE_CUSTOM_TASK_NAME:
      return {
        ...state,
        taskName: action.payload,
      };
    case SAVE_CUSTOM_TASK_DESCRIPTION:
      return {
        ...state,
        taskDescription: action.payload,
      };
    case SAVE_CUSTOM_TASK_NEXT_STEP_STATE:
      return {
        ...state,
        disableTaskNextStep: action.payload,
      };
    case SAVE_RUNTIME_VERSION:
      return {
        ...state,
        runtimeVersion: action.payload,
      };
    case SAVE_SELECTED_PARAMETERS:
      return {
        ...state,
        selectedParameters: action.payload,
      };
    case CLEAR_CUSTOM_TASK_DATA:
      return {
        ...state,
        ...initialState,
        customTaskManifest: state.customTaskManifest,
        taskSearchKey: state.taskSearchKey,
      };
    case ENABLE_ADMIN_TASK_REQUEST:
      return {
        ...state,
        settingEnableStatus: true,
        settingEnableStatusSuccess: false,
        settingEnableStatusFailure: false,
      };
    case ENABLE_ADMIN_TASK_SUCCESS:
      return {
        ...state,
        settingEnableStatus: false,
        settingEnableStatusSuccess: true,
      };
    case ENABLE_ADMIN_TASK_FAILURE:
      return {
        ...state,
        settingEnableStatus: false,
        settingEnableStatusFailure: true,
      };
    case UPDATE_TASK_PERMISSIONS_REQUEST:
      return {
        ...state,
        savingTaskPermissions: true,
        savingTaskPermissionsSuccess: initialState.savingTaskPermissions,
        savingTaskPermissionsFailure: initialState.savingTaskPermissionsFailure,
      };
    case UPDATE_TASK_PERMISSIONS_SUCCESS:
      return {
        ...state,
        savingTaskPermissions: false,
        savingTaskPermissionsSuccess: true,
      };
    case UPDATE_TASK_PERMISSIONS_FAILURE:
      return {
        ...state,
        savingTaskPermissions: false,
        savingTaskPermissionsFailure: true,
      };
    case RESET_TASK_PERMISSION_STATUS:
      return {
        ...state,
        savingTaskPermissions: initialState.savingTaskPermissions,
        savingTaskPermissionsSuccess: initialState.savingTaskPermissions,
        savingTaskPermissionsFailure: initialState.savingTaskPermissionsFailure,
      };
    case GET_CUSTOM_TASK_MANIFEST_REQUEST:
      return {
        ...state,
        loadingManifest: true,
      };
    case GET_CUSTOM_TASK_MANIFEST_SUCCESS:
      return {
        ...state,
        loadingManifest: false,
        customTaskManifest: action.payload,
      };
    case GET_CUSTOM_TASK_MANIFEST_FAILURE:
      return {
        ...state,
        loadingManifest: false,
        manifestLoadFailed: true,
      };
    case CREATE_CUSTOM_TASK_REQUEST:
      return {
        ...state,
        creatingCustomTask: true,
      };
    case CREATE_CUSTOM_TASK_SUCCESS:
      return {
        ...state,
        creatingCustomTask: false,
        customTaskCreated: true,
      };
    case CREATE_CUSTOM_TASK_FAILURE:
      return {
        ...state,
        creatingCustomTask: false,
        customTaskCreateFailed: true,
      };
    case DELETE_CUSTOM_TASK_REQUEST:
      return {
        ...state,
        deletingCustomTask: true,
      };
    case DELETE_CUSTOM_TASK_SUCCESS:
      return {
        ...state,
        deletingCustomTask: false,
        deletedCustomTask: true,
      };
    case DELETE_CUSTOM_TASK_FAILURE:
      return {
        ...state,
        deletingCustomTask: false,
        deleteCustomTaskFailed: true,
      };
    case IMPORT_TASKS_REQUEST:
      return {
        ...state,
        importingTasks: true,
        tasksImported: false,
        importTaskFailed: false,
      };
    case IMPORT_TASKS_SUCCESS:
      return {
        ...state,
        importingTasks: false,
        tasksImported: true,
      };
    case IMPORT_TASKS_FAILURE:
      return {
        ...state,
        importingTasks: false,
        importTaskFailed: true,
      };
    case GET_TASK_EXECUTION_HISTORY_REQUEST:
      return {
        ...state,
        fetchingTaskJobs: true,
        fetchTaskJobsFailed: false,
      };
    case GET_TASK_EXECUTION_HISTORY_SUCCESS:
      return {
        ...state,
        fetchingTaskJobs: false,
        taskJobs: action.payload,
      };
    case GET_TASK_EXECUTION_HISTORY_FAILURE:
      return {
        ...state,
        fetchingTaskJobs: false,
        fetchTaskJobsFailed: true,
      };
    case GET_TASK_EXECUTION_DETAILS_REQUEST:
      return {
        ...state,
        fetchingTaskExecutionDetails: true,
        fetchingTaskExecutionDetailsFailed: false,
      };
    case GET_TASK_EXECUTION_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingTaskExecutionDetails: false,
        jobTaskDetails: action.payload,
      };
    case GET_TASK_EXECUTION_DETAILS_FAILURE:
      return {
        ...state,
        fetchingTaskExecutionDetails: false,
        fetchingTaskExecutionDetailsFailed: true,
      };
    case UPDATE_CUSTOM_TASK_REQUEST:
      return {
        ...state,
        updatingCustomTask: true,
      };
    case UPDATE_CUSTOM_TASK_SUCCESS:
      return {
        ...state,
        updatingCustomTask: false,
        customTaskUpdated: true,
      };
    case UPDATE_CUSTOM_TASK_FAILURE:
      return {
        ...state,
        updatingCustomTask: false,
        updateTaskCreateFailed: true,
      };
    case SAVE_TASK_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_DELETE_TASK_DATA:
      return {
        ...state,
        deletedCustomTask: initialState.deletedCustomTask,
      };
    case DISABLE_TASK_REQUEST:
      return {
        ...state,
        disablingTask: true,
        disabledTask: false,
        errorDisablingTask: initialState.errorDisablingTask,
      };
    case DISABLE_TASK_SUCCESS:
      return {
        ...state,
        disablingTask: false,
        disabledTask: true,
      };
    case DISABLE_TASK_FAILURE:
      return {
        ...state,
        disablingTask: false,
        disabledTask: false,
        errorDisablingTask: true,
      };
    case CLEAR_IMPORT_TASKS_DATA:
      return {
        ...state,
        categoryFilter: initialState.categoryFilter,
        selectedImportedTasks: initialState.selectedImportedTasks,
        importingTasks: initialState.importingTasks,
        tasksImported: initialState.tasksImported,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminTask;
