import React from "react";
import { FeatureToggle, FeatureToggleProvider } from "react-feature-toggles";
import config from "../../config";
import useFeatureEnabled from "../../hooks/useFeatureEnabled";
import featureFlagsDemo from "./feature-flags-demo.json";
import featureFlagsProd from "./feature-flags-prod.json";
import { featureList } from "./feature-list";

export const featuresList = featureList;

const featureFlagList = config.demoApp
  ? config.demoProd
    ? featureFlagsProd
    : featureFlagsDemo
  : featureFlagsProd;

const FeatureFlag = (props) => {
  return (
    <FeatureToggleProvider featureToggleList={featureFlagList}>
      <FeatureToggle
        featureName={props.featureName}
        showOnlyWhenDisabled={props.showOnlyWhenDisabled}
      >
        {props.children}
      </FeatureToggle>
    </FeatureToggleProvider>
  );
};

export const USER_DEFINED_FEATURES = {
  MTEnabled: "MTEnabled",
};

export const FeatureFlagUserFeature = ({ feature, ...props }) => {
  const features = useFeatureEnabled();
  const isFeatureValid = Boolean(features[feature]);
  if (isFeatureValid) {
    return props.children;
  }
  return null;
};

export const isFeatureEnabled = (featureName) => {
  const featureFlags = featureFlagList;
  return featureFlags[featureName];
};

export default FeatureFlag;
