import { toast } from "react-toastify";

import { NOTIFICATIONS_UPDATED } from "../reducers/notifications";

export const postNotification =
  (message, type = "info") =>
  (dispatch, getState) => {
    const { notification } = getState();
    notification.notifications.unshift({ message, time: new Date() });
    dispatch({
      type: NOTIFICATIONS_UPDATED,
      payload: notification.notifications,
    });
    if (type === "danger") {
      toast.error(message, {
        position: "bottom-right",
      });
    } else {
      toast[type](message, {
        position: "bottom-right",
      });
    }
  };
