export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseValidJsonOrFallback = (jsonString, fallback) => {
  if (jsonString && isJson(jsonString)) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return fallback;
    }
  }
  return fallback;
};
