import i18n from "../config/locale/i18n";

const AWS_SUPPORTED_REGIONS = {
  "us-east-1": "US East (North Virginia)",
  "us-east-2": "US East (Ohio)",
  "us-west-1": "US West (North California)",
  "us-west-2": "US West (Oregon)",
  "af-south-1": "Africa (Cape Town)",
  "ap-east-1": "Asia Pacific (Hong Kong)",
  "ap-south-1": "Asia Pacific (Mumbai)",
  "ap-northeast-3": "Asia Pacific (Osaka)",
  "ap-northeast-2": "Asia Pacific (Seoul)",
  "ap-southeast-1": "Asia Pacific (Singapore)",
  "ap-southeast-2": "Asia Pacific (Sydney)",
  "ap-southeast-3": "Asia Pacific (Jakarta)",
  "ap-northeast-1": "Asia Pacific (Tokyo)",
  "ca-central-1": "Canada (Central)",
  "eu-central-1": "EU (Frankfurt)",
  "eu-west-1": "EU (Ireland)",
  "eu-west-2": "EU (London)",
  "eu-west-3": "EU (Paris)",
  "sa-east-1": "South America (Sao Paulo)",
  "eu-south-1": "EU (Milan)",
  "eu-north-1": "EU (Stockholm)",
  "me-south-1": "Middle East (Bahrain)",
  "no-region": i18n.t("common:noRegion"),
  "il-central-1": "Israel (Tel Aviv)",
  global: i18n.t("common:global"),
  "me-central-1": "Middle East (UAE)",
  "eu-south-2": "Europe (Spain)",
  "eu-central-2": "Europe (Zurich)",
  "ca-west-1": "Canada West (Calgary)",
  "ap-southeast-4": "Asia Pacific (Melbourne)",
  "ap-south-2": "Asia Pacific (Hyderabad)",
};

export const getAWSRegionName = (regionCode) => {
  const regionName = AWS_SUPPORTED_REGIONS[regionCode];
  return regionName ? regionName : "-";
};
