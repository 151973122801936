import i18n from "../../config/locale/i18n";
import {
  isArrayWithLength,
  reduceFieldsArrayToParams,
} from "../../utils/array-methods";
import {
  emailRegex,
  numbersOnlyRegex,
  urlRegex,
} from "../../utils/regex-expressions";
import { INPUT_TYPES, DATA_TYPES } from "../../utils/app-constants.json";
import { RULE_KEYS } from "../../utils/rules";
import { getUniqueItems } from "../../utils/array-methods";
import { convertListValueLabel } from "../../utils/convert-select-value";
import { getRegionName } from "../../utils/account-region";

export const ACTION_KEYS = {
  ACTION_TYPE: "action-type",
  CONNECTOR: "connector",
  EVENT_TYPE: "event-type",
  DESTINATION: "destination",
  PAYLOAD_FIELDS: "payload-fields",
  AFTER_EXECUTION: "after-execution",
  BEFORE_EXECUTION: "before-execution",
  STANDALONE: "standalone",
  ACTION: "action",
  SCOPE: "scope",
  CUSTOMIZE_MESSAGE: "customize-message",
};

export const getIntegrationLabel = (type) => {
  let label;
  switch (type) {
    case "Slack":
    case "Teams":
      label = i18n.t("common:webhookURL");
      break;
    case "PagerDuty":
      label = i18n.t("common:integrationKey");
      break;
    case "Severity":
    case "Status":
      label = i18n.t("common:notificationLevel");
      break;
    default:
      break;
  }
  return label;
};

export const getIntegrationPlaceHolder = (type) => {
  let label;
  switch (type) {
    case "Severity":
      label = i18n.t("common:selectKey", {
        label: i18n.t("common:Severities"),
      });
      break;
    case "Status":
      label = i18n.t("common:selectKey", {
        label: i18n.t("common:statusLabel"),
      });
      break;
    default:
      break;
  }
  return label;
};

export const getIntegrationHelperText = (type) => {
  let label;
  switch (type) {
    case "Severity":
      label = i18n.t("bots:severityHelperText");
      break;
    case "Status":
      label = i18n.t("bots:statusHelperText");
      break;
    default:
      break;
  }
  return label;
};

export const validateForm = (values, fields) => {
  const allValueKeys = Object.keys(values);
  const errors = {};
  allValueKeys.forEach((key) => {
    const value = values[key] ? values[key].toString().trim() : "";
    const foundField =
      isArrayWithLength(fields) && fields.find((field) => field.key === key);
    if (foundField) {
      if (foundField.required && !value) {
        errors[key] = i18n.t("common:required");
      } else if (
        foundField.maxLength &&
        value &&
        value.length > foundField.maxLength
      ) {
        errors[key] = i18n.t("common:maxCharAllowed", {
          key: foundField.label,
          maxLength: foundField.maxLength,
        });
      } else if (
        foundField.minLength &&
        value &&
        value.length < foundField.minLength
      ) {
        errors[key] = i18n.t("common:minCharRequired", {
          key: foundField.label,
          minLength: foundField.minLength,
        });
      } else if (
        foundField.type === INPUT_TYPES.EMAIL &&
        !emailRegex.test(value)
      ) {
        errors[key] = i18n.t("common:invalidEmailAddress");
      } else if (foundField.type === INPUT_TYPES.URL && !urlRegex.test(value)) {
        errors[key] = i18n.t("common:pleaseAEnterValid", {
          type: foundField.label,
        });
      }
      if (foundField.key === "AccountNumber") {
        if (value && (value.length !== 12 || !numbersOnlyRegex.test(value))) {
          errors[key] = i18n.t("common:accountNumberError");
        }
      }
    }
  });
  return errors;
};

const getMessagePayload = (fields) => {
  const updatedFields = fields.map((field) => {
    let updatedValue = field.value;
    if (typeof updatedValue === DATA_TYPES.OBJECT) {
      updatedValue = isArrayWithLength(updatedValue)
        ? updatedValue.map((s) => s.value)
        : updatedValue.value || updatedValue;
    }
    return {
      ...field,
      value: updatedValue,
    };
  });
  const params = reduceFieldsArrayToParams(updatedFields);
  return params;
};

export const getActionNode = (actions) => {
  const {
    ACTION_TYPE,
    CONNECTOR,
    DESTINATION,
    PAYLOAD_FIELDS,
    EVENT_TYPE,
    AFTER_EXECUTION,
    BEFORE_EXECUTION,
    STANDALONE,
  } = ACTION_KEYS;
  const request = { Pre: [], Post: [], Standalone: [] };
  isArrayWithLength(actions) &&
    actions.forEach((action) => {
      const messagePayload = getMessagePayload(action[PAYLOAD_FIELDS]);
      const item = {
        Type: action[ACTION_TYPE].value,
        Payload: {
          NotificationChannel: [action[CONNECTOR].category],
          ...messagePayload,
          Scope:
            action[DESTINATION] &&
            ([action[DESTINATION].value] || [action[DESTINATION]]),
        },
      };
      if (action[EVENT_TYPE].value === BEFORE_EXECUTION) {
        request["Pre"].push(item);
      } else if (action[EVENT_TYPE].value === AFTER_EXECUTION) {
        request["Post"].push(item);
      } else if (action[EVENT_TYPE].value === STANDALONE) {
        request["Standalone"].push(item);
      }
    });
  return request;
};

export const getSupportedActions = (integrations) => {
  const actions = [];
  integrations.forEach((integration) => {
    const foundAction =
      isArrayWithLength(actions) &&
      actions.find(
        (action) =>
          (action.actionType && action.actionType.value) ===
          (integration.actionType && integration.actionType.value)
      );
    if (foundAction) {
      foundAction.connectors.push({
        label: integration.name,
        value: integration.service,
        category: integration.category,
      });
    } else {
      if (integration.actionType) {
        actions.push({
          actionType: {
            label: integration.actionType.label,
            value: integration.actionType.value,
          },
          connectors: [
            {
              label: integration.name,
              value: integration.service,
              category: integration.category,
            },
          ],
        });
      }
    }
  });
  return actions;
};

export const saveActionData = (response, actions) => {
  const {
    ACTION_TYPE,
    CONNECTOR,
    DESTINATION,
    PAYLOAD_FIELDS,
    EVENT_TYPE,
    AFTER_EXECUTION,
    BEFORE_EXECUTION,
    STANDALONE,
  } = ACTION_KEYS;

  const allActions = [
    ...(isArrayWithLength(response.Pre)
      ? response.Pre.map((a) => {
          const actionType = {
            label: i18n.t("tasks:beforeExecution"),
            value: BEFORE_EXECUTION,
          };
          return { ...a, actionType };
        })
      : []),
    ...(isArrayWithLength(response.Post)
      ? response.Post.map((a) => {
          const actionType = {
            label: i18n.t("tasks:afterExecution"),
            value: AFTER_EXECUTION,
          };
          return { ...a, actionType };
        })
      : []),

    ...(isArrayWithLength(response?.Standalone || [])
      ? response.Standalone.map((a) => {
          const actionType = {
            label: i18n.t("tasks:standalone"),
            value: STANDALONE,
          };
          return { ...a, actionType };
        })
      : []),
  ];
  return allActions.map((action, index) => {
    const selectedAction = actions.find(
      (a) => a.actionType.value === action.Type
    );
    const selectedConnector =
      selectedAction &&
      selectedAction.connectors.find(
        (connector) =>
          connector.category === action.Payload.NotificationChannel[0]
      );
    const selectedDestination = action.Payload.Scope[0];
    const payloadFields = Object.keys(action.Payload)
      .filter(
        (key) =>
          ![RULE_KEYS.NOTIFICATION_CHANNEL, RULE_KEYS.SCOPE].includes(key)
      )
      .map((key) => {
        return {
          value: action.Payload[key],
          apiKey: key,
        };
      });
    return {
      id: index,
      [EVENT_TYPE]: action.actionType,
      [CONNECTOR]: selectedConnector,
      [ACTION_TYPE]: selectedAction.actionType,
      [DESTINATION]: selectedDestination,
      [PAYLOAD_FIELDS]: payloadFields,
    };
  });
};

const COMMON_FILTER_KEYS = {
  ID: "ID",
  NAME: "Name",
  ACCOUNT_NUMBER: "accountNumber",
  ACCOUNT_NAME: "accountName",
  REGION_CODE: "regionCode",
  REGION_NAME: "regionName",
  LABEL: "label",
  VALUE: "value",
};

export const getCommonFiltersData = (data) => {
  let filters = {};
  if (data) {
    const organizations = isArrayWithLength(data.organizations)
      ? convertListValueLabel(
          data.organizations,
          COMMON_FILTER_KEYS.ID,
          COMMON_FILTER_KEYS.NAME
        )
      : [];
    const departments = isArrayWithLength(data.departments)
      ? convertListValueLabel(
          data.departments,
          COMMON_FILTER_KEYS.ID,
          COMMON_FILTER_KEYS.NAME
        )
      : [];
    const accounts = isArrayWithLength(data.accounts)
      ? convertListValueLabel(
          data.accounts,
          COMMON_FILTER_KEYS.ACCOUNT_NUMBER,
          COMMON_FILTER_KEYS.ACCOUNT_NAME
        )
      : [];
    const regions = isArrayWithLength(data.regions)
      ? convertListValueLabel(
          data.regions,
          COMMON_FILTER_KEYS.REGION_CODE,
          COMMON_FILTER_KEYS.REGION_NAME
        )
      : [];
    const applications = isArrayWithLength(data.applications)
      ? convertListValueLabel(
          data.applications,
          COMMON_FILTER_KEYS.ID,
          COMMON_FILTER_KEYS.NAME
        )
      : [];

    const props = [COMMON_FILTER_KEYS.LABEL, COMMON_FILTER_KEYS.VALUE];

    filters.organizations = getUniqueItems(organizations, props);
    filters.departments = getUniqueItems(departments, props);
    filters.accounts = getUniqueItems(accounts, props);
    filters.applications = getUniqueItems(applications, props);
    filters.regions = getUniqueItems(regions, props);
  }
  return filters;
};

export const getAccountRegionsOptions = (accountRegions, regionsList) => {
  const _formattedAccountRegions = isArrayWithLength(accountRegions)
    ? accountRegions.map((reg) => {
        let regionName =
          reg.regionName || getRegionName(reg.regionCode, regionsList, true);
        return {
          ...reg,
          value: reg.regionCode,
          label: regionName,
          isDisabled: reg.isDisabled || false,
        };
      })
    : [];

  return _formattedAccountRegions;
};
