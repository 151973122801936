import mixpanel from "mixpanel-browser";
import AnalyticsService from "../analytics-service";


export default class MixpanelService extends AnalyticsService {
    constructor(config) {
        super();
        mixpanel.init(config.mixPanelKey, {
            debug: true,
            ignore_dnt: true,
        });
    }

    identify(userId) {
        mixpanel.identify(userId);
    }

    track(eventName, properties) {
        mixpanel.track(eventName, properties);
    }

    trackPage(url) {
        mixpanel.track_pageview(url);
    }
}
