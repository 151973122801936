import {
  COST_FILTER_OPTIONS_FAILURE,
  UPDATE_ACCOUNTS_COLORS,
  UPDATE_RESOURCEGROUPS_COLORS,
  UPDATE_SERVICES_COLORS,
} from "../components/dashboard/actionTypes/CostChartActionTypes";
import { COST_FILTER_OPTIONS_REQUEST } from "../components/dashboard/actionTypes/CostChartActionTypes";
import {
  DELETE_CHART_DATA,
  UPDATE_CHART_DATA_FAILURE,
  UPDATE_CHART_DATA_REQUEST,
  UPDATE_CHART_DATA_SUCCESS,
  UPDATE_COLORS,
  UPDATE_COST_FILTER_OPTIONS,
} from "../components/dashboard/actionTypes/CostChartActionTypes";
import { CLOUD_PROVIDER_KEY } from "../../../utils/app-constants.json";

const initialState = {
  AWS: {
    masterChartData: {},
    accounts: {},
    regions: {},
    services: [],
    isFetching: false,
    errorMessage: null,
    colors: {
      accounts: {},
      regions: {},
      services: {},
    },
  },
  Azure: {
    masterChartData: {},
    accounts: {},
    regions: {},
    resourceGroups: [],
    services: [],
    isFetching: false,
    errorMessage: null,
    colors: {
      accounts: {},
      regions: {},
      services: {},
      resourceGroups: {},
    },
  },
};

const getCloudProvider = () => {
  const cloudProviderType = JSON.parse(
    localStorage.getItem(CLOUD_PROVIDER_KEY)
  );
  return cloudProviderType || "AWS";
};

export default function costChartReducer(state = initialState, action) {
  const cloudProvider = action.payload?.cloudProvider || getCloudProvider();
  switch (action.type) {
    case UPDATE_CHART_DATA_REQUEST:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          masterChartData: {
            ...state[cloudProvider].masterChartData,
            [action.payload.chartId]: {
              ...state[cloudProvider].masterChartData[action.payload.chartId],
              isFetching: true,
            },
          },
        },
      };
    case UPDATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          masterChartData: {
            ...state[cloudProvider].masterChartData,
            [action.payload.chartId]: {
              ...state[cloudProvider].masterChartData[action.payload.chartId],
              [action.payload.queryParam]: action.payload.data,
              isFetching: false,
            },
          },
        },
      };
    case UPDATE_CHART_DATA_FAILURE:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          masterChartData: {
            ...state[cloudProvider].masterChartData,
            [action.payload.chartId]: {
              ...state[cloudProvider].masterChartData[action.payload.chartId],
              isFetching: false,
              errorMessage: action.payload.errorMessage,
            },
          },
        },
      };
    case DELETE_CHART_DATA:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          masterChartData: {},
        },
      };
    case COST_FILTER_OPTIONS_REQUEST:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          isFetching: true,
        },
      };
    case UPDATE_COST_FILTER_OPTIONS:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          accounts: action.payload.accounts,
          regions: action.payload.regions,
          services: action.payload.services,
          resourceGroups: action.payload.resourceGroups,
          isFetching: false,
        },
      };
    case COST_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          isFetching: false,
          errorMessage: action.payload.errorMessage,
        },
      };
    case UPDATE_COLORS:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          colors: {
            accounts: action.payload.accounts,
            regions: action.payload.regions,
            services: action.payload.services,
            resourceGroups: action.payload.resourceGroups,
          },
        },
      };
    case UPDATE_ACCOUNTS_COLORS:
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          colors: {
            ...state[cloudProvider].colors,
            accounts: action.payload,
          },
        },
      };
    case UPDATE_SERVICES_COLORS: {
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          colors: {
            ...state[cloudProvider].colors,
            services: action.payload,
          },
        },
      };
    }
    case UPDATE_RESOURCEGROUPS_COLORS: {
      return {
        ...state,
        [cloudProvider]: {
          ...state[cloudProvider],
          colors: {
            ...state[cloudProvider].colors,
            resourceGroups: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
}
