import i18n from "../config/locale/i18n";
export const isNotEmptyString = (string) => {
  return typeof string === "string" && string.trim().length > 0;
};

export const isEmptyString = (string) => {
  return string.trim().length <= 0;
};

export const isString = (value) => {
  if (value) {
    return value;
  }
  return "";
};

// eslint-disable-next-line no-useless-escape
export const IMAGE_NAME_REGEX = /^[a-zA-Z0-9\(\)\[\]\.\-\_\'\@\s'\/\']{3,128}$/;

export const IMAGE_NAME_REGEX_FOR_SCHEDULE =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9\(\)\[\]\.\-\_\'\@\s'\/\']{3,108}$/;

export const DOMAIN_NAME_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;

export const TAG_KEY_REGEX = /^[a-zA-Z 0-9.,+-=_:/@]{1,128}$/;

export const TAG_VALUE_REGEX = /^[a-zA-Z 0-9.,+-=_:/@]{0,256}$/;

export const capitalizeFirstLetter = (string) => {
  let stringWithSpaces = string && string.replace(/([a-z])([A-Z])/g, "$1 $2");
  return (
    stringWithSpaces &&
    stringWithSpaces
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
};

export const convertStringToPascalCase = (string, character = "_") => {
  return string
    ? string.split(character).map(capitalizeFirstLetter).join("")
    : "";
};

export const pascalCaseToWords = (string) => {
  return string
    .replace(/([A-Z])/g, " $1")
    .trim()
    .split(" ")
    .join(" ");
};

export const booleanToYesNo = (value) => {
  return value ? i18n.t("common:yes") : i18n.t("common:no");
};

export const humanizeText = (text) => {
  if (!text) return "-";
  return text
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const toCamelCase = (text) => {
  if (!text) return "";

  return text
    .toLowerCase()
    .split("_")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
};
