export const getSelectedOrgsAndDepts = (userData) => {
  let selectedOrganizations = [];
  let selectedDepartments = [];
  if (
    userData &&
    userData.Organizations &&
    userData.Organizations.length &&
    userData.Organizations.length > 0
  ) {
    selectedOrganizations = [userData.Organizations[0].ID];
  }
  if (userData && userData.Departments)
    userData.Departments.forEach((dept) => {
      // if (dept.isSelected) {
      selectedDepartments.push(dept.ID);
      // }
    });

  var request = {
    Orgs: selectedOrganizations,
    Depts: selectedDepartments,
  };
  return request;
};

export const getSelectedOrgs = (userData) => {
  let selectedOrganizations = [];
  if (
    userData &&
    userData.Organizations &&
    userData.Organizations.length &&
    userData.Organizations.length > 0
  ) {
    selectedOrganizations = [userData.Organizations[0].ID];
  }

  var request = {
    Orgs: selectedOrganizations,
  };
  return request;
};

export const getSelectedDepts = (userData) => {
  let selectedDepartments = [];
  if (userData && userData.Departments && userData.Departments.length)
    userData.Departments.forEach((dept) => {
      selectedDepartments.push(dept.ID);
    });

  var request = {
    Depts: selectedDepartments,
  };
  return request;
};

export const getSelectedOrgsAndAllDepts = (userData) => {
  let selectedOrganizations = [];
  let selectedDepartments = [];
  if (userData && userData.Organizations && userData.Organizations.length > 0) {
    selectedOrganizations = [userData.Organizations[0].ID];
  }
  if (userData && userData.Departments)
    userData.Departments.forEach((dept) => {
      selectedDepartments.push(dept.ID);
    });

  var request = {
    Orgs: selectedOrganizations,
    Depts: selectedDepartments,
  };
  return request;
};

