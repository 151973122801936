import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { MAP_HISTORICAL_DATA_URL } from "../../../config/service";

export const mapHistoricalData = createApi({
  reducerPath: "mapHistoricalData",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getMapHistoricalData: builder.query({
      query: ({ fromDate, accountNumber, toDate }) => {
        return {
          url: `${MAP_HISTORICAL_DATA_URL}?FromDate=${fromDate}&AccountNumber=${accountNumber}&ToDate=${toDate}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetMapHistoricalDataQuery } = mapHistoricalData;
