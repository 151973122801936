import {
  GET_ALL_IMAGES_REQUEST,
  GET_ALL_IMAGES_SUCCESS,
  GET_ALL_IMAGES_FAILURE,
  COPY_IMAGE_REQUEST,
  COPY_IMAGE_SUCCESS,
  COPY_IMAGE_FAILURE,
  SHARE_IMAGE_REQUEST,
  SHARE_IMAGE_SUCCESS,
  SHARE_IMAGE_FAILURE,
  CLEAR_IMAGE_DATA,
  RESET_IMAGE_FETCHED,
  SAVE_SELECTED_IMAGE,
  SAVE_COPY_IMAGE_PAYLOAD,
  SET_COPY_IMAGE_NAME,
  SET_COPY_IMAGE_DESCRIPTION,
  SET_COPY_IMAGE_ENCRYPT_TARGET,
  DELETE_IMAGE_SNAPSHOT_REQUEST,
  DELETE_IMAGE_SNAPSHOT_SUCCESS,
  DELETE_IMAGE_SNAPSHOT_FAILURE,
  SAVE_SELECTED_SNAPSHOTS_FOR_IMAGE,
  RESET_SELECTED_SNAPSHOTS_FOR_IMAGE,
  SET_SHARE_IMAGE_TYPE,
  SET_SELECTED_ACCOUNT_SHARE_IMAGE,
  SET_REMOVED_ACCOUNT,
  SET_CREATE_VOLUME_PERMISSION,
  SET_RESOURCE_ID,
  CLEAR_SHARE_IMAGE_DATA,
  SET_PREVIOUSLY_SELECTED_IMAGE,
  SET_SHARE_IMAGE_STATE,
} from "../actionTypes/images";

const initialState = {
  isFetching: false,
  imageList: null,
  isImageFetched: false,
  imageCopied: false,
  imageShared: false,
  imageRenamed: false,
  copyRequestCompleted: false,
  shareRequestCompleted: false,
  renameRequestCompleted: false,
  imageFetchFailed: false,
  imagesFailedError: null,
  failedToCopyImage: false,
  copyingImage: false,
  selectedImages: [],
  selectedCopyImagePayload: null,
  setCopyImageName: "",
  setCopyImageDescription: "",
  setCopyImageEncryptTarget: false,
  isDeletingImage: false,
  failedToDeleteImage: false,
  deletedImage: false,
  snapshotsForImage: [],
  isPrivateImage: false,
  selectedAccounts: [],
  removedAccounts: [],
  createVolumePermission: false,
  resourceId: [],
  previouslySelectedImage: null,
};

const imagesServerOperation = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_IMAGES_REQUEST:
      return {
        ...state,
        isFetching: true,
        imageList: initialState.imageList,
        isImageFetched: initialState.isImageFetched,
        imageFetchFailed: initialState.imageFetchFailed,
      };
    case GET_ALL_IMAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isImageFetched: true,
        imageList: action.payload,
      };
    case GET_ALL_IMAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        imageFetchFailed: true,
        imagesFailedError: action.payload,
        isImageFetched: false,
      };
    case RESET_IMAGE_FETCHED:
      return {
        ...state,
        isImageFetched: false,
      };
    case COPY_IMAGE_REQUEST:
      return {
        ...state,
        copyingImage: true,
        imageCopied: initialState.imageCopied,
        copyRequestCompleted: initialState.copyRequestCompleted,
      };
    case COPY_IMAGE_SUCCESS:
      return {
        ...state,
        imageCopied: true,
        copyRequestCompleted: true,
        copyingImage: false,
      };
    case COPY_IMAGE_FAILURE:
      return {
        ...state,
        copyingImage: false,
        failedToCopyImage: action.payload,
        copyRequestCompleted: true,
      };
    case SHARE_IMAGE_REQUEST:
      return {
        ...state,
        imageShared: false,
        shareRequestCompleted: false,
      };
    case SHARE_IMAGE_SUCCESS:
      return {
        ...state,
        imageShared: true,
        shareRequestCompleted: true,
      };
    case SHARE_IMAGE_FAILURE:
      return {
        ...state,
        imageShared: false,
        errorMessage: action.payload,
        shareRequestCompleted: true,
      };
    case CLEAR_IMAGE_DATA:
      return {
        ...state,
        ...initialState,
        imageList: state.imageList,
        isImageFetched: state.isImageFetched,
        imageCopied: false,
        imageShared: false,
        imageRenamed: false,
      };
    case SAVE_SELECTED_IMAGE:
      return {
        ...state,
        selectedImages: action.payload,
      };
    case SAVE_COPY_IMAGE_PAYLOAD:
      return {
        ...state,
        selectedCopyImagePayload: action.payload,
      };
    case SET_COPY_IMAGE_NAME:
      return {
        ...state,
        setCopyImageName: action.payload,
      };
    case SET_COPY_IMAGE_DESCRIPTION:
      return {
        ...state,
        setCopyImageDescription: action.payload,
      };
    case SET_COPY_IMAGE_ENCRYPT_TARGET:
      return {
        ...state,
        setCopyImageEncryptTarget: action.payload,
      };
    case DELETE_IMAGE_SNAPSHOT_REQUEST:
      return {
        ...state,
        isDeletingImage: true,
        failedToDeleteImage: initialState.failedToDeleteImage,
        deletedImage: initialState.deletedImage,
      };
    case DELETE_IMAGE_SNAPSHOT_SUCCESS:
      return {
        ...state,
        isDeletingImage: false,
        deletedImage: true,
      };
    case DELETE_IMAGE_SNAPSHOT_FAILURE:
      return {
        ...state,
        isDeletingImage: false,
        failedToDeleteImage: true,
      };
    case SAVE_SELECTED_SNAPSHOTS_FOR_IMAGE:
      const selectedSnapshotData = state.snapshotsForImage
        ? [...state.snapshotsForImage]
        : [];
      let snapshotIds;
      action.payload &&
        action.payload.length > 0 &&
        action.payload.forEach((list) => {
          if (!selectedSnapshotData.includes(list)) {
            selectedSnapshotData.push(list);
            snapshotIds = selectedSnapshotData;
          } else {
            snapshotIds =
              selectedSnapshotData &&
              selectedSnapshotData.length > 0 &&
              selectedSnapshotData.filter((l) => l !== list);
          }
        });
      return {
        ...state,
        snapshotsForImage: snapshotIds,
      };
    case RESET_SELECTED_SNAPSHOTS_FOR_IMAGE:
      return {
        ...state,
        snapshotsForImage: initialState.snapshotsForImage,
      };
    case SET_SHARE_IMAGE_TYPE:
      return {
        ...state,
        isPrivateImage: action.payload,
      };
    case SET_SELECTED_ACCOUNT_SHARE_IMAGE:
      return {
        ...state,
        selectedAccounts: action.payload,
      };
    case SET_REMOVED_ACCOUNT:
      return {
        ...state,
        removedAccounts: action.payload,
      };
    case SET_CREATE_VOLUME_PERMISSION:
      return {
        ...state,
        createVolumePermission: action.payload,
      };
    case SET_RESOURCE_ID:
      return {
        ...state,
        resourceId: action.payload,
      };
    case SET_PREVIOUSLY_SELECTED_IMAGE:
      return {
        ...state,
        previouslySelectedImage: action.payload,
      };
    case SET_SHARE_IMAGE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_SHARE_IMAGE_DATA:
      return {
        ...state,
        isPrivateImage: initialState.isPrivateImage,
        selectedAccounts: initialState.selectedAccounts,
        removedAccounts: initialState.removedAccounts,
        createVolumePermission: initialState.createVolumePermission,
        imageShared: initialState.imageShared,
        shareRequestCompleted: initialState.shareRequestCompleted,
        resourceId: initialState.resourceId,
        previouslySelectedImage: initialState.previouslySelectedImage,
      };
    default:
      return state;
  }
};

export default imagesServerOperation;
