import { PERIOD, EVALUATION_PERIODS, DATAPOINTS } from "./app-constants.json";
import i18n from "../config/locale/i18n";

export const getValidationPeriod = (value) => {
  let validObject = {
    isValid: false,
    validValue: null,
  };
  validObject.validValue = value;
  if (value && value >= 60 && value <= 86400 && value % 60 === 0) {
    validObject.isValid = true;
    validObject.validValue = null;
  }
  return validObject;
};

export const getValidationEvaluationPeriod = (value, periodValue) => {
  let validObject = {
    isValid: false,
    validValue: null,
  };
  let checkValidValue = periodValue * value;
  validObject.validValue = 86400 / periodValue;
  if (value > 0 && checkValidValue <= 86400) {
    validObject.isValid = true;
    validObject.validValue = null;
  }
  return validObject;
};

export const getValidationDatapoints = (value, evaluationPeriodValue) => {
  let validObject = {
    isValid: false,
    validValue: null,
  };
  validObject.validValue = evaluationPeriodValue;
  if (value <= evaluationPeriodValue) {
    validObject.isValid = true;
    validObject.validValue = null;
  }
  return validObject;
};

export const getHelpTextForCondition = (predicate) => {
  let helpText = "";
  if (predicate === PERIOD) {
    helpText = i18n.t("rules:ruleHelpText.Period");
  }
  if (predicate === EVALUATION_PERIODS) {
    helpText = i18n.t("rules:ruleHelpText.EvaluationPeriod");
  }
  if (predicate === DATAPOINTS) {
    helpText = i18n.t("rules:ruleHelpText.ConsecutiveDatapoints");
  }
  return helpText;
};

export const RULE_KEYS = {
  PHONE_NUMBERS: "PhoneNumbers",
  PHONE_NUMBER: "PhoneNumber",
  MESSAGE: "Message",
  SMS_CHANNEL: "sms",
  DESTINATION: "Destination",
  DAY2_TICKET_ACTION: "Day2TicketAction",
  DAY2_NOTIFICATION_ACTION: "Day2NotificationAction",
  NOTIFICATION_CHANNEL: "NotificationChannel",
  SCOPE: "Scope",
  TEMPLATE_PAYLOAD: "TemplatePayload",
  EVENT: "Event",
  DAY2EVENT: "DAY2Event",
  RESOURCETYPE: "ResourceType",
};

export const RULE_ACTION_TYPES = {
  INTEGRATION: "integration",
};

export const MAX_ALLOWED_ATTRIBUTES = {
  SLACK: 9,
};
