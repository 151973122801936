import {
  SET_SELECTED_MONTH,
  SET_SELECTED_TENANT,
} from "../actionTypes/creditsActionTypes";

const initialState = {
  selectedMonth: "",
  selectedTenant: "",
};

const credits = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MONTH:
      return {
        ...state,
        selectedMonth: action.payload,
      };
    case SET_SELECTED_TENANT:
      return {
        ...state,
        selectedTenant: action.payload,
      };
    default:
      return state;
  }
};

export default credits;
