import {
  GET_INSTANCES_REQUEST,
  GET_INSTANCES_SUCCESS,
  GET_INSTANCES_FAILURE,
  RESET_INSTANCES_IS_FETCHED,
  RESET_INSTANCE_LIST,
} from "../actionTypes/instances";

const initialState = {
  isFetching: false,
  instanceList: null,
  showInstanceList: true,
  isInstanceListFetched: false,
};
const instances = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTANCES_REQUEST:
      return {
        ...state,
        isFetching: true,
        isInstanceListFetched: false,
      };
    case GET_INSTANCES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        instanceList: action.payload,
        showInstanceList: true,
        isInstanceListFetched: true,
      };
    case GET_INSTANCES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        isInstanceListFetched: false,
      };
    case RESET_INSTANCES_IS_FETCHED:
      return {
        ...state,
        isInstanceListFetched: false,
      };
    case RESET_INSTANCE_LIST:
      return {
        ...state,
        instanceList: initialState.instanceList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default instances;
