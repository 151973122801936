import {
  PRE_LOGIN_REQUEST,
  AUTH_CAPTCHA_VERIFICATION,
  AUTH_CAPTCHA_VERIFICATION_FAILED,
  PRE_LOGIN_SUCCESS,
  PRE_LOGIN_REDIRECT,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REDIRECT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  AUTH_PAGE_UNLOADED,
  RESEND_CONFIRMATION_EMAIL,
  CLEAR_RESEND_MESSAGE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT_REQUEST,
  SIGN_UP_NEW_PASSWORD_REQUIRED,
  CLEAR_FORGOT_PASSWORD,
  LOGGED_IN_USER_DETAILS_REQUEST,
  LOGGED_IN_USER_DETAILS_SUCCESS,
  LOGGED_IN_USER_DETAILS_FAILURE,
  CLEAR_LOGIN_STATE,
  SUBSCRIPTION_ID,
  MFA_CODE_REQUIRED_TO_LOGIN,
  USER_LOGIN_DISABLED,
  UPDATE_MFA_CONFIG,
} from "../actionTypes/auth";
import { CUSTOMER_TYPE } from "../../../utils/app-constants";

const customerType = localStorage.getItem(CUSTOMER_TYPE);

const roleId = localStorage.getItem("roleId");

const initialState = {
  isFetching: false,
  isRedirect: false,
  redirectUrl: null,
  isPasswordRequired: false,
  navigateToChangePassword: false,
  passwordChanged: false,
  resetPasswordEmail: "",
  resendConfirmationEmail: false,
  refreshPage: false,
  newPasswordRequiredData: null,
  isResettingPassword: false,
  resetErrorMessage: "",
  passwordReset: false,
  roleId: roleId,
  isSelfManagedUser: false,
  isATrialUser: false,
  isFreemium: false,
  hasNoPlans: false,
  customerType: customerType,
  subscriptionDetails: null,
  subscriptionId: null,
  isFetchingUserDetails: false,
  companyName: "",
  mfaCodeRequiredData: null,
  userLoginDisabledData: null,
  fetchedUserDetails: false,
  isMFAEnabled: null,
  isMFAConfigured: null,
  mfaEnforcementType: "",
  isUserInFreeMode: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case PRE_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case AUTH_CAPTCHA_VERIFICATION:
      return {
        ...state,
        isFetching: true,
      };
    case AUTH_CAPTCHA_VERIFICATION_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case PRE_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isPasswordRequired: true,
        errorMessage: "",
      };
    case PRE_LOGIN_REDIRECT:
      return {
        ...state,
        isFetching: false,
        isPasswordRequired: false,
        isRedirect: true,
        redirectUrl: action.payload,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        ...action.payload,
      };
    case SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case LOGGED_IN_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingUserDetails: false,
        fetchedUserDetails: true,
      };
    case LOGGED_IN_USER_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingUserDetails: true,
        fetchedUserDetails: initialState.fetchedUserDetails,
      };
    case LOGGED_IN_USER_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingUserDetails: false,
        fetchedUserDetails: initialState.fetchedUserDetails,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        refreshPage: true,
      };
    case LOGOUT_REDIRECT:
      return {
        ...state,
        isRedirect: true,
        redirectUrl: action.payload,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
        navigateToChangePassword: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        resetPasswordEmail: action.username,
        navigateToChangePassword: true,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        navigateToChangePassword: true,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        passwordChanged: true,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLEAR_FORGOT_PASSWORD:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        resetPasswordEmail: "",
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResettingPassword: true,
        resetErrorMessage: "",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResettingPassword: false,
        resetErrorMessage: "",
        passwordReset: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResettingPassword: false,
        resetErrorMessage: action.payload,
        passwordReset: false,
      };
    case AUTH_PAGE_UNLOADED:
      return {
        ...state,
        errorMessage: "",
      };
    case CLEAR_RESEND_MESSAGE:
      return {
        ...state,
        isFetching: false,
        resendConfirmationEmail: false,
      };
    case RESEND_CONFIRMATION_EMAIL:
      return {
        ...state,
        isFetching: false,
        resendConfirmationEmail: true,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case SIGN_UP_NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        newPasswordRequiredData: action.payload,
      };
    case MFA_CODE_REQUIRED_TO_LOGIN:
      return {
        ...state,
        mfaCodeRequiredData: action.payload,
      };
    case USER_LOGIN_DISABLED:
      return {
        ...state,
        userLoginDisabledData: action.payload,
      };
    case LOGOUT_REQUEST:
      return state;
    case CLEAR_LOGIN_STATE:
      return {
        ...state,
        isFetching: initialState.isFetching,
        isPasswordRequired: initialState.isPasswordRequired,
        isRedirect: initialState.isRedirect,
        redirectUrl: initialState.redirectUrl,
        errorMessage: initialState.errorMessage,
      };
    case UPDATE_MFA_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
