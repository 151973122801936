import {
  GET_BOTS_REQUEST,
  GET_BOTS_SUCCESS,
  GET_BOTS_FAILURE,
  GET_BOT_MANIFEST_REQUEST,
  GET_BOT_MANIFEST_SUCCESS,
  GET_BOT_MANIFEST_FAILURE,
  GET_BOT_CONFIG_REQUEST,
  GET_BOT_CONFIG_SUCCESS,
  GET_BOT_CONFIG_FAILURE,
  UPDATE_DAY2_BOTS_SEARCH_KEY,
  GET_BOT_DETAILS_REQUEST,
  GET_BOT_DETAILS_SUCCESS,
  GET_BOT_DETAILS_FAILURE,
  SAVE_SELECTED_BOT_POLICIES,
  GET_PREREQUISITES_REQUEST,
  GET_PREREQUISITES_SUCCESS,
  GET_PREREQUISITES_FAILURE,
  SAVE_RUN_TIME_INTERVAL,
  LAUNCH_BOT_CONFIGURATION_REQUEST,
  LAUNCH_BOT_CONFIGURATION_SUCCESS,
  LAUNCH_BOT_CONFIGURATION_FAILURE,
  CLEAR_BOT_CONFIGURATION,
  OFFBOARD_BOT_REQUEST,
  OFFBOARD_BOT_SUCCESS,
  OFFBOARD_BOT_FAILURE,
  SAVE_ALL_POLICIES,
  SAVE_SELECTED_OFFBOARDING_TYPE,
  CLEAR_SELECTED_OFFBOARDING_TYPE,
  CLEAR_BOT_DETAILS,
  SAVE_BOT_EXECUTE_NOW,
  SAVE_BOT_PARAMETERS_DATA,
  SAVE_ACCOUNTS_THAT_NEED_VPC,
} from "../actionTypes/day2-bots";

const initialState = {
  isFetchingBots: false,
  botsList: [],
  fetchingBotsFailed: false,
  isFetchingBotManifest: false,
  botManifest: {},
  fetchingBotManifestFailed: false,
  day2BotsSearchKey: "",
  isFetchingBotDetails: false,
  botDetails: null,
  fetchingBotDetailsFailed: false,
  isFetchingPrerequisitesCheck: false,
  prerequisitesCheck: [],
  fetchingPrerequisitesCheckFailed: false,
  runTimeInterval: null,
  isLaunchingBotConfig: false,
  launchingBotConfigSuccess: false,
  launchingBotConfigFailed: false,
  isOffboardingBot: false,
  offBoardingBotSuccess: false,
  offBoardingBotFailed: false,
  selectedOffboardingType: "",
  botConfig: null,
  fetchingBotConfigFailed: false,
  executeNow: false,
  botParametersData: null,
  accountsThatNeedNewVpc: [],
};

const day2Bots = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOTS_REQUEST:
      return {
        ...state,
        isFetchingBots: true,
        botsList: initialState.botsList,
        fetchingBotsFailed: initialState.fetchingBotsFailed,
      };
    case GET_BOTS_SUCCESS:
      return {
        ...state,
        isFetchingBots: false,
        botsList: action.payload,
      };
    case GET_BOTS_FAILURE:
      return {
        ...state,
        isFetchingBots: false,
        fetchingBotsFailed: true,
      };
    case GET_BOT_MANIFEST_REQUEST:
      return {
        ...state,
        isFetchingBotManifest: true,
        botManifest: initialState.botManifest,
        fetchingBotManifestFailed: initialState.fetchingBotManifestFailed,
      };
    case GET_BOT_MANIFEST_SUCCESS:
      return {
        ...state,
        isFetchingBotManifest: false,
        botManifest: action.payload,
      };
    case GET_BOT_MANIFEST_FAILURE:
      return {
        ...state,
        isFetchingBotManifest: false,
        fetchingBotManifestFailed: true,
      };
    case GET_BOT_CONFIG_REQUEST:
      return {
        ...state,
        isFetchingBotManifest: true,
        botConfig: initialState.botConfig,
        botManifest: initialState.botManifest,
        fetchingBotManifestFailed: initialState.fetchingBotManifestFailed,
      };
    case GET_BOT_CONFIG_SUCCESS:
      return {
        ...state,
        isFetchingBotManifest: false,
        botConfig: action.payload,
        botManifest: action.payload && action.payload.manifest,
      };
    case GET_BOT_CONFIG_FAILURE:
      return {
        ...state,
        isFetchingBotManifest: false,
        fetchingBotManifestFailed: true,
      };
    case UPDATE_DAY2_BOTS_SEARCH_KEY:
      return {
        ...state,
        day2BotsSearchKey: action.payload,
      };
    case GET_BOT_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingBotDetails: true,
        botDetails: initialState.botDetails,
        fetchingBotDetailsFailed: initialState.fetchingBotDetailsFailed,
      };
    case GET_BOT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingBotDetails: false,
        botDetails: action.payload,
      };
    case GET_BOT_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingBotDetails: false,
        fetchingBotDetailsFailed: true,
      };
    case SAVE_SELECTED_BOT_POLICIES:
      const botManifest = { ...state.botManifest };
      let updatedPolicies = [...state.botManifest.Policies];
      botManifest.Policies.forEach(() => {
        let index = updatedPolicies.findIndex(
          (policies) => policies.PolicyGroupId === action.payload.PolicyGroupId
        );
        if (index !== -1) {
          updatedPolicies[index] = action.payload;
        }
      });
      return {
        ...state,
        botManifest: {
          ...botManifest,
          Policies: updatedPolicies,
        },
      };
    case GET_PREREQUISITES_REQUEST:
      return {
        ...state,
        isFetchingPrerequisitesCheck: true,
        prerequisitesCheck: state.prerequisitesCheck,
        fetchingPrerequisitesCheckFailed:
          initialState.fetchingPrerequisitesCheckFailed,
      };
    case GET_PREREQUISITES_SUCCESS:
      return {
        ...state,
        isFetchingPrerequisitesCheck: false,
        prerequisitesCheck: action.payload,
      };
    case GET_PREREQUISITES_FAILURE:
      return {
        ...state,
        isFetchingPrerequisitesCheck: false,
        fetchingPrerequisitesCheckFailed: true,
      };
    case SAVE_RUN_TIME_INTERVAL:
      return {
        ...state,
        runTimeInterval: action.payload,
      };
    case LAUNCH_BOT_CONFIGURATION_REQUEST:
      return {
        ...state,
        isLaunchingBotConfig: true,
        launchingBotConfigSuccess: initialState.launchingBotConfigSuccess,
        launchingBotConfigFailed: initialState.launchingBotConfigFailed,
      };
    case LAUNCH_BOT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isFetchingPrerequisitesCheck: false,
        launchingBotConfigSuccess: true,
      };
    case LAUNCH_BOT_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLaunchingBotConfig: false,
        launchingBotConfigFailed: true,
      };
    case OFFBOARD_BOT_REQUEST:
      return {
        ...state,
        isOffboardingBot: true,
        offBoardingBotSuccess: initialState.offBoardingBotSuccess,
        offBoardingBotFailed: initialState.offBoardingBotFailed,
      };
    case OFFBOARD_BOT_SUCCESS:
      return {
        ...state,
        isFetchingPrerequisitesCheck: false,
        offBoardingBotSuccess: true,
      };
    case OFFBOARD_BOT_FAILURE:
      return {
        ...state,
        isOffboardingBot: false,
        offBoardingBotFailed: true,
      };
    case CLEAR_BOT_CONFIGURATION:
      return {
        ...state,
        ...initialState,
        botsList: state.botsList,
        botManifest: null,
        botDetails: state.botDetails,
      };
    case SAVE_ALL_POLICIES:
      return {
        ...state,
        botManifest: {
          ...state.botManifest,
          Policies: action.payload,
        },
      };
    case SAVE_SELECTED_OFFBOARDING_TYPE: {
      return {
        ...state,
        selectedOffboardingType: action.payload,
      };
    }
    case CLEAR_SELECTED_OFFBOARDING_TYPE: {
      return {
        ...state,
        selectedOffboardingType: initialState.selectedOffboardingType,
      };
    }
    case CLEAR_BOT_DETAILS: {
      return {
        ...state,
        botDetails: null,
      };
    }
    case SAVE_BOT_EXECUTE_NOW: {
      return {
        ...state,
        executeNow: action.payload,
      };
    }
    case SAVE_BOT_PARAMETERS_DATA: {
      return {
        ...state,
        botParametersData: action.payload,
      };
    }
    case SAVE_ACCOUNTS_THAT_NEED_VPC: {
      return {
        ...state,
        accountsThatNeedNewVpc: action.payload,
      };
    }
    default:
      return state;
  }
};

export default day2Bots;
