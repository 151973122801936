const initialState = {
  notifications: [],
  notificationCount: 0,
  isFetchingNotifications: false,
  notificationsList: null,
  notificationOpenCount: null,
  notificationsUpdated: false,
  notificationsUpdatedMessage: "",
  presentDate: null,
  notificationsUpdating: false,
};

export const NOTIFICATIONS_UPDATED = "NOTIFICATIONS_UPDATED";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const DISCARD_ALL_NOTIFICATIONS = "DISCARD_ALL_NOTIFICATIONS";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_FAILURE = "GET_ALL_NOTIFICATIONS_FAILURE";
export const GET_ALL_NOTIFICATIONS_UPDATED = "GET_ALL_NOTIFICATIONS_UPDATED";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

export default function notification(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_DATE:
      return {
        ...state,
        presentDate: action.payload,
      };
    case NOTIFICATIONS_UPDATED:
      return {
        ...state,
        notifications: action.payload,
        notificationCount: state.notificationCount + 1,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationCount: initialState.notificationCount,
      };
    case DISCARD_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        isFetchingNotifications: true,
      };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetchingNotifications: false,
        notificationsList: action.payload.notifications,
        notificationOpenCount: action.payload.openCount,
      };
    case GET_ALL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetchingNotifications: false,
        notificationsError: action.payload,
      };
    case UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationsUpdating: true,
      };
    case UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationsUpdating: false,
      };
    case GET_ALL_NOTIFICATIONS_UPDATED:
      return {
        ...state,
        notificationsUpdated: true,
        notificationsUpdating: false,
        notificationsUpdatedMessage: action.payload,
      };
    default:
      return state;
  }
}
