import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../../../config/customBaseQuery";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../../../helpers/apiService";
import {
  ABC_TENANT_BASE_URL,
  ACCOUNTS,
  ASSOCIATE_CUSTOM_LINE_ITEMS,
  COUNT,
  CUSTOM_LINE_ITEMS,
  LIST_BILLING_GROUPS_URL,
} from "../../../../../../config/service";

export const fetchCustomLineItems = async ({
  tenantId,
  billingPeriod,
  limit,
  offset,
  sortModel,
  lineItemType,
  accountNumber,
  billingGroupArn,
  Arn,
  name,
  description,
  chargeType,
}) => {
  let params;
  params = `?Filters=${encodeURIComponent(
    JSON.stringify({
      BillingPeriod: billingPeriod,
      ...(lineItemType && { LineItemType: lineItemType }),
      ...(accountNumber && { AccountNumber: accountNumber }),
      ...(billingGroupArn && { BillingGroupArn: billingGroupArn }),
      ...(Arn && { Arn: Arn }),
      ...(name && { Name: name }),
      ...(description && { Description: description }),
      ...(chargeType && { ChargeType: chargeType }),
    })
  )}`;

  if (offset) {
    params = params + `&Offset=${offset}`;
  }
  if (limit) {
    params = params + `&Limit=${limit}`;
  }

  if (sortModel) {
    params =
      params + `&SortBy=${sortModel.SortBy}&SortOrder=${sortModel.SortOrder}`;
  }

  const url = `${ABC_TENANT_BASE_URL}${tenantId}${CUSTOM_LINE_ITEMS}${params}`;

  return await getApiService(WEB_REQUEST_TYPE.GET, url)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const fetchAssociatedCustomLineItems = async (
  tenantId,
  lineItemId,
  billingPeriod,
  limit = 25,
  offset,
  sortModel
) => {
  let params;
  params = `?Limit=${limit}`;

  if (offset) {
    params = params + `&Offset=${offset}`;
  }

  if (billingPeriod) {
    params = params + `&BillingPeriod=${billingPeriod}`;
  }
  if (sortModel) {
    params =
      params + `&SortBy=${sortModel.SortBy}&SortOrder=${sortModel.SortOrder}`;
  }

  const url = `${ABC_TENANT_BASE_URL}${tenantId}${CUSTOM_LINE_ITEMS}/${lineItemId}${ASSOCIATE_CUSTOM_LINE_ITEMS}${params}`;

  return await getApiService(WEB_REQUEST_TYPE.GET, url)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const fetchMembersOfBillingGroup = async ({
  tenantId,
  billingGroupId,
  limit = 25,
  offset,
  billingPeriod,
}) => {
  let params;
  params = `?Limit=${limit}`;

  if (offset) {
    params = params + `&Offset=${offset}`;
  }

  if (billingPeriod) {
    params = params + `&BillingPeriod=${billingPeriod}`;
  }

  const url = `${ABC_TENANT_BASE_URL}${tenantId}${LIST_BILLING_GROUPS_URL}/${billingGroupId}${ACCOUNTS}${params}`;

  try {
    const response = await getApiService(WEB_REQUEST_TYPE.GET, url);
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const fetchParentAssociatedCustomLineItems = async ({
  tenantId,
  customLineItemId,
  relationShip,
  limit = 5,
  offset,
}) => {
  let params;
  params = `?Limit=${limit}`;

  if (offset) {
    params = params + `&Offset=${offset}`;
  }

  if (relationShip) {
    params = params + `&Relationship=${relationShip}`;
  }

  const url = `${ABC_TENANT_BASE_URL}${tenantId}${CUSTOM_LINE_ITEMS}/${customLineItemId}${CUSTOM_LINE_ITEMS}${params}`;

  try {
    const response = await getApiService(WEB_REQUEST_TYPE.GET, url);
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const customLineItemsAPI = createApi({
  tagTypes: ["customLineItems"],
  reducerPath: "customLineItems",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    getCustomLineItemDetails: builder.query({
      query: ({ orgId, lineItemId }) => {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}/${lineItemId}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      keepUnusedDataFor: 5,
    }),
    getCustomLineItemCount: builder.query({
      query: ({ orgId, params }) => {
        let enCodedParams = encodeURIComponent(JSON.stringify(params));
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}${COUNT}?Filters=${enCodedParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getAssociatedCustomLineItemCount: builder.query({
      query: ({ orgId, lineItemId, billingPeriod }) => {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}/${lineItemId}${ASSOCIATE_CUSTOM_LINE_ITEMS}${COUNT}?BillingPeriod=${billingPeriod}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),
    createCustomLineItem: builder.mutation({
      query: ({ orgId, body }) => {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}`,
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
    }),
    updateCustomLineItem: builder.mutation({
      query({ orgId, customLineItemId, body }) {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}/${customLineItemId}`,
          method: WEB_REQUEST_TYPE.PATCH,
          body,
        };
      },
    }),
    deleteCustomLineItem: builder.mutation({
      query({ orgId, customLineItemId, body }) {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}/${customLineItemId}`,
          method: WEB_REQUEST_TYPE.DELETE,
          body,
        };
      },
    }),
    getParentAssociatedCustomLineItemsCount: builder.query({
      query: ({ orgId, customLineItemId, relationShip = "" }) => {
        return {
          url: `${ABC_TENANT_BASE_URL}${orgId}${CUSTOM_LINE_ITEMS}/${customLineItemId}${CUSTOM_LINE_ITEMS}${COUNT}?Relationship=${relationShip}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetCustomLineItemDetailsQuery,
  useGetAssociatedCustomLineItemCountQuery,
  useGetCustomLineItemCountQuery,
  useCreateCustomLineItemMutation,
  useUpdateCustomLineItemMutation,
  useDeleteCustomLineItemMutation,
  useGetParentAssociatedCustomLineItemsCountQuery,
} = customLineItemsAPI;
