export const DESIRED_STATE_RULE_TYPE_CHANGE = "DESIRED_STATE_RULE_TYPE_CHANGE";

//list Rules
export const GET_DESIRED_STATE_RULES_SUCCESS =
  "GET_DESIRED_STATE_RULES_SUCCESS";

//delete rule
export const DELETE_DESIRED_STATE_RULE = "DELETE_DESIRED_STATE_RULE";

//save rule
export const SAVE_DESIRED_STATE_RULE = "SAVE_DESIRED_STATE_RULE";

//Rule Params
export const GET_DESIRED_STATE_RULE_PARAMS_REQUEST =
  "GET_DESIRED_STATE_RULE_PARAMS_REQUEST";
export const GET_DESIRED_STATE_RULE_PARAMS_SUCCESS =
  "GET_DESIRED_STATE_RULE_PARAMS_SUCCESS";
export const GET_DESIRED_STATE_RULE_PARAMS_FAILURE =
  "GET_DESIRED_STATE_RULE_PARAMS_FAILURE";

//rule form

export const DESIRED_STATE_RULE_FORM_CHANGE = "DESIRED_STATE_RULE_FORM_CHANGE";
export const DESIRED_STATE_RULE_WINDOWS_REGISTRY =
  "DESIRED_STATE_RULE_WINDOWS_REGISTRY";
export const DESIRED_STATE_RULE_FILES_CHECK_REGISTRY =
  "DESIRED_STATE_RULE_FILES_CHECK_REGISTRY";

//Edit rule
export const EDIT_DESIRED_STATE_RULE = "EDIT_DESIRED_STATE_RULE";
export const DESIRED_STATE_RULE_NAME_CHANGE = "DESIRED_STATE_RULE_NAME_CHANGE";
export const EDIT_DESIRED_STATE_RULE_FORM = "EDIT_DESIRED_STATE_RULE_FORM";

//Remediation
export const REMEDIATION_CHANGE = "REMEDIATION_CHANGE";
export const SET_RULE_REMEDIATION_TYPE = "SET_RULE_REMEDIATION_TYPE";

//RESET
export const RESET_DESIRED_STATE_RULE_FORM = "RESET_DESIRED_STATE_RULE_FORM";

//severity
export const DESIRED_STATE_SEVERITY_CHANGE = "DESIRED_STATE_SEVERITY_CHANGE";

export const SET_REMEDIATION_ENABLED = "SET_REMEDIATION_ENABLED";

export const SET_DRAGGABLE_RULE = "SET_DRAGGABLE_RULE";
export const TOGGLE_WINDOWS_LINUX = "TOGGLE_WINDOWS_LINUX";
