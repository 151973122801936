import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const Label = ({ children, required, tooltip, id, ...otherProps }) => {
  return (
    <>
      <label {...otherProps}>
        {children}
        {required && <span className="bs4-p-1 required-sign">*</span>}
      </label>
      {tooltip && (
        <>
          <span className="bs4-ml-2">
            <i className="fa fa-info-circle color-gray" id={id}></i>
          </span>
          <UncontrolledTooltip placement="right" target={id}>
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

Label.defaultProps = {
  required: false,
};

export default Label;
