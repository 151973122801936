import {
  GET_BOT_INSIGHTS_REQUEST,
  GET_BOT_INSIGHTS_SUCCESS,
  GET_BOT_INSIGHTS_FAILURE,
  REMEDIATE_INSIGHT_REQUEST,
  REMEDIATE_INSIGHT_SUCCESS,
  REMEDIATE_INSIGHT_FAILURE,
  RESET_REMEDIATION_DATA,
  SUPPRESS_INSIGHTS_REQUEST,
  SUPPRESS_INSIGHTS_SUCCESS,
  SUPPRESS_INSIGHTS_FAILURE,
  GET_ALL_BOT_TASKS_REQUEST,
  GET_ALL_BOT_TASKS_SUCCESS,
  GET_ALL_BOT_TASKS_FAILURE,
  SAVE_SELECTED_INSIGHT,
  CLEAR_INSIGHTS_DATA,
  GET_INSIGHTS_EXECUTION_HISTORY_REQUEST,
  GET_INSIGHTS_EXECUTION_HISTORY_SUCCESS,
  GET_INSIGHTS_EXECUTION_HISTORY_FAILURE,
  SAVE_INSIGHTS_CURRENT_TAB,
  GET_BOT_OPS_SUMMARY_REQUEST,
  GET_BOT_OPS_SUMMARY_SUCCESS,
  GET_BOT_OPS_SUMMARY_FAILURE,
  GET_BOT_SUMMARY_REQUEST,
  CLEAR_BOT_SUMMARY_DATA,
  GET_BOT_RESOURCE_SUMMARY_REQUEST,
  GET_BOT_STATUS_SUMMARY_REQUEST,
  GET_BOT_SEVERITY_SUMMARY_REQUEST,
  GET_BOT_TOP_FIVE_CHECKS_REQUEST,
  GET_BOT_TOP_FIVE_RESOURCES_REQUEST,
  GET_BOT_OPEN_ISSUES_REQUEST,
  GET_BOT_INDUSTRY_STANDARDS_REQUEST,
  CLEAR_INSIGHTS_LIST_DATA,
  CLEAR_CHART_DATA,
  GET_BOT_SECURITY_OPS_SUMMARY_SUCCESS,
  GET_BOT_COMPLIANCE_OPS_SUMMARY_SUCCESS,
  SECURITY_BOT_NOT_CONFIGURED,
  COMPLIANCE_BOT_NOT_CONFIGURED,
  CLEAR_INSIGHT_JOB_DATA,
  UPDATE_COMPLIANCE_BOT_SUMMARY_LAST_UPDATED,
  UPDATE_SECURITY_BOT_SUMMARY_LAST_UPDATED,
} from "../actionTypes/bot-insight";

import {
  BOT_TYPE_SECURITY,
  BOT_TYPE_COMPLIANCE,
} from "../../../utils/app-constants.json";

import moment from "moment";

const initialState = {
  isFetchingInsights: false,
  botInsights: null,
  fetchingBotInsightsFailed: false,
  isRemediatingInsight: false,
  remediatingInsightSuccess: false,
  remediatingInsightFailed: false,
  isSuppressingInsights: false,
  suppressingInsightsSuccess: false,
  suppressingInsightsFailed: false,
  isFetchingBotTasks: false,
  botTasks: null,
  fetchingBotTasksFailed: false,
  selectedInsight: null,
  botNotConfigured: false,
  botDetailsFetched: false,
  insightsJobs: null,
  fetchingInsightsJobs: false,
  fetchingInsightsJobsFailed: false,
  insightsCurrentTab: "",
  botInsightOffset: null,
  isFetchingBotOpsSummary: false,
  botOpsSummary: null,
  fetchingBotOpsSummaryFailed: false,
  botSummary: null,
  botInsightCount: 0,
  botResourceSummary: null,
  botStatusSummary: null,
  botSeveritySummary: null,
  botTopFiveChecks: null,
  botTopFiveResources: null,
  botOpenIssues: null,
  botIndustryStandards: null,
  botSecurityOpsSummary: null,
  botComplianceOpsSummary: null,
  securityBotNotConfigured: false,
  complianceBotNotConfigured: false,
  botInsightLastUpdated: moment.utc(),
  botInsightJobLastUpdated: moment.utc(),
  botSecuritySummaryLastUpdated: moment.utc(),
  botComplianceSummaryLastUpdated: moment.utc(),
  isFetchedBotOpenIssues: false,
};

const botInsight = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SELECTED_INSIGHT:
      return {
        ...state,
        selectedInsight: action.payload,
      };
    case GET_BOT_INSIGHTS_REQUEST:
      return {
        ...state,
        isFetchingInsights: true,
        fetchingBotInsightsFailed: initialState.fetchingBotInsightsFailed,
        botNotConfigured: initialState.botNotConfigured,
      };
    case GET_BOT_INSIGHTS_SUCCESS:
      return {
        ...state,
        isFetchingInsights: false,
        botInsights: action.payload.processedData,
        botNotConfigured: action.payload.botNotConfigured,
        botInsightOffset: action.payload.botInsightOffset,
        botDetailsFetched: !action.payload.botNotConfigured,
        botInsightCount: action.payload.botInsightCount,
        botInsightLastUpdated: moment.utc(),
      };
    case GET_BOT_INSIGHTS_FAILURE:
      return {
        ...state,
        isFetchingInsights: false,
        fetchingBotInsightsFailed: true,
      };
    case REMEDIATE_INSIGHT_REQUEST:
      return {
        ...state,
        isRemediatingInsight: true,
        remediatingInsightSuccess: initialState.remediatingInsightSuccess,
        remediatingInsightFailed: initialState.remediatingInsightFailed,
      };
    case REMEDIATE_INSIGHT_SUCCESS:
      return {
        ...state,
        isRemediatingInsight: false,
        remediatingInsightSuccess: true,
      };
    case REMEDIATE_INSIGHT_FAILURE:
      return {
        ...state,
        isRemediatingInsight: false,
        remediatingInsightFailed: true,
      };

    case RESET_REMEDIATION_DATA:
      return {
        ...state,
        isRemediatingInsight: initialState.isRemediatingInsight,
        remediatingInsightSuccess: initialState.remediatingInsightSuccess,
        remediatingInsightFailed: initialState.remediatingInsightFailed,
        isSuppressingInsights: initialState.isSuppressingInsights,
        suppressingInsightsSuccess: initialState.suppressingInsightsSuccess,
        suppressingInsightsFailed: initialState.suppressingInsightsFailed,
      };
    case SUPPRESS_INSIGHTS_REQUEST:
      return {
        ...state,
        isSuppressingInsights: true,
        suppressingInsightsSuccess: initialState.suppressingInsightsSuccess,
        suppressingInsightsFailed: initialState.suppressingInsightsFailed,
      };
    case SUPPRESS_INSIGHTS_SUCCESS:
      return {
        ...state,
        isSuppressingInsights: false,
        suppressingInsightsSuccess: true,
      };
    case SUPPRESS_INSIGHTS_FAILURE:
      return {
        ...state,
        isSuppressingInsights: false,
        suppressingInsightsFailed: true,
      };
    case GET_ALL_BOT_TASKS_REQUEST:
      return {
        ...state,
        isFetchingBotTasks: true,
        botTasks: initialState.botTasks,
        fetchingBotTasksFailed: initialState.fetchingBotTasksFailed,
      };
    case GET_ALL_BOT_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingBotTasks: false,
        botTasks: action.payload,
      };
    case GET_ALL_BOT_TASKS_FAILURE:
      return {
        ...state,
        isFetchingBotTasks: false,
        fetchingBotTasksFailed: true,
      };
    case CLEAR_INSIGHTS_DATA:
      return {
        ...initialState,
        insightsCurrentTab: state.insightsCurrentTab,
      };
    case CLEAR_INSIGHTS_LIST_DATA:
      return {
        ...state,
        botInsights: initialState.botInsights,
      };
    case CLEAR_CHART_DATA:
      const securityBot = action.payload === BOT_TYPE_SECURITY;
      const complianceBot = action.payload === BOT_TYPE_COMPLIANCE;
      return {
        ...state,
        botResourceSummary: {
          ...state.botResourceSummary,
          [action.payload]: initialState.botResourceSummary,
        },
        botStatusSummary: {
          ...state.botStatusSummary,
          [action.payload]: initialState.botStatusSummary,
        },
        botSeveritySummary: {
          ...state.botSeveritySummary,
          [action.payload]: initialState.botSeveritySummary,
        },
        botTopFiveChecks: {
          ...state.botTopFiveChecks,
          [action.payload]: initialState.botTopFiveChecks,
        },
        botTopFiveResources: {
          ...state.botTopFiveResources,
          [action.payload]: initialState.botTopFiveResources,
        },
        botIndustryStandards: {
          ...state.botIndustryStandards,
          [action.payload]: initialState.botIndustryStandards,
        },
        botSecurityOpsSummary: securityBot
          ? initialState.botSecurityOpsSummary
          : state.botSecurityOpsSummary,
        botComplianceOpsSummary: complianceBot
          ? initialState.botComplianceOpsSummary
          : state.botComplianceOpsSummary,
      };
    case GET_INSIGHTS_EXECUTION_HISTORY_REQUEST:
      return {
        ...state,
        insightsJobs: initialState.insightsJobs,
        fetchingInsightsJobs: initialState.fetchingInsightsJobs,
        fetchingInsightsJobsFailed: initialState.fetchingInsightsJobsFailed,
      };
    case GET_INSIGHTS_EXECUTION_HISTORY_SUCCESS:
      return {
        ...state,
        insightsJobs: action.payload,
        fetchingInsightsJobs: false,
        botInsightJobLastUpdated: moment.utc(),
      };
    case GET_INSIGHTS_EXECUTION_HISTORY_FAILURE:
      return {
        ...state,
        fetchingInsightsJobs: false,
        fetchingInsightsJobsFailed: true,
      };
    case SAVE_INSIGHTS_CURRENT_TAB:
      return {
        ...state,
        insightsCurrentTab: action.payload,
      };
    case GET_BOT_OPS_SUMMARY_REQUEST:
      return {
        ...state,
        botOpsSummary: initialState.botOpsSummary,
        isFetchingBotOpsSummary: true,
        fetchingBotOpsSummaryFailed: initialState.fetchingBotOpsSummaryFailed,
      };
    case GET_BOT_OPS_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        botOpsSummary: action.payload,
      };
    case GET_BOT_OPS_SUMMARY_FAILURE:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        fetchingBotOpsSummaryFailed: true,
      };
    case GET_BOT_SUMMARY_REQUEST:
      return {
        ...state,
        botSummary: action.payload,
      };
    case CLEAR_BOT_SUMMARY_DATA:
      return {
        ...state,
        ...initialState,
      };
    case GET_BOT_RESOURCE_SUMMARY_REQUEST:
      return {
        ...state,
        botResourceSummary: action.payload,
      };
    case GET_BOT_STATUS_SUMMARY_REQUEST:
      return {
        ...state,
        botStatusSummary: action.payload,
      };
    case GET_BOT_SEVERITY_SUMMARY_REQUEST:
      return {
        ...state,
        botSeveritySummary: action.payload,
      };
    case GET_BOT_TOP_FIVE_CHECKS_REQUEST:
      return {
        ...state,
        botTopFiveChecks: action.payload,
      };
    case GET_BOT_TOP_FIVE_RESOURCES_REQUEST:
      return {
        ...state,
        botTopFiveResources: action.payload,
      };
    case GET_BOT_OPEN_ISSUES_REQUEST:
      return {
        ...state,
        botOpenIssues: action.payload,
        isFetchedBotOpenIssues: true,
      };
    case GET_BOT_INDUSTRY_STANDARDS_REQUEST:
      return {
        ...state,
        botIndustryStandards: action.payload,
      };
    case GET_BOT_SECURITY_OPS_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        botSecurityOpsSummary: action.payload,
        securityBotNotConfigured: false,
        botSecuritySummaryLastUpdated: moment.utc(),
      };
    case GET_BOT_COMPLIANCE_OPS_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        botComplianceOpsSummary: action.payload,
        complianceBotNotConfigured: false,
        botComplianceSummaryLastUpdated: moment.utc(),
      };
    case SECURITY_BOT_NOT_CONFIGURED:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        securityBotNotConfigured: true,
      };
    case COMPLIANCE_BOT_NOT_CONFIGURED:
      return {
        ...state,
        isFetchingBotOpsSummary: false,
        complianceBotNotConfigured: true,
      };
    case CLEAR_INSIGHT_JOB_DATA:
      return {
        ...state,
        insightsJobs: initialState.insightsJobs,
      };
    case UPDATE_SECURITY_BOT_SUMMARY_LAST_UPDATED:
      return {
        ...state,
        botSecuritySummaryLastUpdated: moment.utc(),
      };
    case UPDATE_COMPLIANCE_BOT_SUMMARY_LAST_UPDATED:
      return {
        ...state,
        botComplianceSummaryLastUpdated: moment.utc(),
      };
    default:
      return state;
  }
};

export default botInsight;
