import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  GOVERNANCE_SUMMARY,
  OPERATIONAL_SUMMARY,
  AZURE_GOVERNANCE_SUMMARY,
} from "../../../utils/action-names.json";

import {
  GET_AWS_RESOURCES_COUNT_SUCCESS,
  GET_AWS_RESOURCES_COUNT_REQUEST,
  GET_AZURE_RESOURCES_COUNT_REQUEST,
  GET_AZURE_RESOURCES_COUNT_SUCCESS,
  GET_GLOBAL_AWS_RESOURCE_SUMMARY_REQUEST,
  GET_GLOBAL_AWS_RESOURCE_SUMMARY_SUCCESS,
  GET_GLOBAL_AWS_RESOURCE_SUMMARY_FAILURE,
  GET_GLOBAL_GOVERNANCE_SUMMARY_REQUEST,
  GET_GLOBAL_GOVERNANCE_SUMMARY_SUCCESS,
  GET_GLOBAL_GOVERNANCE_SUMMARY_FAILURE,
  GET_GLOBAL_AWS_REGION_SUMMARY_REQUEST,
  GET_GLOBAL_AWS_REGION_SUMMARY_SUCCESS,
  GET_GLOBAL_AWS_REGION_SUMMARY_FAILURE,
} from "../actionTypes/globalInventory";

import {
  GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST,
  GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS,
  GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE,
  GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST,
  GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS,
  GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE,
  GET_AWS_INVENTORY_SUMMARY_BY_REGION_REQUEST,
  GET_AWS_INVENTORY_SUMMARY_BY_REGION_SUCCESS,
  GET_AWS_INVENTORY_SUMMARY_BY_REGION_FAILURE,
  GET_AZURE_INVENTORY_SUMMARY_BY_REGION_REQUEST,
  GET_AZURE_INVENTORY_SUMMARY_BY_REGION_SUCCESS,
  GET_AZURE_INVENTORY_SUMMARY_BY_REGION_FAILURE,
  GET_AWS_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT,
  GET_AZURE_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT,
  GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST,
  GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS,
  GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE,
  GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST,
  GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS,
  GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE,
  CLEAR_AWS_INVENTORY_SUMMARY,
  CLEAR_AZURE_INVENTORY_SUMMARY,
} from "../actionTypes/inventory";

const globalInventoryInitialState = {
  fetchingTotalAzureResourceCount: false,
  totalAzureResourceCount: 0,
  fetchingTotalAwsResourceCount: false,
  totalAwsResourceCount: 0,
  awsResourceSummary: null,
  isFetchingAwsResourceSummary: false,
  isFetchedAwsResourceSummary: false,
  isFailedToFetchAwsResourceSummary: false,
  azureResourceSummary: null,
  isFetchingAzureResourceSummary: false,
  isFetchedAzureResourceSummary: false,
  isFailedToFetchAzureResourceSummary: false,
  awsRegionSummary: null,
  isFetchingAwsRegionSummary: false,
  isFetchedAwsRegionSummary: false,
  isFailedToFetchAwsRegionSummary: false,
  azureRegionSummary: null,
  isFetchingAzureRegionSummary: false,
  isFetchedAzureRegionSummary: false,
  isFailedToFetchAzureRegionSummary: false,
  governanceSummary: null,
  isFetchingGovernanceSummary: false,
  isFailedToFetchGovernanceSummary: false,
};

const globalInventory = (state = globalInventoryInitialState, action) => {
  switch (action.type) {
    case GET_AWS_RESOURCES_COUNT_REQUEST:
      return {
        ...state,
        fetchingTotalAwsResourceCount: true,
      };
    case GET_AWS_RESOURCES_COUNT_SUCCESS:
      return {
        ...state,
        totalAwsResourceCount: action.payload,
        fetchingTotalAwsResourceCount: false,
      };
    case GET_AZURE_RESOURCES_COUNT_REQUEST:
      return {
        ...state,
        fetchingTotalAwsResourceCount: true,
      };
    case GET_AZURE_RESOURCES_COUNT_SUCCESS:
      return {
        ...state,
        totalAzureResourceCount: action.payload,
        fetchingTotalAzureResourceCount: false,
      };
    case GET_GLOBAL_AWS_RESOURCE_SUMMARY_REQUEST:
      return {
        ...state,
        isFetchingAwsResourceSummary: true,
        isFetchedAwsResourceSummary: false,
      };
    case GET_GLOBAL_AWS_RESOURCE_SUMMARY_SUCCESS:
      return {
        ...state,
        awsResourceSummary: action.payload,
        isFetchingAwsResourceSummary: false,
        isFetchedAwsResourceSummary: true,
      };
    case GET_GLOBAL_AWS_RESOURCE_SUMMARY_FAILURE:
      return {
        ...state,
        isFailedToFetchAwsResourceSummary: true,
        isFetchingAwsResourceSummary: false,
        isFetchedAwsResourceSummary: true,
      };
    case GET_GLOBAL_AWS_REGION_SUMMARY_REQUEST:
      return {
        ...state,
        isFetchingAwsRegionSummary: true,
        isFetchedAwsRegionSummary: false,
      };
    case GET_GLOBAL_AWS_REGION_SUMMARY_SUCCESS:
      return {
        ...state,
        awsRegionSummary: action.payload,
        isFetchingAwsRegionSummary: false,
        isFetchedAwsRegionSummary: true,
      };
    case GET_GLOBAL_AWS_REGION_SUMMARY_FAILURE:
      return {
        ...state,
        isFailedToFetchAwsResourceSummary: true,
        isFetchingAwsRegionSummary: false,
        isFetchedAwsRegionSummary: true,
      };
    case GET_GLOBAL_GOVERNANCE_SUMMARY_REQUEST:
      return {
        ...state,
        isFetchingGovernanceSummary: true,
      };
    case GET_GLOBAL_GOVERNANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        governanceSummary: action.payload,
        isFetchingGovernanceSummary: false,
      };
    case GET_GLOBAL_GOVERNANCE_SUMMARY_FAILURE:
      return {
        ...state,
        isFailedToFetchGovernanceSummary: true,
        isFetchingGovernanceSummary: false,
      };
    default:
      return {
        ...state,
      };
  }
};

const summaryInitialState = {
  awsAccountSummary: null,
  isFetchingAwsAccountSummary: false,
  isFailedToFetchAwsAccountSummary: false,
  azureAccountSummary: null,
  isFetchingAzureAccountSummary: false,
  isFailedToFetchAzureAccountSummary: false,
  awsRegionSummary: null,
  isFetchingAwsRegionSummary: false,
  isFailedToFetchAwsRegionSummary: false,
  azureRegionSummary: null,
  isFetchingAzureRegionSummary: false,
  isFailedToFetchAzureRegionSummary: false,
  awsResourceTotalCount: 0,
  azureResourceTotalCount: 0,
  awsResourceSummary: null,
  isFetchingAwsResourceSummary: false,
  isFailedToFetchAwsResourceSummary: false,
  azureResourceSummary: null,
  isFetchingAzureResourceSummary: false,
  isFailedToFetchAzureResourceSummary: false,
};

const summary = (state = summaryInitialState, action) => {
  switch (action.type) {
    case GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetchingAwsAccountSummary: true,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetchingAwsAccountSummary: false,
        awsAccountSummary: action.payload,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetchingAwsAccountSummary: false,
        isFailedToFetchAwsAccountSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetchingAzureAccountSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetchingAzureAccountSummary: false,
        azureAccountSummary: action.payload,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetchingAzureAccountSummary: false,
        isFailedToFetchAzureAccountSummary: true,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_REGION_REQUEST:
      return {
        ...state,
        isFetchingAwsRegionSummary: true,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_REGION_SUCCESS:
      return {
        ...state,
        isFetchingAwsRegionSummary: false,
        awsRegionSummary: action.payload,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_REGION_FAILURE:
      return {
        ...state,
        isFetchingAwsRegionSummary: false,
        isFailedToFetchAwsRegionSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_REGION_REQUEST:
      return {
        ...state,
        isFetchingAzureRegionSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_REGION_SUCCESS:
      return {
        ...state,
        isFetchingAzureRegionSummary: false,
        azureRegionSummary: action.payload,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_REGION_FAILURE:
      return {
        ...state,
        isFetchingAzureRegionSummary: false,
        isFailedToFetchAzureRegionSummary: true,
      };
    case GET_AWS_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT:
      return {
        ...state,
        awsResourceTotalCount: action.payload,
      };
    case GET_AZURE_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT:
      return {
        ...state,
        azureResourceTotalCount: action.payload,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST:
      return {
        ...state,
        isFetchingAwsResourceSummary: true,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS:
      return {
        ...state,
        isFetchingAwsResourceSummary: false,
        awsResourceSummary: action.payload,
      };
    case GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE:
      return {
        ...state,
        isFetchingAwsResourceSummary: false,
        isFailedToFetchAwsResourceSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST:
      return {
        ...state,
        isFetchingAzureResourceSummary: true,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS:
      return {
        ...state,
        isFetchingAzureResourceSummary: false,
        azureResourceSummary: action.payload,
      };
    case GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE:
      return {
        ...state,
        isFetchingAzureResourceSummary: false,
        isFailedToFetchAzureResourceSummary: true,
      };
    case CLEAR_AWS_INVENTORY_SUMMARY:
      return {
        ...state,
        awsAccountSummary: summaryInitialState.awsAccountSummary,
        awsRegionSummary: summaryInitialState.awsRegionSummary,
        awsResourceSummary: summaryInitialState.awsResourceSummary,
        awsResourceTotalCount: summaryInitialState.awsResourceTotalCount,
      };
    case CLEAR_AZURE_INVENTORY_SUMMARY:
      return {
        ...state,
        azureAccountSummary: summaryInitialState.azureAccountSummary,
        azureRegionSummary: summaryInitialState.azureRegionSummary,
        azureResourceSummary: summaryInitialState.azureResourceSummary,
        azureResourceTotalCount: summaryInitialState.azureResourceTotalCount,
      };
    default:
      return {
        ...state,
      };
  }
};

const governanceSummary = createReducer(GOVERNANCE_SUMMARY);
const operationalSummary = createReducer(OPERATIONAL_SUMMARY);
const azureGovernanceSummary = createReducer(AZURE_GOVERNANCE_SUMMARY);

export default combineReducers({
  governanceSummary,
  operationalSummary,
  globalInventory,
  azureGovernanceSummary,
  summary,
});
