import {
  GET_SCHEDULE_DETAILS_REQUEST,
  GET_SCHEDULE_DETAILS_SUCCESS,
  GET_SCHEDULE_DETAILS_FAILURE,
  GET_SCHEDULE_JOBS_REQUEST,
  GET_SCHEDULE_JOBS_SUCCESS,
  GET_SCHEDULE_JOBS_FAILURE,
  GET_JOB_DETAILS_REQUEST,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAILURE,
  CLEAR_TASK_DETAILS,
  GET_TASK_OUTPUT_REQUEST,
  GET_TASK_OUTPUT_SUCCESS,
  GET_TASK_OUTPUT_FAILURE,
  CLEAR_TASK_OUTPUT,
  CLEAR_TASK_SCHEDULE_DETAILS,
} from "../actionTypes/task";

const initialState = {
  isFetchingDetails: false,
  scheduleDetails: null,
  isFetchingScheduleJobs: false,
  scheduleJobs: null,
  errorMessage: "",
  isFetchingJobDetails: false,
  fetchingJobDetailsFailed: false,
  jobDetails: null,
  isFetchingTaskOutput: false,
  taskOutput: null,
  fetchTaskOutputSuccess: false,
  fetchTaskOutputFailure: false,
  scheduleJobsOffset: null,
};

const task = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
        scheduleDetails: null,
      };
    case GET_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        scheduleDetails: action.payload,
      };
    case GET_SCHEDULE_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload,
      };
    case GET_SCHEDULE_JOBS_REQUEST:
      return {
        ...state,
        isFetchingScheduleJobs: true,
      };
    case GET_SCHEDULE_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingScheduleJobs: false,
        scheduleJobs: action.payload.tasks,
        scheduleJobsOffset: action.payload.offset,
      };
    case GET_SCHEDULE_JOBS_FAILURE:
      return {
        ...state,
        isFetchingScheduleJobs: false,
        errorMessage: action.payload,
      };
    case GET_JOB_DETAILS_REQUEST:
      let jobDetails = state.jobDetails;
      if (action.request !== "refresh") {
        jobDetails = null;
      }
      return {
        ...state,
        isFetchingJobDetails: true,
        jobDetails: jobDetails,
        fetchingJobDetailsFailed: initialState.fetchingJobDetailsFailed,
      };
    case GET_JOB_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingJobDetails: false,
        jobDetails: action.payload,
      };
    case GET_JOB_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingJobDetails: false,
        fetchingJobDetailsFailed: true,
      };
    case CLEAR_TASK_DETAILS:
      return {
        ...state,
        ...initialState,
      };
    case GET_TASK_OUTPUT_REQUEST:
      return {
        ...state,
        isFetchingTaskOutput: true,
        taskOutput: initialState.taskOutput,
        fetchTaskOutputSuccess: initialState.fetchTaskOutputSuccess,
        fetchTaskOutputFailure: initialState.fetchTaskOutputFailure,
      };
    case GET_TASK_OUTPUT_SUCCESS:
      return {
        ...state,
        isFetchingTaskOutput: false,
        taskOutput: action.payload,
        fetchTaskOutputSuccess: true,
      };
    case GET_TASK_OUTPUT_FAILURE:
      return {
        ...state,
        isFetchingTaskOutput: false,
        fetchTaskOutputFailure: true,
      };
    case CLEAR_TASK_OUTPUT:
      return {
        ...state,
        isFetchingTaskOutput: initialState.isFetchingTaskOutput,
        taskOutput: initialState.taskOutput,
        fetchTaskOutputSuccess: initialState.fetchTaskOutputSuccess,
        fetchTaskOutputFailure: initialState.fetchTaskOutputFailure,
      };
    case CLEAR_TASK_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: initialState.scheduleDetails,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default task;
