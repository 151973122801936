import {
  SET_SELECTED_DATE_RANGE,
  SET_MONITORING_START_DATE,
  SET_MONITORING_END_DATE,
  UPDATE_MONITORING_DASHBOARD_DATA,
  UPDATE_MONITORING_METRICS_REQUEST,
  UPDATE_MONITORING_METRICS_SUCCESS,
  UPDATE_MONITORING_METRICS_FAILURE,
  CLEAR_APPLICATION_MONITORING_DATA,
  GET_APPLICATION_MONITORING_METRICS_REQUEST,
  GET_APPLICATION_MONITORING_METRICS_SUCCESS,
  GET_APPLICATION_MONITORING_METRICS_FAILURE,
  GET_MONITORING_METRICS_DATA_REQUEST,
  GET_MONITORING_METRICS_DATA_SUCCESS,
  GET_MONITORING_METRICS_DATA_FAILURE,
} from "../actionTypes/monitoring";

import { getDefaultDateTime } from "../../../utils/task";
import { setMetricsData } from "../../../utils/monitoring";

const dateRangeOptions = [
  { label: "1 Hour", value: "1" },
  { label: "3 Hours", value: "3" },
  { label: "5 Hours", value: "5" },
  { label: "8 Hours", value: "8" },
  { label: "12 Hours", value: "12" },
  { label: "24 Hours", value: "24" },
  { label: "1 Week", value: "168" },
  { label: "2 Weeks", value: "336" },
  { label: "4 Weeks", value: "672" },
];

const initialState = {
  selectedDateRange: { label: "24 Hours", value: "24" },
  dateRangeOptions,
  monitoringStartDate: new Date(getDefaultDateTime()),
  monitoringDashboardData: null,
  loadingMonitoringDashboardData: false,
  loadingMonitoringDashboardDataFailure: false,
  updatingMetrics: false,
  metricsUpdated: false,
  updateMetricFailed: false,
  gettingMonitingMetrics: false,
  monitoringMetrics: null,
  gettingMonitingMetricsFailed: false,
  monitoringMetricsData: [],
};

const appMonitoring = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.payload,
      };
    case SET_MONITORING_START_DATE:
      return {
        ...state,
        monitoringStartDate: action.payload,
      };
    case SET_MONITORING_END_DATE:
      return {
        ...state,
      };
    case UPDATE_MONITORING_DASHBOARD_DATA:
      return {
        ...state,
        monitoringDashboardData: action.payload,
      };
    case GET_APPLICATION_MONITORING_METRICS_REQUEST:
      return {
        ...state,
        gettingMonitingMetrics: true,
      };
    case GET_APPLICATION_MONITORING_METRICS_SUCCESS:
      return {
        ...state,
        gettingMonitingMetrics: false,
        monitoringMetrics: action.payload,
      };
    case GET_APPLICATION_MONITORING_METRICS_FAILURE:
      return {
        ...state,
        gettingMonitingMetrics: false,
        gettingMonitingMetricsFailed: true,
      };
    case UPDATE_MONITORING_METRICS_REQUEST:
      return {
        ...state,
        updatingMetrics: true,
        metricsUpdated: false,
        updateMetricFailed: false,
      };
    case UPDATE_MONITORING_METRICS_SUCCESS:
      return {
        ...state,
        updatingMetrics: false,
        metricsUpdated: true,
      };
    case UPDATE_MONITORING_METRICS_FAILURE:
      return {
        ...state,
        updatingMetrics: false,
        updateMetricFailed: true,
      };
    case GET_MONITORING_METRICS_DATA_REQUEST:
      return {
        ...state,
        monitoringMetricsData: setMetricsData(
          state.monitoringMetricsData,
          action.payload
        ),
      };
    case GET_MONITORING_METRICS_DATA_SUCCESS:
      return {
        ...state,
        monitoringMetricsData: setMetricsData(
          state.monitoringMetricsData,
          action.payload
        ),
      };
    case GET_MONITORING_METRICS_DATA_FAILURE:
      return {
        ...state,
        monitoringMetricsData: setMetricsData(
          state.monitoringMetricsData,
          action.payload
        ),
      };
    case CLEAR_APPLICATION_MONITORING_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default appMonitoring;
