import { getApiService } from "../../../helpers/apiService";
import {
  USER_SIGN_UP,
  USER_RESEND_CONFIRMATION,
} from "../../../config/service";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const signUpApi = async (params, queryParams) => {
  let signupUrl = `${USER_SIGN_UP}${queryParams}`;
  try {
    const response = await getApiService(
      WEB_REQUEST_TYPE.POST,
      signupUrl,
      params
    );
    const { data } = response;
    return data.Data;
  } catch (error) {
    throw Error(error);
  }
};

export const resendConfirmationApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_RESEND_CONFIRMATION, params)
    .then((response) => {
      const { data } = response;
      return data.Data;
    })
    .catch((error) => {
      throw Error(error);
    });
};
