import React from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import McAlert from "../../../../../components/Alert";

import { SUPPORT_LINK_ORG_ACCOUNT_PRE_REQUISITE } from "../../../../../config/service";
import { ACCOUNT_TYPES } from "../../../utils/account-constants";

const ConnectAccountInfo = ({ selectedAccountType, firstRunView }) => {
  const { t } = useTranslation("addAccount");
  const isManagementAccount =
    selectedAccountType === ACCOUNT_TYPES.MANAGEMENT ? true : false;

  const CLOUDTRAIL_CONFIGURATION_INFO_INDEX = "1. ";
  const TRUSTED_ACCESS_INFO_INDEX = isManagementAccount ? "2. " : "1. ";

  return (
    <McAlert
      type="info"
      content={
        <div className={classNames(firstRunView ? "w-100" : "w-75")}>
          <strong className="mc-text-base-regular">
            {t("onboarding.cloudtrailpreq.title")}
          </strong>
          {isManagementAccount ? (
            <div>
              {CLOUDTRAIL_CONFIGURATION_INFO_INDEX}
              <Trans
                i18nKey="addAccount:onboarding.cloudtrailpreq.message1"
                components={[
                  <strong></strong>,
                  <a
                    href={SUPPORT_LINK_ORG_ACCOUNT_PRE_REQUISITE}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                  </a>,
                ]}
              />
            </div>
          ) : null}
          <div>
            {TRUSTED_ACCESS_INFO_INDEX}
            <Trans
              i18nKey="addAccount:onboarding.cloudtrailpreq.message2"
              components={[<strong></strong>]}
            />
          </div>
        </div>
      }
      className="bs4-mb-4"
    />
  );
};

export default ConnectAccountInfo;
