import React from "react";
import {
  getInstanceSessionLog,
  getInstanceDetails,
} from "../../actions/instance";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import s from "./Instances.module.scss";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { logArticleLink } from "../../../../utils/app-links";
import { withRouter } from "@hocs/withRouter";

class InstanceRemoteSessionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.store = {
      Term: null,
      Socket: null,
    };
    this.state = {
      SessionId: null,
      InstanceId: null,
    };
  }
  componentDidMount() {
    let instanceId = this.props.match.params.instanceId;
    let sessionId = this.props.match.params.sessionId;
    this.props.getInstanceDetails(instanceId);
    this.setState({
      InstanceId: instanceId,
      SessionId: sessionId,
    });
    if (instanceId && sessionId) {
      this.props.getInstanceSessionLog(instanceId, sessionId);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.hasFetchedInstanceSessionLog && props.instanceSessionLog) {
      this.setUpSessionLogData(props.instanceSessionLog.Output);
    }
  }

  setUpSessionLogData = (log) => {
    const term = new Terminal();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);

    term.open(document.getElementById("xterm"));
    fitAddon.fit();
    this.store["Term"] = term;
    this.store.Term.write(window.atob(log));
  };

  closeWindow = () => {
    window.close();
  };

  render() {
    const {
      isFetchingInstanceSessionLog,
      sessionLogErrorMessage,
      t,
      instanceDetails,
    } = this.props;
    const { InstanceId, SessionId } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {t("Day2") +
              InstanceId +
              "-" +
              SessionId +
              t("remoteConsoleSession")}
          </title>
        </Helmet>
        <Row className={s.header}>
          <Col sm={6}>
            <h5 className="bs4-mt-1">
              <img
                loading="lazy"
                src="/images/mc-logo.png"
                alt="MontyCloud"
                className="bs4-mr-2"
              />
              {t("remoteConsoleSessionHistory")}{" "}
              <strong>
                {instanceDetails && instanceDetails.name
                  ? `${instanceDetails.name} (${InstanceId})`
                  : InstanceId}
                - {SessionId}
              </strong>
            </h5>
          </Col>
          <Col sm={6}>
            <Button
              className={classnames("btn btn-sm bs4-mr-2 pull-right", s.red)}
              onClick={() => this.closeWindow()}
            >
              {t("closeWindow")}
            </Button>
          </Col>
        </Row>
        {isFetchingInstanceSessionLog ? (
          <div className={s.loading}>
            <i className="fa fa-spin fa-spinner" />
          </div>
        ) : null}
        <div id="xterm"></div>
        {sessionLogErrorMessage && (
          <>
            <div className={s.terminateText}>
              <div>{t("sessionLogErrorMessage")}</div>
              <div className="bs4-mt-3">{t("sessionLogErrorMessage2")}</div>
            </div>
            <div className="bs4-text-center">
              <a
                href={logArticleLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-info-circle" /> {t("kbArticleMessage")}
              </a>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ computeInstance }) => {
  const {
    isFetchingInstanceSessionLog,
    instanceSessionLog,
    hasFetchedInstanceSessionLog,
    sessionLogErrorMessage,
    instanceDetails,
  } = computeInstance;

  return {
    isFetchingInstanceSessionLog,
    instanceSessionLog,
    hasFetchedInstanceSessionLog,
    sessionLogErrorMessage,
    instanceDetails,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getInstanceSessionLog,
    getInstanceDetails,
  })(withTranslation(["ec2InstanceList"])(InstanceRemoteSessionHistory))
);
