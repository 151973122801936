import config from "../config";
import {
  CUSTOMER_ROLE_APP_ACCESS_ID,
  CUSTOMER_ROLE_DAY2_ADMIN_ID,
  CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID,
  CUSTOMER_ROLE_FULL_ACCESS_ID,
  CUSTOMER_ROLE_REPORT_ACCESS_ID,
  CUSTOMER_ROLE_TYPE,
  IS_USER_IN_FREE_MODE,
  ROLE_ID,
} from "./app-constants.json";
import { isArrayWithLength } from "./array-methods";

export const isAdminUser =
  config.demoApp ||
  localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_FULL_ACCESS_ID ||
  localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_DAY2_ADMIN_ID;

export let isAdminUserFn = () => {
  return (
    localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_FULL_ACCESS_ID ||
    localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_DAY2_ADMIN_ID
  );
};

export const isAdminOrDeptAdminUser = [
  CUSTOMER_ROLE_FULL_ACCESS_ID,
  CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID,
  CUSTOMER_ROLE_DAY2_ADMIN_ID,
].includes(localStorage.getItem(ROLE_ID));

export let isAdminOrDeptAdminUserFn = () => {
  return [
    CUSTOMER_ROLE_FULL_ACCESS_ID,
    CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID,
    CUSTOMER_ROLE_DAY2_ADMIN_ID,
  ].includes(localStorage.getItem(ROLE_ID));
};

export const isDeptAdminUser =
  localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID;

export let isDeptAdminUserFn = () => {
  return localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID;
};

export const isAppAccessUser =
  localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_APP_ACCESS_ID;

export let isAppAccessUserFn = () => {
  return localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_APP_ACCESS_ID;
};

export const isNoRoleUser = () =>
  localStorage.getItem(CUSTOMER_ROLE_TYPE) === "unassigned";

export const isReportsAccessUser =
  config.demoApp ||
  localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_REPORT_ACCESS_ID;

export let isReportsAccessUserFn = () => {
  return (
    config.demoApp ||
    localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_REPORT_ACCESS_ID
  );
};

export const isFreeModeUser = () => {
  return localStorage.getItem(IS_USER_IN_FREE_MODE) === "true";
};

export const isOnlyChildTenantUser = (organizations) => {
  if (
    isArrayWithLength(organizations) &&
    organizations.length === 1 &&
    organizations[0].ParentOrganizationId
  ) {
    return true;
  } else {
    return false;
  }
};

export let isDay2AdminUserFn = () => {
  return localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_DAY2_ADMIN_ID;
};

export let isOnlyAdminUserFn = () => {
  return localStorage.getItem(ROLE_ID) === CUSTOMER_ROLE_FULL_ACCESS_ID;
};

export let isProjectOwner = () =>
  localStorage.getItem(CUSTOMER_ROLE_TYPE) === "department-access";
