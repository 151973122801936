import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  PRODUCT_CATALOG,
  SUBSCRIPTION_PLAN,
  PAYMENT_METHOD,
  SUBSCRIBED_PLANS,
  ENTERPRISE_CONTACT,
  PLANS_PERMISSION,
  REDIRECT_FAC_VIEW_PLAN,
  PLANS_PERMISSION_MODAL,
} from "../../../utils/action-names.json";

const productCatalog = createReducer(PRODUCT_CATALOG);
const subscribedPlan = createReducer(SUBSCRIPTION_PLAN);
const supportedPaymentMethods = createReducer(PAYMENT_METHOD);
const subscribedPlans = createReducer(SUBSCRIBED_PLANS);
const enterprise = createReducer(ENTERPRISE_CONTACT);
const plansPermissions = createReducer(PLANS_PERMISSION);
const redirectToPlans = createReducer(REDIRECT_FAC_VIEW_PLAN);
const setFacPermissionModal = createReducer(PLANS_PERMISSION_MODAL);

export default combineReducers({
  productCatalog,
  subscribedPlan,
  supportedPaymentMethods,
  subscribedPlans,
  enterprise,
  plansPermissions,
  redirectToPlans,
  setFacPermissionModal,
});
