export const GET_SCAN_SCHEDULED_TASKS_REQUEST = 'GET_SCAN_SCHEDULED_TASKS_REQUEST';
export const GET_SCAN_SCHEDULED_TASKS_SUCCESS = 'GET_SCAN_SCHEDULED_TASKS_SUCCESS';
export const GET_SCAN_SCHEDULED_TASKS_FAILURE = 'GET_SCAN_SCHEDULED_TASKS_FAILURE';
export const GET_SCAN_JOBS_REQUEST = "GET_SCAN_JOBS_REQUEST";
export const GET_SCAN_JOBS_SUCCESS = "GET_SCAN_JOBS_SUCCESS";
export const GET_SCAN_JOBS_FAILURE = "GET_SCAN_JOBS_FAILURE";
export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE';
export const CLEAR_DELETE_SCHEDULE_DATA = "CLEAR_DELETE_SCHEDULE_DATA";
