import {
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_AZURE_REGIONS_REQUEST,
  GET_AZURE_REGIONS_SUCCESS,
  GET_AZURE_REGIONS_FAILURE,
} from "../actionTypes/regions";

import { GLOBAL_CONFIG_REGIONS } from "../../../reducers/global-config";

import { getRegionList, getAzureRegionList } from "../services/regions";

function requestGetRegions() {
  return {
    type: GET_REGIONS_REQUEST,
  };
}

export function receiveGetRegions(payload) {
  return {
    type: GET_REGIONS_SUCCESS,
    payload,
  };
}

export const filterUserData = (allRegions) => (dispatch) => {
  dispatch({
    type: GLOBAL_CONFIG_REGIONS,
    payload: allRegions,
  });
};

function getRegionsError(payload) {
  return {
    type: GET_REGIONS_FAILURE,
    payload,
  };
}

export const getRegions = () => (dispatch) => {
  dispatch(requestGetRegions());
  getRegionList()
    .then((data) => {
      if (data.Status && data.Status === "Failed") {
        dispatch(getRegionsError(data.Error));
        dispatch(filterUserData([]));
      } else {
        const regions = preProcessRegions(data);
        dispatch(receiveGetRegions(regions));
        dispatch(filterUserData(regions));
      }
    })
    .catch((err) => {
      dispatch(getRegionsError(err.body));
      dispatch(filterUserData([]));
    });
};

const preProcessRegions = (regions) => {
  const _regions = [];
  regions.forEach((region) => {
    _regions.push({
      regionCode: region.RegionCode,
      regionName: region.RegionName,
      isSelected: false,
    });
  });
  return _regions;
};

export const getAzureRegions = () => (dispatch) => {
  dispatch({
    type: GET_AZURE_REGIONS_REQUEST,
  });
  getAzureRegionList()
    .then((data) => {
      if (data.Status && data.Status === "Failed") {
        dispatch({
          type: GET_AZURE_REGIONS_FAILURE,
          payload: data.Error,
        });
      } else {
        const regions = preProcessRegions(data);
        dispatch({
          type: GET_AZURE_REGIONS_SUCCESS,
          payload: regions,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_AZURE_REGIONS_FAILURE,
      });
    });
};
