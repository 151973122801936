import {
  FETCHING_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_RESOURCES,
  ORGANIZATION_RESOURCES_LIST,
  SAVE_ORGANIZATION,
  SET_ORGANIZATION_NAME,
} from "../actionTypes/organization";

import {
  getOrganizationDetailsApi,
  getOrganizationResourcesApi,
  saveOrganizationApi,
} from "../services/organization";

import { preprocessResources } from "./common";

export const getOrganizationDetails = (orgId) => (dispatch, getState) => {
  dispatch({ type: FETCHING_ORGANIZATION_DETAILS });
  getOrganizationDetailsApi(orgId)
    .then((result) => {
      dispatch({
        type: GET_ORGANIZATION_DETAILS,
        payload: result,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ORGANIZATION_DETAILS,
        payload: error,
      });
    });
};

export const getOrganizationResources = (orgId) => (dispatch, getState) => {
  getOrganizationResourcesApi(orgId)
    .then((result) => {
      dispatch({
        type: ORGANIZATION_RESOURCES_LIST,
        payload: result,
      });
      dispatch({
        type: GET_ORGANIZATION_RESOURCES,
        payload: preprocessResources(result),
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ORGANIZATION_RESOURCES,
        payload: error,
      });
    });
};

export const onOrganizationChange = (organizationName) => ({
  type: SET_ORGANIZATION_NAME,
  payload: organizationName,
});

export const onOrganizationSave = () => (dispatch, getState) => {
  const { accountOnboard } = getState();
  const param = {
    Name: accountOnboard.organizationName,
    Description: "",
  };
  saveOrganizationApi(param)
    .then((result) => {
      dispatch({
        type: SAVE_ORGANIZATION,
        payload: result,
      });
    })
    .catch((error) => {
      dispatch({
        type: SAVE_ORGANIZATION,
        payload: "ERROR",
      });
    });
};
