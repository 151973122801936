export const SET_SELECTED_DATE_RANGE = "SET_SELECTED_DATE_RANGE";
export const SET_MONITORING_START_DATE = "SET_MONITORING_START_DATE";
export const SET_MONITORING_END_DATE = "SET_MONITORING_END_DATE";
export const SAVE_MONITORING_DASHBOARD_DATA = "SAVE_MONITORING_DASHBOARD_DATA";
export const CLEAR_APPLICATION_MONITORING_DATA =
  "CLEAR_APPLICATION_MONITORING_DATA";
export const UPDATE_MONITORING_DASHBOARD_DATA =
  "SAVE_MONITORING_DASHBOARD_DATA_FAILURE";
export const UPDATE_MONITORING_METRICS_REQUEST =
  "UPDATE_MONITORING_METRICS_REQUEST";
export const UPDATE_MONITORING_METRICS_SUCCESS =
  "UPDATE_MONITORING_METRICS_SUCCESS";
export const UPDATE_MONITORING_METRICS_FAILURE =
  "UPDATE_MONITORING_METRICS_FAILURE";
export const GET_APPLICATION_MONITORING_METRICS_REQUEST =
  "GET_APPLICATION_MONITORING_METRICS_REQUEST";
export const GET_APPLICATION_MONITORING_METRICS_SUCCESS =
  "GET_APPLICATION_MONITORING_METRICS_SUCCESS";
export const GET_APPLICATION_MONITORING_METRICS_FAILURE =
  "GET_APPLICATION_MONITORING_METRICS_FAILURE";
export const GET_MONITORING_METRICS_DATA_REQUEST =
  "GET_MONITORING_METRICS_DATA_REQUEST";
export const GET_MONITORING_METRICS_DATA_SUCCESS =
  "GET_MONITORING_METRICS_DATA_SUCCESS";
export const GET_MONITORING_METRICS_DATA_FAILURE =
  "GET_MONITORING_METRICS_DATA_FAILURE";
