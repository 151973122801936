import {
  SET_START_DATE_RANGE_FILTER,
  SET_END_DATE_RANGE_FILTER,
  SET_DATE_RANGE_ERROR,
  CLEAR_DATE_RANGE_ERROR,
} from "../actionTypes/dateRangeFilter";

const initialState = {
  startDate: null,
  endDate: null,
  hasError: false,
  errorMessage: "",
};

const dateRangeFilter = (state = initialState, action) => {
  switch (action.type) {
    case SET_START_DATE_RANGE_FILTER:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_END_DATE_RANGE_FILTER:
      return {
        ...state,
        endDate: action.payload,
      };
    case SET_DATE_RANGE_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
      };
    case CLEAR_DATE_RANGE_ERROR:
      return {
        ...state,
        hasError: initialState.hasError,
        errorMessage: initialState.errorMessage,
      };
    default:
      return state;
  }
};

export default dateRangeFilter;
