import {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE
} from "../actionTypes/roles";

const initialState = {
  isFetchingRoles: false,
  rolesList: null,
  fetchedRoles: false,
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        isFetchingRoles: true,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        isFetchingRoles: false,
        rolesList: action.payload,
        fetchedRoles: true,
      };
    case GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        isFetchingRoles: false,
        errorMessage: action.payload,
        fetchedRoles: true,
      };
    default:
      return state;
  }
};

export default roles;
