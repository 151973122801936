export const isSingleLineEllipsisApplied = (element) => {
  return element.scrollWidth > element.offsetWidth;
};

export const isMultiLineEllipsisApplied = (element) => {
  if (!element) return false;
  const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
  const numberOfLines = Math.floor(element.clientHeight / lineHeight);

  return element.scrollHeight > numberOfLines * lineHeight;
};
