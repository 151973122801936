import { useEffect, useRef, useState } from "react";

import { isArrayWithLength } from "../../../utils/array-methods";

export const filterMapProjectsByStatus = (data = [], status = "") => {
  const completed = [];
  const pending = [];
  data.forEach((project) => {
    if (project.Status === "IN-PROGRESS") {
      pending.push(project);
    } else {
      completed.push(project);
    }
  });

  return {
    pending,
    completed,
  };
  //return mapProjects.filter((project) => project.Status === status);
};

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export const formatNumberToUSDCurrency = (value) => {
  return USDollar.format(value);
};

export const useDeferredValue = (value, delay = 800) => {
  const [deferredValue, setDeferredValue] = useState(value);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setDeferredValue(value);
      timeoutRef.current = null;
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [value, delay]);

  return deferredValue;
};
export const getIsScopeMismatch = ({
  mapProjectScope = [],
  accountList = [],
}) => {
  let isScopeMismatch = false;
  let offboardedAccounts = [];
  mapProjectScope.forEach(({ value }) => {
    const accountNumberList = isArrayWithLength(accountList)
      ? accountList.map(({ accountNumber }) => accountNumber)
      : [];
    if (!accountNumberList.includes(value)) {
      isScopeMismatch = true;
      offboardedAccounts.push(value);
    }
  });
  return { isScopeMismatch, offboardedAccounts };
};

export const getResourceCount = ({ data, resourceFilterValue, t }) => {
  let resourceCount = data.ResourceCountTotal;
  if (resourceFilterValue === t("resources.tagged")) {
    resourceCount = data.ResourceCountTagged;
  }
  if (resourceFilterValue === t("resources.untagged")) {
    resourceCount = data.ResourceCountUntagged;
  }
  return resourceCount;
};

export const taggingStatusChartTooltip = (title, content, color) => {
  return `
  <div class="ag-chart-tooltip-title d-flex align-items-center justify-content-center" style="background-color: ${color}">
      ${title}
  </div>
  <div class="ag-chart-tooltip-content d-flex align-items-center justify-content-center mc-text-base-bold" style="background-color: #FFF">
      ${content}
  </div>`;
};
