import { STANDARD_PLAN_ID } from "../config/service/index";

import {
  CUSTOMER_TYPE,
  ACTIVE,
  CONNECTED_STATUS,
} from "../utils/app-constants.json";

const initialState = {
  accounts: [],
  regions: [],
  organizations: [],
  applications: null,
  environments: null,
  departments: [],
  userData: null,
  userDataLoaded: false,
  accountsLoaded: false,
  regionsLoaded: false,
  organizationsLoaded: false,
  departmentsLoaded: false,
  filteredUserData: null,
  filteredUserDataLoaded: false,
  isFirstRun: false,
  hasAccessToAddAccount: null,
  customerType: localStorage.getItem(CUSTOMER_TYPE),
  azureSubscriptions: [],
  azureSubscriptionsLoaded: false,
};

export const GLOBAL_CONFIG_ACCOUNTS = "GLOBAL_CONFIG_ACCOUNTS";
export const GLOBAL_CONFIG_REGIONS = "GLOBAL_CONFIG_REGIONS";
export const GLOBAL_CONFIG_USER_DATA = "GLOBAL_CONFIG_USER_DATA";
export const GLOBAL_CONFIG_ORGANIZATION = "GLOBAL_CONFIG_ORGANIZATION";
export const GLOBAL_CONFIG_DEPARTMENT = "GLOBAL_CONFIG_DEPARTMENT";
export const GLOBAL_CUSTOMER_TYPE = "GLOBAL_CUSTOMER_TYPE";
export const GLOBAL_CONFIG_AZURE_SUBSCRIPITIONS =
  "GLOBAL_CONFIG_AZURE_SUBSCRIPITIONS";

const checkFRE = (data) => {
  const { organizationsLoaded, accountsLoaded, organizations, allAccounts } =
    data;
  if (organizationsLoaded && accountsLoaded) {
    return !organizations.length || !allAccounts.length;
  } else if (organizationsLoaded) {
    return !organizations.length;
  }
  return false;
};

export default function globalConfig(state = initialState, action) {
  let filteredUserDataLoaded = false;
  let isSelfManagedUser = state.customerType === STANDARD_PLAN_ID;
  switch (action.type) {
    case GLOBAL_CONFIG_ACCOUNTS:
      const accounts = action.payload;
      const isDepartmentView = action.isDepartmentView;
      let filteredAccounts = accounts.filter(
        (a) =>
          (a.accountStatus === "ACTIVE" || a.accountStatus === "CONNECTED") &&
          !a.isDeleted &&
          !a.isDisabled
      );
      if (state.userDataLoaded && state.userData && state.userData.Accounts) {
        const allAccounts = [];
        filteredAccounts.forEach((acc) => {
          var index = state.userData.Accounts.findIndex(
            (a) => a.accountNumber === acc.accountNumber
          );
          if (index >= 0) {
            acc.isSelected = state.userData.Accounts[index].isSelected;
          }
          allAccounts.push(acc);
        });
        filteredAccounts = allAccounts;
      }
      let userDataAccounts = {
        Accounts: filteredAccounts,
        Regions: state.regions,
        Organizations: state.organizations,
        Departments: state.departments,
        AzureSubscriptions: state.azureSubscriptions,
      };
      if (
        state.userDataLoaded &&
        state.departmentsLoaded &&
        state.organizationsLoaded &&
        state.azureSubscriptionsLoaded
      ) {
        filteredUserDataLoaded = true;
      }
      const accountData = {
        ...state,
        accounts: filteredAccounts,
        filteredUserData: userDataAccounts,
        accountsLoaded: true,
        filteredUserDataLoaded,
        allAccounts: accounts,
      };
      return {
        ...accountData,
        isFirstRun: isDepartmentView ? false : checkFRE(accountData),
      };
    case GLOBAL_CONFIG_REGIONS:
      let regions = action.payload;
      if (state.userDataLoaded && state.userData && state.userData.Regions) {
        const allRegions = [];
        regions.forEach((reg) => {
          var index = state.userData.Regions.findIndex(
            (r) => r.regionCode === reg.regionCode
          );
          if (index >= 0) {
            reg.isSelected = state.userData.Regions[index].isSelected;
          }
          allRegions.push(reg);
        });
        regions = allRegions;
      }
      let userDataRegions = {
        Accounts: state.accounts,
        Regions: regions,
        Organizations: state.organizations,
        Departments: state.departments,
        AzureSubscriptions: state.azureSubscriptions,
      };
      if (
        state.userDataLoaded &&
        state.accountsLoaded &&
        state.departmentsLoaded &&
        state.organizationsLoaded &&
        state.azureSubscriptionsLoaded
      ) {
        filteredUserDataLoaded = true;
      }
      if (
        isSelfManagedUser &&
        (state.userDataLoaded || state.organizationsLoaded)
      ) {
        filteredUserDataLoaded = true;
      }
      return {
        ...state,
        regions: regions,
        filteredUserData: userDataRegions,
        regionsLoaded: true,
        filteredUserDataLoaded,
      };
    case GLOBAL_CONFIG_ORGANIZATION:
      let organizations = action.payload.Organizations;
      let applications = action.payload.Applications;
      let environments = action.payload.Environments;
      if (
        state.userDataLoaded &&
        state.userData &&
        state.userData.Organizations
      ) {
        const allOrganizations = [];
        organizations.forEach((org) => {
          org.isSelected = false;
          var index = state.userData.Organizations.findIndex(
            (r) => r.ID === org.ID
          );
          if (index >= 0) {
            org.isSelected = state.userData.Organizations[index].isSelected;
          }
          allOrganizations.push(org);
        });
        organizations = allOrganizations;
      }
      let userDataOrganizations = {
        Accounts: state.accounts,
        Regions: state.regions,
        Departments: state.departments,
        Organizations: organizations,
        Applications: applications,
        Environments: environments,
        AzureSubscriptions: state.azureSubscriptions,
      };
      if (
        state.userDataLoaded &&
        state.departmentsLoaded &&
        state.accountsLoaded &&
        state.azureSubscriptionsLoaded
      ) {
        filteredUserDataLoaded = true;
      }
      if (!organizations.length && isSelfManagedUser) {
        filteredUserDataLoaded = true;
      }
      const orgData = {
        ...state,
        organizations: organizations,
        applications: applications,
        environments: environments,
        filteredUserData: userDataOrganizations,
        organizationsLoaded: true,
        filteredUserDataLoaded,
      };

      return {
        ...orgData,
        isFirstRun: checkFRE(orgData),
      };
    case GLOBAL_CONFIG_DEPARTMENT:
      let departments = action.payload;
      if (
        state.userDataLoaded &&
        state.userData &&
        state.userData.Departments
      ) {
        const allDepartments = [];
        departments.forEach((dept) => {
          dept.isSelected = false;
          var index = state.userData.Departments.findIndex(
            (r) => r.ID === dept.ID
          );
          if (index >= 0) {
            dept.isSelected = state.userData.Departments[index].isSelected;
          }
          allDepartments.push(dept);
        });
        departments = allDepartments;
      }
      let userDataDepartments = {
        Accounts: state.accounts,
        Regions: state.regions,
        Departments: departments,
        Organizations: state.organizations,
        Applications: state.applications,
        Environments: state.environments,
        AzureSubscriptions: state.azureSubscriptions,
      };
      if (
        state.userDataLoaded &&
        state.organizationsLoaded &&
        state.accountsLoaded &&
        state.azureSubscriptionsLoaded
      ) {
        filteredUserDataLoaded = true;
      }
      const deptData = {
        ...state,
        departments: departments,
        filteredUserData: userDataDepartments,
        departmentsLoaded: true,
        filteredUserDataLoaded,
      };
      return {
        ...deptData,
        isFirstRun: checkFRE(deptData),
      };
    case GLOBAL_CONFIG_USER_DATA:
      let userData = action.payload;
      let filteredUserData = {};
      if (state.accountsLoaded) {
        const accounts = state.accounts;
        const allAccounts = [];
        accounts.forEach((acc) => {
          if (userData && userData.Accounts) {
            var index = userData.Accounts.findIndex(
              (a) => a.accountNumber === acc.accountNumber
            );
            if (index >= 0) {
              acc.isSelected = userData.Accounts[index].isSelected;
            }
          }
          allAccounts.push(acc);
        });
        userData["Accounts"] = allAccounts;
      }
      if (state.regionsLoaded) {
        const regions = state.regions;
        const allRegions = [];
        regions.forEach((reg) => {
          if (userData && userData.Regions) {
            var index = userData.Regions.findIndex(
              (r) => r.regionCode === reg.regionCode
            );
            if (index >= 0) {
              reg.isSelected = userData.Regions[index].isSelected;
            }
          }
          allRegions.push(reg);
        });
        userData["Regions"] = allRegions;
      }
      if (state.organizationsLoaded) {
        const organizations = state.organizations;
        const allOrganizations = [];
        organizations.forEach((org) => {
          if (userData && userData.Organizations) {
            var index = userData.Organizations.findIndex(
              (d) => d.ID === org.ID
            );
            if (index >= 0) {
              org.isSelected = userData.Organizations[index].isSelected;
            }
          }
          allOrganizations.push(org);
        });
        userData["Organizations"] = allOrganizations;
      }
      if (state.departmentsLoaded) {
        const allDepartments = [];
        state.departments.forEach((dept) => {
          if (userData && userData.Departments) {
            var index = userData.Departments.findIndex((d) => d.ID === dept.ID);
            if (index >= 0) {
              dept.isSelected = userData.Departments[index].isSelected;
            }
          }
          allDepartments.push(dept);
        });
        userData["Departments"] = allDepartments;
      }

      if (state.azureSubscriptionsLoaded) {
        const azureSubscriptions = state.azureSubscriptions;
        const allAzureSubscriptions = [];
        azureSubscriptions.forEach((sub) => {
          if (userData && userData.AzureSubscriptions) {
            var index = userData.AzureSubscriptions.findIndex(
              (s) => s.subscriptionId === sub.subscriptionId
            );
            if (index >= 0) {
              sub.isSelected = userData.AzureSubscriptions[index].isSelected;
            }
          }
          allAzureSubscriptions.push(sub);
        });
        userData["AzureSubscriptions"] = allAzureSubscriptions;
      }
      if (
        state.departmentsLoaded &&
        state.organizationsLoaded &&
        state.accountsLoaded &&
        state.azureSubscriptionsLoaded
      ) {
        filteredUserData = userData;
        filteredUserDataLoaded = true;
      }
      if (
        userData.Organizations &&
        !userData.Organizations.length &&
        isSelfManagedUser
      ) {
        filteredUserDataLoaded = true;
      }
      return {
        ...state,
        userData: userData,
        userDataLoaded: true,
        filteredUserData,
        filteredUserDataLoaded,
      };
    case GLOBAL_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload,
      };
    case GLOBAL_CONFIG_AZURE_SUBSCRIPITIONS:
      const subscriptions = action.payload;
      let filteredSubscriptions =
        subscriptions &&
        subscriptions.filter(
          (s) =>
            (s.subscriptionStatus === ACTIVE ||
              s.subscriptionStatus === CONNECTED_STATUS) &&
            !s.isDeleted &&
            !s.isDisabled
        );

      if (
        state.userDataLoaded &&
        state.userData &&
        state.userData.AzureSubscriptions
      ) {
        const allAzureSubscriptions = [];
        filteredSubscriptions.forEach((sub) => {
          var index = state.userData.AzureSubscriptions.findIndex(
            (s) => s.subscriptionId === sub.subscriptionId
          );
          if (index >= 0) {
            sub.isSelected =
              state.userData.AzureSubscriptions[index].isSelected;
          }
          allAzureSubscriptions.push(sub);
        });
        filteredSubscriptions = allAzureSubscriptions;
      }

      let userDataAzureSubscriptions = {
        ...(state.filteredUserData ? { ...state.filteredUserData } : {}),
        AzureSubscriptions: filteredSubscriptions,
      };

      if (
        state.userDataLoaded &&
        state.accountsLoaded &&
        state.departmentsLoaded &&
        state.organizationsLoaded
      ) {
        filteredUserDataLoaded = true;
      }

      return {
        ...state,
        azureSubscriptions: filteredSubscriptions,
        azureSubscriptionsLoaded: true,
        filteredUserDataLoaded,
        filteredUserData: userDataAzureSubscriptions,
      };
    default:
      return state;
  }
}
