import i18n from "../../config/locale/i18n";
import { DISABLED_STATUS } from "../../utils/app-constants.json";
import { isAdminUserFn } from "../../utils/users";

export const preProcessMFADetails = (details) => {
  return {
    secretKey: details.SecretKey,
    qrCodeBase64Encoded: details.QRCode,
  };
};

export const userActions = {
  EDIT: {
    type: "edit",
    title: i18n.t("common:editLabel"),
    icon: "fa fa-edit",
  },
  DELETE: {
    type: "delete",
    title: i18n.t("common:deleteLabel"),
    icon: "fa fa-trash",
  },
  RESET_MFA: {
    type: "reset-mfa",
    title: i18n.t("mfa:resetMFA"),
    icon: "fa fa-undo",
  },
};

const isAdminUser = isAdminUserFn();

export const supportedUserActions = (user) => {
  const isLocked = user.isLocked;
  const mfaConfigured = user.MFAConfigured;
  const status = user.Status;
  const isDisabled = status && status === DISABLED_STATUS;
  const actions = isDisabled
    ? [userActions.DELETE]
    : [userActions.DELETE, userActions.EDIT];
  if ((isLocked || mfaConfigured) && isAdminUser) {
    actions.unshift(userActions.RESET_MFA);
  }
  return actions;
};

export const USER_STATUS = {
  active: 'Active',
  disabled: 'Disabled'
}