export const getDesiredStatus = (status) => {
  let classNames = "circle account-status ";
  let humanizedStatus = "Not Configured";
  switch (status) {
    case "SUCCESS":
      classNames = classNames.concat("connected");
      humanizedStatus = "Success";
      break;
    case "FAILED":
      classNames = classNames.concat("failed");
      humanizedStatus = "Failed";
      break;
    case "INITIATED":
      classNames = classNames.concat("in-progress");
      humanizedStatus = "Running";
      break;
    default:
      break;
  }
  return {
    classNames,
    humanizedStatus,
  };
};

export const getDesiredStateEnabledStatus = (status) => {
  return status === "FAILED" || status === "NOT AVAILABLE";
};
