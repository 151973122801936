import {
  CHANGE_THEME,
  CLEAR_UTILITY,
  TOGGLE_SWITCHER_SIDEBAR,
  TOGGLE_CREATE_SIDEBAR,
} from "../actions/layout";

export const DashboardThemes = {
  LIGHT: "light",
  DARK: "dark",
};

Object.freeze(DashboardThemes);

export const UTILITY_LAYOUT_TYPE = {
  MT_ORG_SWITCHER: "MT_ORG_SWITCHER",
  MT_CREATE_ORG: "MT_CREATE_ORG",
  MT_EDIT_ORG: "MT_EDIT_ORG",
};

const defaultState = {
  dashboardTheme: DashboardThemes.DARK,
  utility: {
    layoutType: null,
  },
};

export default function layoutReducer(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        dashboardTheme: action.payload,
      };

    case TOGGLE_SWITCHER_SIDEBAR:
      return {
        ...state,
        utility: {
          ...state.utility,
          layoutType: UTILITY_LAYOUT_TYPE.MT_ORG_SWITCHER,
        },
      };

    case TOGGLE_CREATE_SIDEBAR:
      return {
        ...state,
        utility: {
          ...state.utility,
          layoutType: UTILITY_LAYOUT_TYPE.MT_CREATE_ORG,
        },
      };

    case CLEAR_UTILITY:
      return {
        ...state,
        utility: {
          ...defaultState.utility,
        },
      };
    default:
      return state;
  }
}
