import {
  CHANGE_PARAMETER_CONFIGURATION_REQUEST,
  CHANGE_PARAMETER_CONFIGURATION_SUCCESS,
  CHANGE_PARAMETER_CONFIGURATION_FAILURE,
  RESET_APPLICATION_PARAMETER_STATE,
} from "../actionTypes/parameters";

const initialState = {
  updatingParameters: false,
  updatingParametersSuccess: false,
  updatingParametersFailed: false,
};

const appParameters = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PARAMETER_CONFIGURATION_REQUEST:
      return {
        ...state,
        updatingParameters: true,
        updatingParametersSuccess: initialState.updatingParametersSuccess,
        updatingParametersFailed: initialState.updatingParametersFailed,
      };
    case CHANGE_PARAMETER_CONFIGURATION_SUCCESS:
      return {
        ...state,
        updatingParameters: false,
        updatingParametersSuccess: true,
      };
    case CHANGE_PARAMETER_CONFIGURATION_FAILURE:
      return {
        ...state,
        updatingParameters: false,
        updatingParametersFailed: true,
      };
    case RESET_APPLICATION_PARAMETER_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default appParameters;
