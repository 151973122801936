export const featureList = {
  AMI: "AMI",
  TOP_CARDS: "TOP_CARDS",
  DATA_REFRESH: "DATA_REFRESH",
  REPORT_COST: "REPORT_COST",
  REPORT_OPERATIONS: "REPORT_OPERATIONS",
  DEPARTMENT_POLICIES: "DEPARTMENT_POLICIES",
  ENVIRONMENT_POLICIES: "ENVIRONMENT_POLICIES",
  ENVIRONMENT_CONFIG: "ENVIRONMENT_CONFIG",
  ENVIRONMENT_ALERTS: "ENVIRONMENT_ALERTS",
  ENVIRONMENT_RULES: "ENVIRONMENT_RULES",
  COST_POLICIES: "COST_POLICIES",
  SECURITY_POLICIES: "SECURITY_POLICIES",
  USERS_ROLES_AI: "USERS_ROLES_AI",
  ROLES_ADD_USER: "ROLES_ADD_USER",
  SCOPE_CUSTOMERS: "SCOPE_CUSTOMERS",
  SCRIPTS_OWNER: "SCRIPTS_OWNER",
  ADMIN_ASSETS: "ADMIN_ASSETS",
  EVENT_TIMELINE: "EVENT_TIMELINE",
  PATCH: "PATCH",
  DEPARTMENT_COST: "DEPARTMENT_COST",
  DESIRED_STATE_CREATION: "DESIRED_STATE_CREATION",
  DESIRED_STATE_EXECUTION: "DESIRED_STATE_EXECUTION",
  CLASSIFICATION_ARCHITECTURE: "CLASSIFICATION_ARCHITECTURE",
  RESOURCE_COUNT: "RESOURCE_COUNT",
  BP_APP_TASKS_ENABLE_DISABLE: "BP_APP_TASKS_ENABLE_DISABLE",
  HIDE_TAG_TASKS: "HIDE_TAG_TASKS",
  DESIRED_ACCOUNT_STATE: "DESIRED_ACCOUNT_STATE",
  SHOW_MONITORING_HIGH_LEVEL_CHARTS: "SHOW_MONITORING_HIGH_LEVEL_CHARTS",
  HYBRID_ACTIVATION: "HYBRID_ACTIVATION",
  ACCOUNT_LEVEL_BOTS: "ACCOUNT_LEVEL_BOTS",
  BOT_INSIGHTS: "BOT_INSIGHTS",
  BOT_POLICY_COUNT: "BOT_POLICY_COUNT",
  INTEGRATION_LIST: "INTEGRATION_LIST",
  ASSESSMENTS: "ASSESSMENTS",
  DESIRED_REGION_STATE: "DESIRED_REGION_STATE",
  BLUEPRINT_COST_ESTIMATE: "BLUEPRINT_COST_ESTIMATE",
  ORG_MANAGEMENT: "ORG_MANAGEMENT",
  AZURE: "AZURE",
};
