import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  FINDING_SUMMARY_URL,
  ORG_BASE_URL,
  ORG_COST_OPTIMIZATION_SUMMARY,
  RESOURCE_INSIGHT_SUMMARY_URL,
} from "../../../config/service";

export const resourcesSavingsApi = createApi({
  reducerPath: "resourcesSavings",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getResourcesSavings: builder.query({
      query: ({ resource }) => {
        const params = encodeURIComponent(JSON.stringify(resource));
        return {
          url: `${ORG_COST_OPTIMIZATION_SUMMARY}?Filters=${params}`,
          method: "GET",
        };
      },
    }),
    getIAMResourceType: builder.query({
      query: ({ resource }) => {
        const params = encodeURIComponent(JSON.stringify(resource));
        return {
          url: `${ORG_BASE_URL}${RESOURCE_INSIGHT_SUMMARY_URL}?Filters=${params}`,
          method: "GET",
        };
      },
    }),
    getFindingSummary: builder.query({
      query: ({ resource }) => {
        const params = encodeURIComponent(JSON.stringify(resource));
        return {
          url: `${FINDING_SUMMARY_URL}?Filters=${params}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetResourcesSavingsQuery,
  useGetIAMResourceTypeQuery,
  useGetFindingSummaryQuery,
} = resourcesSavingsApi;
