// MAPProject List
export const FETCHING_MAP_PROJECT_START = "FETCHING_MAP_PROJECT_START";
export const FETCHING_MAP_PROJECT_SUCCESS = "FETCHING_MAP_PROJECT_SUCCESS";
export const FETCHING_MAP_PROJECT_FAILURE = "FETCHING_MAP_PROJECT_FAILURE";

export const FETCHING_COMPLETED_MAP_PROJECT_FAILURE =
  "FETCHING_COMPLETED_MAP_PROJECT_FAILURE";
export const FETCHING_COMPLETED_MAP_PROJECT_SUCCESS =
  "FETCHING_COMPLETED_MAP_PROJECT_SUCCESS";

//MAP Project Count
export const FETCHING_MAP_PROJECT_COUNT_SUCCESS =
  "FETCHING_MAP_PROJECT_COUNT_SUCCESS";
export const FETCHING_MAP_PROJECT_COUNT_FAILURE =
  "FETCHING_MAP_PROJECT_COUNT_FAILURE";
export const FETCHING_MAP_PROJECT_COUNT_START =
  "FETCHING_MAP_PROJECT_COUNT_START";

// Delete Map Project
export const DELETE_MAP_PROJECT_START = "DELETE_MAP_PROJECT_START";
export const DELETE_MAP_PROJECT_SUCCESS = "DELETE_MAP_PROJECT_SUCCESS";
export const DELETE_MAP_PROJECT_FAILURE = "DELETE_MAP_PROJECT_FAILURE";

//Update Map Project
export const UPDATE_MAP_PROJECT_START = "UPDATE_MAP_PROJECT_START";
export const UPDATE_MAP_PROJECT_SUCCESS = "UPDATE_MAP_PROJECT_SUCCESS";
export const UPDATE_MAP_PROJECT_FAILURE = "UPDATE_MAP_PROJECT_FAILURE";
