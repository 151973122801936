import {
  GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_REQUEST,
  GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_SUCCESS,
  GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_FAILURE,
} from "../actionTypes/billingGroupsListActionTypes";

const initialState = {
  billingGroupsList: [],
  isFetchingTenantManagementAccountList: false,
  fetchingTenantManagementAccountListFailed: false,
  isFetchedTenantManagementAccountList: false,
  tenantManagementAccountList: {},
};

const billingGroupsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingTenantManagementAccountList: true,
        fetchingTenantManagementAccountListFailed:
          initialState.fetchingTenantManagementAccountListFailed,
        isFetchedTenantManagementAccountList:
          initialState.isFetchedTenantManagementAccountList,
      };
    case GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingTenantManagementAccountList: false,
        isFetchedTenantManagementAccountList: true,
        tenantManagementAccountList: {
          ...state.tenantManagementAccountList,
          [action.payload.tenantId]: action.payload.managementAccounts,
        },
      };
    case GET_TENANT_MANAGEMENT_ACCOUNTS_LIST_FAILURE:
      return {
        ...state,
        isFetchingTenantManagementAccountList: false,
        fetchingTenantManagementAccountListFailed: true,
        isFetchedTenantManagementAccountList: true,
      };
    default:
      return state;
  }
};

export default billingGroupsList;
