import React, { useCallback, useEffect } from "react";
import Day2FeatureSelection from "./Day2FeatureSelection";

import { HOME_BASE_PATH } from "../../../utils/app-paths.json";
import { isArrayWithLength } from "../../../utils/array-methods";
import { shallowEqual, useSelector } from "react-redux";

import styles from "./NewFirstRun.module.scss";
import classNames from "classnames";
import { withRouter } from "@hocs/withRouter";

const Day2FeatureSelectionPage = ({ history }) => {
  const {
    customerPreferencesFetched,
    customerPreferences,
    usersActiveOrganizationId,
  } = useSelector(
    ({
      newFirstRun: { customerPreferencesFetched, customerPreferences },
      auth: { usersActiveOrganizationId },
    }) => {
      return {
        customerPreferencesFetched,
        customerPreferences,
        usersActiveOrganizationId,
      };
    },
    shallowEqual
  );

  const redirectAfterSelection = useCallback(() => {
    history.push(HOME_BASE_PATH);
  }, [history]);

  useEffect(() => {
    if (
      (customerPreferencesFetched && isArrayWithLength(customerPreferences)) ||
      !usersActiveOrganizationId
    ) {
      redirectAfterSelection();
    }
  }, [
    customerPreferences,
    customerPreferencesFetched,
    redirectAfterSelection,
    usersActiveOrganizationId,
  ]);

  return (
    <div className={classNames(styles.BackGround)}>
      <div
        className={classNames(
          "d-flex justify-content-center align-items-center",
          styles.FirstRun,
          styles.FirstStep
        )}
      >
        <Day2FeatureSelection
          parentNext={redirectAfterSelection}
          isOnlyFeatureSelection
        />
      </div>
    </div>
  );
};

export default withRouter(Day2FeatureSelectionPage);
