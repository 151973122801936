export const GET_APP_FILE_OBJECTS_REQUEST = "GET_APP_FILE_OBJECTS_REQUEST";
export const GET_APP_FILE_OBJECTS_SUCCESS = "GET_APP_FILE_OBJECTS_SUCCESS";
export const GET_APP_FILE_OBJECTS_FAILURE = "GET_APP_FILE_OBJECTS_FAILURE";
export const DELETE_APP_FILE_OBJECTS_REQUEST =
  "DELETE_APP_FILE_OBJECTS_REQUEST";
export const DELETE_APP_FILE_OBJECTS_SUCCESS =
  "DELETE_APP_FILE_OBJECTS_SUCCESS";
export const DELETE_APP_FILE_OBJECTS_FAILURE =
  "DELETE_APP_FILE_OBJECTS_FAILURE";
export const CLEAR_DELETE_APP_FILE_OBJECTS_DATA =
  "CLEAR_DELETE_APP_FILE_OBJECTS_DATA";
export const SAVE_SELECTED_FILES_STATUS = "SAVE_SELECTED_FILES_STATUS";
export const CLEAR_SELECTED_FILES_STATUS = "CLEAR_SELECTED_FILES_STATUS";
export const CREATE_EDIT_OBJECT_REQUEST = "CREATE_EDIT_OBJECT_REQUEST";
export const CREATE_EDIT_OBJECT_SUCCESS = "CREATE_EDIT_OBJECT_SUCCESS";
export const CREATE_EDIT_OBJECT_FAILURE = "CREATE_EDIT_OBJECT_FAILURE";
export const CLEAR_CREATE_EDIT_OBJECT_DATA = "CLEAR_CREATE_EDIT_OBJECT_DATA";
export const SAVE_SELECTED_FILES_PERCENTAGE = "SAVE_SELECTED_FILES_PERCENTAGE";
export const SAVE_SELECTED_PATH = "SAVE_SELECTED_PATH";
export const CLEAR_STORAGE_DATA = "CLEAR_STORAGE_DATA";
export const UPDATE_FILE_UPLOAD_BY_INSTANCE_STATUS =
  "UPDATE_FILE_UPLOAD_BY_INSTANCE_STATUS";
export const DOWNLOAD_FILE_TO_AN_INSTANCE_REQUEST =
  "DOWNLOAD_FILE_TO_AN_INSTANCE_REQUEST";
export const DOWNLOAD_FILE_TO_AN_INSTANCE_SUCCESS =
  "DOWNLOAD_FILE_TO_AN_INSTANCE_SUCCESS";
export const DOWNLOAD_FILE_TO_AN_INSTANCE_FAILURE =
  "DOWNLOAD_FILE_TO_AN_INSTANCE_FAILURE";
export const CLEAR_DOWNLOAD_FILE_TO_AN_INSTANCE_DATA =
  "CLEAR_DOWNLOAD_FILE_TO_AN_INSTANCE_DATA";
export const CANCEL_UPLOAD_SUCCESS = 
"CANCEL_UPLOAD_SUCCESS";
export const CANCEL_UPLOAD_FAILURE = 
"CANCEL_UPLOAD_FAILURE";
export const  CLEAR_CANCELLED_FILE_OBJECTS_DATA =
"CLEAR_CANCELLED_FILE_OBJECTS_DATA";
