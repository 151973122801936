export const GET_DESIRED_STATE_SCHEDULED_TASKS_REQUEST = 'GET_DESIRED_STATE_SCHEDULED_TASKS_REQUEST';
export const GET_DESIRED_STATE_SCHEDULED_TASKS_SUCCESS = 'GET_DESIRED_STATE_SCHEDULED_TASKS_SUCCESS';
export const GET_DESIRED_STATE_SCHEDULED_TASKS_FAILURE = 'GET_DESIRED_STATE_SCHEDULED_TASKS_FAILURE';
export const GET_DESIRED_STATE_JOBS_REQUEST = "GET_DESIRED_STATE_JOBS_REQUEST";
export const GET_DESIRED_STATE_JOBS_SUCCESS = "GET_DESIRED_STATE_JOBS_SUCCESS";
export const GET_DESIRED_STATE_JOBS_FAILURE = "GET_DESIRED_STATE_JOBS_FAILURE";
export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE';
export const CLEAR_DELETE_SCHEDULE_DATA = "CLEAR_DELETE_SCHEDULE_DATA";
export const SAVE_DESIRED_STATE_POLICY = "SAVE_DESIRED_STATE_POLICY";
export const SET_ADD_DESIRED_STATE_EXECUTE = "SET_ADD_DESIRED_STATE_EXECUTE";


