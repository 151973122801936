import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../config/customBaseQuery";
import {
  HEALTH_EVENTS_BASE_URL,
  HEALTH_EVENTS_SUMMARY,
} from "../../../../config/service";
import { WEB_REQUEST_TYPE } from "../../../../helpers/apiServiceHelper";

export const healthEventsAPI = createApi({
  reducerPath: "healthEventsAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    healthEventsSummary: builder.query({
      query: () => {
        return {
          url: `${HEALTH_EVENTS_BASE_URL}${HEALTH_EVENTS_SUMMARY}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const { useHealthEventsSummaryQuery } = healthEventsAPI;
