export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAILURE = "GET_TASKS_FAILURE";
export const GET_JOBS_REQUEST = "GET_JOBS_REQUEST";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";
export const GET_APP_JOBS_REQUEST = "GET_APP_JOBS_REQUEST";
export const GET_APP_JOBS_SUCCESS = "GET_APP_JOBS_SUCCESS";
export const GET_APP_JOBS_FAILURE = "GET_APP_JOBS_FAILURE";
export const CLEAR_TASKS = "CLEAR_TASKS";
export const CLEAR_APP_JOBS = "CLEAR_APP_JOBS";
