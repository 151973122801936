import {
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_AZURE_REGIONS_REQUEST,
  GET_AZURE_REGIONS_SUCCESS,
  GET_AZURE_REGIONS_FAILURE,
} from "../actionTypes/regions";

export default function userData(
  state = {
    isFetching: false,
    regionList: null,
    isFetchingAzure: false,
    azureRegionList: null,
  },
  action
) {
  switch (action.type) {
    case GET_REGIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case GET_REGIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        regionList: action.payload,
      });
    case GET_REGIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    case GET_AZURE_REGIONS_REQUEST:
      return Object.assign({}, state, {
        isFetchingAzure: true,
      });
    case GET_AZURE_REGIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingAzure: false,
        azureRegionList: action.payload,
      });
    case GET_AZURE_REGIONS_FAILURE:
      return Object.assign({}, state, {
        isFetchingAzure: false,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
}
