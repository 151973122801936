import classnames from "classnames";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

import SingleCheckbox from "../../../../../src/components/SingleCheckbox";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Popup from "../../../../components/Popup/Popup";
import { RegionStatusFormatter } from "../../../../helpers/account-status-formatter";
import { DEFAULT_REGION } from "../../../../utils/app-constants.json";
import regionCodes from "../../../../utils/regionFlagCodes.json";
import { saveSelectedRegions, setDisableNextStep } from "../../actions/account";
import styles from "./Account.module.scss";
import { isArrayWithLength } from "../../../../utils/array-methods";

const RegionSelector = ({
  regionsList,
  isEditRegion,
  t,
  firstRunView,
  disableDefaultRegion = false,
  hideComment = false,
  nonSupportedRegions = [],
}) => {
  const [selecteAllRegions, setSelecteAllRegions] = useState(false);
  const [selectedRegionCount, setSelectedRegionCount] = useState(0);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [failedRegion, setFailedRwegion] = useState();

  const dispatch = useDispatch();

  const filteredRegions = useMemo(() => {
    if (
      isArrayWithLength(nonSupportedRegions) &&
      isArrayWithLength(regionsList)
    ) {
      return regionsList.filter(
        (region) => !nonSupportedRegions.includes(region.regionCode)
      );
    } else {
      return regionsList;
    }
  }, [nonSupportedRegions, regionsList]);

  useEffect(() => {
    if (isArrayWithLength(filteredRegions)) {
      // Check added to make sure us-east-1 region is disabled by default for on-boarding
      filteredRegions.forEach((reg) => {
        if (reg.regionCode === DEFAULT_REGION && !disableDefaultRegion) {
          reg.isDisabled = true;
          reg.isSelected = true;
        }
      });

      let selectedCount = countSelected(filteredRegions);
      setSelectedRegionCount(selectedCount);
      if (selectedCount === filteredRegions.length) {
        setSelecteAllRegions(true);
      } else {
        setSelecteAllRegions(false);
      }
    }
  }, [filteredRegions]);

  const regionContent = (region) => (
    <Row>
      <Col xl={3}>
        <ReactCountryFlag
          style={{
            width: firstRunView ? "16px" : "32px",
            height: "32px",
          }}
          countryCode={regionCodes[region.regionCode]}
          svg
        />
      </Col>
      <Col xl={9} className={classnames("bs4-text-left", firstRunView && "pl-0")}>
        <h6 className="mc-text-base-regular">{region.regionCode}</h6>
        <h6 className="mc-text-base-regular">{region.regionName}</h6>
        {!firstRunView &&
          region.isDisabled &&
          RegionStatusFormatter(region.status)}
        {region.errorMsg && (
          <span
            title={region.errorMsg[0].step}
            className="bs4-p-0"
            onClick={() => {
              toggleConnectionErrorPopUp(region);
            }}
          >
            {" "}
            <i className="fa fa-info-circle" />
          </span>
        )}
      </Col>
    </Row>
  );
  const sortedFilteredRegions = useMemo(() => {
    return isArrayWithLength(filteredRegions)
      ? filteredRegions.sort((a, b) => {
          if (a.isDisabled && !b.isDisabled) {
            return -1;
          }
          if (!a.isDisabled && b.isDisabled) {
            return 1;
          }
          return 0;
        })
      : [];
  }, [filteredRegions]);
  const handleSelectRegion = (event) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const regions = sortedFilteredRegions;
    let tempRegionsList = [];
    regions.forEach((region) => {
      if (region.regionCode === id && !region.isDisabled) {
        region = { ...region, isSelected: checked };
      }
      tempRegionsList = tempRegionsList.concat(region);
    });
    const selectedCount = countSelected(tempRegionsList);
    setSelectedRegionCount(selectedCount);
    setSelecteAllRegions(selectedCount === tempRegionsList.length);
    dispatch(saveSelectedRegions(tempRegionsList));
    handleDisableNextStep(selectedCount);
  };

  const countSelected = (regionList) => {
    return (
      regionList &&
      regionList.length > 0 &&
      regionList.filter((region) => region.isSelected).length
    );
  };

  const toggleAllRegion = (e) => {
    let regionList =
      isArrayWithLength(sortedFilteredRegions) &&
      sortedFilteredRegions.map((region) => ({
        ...region,
        isSelected: region.isDisabled ? true : !selecteAllRegions,
      }));
    const selectedCount = countSelected(regionList);
    setSelectedRegionCount(selectedCount);
    setSelecteAllRegions(!selecteAllRegions);
    dispatch(saveSelectedRegions(regionList));
    handleDisableNextStep(selectedCount);
  };

  const handleDisableNextStep = (selectedCount) => {
    selectedCount
      ? dispatch(setDisableNextStep(false))
      : dispatch(setDisableNextStep(true));
  };

  const toggleConnectionErrorPopUp = (region) => {
    setFailedRwegion(region);
    setShowConnectionError(!showConnectionError);
  };
  const errorContent = (region) => {
    return (
      <>
        <h5>{t("errorTypeLabel")}:</h5>
        <p>{region.errorMsg[0].step} </p>
        <h5>{t("reasonLabel")}:</h5>
        <p>{region.errorMsg[0].reason} </p>
      </>
    );
  };

  return (
    <>
      <div className="bs4-mt-3">
        {!hideComment && !firstRunView && (
          <p className={classnames(styles.comment, "mc-text-base-regular")}>
            <i className="fa fa-info-circle text-primary"></i>&nbsp;
            {isEditRegion
              ? `${t("selectRegionMessage2")} ${t("selectRegionMessage3")}`
              : `${t("selectRegionMessage3")} ${t("selectRegionMessage4")}`}
          </p>
        )}

        <SingleCheckbox
          checked={selecteAllRegions}
          onChange={(e) => toggleAllRegion(e)}
          text={t("common:selectAll")}
        />
      </div>
      <Row className="row-no-margin bs4-mt-0">
        {isArrayWithLength(sortedFilteredRegions) &&
          sortedFilteredRegions.map((region, index) => (
            <Col
              key={region.regionCode + index}
              xs={firstRunView ? 4 : 3}
              className={firstRunView && styles.customCol}
            >
              <div key={region.regionCode}>
                <Checkbox
                  className={classnames(
                    styles.backgroundBlue,
                    firstRunView && styles.regionCard
                  )}
                  contentClass={styles.blockContent}
                  type="checkbox"
                  id={region.regionCode}
                  name="region"
                  checked={region.isSelected}
                  content={regionContent(region)}
                  onChange={(e) => handleSelectRegion(e)}
                  isDisabled={region.isDisabled}
                />
              </div>
            </Col>
          ))}
      </Row>
      <div>
        <h6>
          {t("Selected")} {selectedRegionCount}/
          {sortedFilteredRegions ? sortedFilteredRegions.length : 0}{" "}
          {t("nav.Regions")}
        </h6>
      </div>
      {showConnectionError && (
        <Popup
          isOpen={showConnectionError}
          toggle={toggleConnectionErrorPopUp}
          content={errorContent(failedRegion)}
          header={<div>{failedRegion.regionName}</div>}
        />
      )}
    </>
  );
};

export default withTranslation(["addAccount", "common"])(RegionSelector);
