import {
  GET_INVENTORY_POLICIES_REQUEST,
  GET_INVENTORY_POLICIES_SUCCESS,
  GET_INVENTORY_POLICIES_FAILURE
} from "../actionTypes/inventory-policies";

const initialState = {
  isFetching: false,
  inventoryPolicyList: null,
  showInventoryPolicyList: true
};

const inventoryPolicies = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_POLICIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        inventoryPolicyList: initialState.inventoryPolicyList
      };
    case GET_INVENTORY_POLICIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        inventoryPolicyList: action.payload,
        showInventoryPolicyList: true
      };
    case GET_INVENTORY_POLICIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default inventoryPolicies;
