import {
  GET_DESIRED_STATE_SCHEDULED_TASKS_REQUEST,
  GET_DESIRED_STATE_SCHEDULED_TASKS_SUCCESS,
  GET_DESIRED_STATE_SCHEDULED_TASKS_FAILURE,
  GET_DESIRED_STATE_JOBS_REQUEST,
  GET_DESIRED_STATE_JOBS_SUCCESS,
  GET_DESIRED_STATE_JOBS_FAILURE,
  SAVE_DESIRED_STATE_POLICY,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  CLEAR_DELETE_SCHEDULE_DATA,
  SET_ADD_DESIRED_STATE_EXECUTE,
} from "../actionTypes/desired-state";

const initialState = {
  isFetchingDesiredStateScheduledTasks: false,
  desiredStateScheduleTaskList: null,
  isFetchingDesiredStateJobs: false,
  desiredStateExecutionHistoryList: null,
  deletingSchedule: false,
  deletedSchedule: false,
  deleteScheduleMessage: null,
  selectedDesiredStatePolicy: [],
  setAddDesiredState: false,
  desiredStateScheduleTaskOffset: null,
  desiredStateExecutionHistoryOffset: null,
};

const desiredState = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADD_DESIRED_STATE_EXECUTE:
      return {
        ...state,
        setAddDesiredState: action.payload,
      };
    case GET_DESIRED_STATE_SCHEDULED_TASKS_REQUEST:
      return {
        ...state,
        isFetchingDesiredStateScheduledTasks: true,
      };
    case GET_DESIRED_STATE_SCHEDULED_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingDesiredStateScheduledTasks: false,
        desiredStateScheduleTaskList: action.payload.tasks,
        desiredStateScheduleTaskOffset: action.payload.offset,
      };
    case GET_DESIRED_STATE_SCHEDULED_TASKS_FAILURE:
      return {
        ...state,
        isFetchingDesiredStateScheduledTasks: false,
        desiredStateScheduleTaskList: action.payload,
      };
    case GET_DESIRED_STATE_JOBS_REQUEST:
      return {
        ...state,
        isFetchingDesiredStateJobs: true,
      };
    case GET_DESIRED_STATE_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingDesiredStateJobs: false,
        desiredStateExecutionHistoryList: action.payload.tasks,
        desiredStateExecutionHistoryOffset: action.payload.offset,
      };
    case GET_DESIRED_STATE_JOBS_FAILURE:
      return {
        ...state,
        isFetchingDesiredStateJobs: false,
        desiredStateExecutionHistoryList: action.payload,
      };
    case DELETE_SCHEDULE_REQUEST:
      return {
        deletingSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        deletingSchedule: false,
        deletedSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_FAILURE:
      return {
        deletingSchedule: false,
        deletedSchedule: false,
        deleteScheduleMessage: action.payload,
      };
    case CLEAR_DELETE_SCHEDULE_DATA:
      return {
        ...state,
        deletingSchedule: initialState.deletedSchedule,
        deletedSchedule: initialState.deletedSchedule,
        deleteScheduleMessage: initialState.deleteScheduleMessage,
      };
    case SAVE_DESIRED_STATE_POLICY:
      return {
        ...state,
        selectedDesiredStatePolicy: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default desiredState;
