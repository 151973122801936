export const SAVE_ADD_METRIC_STATE = "SAVE_ADD_METRIC_STATE";
export const SAVE_ADDED_METRICS = "SAVE_ADDED_METRICS";
export const SAVE_MANIFEST_RESOURCES = "SAVE_MANIFEST_RESOURCES";
export const SET_SELECTED_MONITORING_RESOURCE_TYPE =
  "SET_SELECTED_MONITORING_RESOURCE_TYPE";
export const SET_SELECTED_MONITORING_METRICS =
  "SET_SELECTED_MONITORING_METRICS";
export const SAVE_APPLICATION_RESOURCES = "SAVE_APPLICATION_RESOURCES";
export const SAVE_SELECTED_APPLICATION_RESOURCES =
  "SAVE_SELECTED_APPLICATION_RESOURCES";
export const CLEAR_MONITORING_DATA = "CLEAR_MONITORING_DATA";
