import { v4 as uuidv4 } from "uuid";

import {
  BuildByteArrayByOffsets,
  ConvertStringToByteArray,
  getDigestBytesForMessage,
  int32ToBytes,
  uuidToBytes
} from "./serialize_utility";

/**
 
|
| HL| MessageType |Ver| CD | Seq | Flags | MessageId | Digest |PayType|PayLen| Payload |
|
• HL - HeaderLength is a 4-byte integer that represents the header length.
• MessageType is a 32-byte UTF-8 string containing the message type.
• Ver - SchemaVersion is a 4-byte integer containing the message schema version number.
• CD - CreatedDate is an 8-byte integer containing the message create epoch millis in UTC.
• Seq - SequenceNumber is an 8-byte integer containing the message sequence number for
serialized message streams.
• Flags is an 8-byte unsigned integer containing a packed array of control flags:
    - Bit 0 is SYN - SYN is set (1) when the recipient should consider Sequence to be the first message number in the stream
    - Bit 1 is FIN - FIN is set (1) when this message is the final message in the sequence.
• MessageId is a 40-byte UTF-8 string containing a random UUID identifying this message.
• Digest - Payload digest is a 32-byte containing the SHA-256 hash of the payload.
• PayType - Payload Type is a 4-byte integer containing the payload type

 */

const ClientMessageHLLength = 4;
const ClientMessageMessageTypeLength = 32;
const ClientMessageSchemaVersionLength = 4;
const ClientMessageCreatedDateLength = 8;
const ClientMessageSequenceNumberLength = 8;
const ClientMessageFlagsLength = 8;
const ClientMessageMessageIdLength = 16;
const ClientMessagePayloadDigestLength = 32;
const ClientMessagePayloadTypeLength = 4;
const ClientMessagePayloadLengthLength = 4;

const ClientMessageHLOffset = 0;
const ClientMessageMessageTypeOffset =
  ClientMessageHLOffset + ClientMessageHLLength;
const ClientMessageSchemaVersionOffset =
  ClientMessageMessageTypeOffset + ClientMessageMessageTypeLength;
const ClientMessageCreatedDateOffset =
  ClientMessageSchemaVersionOffset + ClientMessageSchemaVersionLength;
const ClientMessageSequenceNumberOffset =
  ClientMessageCreatedDateOffset + ClientMessageCreatedDateLength;
const ClientMessageFlagsOffset =
  ClientMessageSequenceNumberOffset + ClientMessageSequenceNumberLength;
const ClientMessageMessageIdOffset =
  ClientMessageFlagsOffset + ClientMessageFlagsLength;
const ClientMessagePayloadDigestOffset =
  ClientMessageMessageIdOffset + ClientMessageMessageIdLength;
const ClientMessagePayloadTypeOffset =
  ClientMessagePayloadDigestOffset + ClientMessagePayloadDigestLength;
const ClientMessagePayloadLengthOffset =
  ClientMessagePayloadTypeOffset + ClientMessagePayloadTypeLength;
const ClientMessagePayloadOffset =
  ClientMessagePayloadLengthOffset + ClientMessagePayloadLengthLength;

/**
 * Serialize client message before sending
 * @param {*} sequenceNumber
 * @param {*} messageType
 * @param {*} payloadByteArray
 * @param {*} payloadType
 */
export function serializeClientMessage(
  sequenceNumber,
  messageType,
  payloadByteArray,
  payloadType
) {
  /**
     
    |
    | HL| MessageType |Ver| CD | Seq | Flags | MessageId | Digest |PayType|PayLen| Payload |
    |

    */
  const payloadLength = payloadByteArray.byteLength;
  const headerLength = ClientMessagePayloadLengthOffset;
  const totalMessageLength =
    headerLength + ClientMessagePayloadLengthLength + payloadLength;
  const serializedMessage = new Uint8Array(totalMessageLength);

  // Serialize HeaderLength
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(headerLength),
    ClientMessageHLOffset,
    ClientMessageHLOffset + ClientMessageHLLength
  );
  // console.log("Serialize HeaderLength", serializedMessage)

  // serialize MessageType
  BuildByteArrayByOffsets(
    serializedMessage,
    ConvertStringToByteArray(messageType),
    ClientMessageMessageTypeOffset,
    ClientMessageMessageTypeOffset + ClientMessageMessageTypeLength
  );
  // serialize SchemaVersion
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(1),
    ClientMessageSchemaVersionOffset,
    ClientMessageSchemaVersionOffset + ClientMessageSchemaVersionLength
  );
  // serialize CreatedDate
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(new Date().getTime()),
    ClientMessageCreatedDateOffset,
    ClientMessageCreatedDateOffset + ClientMessageCreatedDateLength
  );
  // serialize SequenceNumber
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(sequenceNumber),
    ClientMessageSequenceNumberOffset,
    ClientMessageSequenceNumberOffset + ClientMessageSequenceNumberLength
  );
  // serialize Flags
  const FinFlag = 1;
  const SynFlag = 0;
  const flag = sequenceNumber === 0 ? FinFlag : SynFlag;

  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(flag),
    ClientMessageFlagsOffset,
    ClientMessageFlagsOffset + ClientMessageFlagsLength
  );
  // serialize MessageId
  BuildByteArrayByOffsets(
    serializedMessage,
    uuidToBytes(uuidv4()),
    ClientMessageMessageIdOffset,
    ClientMessageMessageIdOffset + ClientMessageMessageIdLength
  );
  // serialize PayloadDigest
  // const shajs = require('sha.js');
  const hashed_payload_bytes = getDigestBytesForMessage(payloadByteArray);

  BuildByteArrayByOffsets(
    serializedMessage,
    hashed_payload_bytes,
    ClientMessagePayloadDigestOffset,
    ClientMessagePayloadDigestOffset + ClientMessagePayloadDigestLength
  );

  // serialize PayloadType
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(payloadType),
    ClientMessagePayloadTypeOffset,
    ClientMessagePayloadTypeOffset + ClientMessagePayloadTypeLength
  );
  // serialize PayloadLength
  BuildByteArrayByOffsets(
    serializedMessage,
    int32ToBytes(payloadLength),
    ClientMessagePayloadLengthOffset,
    ClientMessagePayloadLengthOffset + ClientMessagePayloadLengthLength
  );
  // serialize Payload
  BuildByteArrayByOffsets(
    serializedMessage,
    payloadByteArray,
    ClientMessagePayloadOffset,
    ClientMessagePayloadOffset + payloadLength
  );
  return serializedMessage;
}
