import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../../../../config/customBaseQuery";
import {
  COST_DASHBOARD_LOOKUP,
  COST_DASHBOARD_TAG_KEY,
} from "../../../../../../../config/service";
import { isEqual } from "lodash";

export const costTagKeysApi = createApi({
  reducerPath: "costTagKeys",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTagKeys: builder.query({
      query: ({ queryParams }) => {
        return {
          url: `${COST_DASHBOARD_LOOKUP}${COST_DASHBOARD_TAG_KEY}?${queryParams}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
      serializeQueryArgs: ({ endpointName }) => {
        return `tag-keys_${endpointName}}`;
      },
      merge: (currentCache, newItems, { arg: { queryParams } }) => {
        const newSearchTerm =
          new URLSearchParams(queryParams).get("KeywordSearch") || "";
        const currentSearchTerm = currentCache?.searchTerm || "";
        if (newSearchTerm !== currentSearchTerm) {
          return {
            Items: newItems.Items,
            Offset: newItems.Offset,
            searchTerm: newSearchTerm,
          };
        }
        return {
          ...currentCache,
          Items: [...(currentCache.Items || []), ...newItems.Items],
          Offset: newItems.Offset,
          searchTerm: newSearchTerm,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response, meta, arg) => {
        const newSearhTerm =
          new URLSearchParams(arg.queryParams).get("KeywordSearch") || "";
        return {
          Items:
            response?.Items?.map((tagKey) => ({
              label: tagKey,
              value: tagKey,
            })) || [],
          Offset: response?.Offset,
          searchTerm: newSearhTerm,
        };
      },
    }),
  }),
});

export const { useGetTagKeysQuery } = costTagKeysApi;
