import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WEB_REQUEST_TYPE, getApiService } from "../../../helpers/apiService";
import { MANAGEMENT_REPORTS_EXPORTS, FILE } from "../../../config/service";
import { isArrayWithLength } from "../../../utils/array-methods";

export const getManagementReportExportsApi = ({
  limit,
  offset = 0,
  sortModel,
  filterModel = [],
}) => {
  const filters = encodeURIComponent(
    JSON.stringify({
      ...(isArrayWithLength(filterModel) && { KeywordSearch: filterModel }),
    })
  );
  let params = `?Limit=${limit}&Offset=${offset}&Filters=${filters}`;
  if (sortModel?.SortBy) {
    const { SortOrder, SortBy } = sortModel;
    params = `${params}&SortBy=${SortBy}&SortOrder=${SortOrder}`;
  }
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${MANAGEMENT_REPORTS_EXPORTS}${params}`
  );
};

export const managementReportsAPI = createApi({
  reducerPath: "managementReports",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    downloadManagementReport: builder.query({
      query({ ReportId, FileName }) {
        const enCodedParams = encodeURIComponent(JSON.stringify({ FileName }));
        return {
          url: `${MANAGEMENT_REPORTS_EXPORTS}/${ReportId}${FILE}?Filters=${enCodedParams}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const { useLazyDownloadManagementReportQuery } = managementReportsAPI;
