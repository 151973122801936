import React from "react";

import styles from "./Loader.module.scss";

const MCLoader = () => {
  return (
    <div className={styles.mcLoader}>
      <img className="logo" src="/images/DAY2Logo-NoBg.png" alt="MontyCloud" />
      <div className={styles.loader} id="logo-loader" />
    </div>
  );
};

export default MCLoader;
