import {
  GET_SECURITY_POLICIES_REQUEST,
  GET_SECURITY_POLICIES_SUCCESS,
  GET_SECURITY_POLICIES_FAILURE
} from "../actionTypes/security-policies";

const initialState = {
  isFetching: false,
  securityPolicyList: null,
  showSecurityPolicyList: true
};

const securityPolicies = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITY_POLICIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        securityPolicyList: initialState.securityPolicyList
      };
    case GET_SECURITY_POLICIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        securityPolicyList: action.payload,
        showSecurityPolicyList: true
      };
    case GET_SECURITY_POLICIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default securityPolicies;
