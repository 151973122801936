import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "@hocs/withRouter";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import Widget from "../../../../components/Widget";
import {
  clearLoginScreen,
  clearLoginState,
  clearResendMessage,
  dispatchLoginDisabled,
  loginUser,
  preSignIn,
  authCaptchaVerification,
  authCaptchaVerificationFailed,
  receiveToken,
  ssologinUser,
} from "../../../../modules/authentication/actions/auth";
import { resendConfirmation } from "../../../../modules/authentication/actions/signup";
import { SUPPORT_EMAIL } from "../../../../utils/app-constants.json";
import { getCurrentYear } from "../../../../utils/convert-date";
import { AUTH_TYPE } from "../../constants";
import styles from "./Login.module.scss";
import MFALogin from "./MFALogin";
import RedirectTo from "./RedirectTo";
import {
  WHITELABEL_PARTNER_LIST,
  MC,
} from "../../../../utils/app-constants.json";
import {
  getPartnerAltText,
  getPartnerBackgroundImageClassName,
  getPartnerButtonClassName,
  getPartnerLogo,
  getPartnerTextClassName,
  getPartnerType,
} from "../../../../helpers/auth-partner-helper-functions";
import classNames from "classnames";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LOGIN_PAGE } from "../signup/utils";
import analytics from "../../../../libs/Analytics/analytics";
import { LOGIN_EVENT_CAPTCHA_ERROR } from "../../../../libs/Analytics/global-event-types";

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    partnerType: PropTypes.string,
  };

  static isAuthenticated(token) {
    if (!token) return;
    return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      isLoggingInFromSSO: false,
      showPassword: false,
    };

    this.doLogin = this.doLogin.bind(this);
    this.doPreLogin = this.doPreLogin.bind(this);
    this.changeLogin = this.changeLogin.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  changeLogin(event) {
    this.setState({ Username: event.target.value });
  }

  changePassword(event) {
    this.setState({ Password: event.target.value });
  }

  togglePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  doPreLogin(e) {
    e.preventDefault();
    const { googleReCaptchaProps } = this.props;
    if (!googleReCaptchaProps || !googleReCaptchaProps.executeRecaptcha) {
      const event = {
        eventName: LOGIN_EVENT_CAPTCHA_ERROR,
        additionalProps: {
          email: this.state.Username,
        },
      };
      analytics.track(event);
      return;
    }

    this.props.authCaptchaVerification();
    googleReCaptchaProps
      .executeRecaptcha(LOGIN_PAGE)
      .then((recaptchaToken) => {
        this.props.preSignIn({
          Username: this.state.Username,
          CaptchaCode: recaptchaToken,
        });
      })
      .catch((error) => {
        this.props.authCaptchaVerificationFailed();
        const event = {
          eventName: LOGIN_EVENT_CAPTCHA_ERROR,
          additionalProps: {
            email: this.state.Username,
            error,
          },
        };
        analytics.track(event);
      });
  }

  doLogin(e) {
    e.preventDefault();

    const { googleReCaptchaProps } = this.props;
    if (!googleReCaptchaProps || !googleReCaptchaProps.executeRecaptcha) {
      console.error("Recaptcha is not available.");
      return;
    }

    this.props.authCaptchaVerification();
    googleReCaptchaProps
      .executeRecaptcha(LOGIN_PAGE)
      .then((recaptchaToken) => {
        this.props.loginUser({
          Username: this.state.Username,
          Password: this.state.Password,
          CaptchaCode: recaptchaToken,
        });
      })
      .catch((error) => {
        this.props.authCaptchaVerificationFailed();
      });
  }

  doSSOLogin = (code, state, error_description, error) => {
    this.props.ssologinUser(code, state, error_description, error);
  };

  resendConfirmationEmail() {
    this.props.clearResendMessage();
    this.props.resendConfirmation({ Username: this.state.Username });
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const partnerType = params.get("partner") || MC; // Default to MontyCloud if partner is not provided

    if (WHITELABEL_PARTNER_LIST.includes(partnerType)) {
      this.setState({ partnerType });
      localStorage.setItem("whitelabellingPartnerType", partnerType);
    } else {
      this.setState({ partnerType: MC });
      localStorage.setItem("whitelabellingPartnerType", MC);
    }

    // Check if the URL contains an SSO code
    if (window.location.href.includes("state=")) {
      const code = params.get("code");
      const state = params.get("state");
      const error_description = params.get("error_description");
      const error = params.get("error");
      this.setState({
        isLoggingInFromSSO: true,
      });
      this.doSSOLogin(code, state, error_description, error);
    }
    const token = params.get("token");
    if (token) {
      this.props.receiveToken(token);
    }
  }

  resetLoginData = () => {
    this.props.clearLoginScreen();
    this.props.clearLoginState();
  };

  componentWillUnmount() {
    this.resetLoginData();
  }

  handleUseDifferentAccount = () => {
    this.resetLoginData();
    this.props.dispatchLoginDisabled();
    this.setState({ Username: "", Password: "" });
  };

  render() {
    const {
      t,
      isFetching,
      newPasswordRequiredData,
      isRedirect,
      redirectUrl,
      isPasswordRequired,
      mfaCodeRequiredData,
      userLoginDisabledData,
    } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: "/verify-mfa" },
    };
    // cant access login page while logged in
    if (Login.isAuthenticated(localStorage.getItem("token"))) {
      return <Navigate to={from} />;
    }
    if (isRedirect) {
      window.location = redirectUrl;
    }

    if (
      newPasswordRequiredData &&
      newPasswordRequiredData.session &&
      newPasswordRequiredData.username
    ) {
      return <Navigate to="/reset-password" />;
    }

    const mfaUsername = mfaCodeRequiredData && mfaCodeRequiredData.username;
    const disabledUsername =
      userLoginDisabledData && userLoginDisabledData.username;

    const getButtonText = () => {
      let btnText = t("nextTitle");
      if (isFetching && !isPasswordRequired) {
        btnText = `${t("verifying")}...`;
      } else if (isFetching && isPasswordRequired) {
        btnText = `${t("loggingIn")}...`;
      } else if (isPasswordRequired) {
        btnText = `${t("loginTitle")}`;
      } else if (isRedirect) {
        btnText = `${t("redirecting")}...`;
      }
      return btnText;
    };

    const getWidgetTitle = () => {
      let title = t("loginTitle");
      if (mfaUsername) {
        title = t("enterCode");
      } else if (disabledUsername) {
        title = t("loginDisabled");
      }
      return title;
    };

    const partnerType = getPartnerType();

    const partnerBackgroundImage = getPartnerBackgroundImageClassName(
      this.state.partnerType
    );

    return (
      <div className={classNames("auth-container", partnerBackgroundImage)}>
        <Row className="row-transparent-bg">
          <Col xl={12}>
            <Container className={styles.LoginPopup}>
              <div>
                <Widget
                  className="widget padding-30px bs4-mx-auto"
                  title={
                    this.state.isLoggingInFromSSO ? (
                      ""
                    ) : (
                      <div className="bs4-ml-2">
                        <div
                          className={classNames(
                            "mc-h4-regular bs4-mt-3 bs4-mb-2",
                            getPartnerTextClassName(partnerType)
                          )}
                        >
                          {getWidgetTitle()}
                        </div>
                        {(mfaUsername || disabledUsername) && (
                          <div className="mc-text-base-bold bs4-mt-4">
                            {mfaUsername || disabledUsername}
                          </div>
                        )}
                      </div>
                    )
                  }
                  logo={
                    <a
                      href="https://www.montycloud.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        className="logo"
                        src={getPartnerLogo(this.state.partnerType)}
                        alt={getPartnerAltText(this.state.partnerType)}
                      ></img>
                    </a>
                  }
                >
                  {mfaUsername ? (
                    <div className={styles.LoginContent}>
                      <MFALogin mfaData={mfaCodeRequiredData} />
                    </div>
                  ) : disabledUsername ? (
                    <div
                      className={classNames(styles.LoginContent, "bs4-mt-3")}
                    >
                      <div>{t("accountIsDisabled")}</div>
                      <div className="mc-h6-regular clearfix bs4-mt-4">
                        <span
                          className="external-link"
                          onClick={this.handleUseDifferentAccount}
                        >
                          {t("useDifferentAccount")}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <form
                        className="mt"
                        onSubmit={
                          isPasswordRequired ? this.doLogin : this.doPreLogin
                        }
                      >
                        {this.props.errorMessage && (
                          <Alert className="alert-sm" color="danger">
                            {this.props.errorMessage ===
                            t("userNotActivated") ? (
                              <>
                                {t("errorMsg")}​
                                <br />
                                {t("contact")} &nbsp;
                                <a
                                  className={styles.emailText}
                                  href={`mailto:${SUPPORT_EMAIL}`}
                                >
                                  {SUPPORT_EMAIL}
                                </a>
                              </>
                            ) : (
                              this.props.errorMessage
                            )}
                          </Alert>
                        )}
                        {this.props.resendConfirmationEmail && (
                          <Alert className="alert-sm" color="warning">
                            {t("message1")}
                            <div
                              className={styles.underlinedButton}
                              onClick={this.resendConfirmationEmail}
                            >
                              {t("message2")}
                            </div>
                          </Alert>
                        )}
                        {this.props.confirmationEmailResent && (
                          <Alert className="alert-sm" color="success">
                            {t("message3")}
                          </Alert>
                        )}

                        {this.state.isLoggingInFromSSO &&
                        !this.props.errorMessage ? (
                          <div className={styles.loading}>
                            <i className="fa fa-spin fa-spinner bs4-mr-2" />
                            {t("loggingIn")}...
                          </div>
                        ) : !this.state.isLoggingInFromSSO ? (
                          <div>
                            <FormGroup row>
                              <Col>
                                <InputGroup>
                                  {isPasswordRequired ? (
                                    <>
                                      <div
                                        className={classNames(
                                          styles.disabledEmailField,
                                          "form-control"
                                        )}
                                      >
                                        {this.state.Username}
                                      </div>
                                      <span
                                        className={classNames(
                                          "input-group-text cursor-pointer",
                                          styles.iconPadding
                                        )}
                                        onClick={() => {
                                          this.props.clearLoginState();
                                        }}
                                      >
                                        <i className="fa fa-edit" />
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <Input
                                        className={classNames(
                                          styles.emailField,
                                          "form-control"
                                        )}
                                        value={this.state.Username}
                                        onChange={this.changeLogin}
                                        type="email"
                                        required
                                        name="username"
                                        placeholder={t("email")}
                                      />
                                      <span
                                        className={classNames(
                                          "input-group-text",
                                          styles.iconPadding
                                        )}
                                      >
                                        <i className="fa fa-envelope-o" />
                                      </span>
                                    </>
                                  )}
                                </InputGroup>
                              </Col>
                            </FormGroup>
                            {isPasswordRequired ? (
                              <FormGroup row className="bs4-mt-4">
                                <Col>
                                  <InputGroup>
                                    <Input
                                      className={classNames(
                                        styles.passwordField,
                                        "form-control"
                                      )}
                                      value={this.state.Password}
                                      onChange={this.changePassword}
                                      type={
                                        this.state.showPassword
                                          ? "text"
                                          : "password"
                                      }
                                      required
                                      name="password"
                                      placeholder={t("password")}
                                    />
                                    <span
                                      className={classNames(
                                        "input-group-text cursor-pointer",
                                        styles.iconPadding
                                      )}
                                      onClick={this.togglePassword}
                                    >
                                      {this.state.showPassword ? (
                                        <i
                                          className="fa fa-eye-slash"
                                          title="hide"
                                        />
                                      ) : (
                                        <i className="fa fa-eye" title="show" />
                                      )}
                                    </span>
                                  </InputGroup>
                                </Col>
                              </FormGroup>
                            ) : null}

                            <div className="clearfix">
                              {isPasswordRequired && (
                                <Link
                                  className={classNames(
                                    "mt-sm mc-text-base-regular",
                                    getPartnerTextClassName(partnerType)
                                  )}
                                  to={`/forgot-password?email=${encodeURIComponent(
                                    this.state.Username
                                  )}`}
                                >
                                  {t("forgotPassword")}
                                </Link>
                              )}

                              <div className="btn-toolbar float-right">
                                <button
                                  type="submit"
                                  href="/app"
                                  disabled={isFetching || isRedirect}
                                  className={classNames(
                                    "btn btn-md",
                                    styles.iconPadding,
                                    getPartnerButtonClassName(partnerType)
                                  )}
                                >
                                  {getButtonText()}
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </form>
                      {this.state.isLoggingInFromSSO &&
                      this.props.errorMessage ? (
                        <RedirectTo type={AUTH_TYPE.LOGIN} />
                      ) : !this.state.isLoggingInFromSSO ? (
                        <RedirectTo type={AUTH_TYPE.SIGNUP} />
                      ) : null}
                    </>
                  )}
                </Widget>
                <Row>
                  <Col>
                    <p className="bs4-text-center color-white">
                      © {getCurrentYear()} {t("montyCloud")} |{" "}
                      <a
                        className="color-white"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://montycloud.com/platform-terms"
                      >
                        {t("termsAndConditions")}
                      </a>
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, signup, globalConfig, tokenReducer }) => {
  const {
    isFetching,
    errorMessage,
    resendConfirmationEmail,
    newPasswordRequiredData,
    isRedirect,
    redirectUrl,
    isPasswordRequired,
    mfaCodeRequiredData,
    userLoginDisabledData,
  } = auth;

  const { isAuthenticated } = tokenReducer;

  const { confirmationEmailResent } = signup;
  const { isFirstRun } = globalConfig;
  return {
    isFetching,
    isAuthenticated,
    errorMessage,
    resendConfirmationEmail,
    newPasswordRequiredData,
    confirmationEmailResent,
    isFirstRun,
    isRedirect,
    redirectUrl,
    isPasswordRequired,
    mfaCodeRequiredData,
    userLoginDisabledData,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    preSignIn,
    authCaptchaVerification,
    authCaptchaVerificationFailed,
    loginUser,
    ssologinUser,
    resendConfirmation,
    receiveToken,
    clearLoginState,
    clearResendMessage,
    clearLoginScreen,
    dispatchLoginDisabled,
  })(withTranslation(["loginForm"])(withGoogleReCaptcha(Login)))
);
