import {
  SET_FEATURES_LIST,
  START_PLAN_REQUEST,
  START_PLAN_SUCCESS,
  START_PLAN_FAILURE,
  SAVE_FEATURES_REQUEST,
  SAVE_FEATURES_SUCCESS,
  SAVE_FEATURES_FAILURE,
  SAVE_EMAILS_REQUEST,
  SAVE_EMAILS_SUCCESS,
  SAVE_EMAILS_FAILURE,
  CLEAR_START_PLAN,
  GET_CUSTOMER_PREFERENCE_REQUEST,
  GET_CUSTOMER_PREFERENCE_SUCCESS,
  GET_CUSTOMER_PREFERENCE_FAILURE,
} from "../actionTypes/new-first-run";

import { featureList } from "../components/featureList";

const initialState = {
  featureList: featureList,
  requestingPlan: false,
  successfullySubscribedToPlan: false,
  failedToSubscribeToPlan: false,
  savingFeaturePreference: false,
  successfullySavedFeaturePreference: false,
  failedToSaveFeaturePreference: false,
  savingEmailPreference: false,
  successfullySavedEmailPreference: false,
  failedToSaveEmailPreference: false,
  customerPreferences: null,
  customerPreferencesFetched: false,
  customerPreferencesFetchedFailed: false,
};

const newFirstRun = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEATURES_LIST:
      return {
        ...state,
        featureList: action.payload,
      };
    case START_PLAN_REQUEST:
      return {
        ...state,
        requestingPlan: true,
      };
    case START_PLAN_SUCCESS:
      return {
        ...state,
        requestingPlan: false,
        successfullySubscribedToPlan: true,
      };
    case START_PLAN_FAILURE:
      return {
        ...state,
        requestingPlan: false,
        failedToSubscribeToPlan: true,
      };
    case SAVE_FEATURES_REQUEST:
      return {
        ...state,
        savingFeaturePreference: true,
      };
    case SAVE_FEATURES_SUCCESS:
      return {
        ...state,
        savingFeaturePreference: false,
        successfullySavedFeaturePreference: true,
        customerPreferences: action.payload,
      };
    case SAVE_FEATURES_FAILURE:
      return {
        ...state,
        customerPreferences: [],
        successfullySavedFeaturePreference: false,
        savingFeaturePreference: false,
        failedToSaveFeaturePreference: false,
      };
    case SAVE_EMAILS_REQUEST:
      return {
        ...state,
        savingEmailPreference: true,
        successfullySavedEmailPreference:
          initialState.successfullySavedEmailPreference,
        failedToSaveEmailPreference: initialState.failedToSaveEmailPreference,
      };
    case SAVE_EMAILS_SUCCESS:
      return {
        ...state,
        savingEmailPreference: false,
        successfullySavedEmailPreference: true,
      };
    case SAVE_EMAILS_FAILURE:
      return {
        ...state,
        savingEmailPreference: false,
        failedToSaveEmailPreference: false,
      };
    case CLEAR_START_PLAN:
      return {
        ...state,
        requestingPlan: initialState.requestingPlan,
        successfullySubscribedToPlan: initialState.successfullySubscribedToPlan,
        failedToSubscribeToPlan: initialState.failedToSubscribeToPlan,
      };
    case GET_CUSTOMER_PREFERENCE_REQUEST:
      return {
        ...state,
        customerPreferences: initialState.customerPreferences,
        customerPreferencesFetchedFailed:
          initialState.customerPreferencesFetchedFailed,
        customerPreferencesFetched: initialState.customerPreferencesFetched,
      };
    case GET_CUSTOMER_PREFERENCE_SUCCESS:
      return {
        ...state,
        customerPreferences: action.payload,
        customerPreferencesFetched: true,
      };
    case GET_CUSTOMER_PREFERENCE_FAILURE:
      return {
        ...state,
        customerPreferences: initialState.customerPreferences,
        customerPreferencesFetched: true,
        customerPreferencesFetchedFailed: true,
      };
    default:
      return { ...state };
  }
};

export default newFirstRun;
