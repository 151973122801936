/* eslint-disable no-sequences */
import desiredAccountStateConfig from "../modules/task/components/desired-account-state/DesiredAccountStateConfig.json";
import { isEmptyString } from "./format-string";
import moment from "moment";
import { useSelector, shallowEqual } from "react-redux";
import { isArrayWithLength } from "../utils/array-methods";
import { checkAdminPermission } from "../utils/account-permission";
import {
  CONFIGURE_CLOUD_WATCH,
  RUN_COMMAND,
  COMPLIANCE,
  SECURITY,
} from "../utils/app-constants.json";
import i18n from "../config/locale/i18n";

export const getDefaultScheduleName = (taskType) => {
  return (
    taskType +
    "_" +
    moment().format("YYYY-MM-DD") +
    "_" +
    moment().format("HH:mm:ss")
  );
};

export const getDefaultAppName = () => {
  return (
    "App_" + moment().format("YYYY-MM-DD") + "_" + moment().format("HH-mm-ss")
  );
};

export const getDefaultDateTime = () => {
  return moment().format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");
};

export const getRequestExecuteTask = (
  account,
  region,
  parameters,
  type,
  selectedInstances
) => {
  let request = {
    Parameters: type === "PythonScript" ? {} : [],
  };
  parameters &&
    Array.isArray(parameters) &&
    parameters.forEach((p) => {
      let key = p.parameterKey;
      let value = p.parameterValue;
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (p.parameterType === "List") {
        if (p.isMultiSelect && Array.isArray(p.parameterValue)) {
          value = p.parameterValue.map((p) => p.label || p);
        } else if (!p.isMultiSelect && Array.isArray(p.parameterValue)) {
          value = p.parameterValue.map((p) => p.label || p).join(",");
        } else {
          const val = p.parameterValue.value
            ? p.parameterValue.value
            : p.parameterValue;
          value = val.toString();
        }
      }
      if (type === "PythonScript") {
        request.Parameters[key] = value;
        if (p.parameterType === "Number") {
          request.Parameters[key] = parseInt(value);
        }
      } else {
        request.Parameters.push({
          ParameterKey: key,
          ParameterValue: value,
        });
      }
    });
  if (account && account.accountNumber) {
    request.AccountNumber = account.accountNumber;
  }
  if (region) {
    request.RegionCode = region;
  }
  if (type === RUN_COMMAND) {
    request.InstanceIds = isArrayWithLength(selectedInstances)
      ? selectedInstances.map((instance) => instance.instanceId)
      : [];
  }
  return request;
};

const getParameterValue = (value) => {
  let val = value;
  let parameterType = typeof value;
  switch (parameterType) {
    case "boolean":
      val = value.toString();
      break;
    case "number":
      val = value.toString();
      val = isEmptyString(val) ? " " : val;
      break;
    case "string":
      val = value.toString();
      val = isEmptyString(val) ? " " : val;
      break;
    default:
      break;
  }
  return val;
};

const getParameters = (parameters, taskType) => {
  const params = Object.keys(parameters).map((p) => {
    return {
      ParameterKey: p,
      ParameterValue: getParameterValue(parameters[p]),
    };
  });

  const sectionsWithDependentParams = Object.values(desiredAccountStateConfig)
    .flat()
    .filter((o) => o.key && o.type === taskType);
  sectionsWithDependentParams.forEach((key) => {
    let parentKeyValue = "true";
    key.parameters.forEach((p) => {
      const param = params.find((pp) => pp.ParameterKey === p.parameterKey);
      let childParamValue = param.ParameterValue;
      if (p.parameterType === "Dropdown") {
        childParamValue =
          (param.ParameterValue && param.ParameterValue.value) || "";
        param.ParameterValue =
          (param.ParameterValue && param.ParameterValue.value) || " ";
      }
      if (isEmptyString(childParamValue)) {
        parentKeyValue = "false";
      }
      if (p.defaultTemplateValue && param.ParameterValue === " ") {
        param.ParameterValue = p.defaultTemplateValue;
      }
      if (p.dependentRequiredParameter) {
        param.ParameterValue = isEmptyString(
          params.find(
            (par) => par.ParameterKey === p.dependentRequiredParameter
          ).ParameterValue
        )
          ? "false"
          : param.ParameterValue;
      }
    });
    params.push({
      ParameterKey: key.key,
      ParameterValue: parentKeyValue,
    });
  });

  let allParams = [];
  params.forEach((param) => {
    if (param.ParameterKey === "ResourcesTypesforTagCheck") {
      allParams.push({
        ParameterKey: param.ParameterKey,
        ParameterValue:
          param.ParameterValue && param.ParameterValue.length
            ? param.ParameterValue.map((v) => v.value).join(",")
            : " ",
      });
    } else if (param.ParameterKey === "Tags") {
      param.ParameterValue.forEach((p, i) => {
        allParams.push({
          ParameterKey: `RequiredTagKey${i + 1}`,
          ParameterValue: isEmptyString(p.Value) ? " " : p.Value,
        });
        allParams.push({
          ParameterKey: `RequiredTagValue${i + 1}`,
          ParameterValue: isEmptyString(p.Value) ? " " : p.Value,
        });
      });
    } else if (
      param.ParameterKey === "EnableVPCFlowLog" ||
      param.ParameterKey === "EnableSecurityHub" ||
      param.ParameterKey === "EnableIAMAccessAnalyzer" ||
      param.ParameterKey === "EnableTrustedAdvisorNotification"
    ) {
      allParams.push({
        ParameterKey: param.ParameterKey,
        ParameterValue: param.ParameterValue === "true" ? "Yes" : "No",
      });
    } else {
      allParams.push(param);
    }
  });
  return allParams;
};

export const desiredAccountRegionStateRequest = (
  parameters,
  task,
  taskType,
  orgId
) => {
  const request = {
    AccountNumber:
      task && task.selectedAccount && task.selectedAccount.accountNumber,
    DepartmentId:
      task && task.selectedAccount && task.selectedAccount.departmentId,
    OrganizationId: orgId,
    Parameters:
      parameters && parameters.parameters
        ? getParameters(parameters.parameters, taskType)
        : [],
    Job: {
      Name: `Setup desired ${
        taskType === "desired-account-state" ? "account" : "region"
      } state for ${
        task && task.selectedAccount && task.selectedAccount.accountName
      } (${
        task && task.selectedAccount && task.selectedAccount.accountNumber
      }) ${
        taskType === "desired-region-state"
          ? task.selectedRegions &&
            task.selectedRegions.length &&
            task.selectedRegions.map((r) => r).join(", ")
          : ""
      } `,
    },
  };
  if (taskType === "desired-account-state") {
    request.RegionCode = task && task.selectedRegion;
  } else {
    request.Regions = task && task.selectedRegions;
  }
  return request;
};

export const HasAdminPermissionToExecuteTask = () => {
  const { selectedAccount, selectedInstances, selectedTask, filteredUserData } =
    useSelector(({ task, globalConfig }) => {
      return {
        selectedAccount: task.selectedAccount,
        selectedInstances: task.selectedInstances,
        selectedTask: task.selectedTask,
        filteredUserData: globalConfig.filteredUserData,
      };
    }, shallowEqual);

  let hasAdminPermission = checkAdminPermission(selectedAccount);

  if (selectedTask && selectedTask.type === CONFIGURE_CLOUD_WATCH) {
    hasAdminPermission =
      filteredUserData &&
      isArrayWithLength(selectedInstances) &&
      isArrayWithLength(filteredUserData.Accounts) &&
      checkAdminPermission(selectedInstances[0], filteredUserData.Accounts);
  }
  return hasAdminPermission;
};

const taskCategories = {
  REMEDIATION: "Remediation",
  CUSTOM_ACTIONS: "CustomActions",
  EC2: "EC2",
  STORAGE: "Storage",
  PATCHSCAN: "PATCH-SCAN",
  PATCHINSTALL: "PATCH-INSTALL",
  DESIREDSERVERSTATE: "DESIRED-SERVER-STATE",
  VOLUME: "Volume",
  BACKUP_STORE: "BackupRestore",
};

export const getTaskFilters = (
  predefinedFilters = {},
  extraCategories = []
) => {
  const filters = {
    ...predefinedFilters,
    TaskType: "All",
    Category: [
      ...extraCategories,
      COMPLIANCE,
      SECURITY,
      ...Object.values(taskCategories),
    ],
  };
  return "?Filters=" + encodeURI(JSON.stringify(filters));
};

export const resourceNameMapping = {
  Vpc: i18n.t("resources:grid.vpc"),
  RouteTable: i18n.t("resources:routeTable"),
};
