import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  INTEGRATIONS,
  INTEGRATION_DESTINATIONS,
  INTEGRATION_DESTINATION,
  INTEGRATION_SPECIFICATION,
  DESTINATION_USAGE,
} from "../../../utils/action-names.json";

const integrations = createReducer(INTEGRATIONS);
const destinations = createReducer(INTEGRATION_DESTINATIONS);
const destination = createReducer(INTEGRATION_DESTINATION);
const specification = createReducer(INTEGRATION_SPECIFICATION);
const destinationUsage = createReducer(DESTINATION_USAGE);

export default combineReducers({
  integrations,
  destinations,
  destination,
  specification,
  destinationUsage,
});
