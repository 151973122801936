import {
  GLOBAL_REGION_LIST_URL,
  AZURE_GLOBAL_REGION_LIST_URL,
} from "../../../config/service/index";
import { getApiService, } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";

export const getRegionList = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, GLOBAL_REGION_LIST_URL)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};

export const getAzureRegionList = () => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${AZURE_GLOBAL_REGION_LIST_URL}?CloudProvider=${CLOUDPROVIDERS.AZURE}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};
