import {
  EC2_INSTANCE_BASE_URL,
  EC2_SNAPSHOT_INSTANCE_URL,
  EC2_INSTANCE_SESSIONS_LIST_URL,
  EC2_INSTANCE_SESSION_URL,
  EC2_INSTANCE_SESSION_START_URL,
  EC2_INSTANCE_SESSION_RESUME_URL,
  EC2_INSTANCE_SESSION_TERMINATE_URL,
  RDP_CONFIG_URL,
  CONVERT_DAY2_MANAGED,
  EC2_INSTANCE_LIST_URL,
  TYPES,
} from "../../../config/service/index";
import { getApiService, } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const performComputeOperations = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, EC2_INSTANCE_BASE_URL, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const snapshotEC2Instances = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, EC2_SNAPSHOT_INSTANCE_URL, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getInstanceDetailsApi = (instanceId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    EC2_INSTANCE_BASE_URL + "/" + instanceId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};

export const getInstanceSessionsHistoryApi = (instanceId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    EC2_INSTANCE_SESSIONS_LIST_URL + instanceId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getInstanceSessionLogApi = (instanceId, sessionId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    EC2_INSTANCE_SESSION_URL + instanceId + "/" + sessionId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const startSessionApi = (instanceId, param) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    EC2_INSTANCE_SESSION_START_URL + instanceId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const resumeSessionApi = (instanceId, sessionId) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    EC2_INSTANCE_SESSION_RESUME_URL + instanceId + "/" + sessionId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const terminateSessionApi = (instanceId, sessionId) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    EC2_INSTANCE_SESSION_TERMINATE_URL + instanceId + "/" + sessionId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getRDPConfigApi = (instanceId) => {
  const url = `${EC2_INSTANCE_BASE_URL}/${instanceId}/${RDP_CONFIG_URL}`;
  return getApiService(WEB_REQUEST_TYPE.GET, url);
};

export const convertToManageInstance = (params) => {
  const url = `${EC2_INSTANCE_BASE_URL}${CONVERT_DAY2_MANAGED}`;
  return getApiService(WEB_REQUEST_TYPE.POST, url, params);
};

export const getInstanceTypesApi = (params) => {
  const url = `${EC2_INSTANCE_LIST_URL}${TYPES}?${params}`;
  return getApiService(WEB_REQUEST_TYPE.GET, url);
};
