export const GET_BLUEPRINT_SHARED_CATALOG_REQUEST =
  "GET_BLUEPRINT_SHARED_CATALOG_REQUEST";
export const GET_BLUEPRINT_SHARED_CATALOG_SUCCESS =
  "GET_BLUEPRINT_SHARED_CATALOG_SUCCESS";
export const GET_BLUEPRINT_SHARED_CATALOG_MASTER_SUCCESS =
  "GET_BLUEPRINT_SHARED_CATALOG_MASTER_SUCCESS";

export const GET_BLUEPRINT_SHARED_CATALOG_FAILURE =
  "GET_BLUEPRINT_SHARED_CATALOG_FAILURE";
export const SHARE_BLUEPRINT_REQUEST = "SHARE_BLUEPRINT_REQUEST";
export const SHARE_BLUEPRINT_SUCCESS = "SHARE_BLUEPRINT_SUCCESS";
export const SHARE_BLUEPRINT_FAILURE = "SHARE_BLUEPRINT_FAILURE";
export const GET_BLUEPRINT_SHARES_LIST_REQUEST =
  "GET_BLUEPRINT_SHARES_LIST_REQUEST";
export const GET_BLUEPRINT_SHARES_LIST_SUCCESS =
  "GET_BLUEPRINT_SHARES_LIST_SUCCESS";
export const GET_BLUEPRINT_SHARES_LIST_FAILURE =
  "GET_BLUEPRINT_SHARES_LIST_FAILURE";
export const SHARE_BLUEPRINT_RESET = "SHARE_BLUEPRINT_RESET";

//ASSIGN PROJECT TO BLUEPRINT ACTION TYPES
export const ASSIGN_PROJECT_TO_BLUEPRINT_REQUEST =
  "ASSIGN_PROJECT_TO_BLUEPRINT_REQUEST";
export const ASSIGN_PROJECT_TO_BLUEPRINT_SUCCESS =
  "ASSIGN_PROJECT_TO_BLUEPRINT_SUCCESS";
export const ASSIGN_PROJECT_TO_BLUEPRINT_FAILURE =
  "ASSIGN_PROJECT_TO_BLUEPRINT_FAILURE";
export const UNSHARE_BLUEPRINT_REQUEST = "UNSHARE_BLUEPRINT_REQUEST";
export const UNSHARE_BLUEPRINT_SUCCESS = "UNSHARE_BLUEPRINT_SUCCESS";
export const UNSHARE_BLUEPRINT_RESET = "UNSHARE_BLUEPRINT_RESET";
export const UNSHARE_BLUEPRINT_FAILURE = "UNSHARE_BLUEPRINT_FAILURE";
