import {
  CREATE_REPORT_SCHEDULE_REQUEST,
  CREATE_REPORT_SCHEDULE_SUCCESS,
  CREATE_REPORT_SCHEDULE_FAILURE,
  GET_REPORT_SCHEDULES_REQUEST,
  GET_REPORT_SCHEDULES_FAILURE,
  GET_REPORT_SCHEDULES_SUCCESS,
  RESET_CREATE_REPORT_SCHEDULE,
  EDIT_REPORT_SCHEDULE_REQUEST,
  EDIT_REPORT_SCHEDULE_SUCCESS,
  EDIT_REPORT_SCHEDULE_FAILURE,
  RESET_EDIT_REPORT_SCHEDULE,
} from "../actionTypes/reportSchedule";

const initialState = {
  isCreatingReportSchedule: false,
  createdReportSchedule: false,
  isFetching: false,
  reportScheduleList: null,
  reportScheduleOffset: null,
  isEditingReportSchedule: false,
  editedReportSchedule: false,
};

const reportSchedule = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT_SCHEDULE_REQUEST:
      return {
        ...state,
        isCreatingReportSchedule: true,
        createdReportSchedule: false,
      };
    case CREATE_REPORT_SCHEDULE_SUCCESS:
      return {
        ...state,
        isCreatingReportSchedule: false,
        createdReportSchedule: true,
      };
    case CREATE_REPORT_SCHEDULE_FAILURE:
      return {
        ...state,
        isCreatingReportSchedule: false,
      };
    case GET_REPORT_SCHEDULES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_REPORT_SCHEDULES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reportScheduleList: action.payload.reportScheduleList,
        reportScheduleOffset: action.payload.offset,
      };
    case GET_REPORT_SCHEDULES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case RESET_CREATE_REPORT_SCHEDULE:
      return {
        ...state,
        createdReportSchedule: initialState.createdReportSchedule,
      };
    case EDIT_REPORT_SCHEDULE_REQUEST:
      return {
        ...state,
        isEditingReportSchedule: true,
        editedReportSchedule: false,
      };
    case EDIT_REPORT_SCHEDULE_SUCCESS:
      return {
        ...state,
        isEditingReportSchedule: false,
        editedReportSchedule: true,
      };
    case EDIT_REPORT_SCHEDULE_FAILURE:
      return {
        ...state,
        isEditingReportSchedule: false,
      };
    case RESET_EDIT_REPORT_SCHEDULE:
      return {
        ...state,
        editedReportSchedule: initialState.editedReportSchedule,
      };
    default:
      return state;
  }
};

export default reportSchedule;
