import {
  COMPUTE_INSTANCE_REQUEST,
  COMPUTE_INSTANCE_SUCCESS,
  COMPUTE_INSTANCE_FAILURE,
  CREATE_BACKUP_INSTANCE_REQUEST,
  CREATE_BACKUP_INSTANCE_SUCCESS,
  CREATE_BACKUP_INSTANCE_FAILURE,
  GET_INSTANCE_DETAILS_REQUEST,
  GET_INSTANCE_DETAILS_SUCCESS,
  GET_INSTANCE_DETAILS_FAILURE,
  CLEAR_COMPUTE_INSTANCE_STATE,
  GET_INSTANCE_SESSION_HISTORY_REQUEST,
  GET_INSTANCE_SESSION_HISTORY_SUCCESS,
  GET_INSTANCE_SESSION_HISTORY_FAILURE,
  GET_INSTANCE_SESSION_LOG_REQUEST,
  GET_INSTANCE_SESSION_LOG_SUCCESS,
  GET_INSTANCE_SESSION_LOG_FAILURE,
  INSTANCE_SESSION_START_REQUEST,
  INSTANCE_SESSION_START_SUCCESS,
  INSTANCE_SESSION_START_FAILURE,
  INSTANCE_SESSION_RESUME_REQUEST,
  INSTANCE_SESSION_RESUME_SUCCESS,
  INSTANCE_SESSION_RESUME_FAILURE,
  INSTANCE_SESSION_TERMINATE_REQUEST,
  INSTANCE_SESSION_TERMINATE_SUCCESS,
  INSTANCE_SESSION_TERMINATE_FAILURE,
  SET_IMAGE_DESCRIPTION,
  SET_IMAGE_NAME,
  SET_IMAGE_MANAGED_TAGS,
  GET_RDP_CONFIG_REQUEST,
  GET_RDP_CONFIG_SUCCESS,
  GET_RDP_CONFIG_FAILURE,
  CLEAR_RDP_CONFIG_DATA,
  GET_INSTANCE_TYPES,
  CLEAR_INSTANCE_DETAILS,
} from "../actionTypes/instance";

const initialState = {
  isSaving: false,
  savedInstanceState: false,
  isCreating: false,
  createdBackup: false,
  isFetchingDetails: false,
  instanceDetails: null,
  failedToFetchInstanceDetails: false,
  createSnapshotErrorMessages: null,
  isFetchingInstanceSessions: false,
  instanceSessionList: null,
  hasFetchedInstanceSessionList: false,
  fetchingInstanceSessionsFailed: false,
  isFetchingInstanceSessionLog: false,
  sessionLogErrorMessage: null,
  instanceSessionLog: null,
  hasFetchedInstanceSessionLog: false,
  startSessionRequest: false,
  startSessionData: null,
  startSessionFailed: false,
  startSessionFailureMessage: null,
  resumeSessionRequest: false,
  resumeSessionData: null,
  resumeSessionFailed: false,
  terminateSessionRequest: false,
  terminateSessionData: null,
  terminateSessionFailed: false,
  terminateSessionFailureMessage: null,
  imageDescription: "",
  imageName: "",
  manageTagsResource: null,
  fetchingRDPConfig: false,
  rdpConfig: null,
  fetchingRDPConfigFailed: false,
  isAccessDenied: false,
  instanceTypesData: null,
  fetchedInstanceDetails: false,
};
export default function computeInstance(state = initialState, action) {
  switch (action.type) {
    case COMPUTE_INSTANCE_REQUEST:
      return {
        ...state,
        isSaving: true,
        savedInstanceState: false,
      };
    case COMPUTE_INSTANCE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        savedInstanceState: true,
      };
    case COMPUTE_INSTANCE_FAILURE:
      return {
        ...state,
        isSaving: false,
        errorMessage: action.payload,
        savedInstanceState: false,
      };
    case CREATE_BACKUP_INSTANCE_REQUEST:
      return {
        ...state,
        isCreating: true,
        createdBackup: false,
        createSnapshotErrorMessages: null,
      };
    case CREATE_BACKUP_INSTANCE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdBackup: true,
      };
    case CREATE_BACKUP_INSTANCE_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSnapshotErrorMessages: action.payload,
        createdBackup: false,
      };
    case GET_INSTANCE_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
        failedToFetchInstanceDetails: initialState.failedToFetchInstanceDetails,
        instanceDetails: null,
        fetchedInstanceDetails: initialState.fetchedInstanceDetails,
      };
    case GET_INSTANCE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        instanceDetails: action.payload,
        fetchedInstanceDetails: true,
      };
    case GET_INSTANCE_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        failedToFetchInstanceDetails: true,
        errorMessage: action.payload,
      };
    case CLEAR_INSTANCE_DETAILS: {
      return {
        ...state,
        isFetchingDetails: false,
        instanceDetails: null,
        fetchedInstanceDetails: false
      }
    }
    case CLEAR_COMPUTE_INSTANCE_STATE:
      return {
        ...state,
        savedInstanceState: false,
        createSnapshotErrorMessages: null,
        createdBackup: false,
        imageName: initialState.imageName,
        imageDescription: initialState.imageDescription,
        manageTagsResource: initialState.manageTagsResource,
      };
    case GET_INSTANCE_SESSION_HISTORY_REQUEST:
      return {
        ...state,
        isFetchingInstanceSessions: true,
        instanceSessionList: initialState.instanceSessionList,
        hasFetchedInstanceSessionList:
          initialState.hasFetchedInstanceSessionList,
      };
    case GET_INSTANCE_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        isFetchingInstanceSessions: initialState.isFetchingInstanceSessions,
        instanceSessionList: action.payload,
        hasFetchedInstanceSessionList: true,
      };
    case GET_INSTANCE_SESSION_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingInstanceSessions: false,
        instanceSessionList: initialState.instanceSessionList,
        hasFetchedInstanceSessionList: false,
        fetchingInstanceSessionsFailed: true,
      };
    case GET_INSTANCE_SESSION_LOG_REQUEST:
      return {
        ...state,
        isFetchingInstanceSessionLog: true,
        instanceSessionLog: initialState.instanceSessionLog,
        hasFetchedInstanceSessionLog: initialState.hasFetchedInstanceSessionLog,
      };
    case GET_INSTANCE_SESSION_LOG_SUCCESS:
      return {
        ...state,
        isFetchingInstanceSessionLog: initialState.isFetchingInstanceSessionLog,
        instanceSessionLog: action.payload,
        hasFetchedInstanceSessionLog: true,
      };
    case GET_INSTANCE_SESSION_LOG_FAILURE:
      return {
        ...state,
        isFetchingInstanceSessionLog: false,
        instanceSessionLog: initialState.instanceSessionLog,
        hasFetchedInstanceSessionLog: false,
        sessionLogErrorMessage: action.payload,
      };
    case INSTANCE_SESSION_START_REQUEST:
      return {
        ...state,
        startSessionRequest: true,
        startSessionData: initialState.startSessionData,
        startSessionFailed: initialState.startSessionFailed,
      };
    case INSTANCE_SESSION_START_SUCCESS:
      return {
        ...state,
        startSessionRequest: initialState.startSessionRequest,
        startSessionData: action.payload,
        startSessionFailed: initialState.startSessionFailed,
      };
    case INSTANCE_SESSION_START_FAILURE:
      return {
        ...state,
        startSessionRequest: initialState.startSessionRequest,
        startSessionData: initialState.startSessionData,
        startSessionFailureMessage: action.payload,
        startSessionFailed: true,
      };
    case INSTANCE_SESSION_RESUME_REQUEST:
      return {
        ...state,
        resumeSessionRequest: true,
        resumeSessionData: initialState.resumeSessionData,
        resumeSessionFailed: initialState.resumeSessionFailed,
      };
    case INSTANCE_SESSION_RESUME_SUCCESS:
      return {
        ...state,
        resumeSessionRequest: initialState.resumeSessionRequest,
        resumeSessionData: action.payload,
        resumeSessionFailed: initialState.resumeSessionFailed,
      };
    case INSTANCE_SESSION_RESUME_FAILURE:
      return {
        ...state,
        resumeSessionRequest: initialState.resumeSessionRequest,
        resumeSessionData: initialState.resumeSessionData,
        resumeSessionFailed: true,
      };
    case INSTANCE_SESSION_TERMINATE_REQUEST:
      return {
        ...state,
        terminateSessionRequest: true,
        terminateSessionData: initialState.terminateSessionData,
        terminateSessionFailed: initialState.terminateSessionFailed,
      };
    case INSTANCE_SESSION_TERMINATE_SUCCESS:
      return {
        ...state,
        terminateSessionRequest: initialState.terminateSessionRequest,
        terminateSessionData: action.payload,
        terminateSessionFailed: initialState.terminateSessionFailed,
      };
    case INSTANCE_SESSION_TERMINATE_FAILURE:
      return {
        ...state,
        terminateSessionRequest: initialState.terminateSessionRequest,
        terminateSessionData: initialState.terminateSessionData,
        terminateSessionFailureMessage: action.payload,
        terminateSessionFailed: true,
      };
    case SET_IMAGE_NAME:
      return {
        ...state,
        imageName: action.payload,
      };
    case SET_IMAGE_DESCRIPTION:
      return {
        ...state,
        imageDescription: action.payload,
      };
    case SET_IMAGE_MANAGED_TAGS:
      return {
        ...state,
        manageTagsResource: action.payload,
      };
    case GET_RDP_CONFIG_REQUEST:
      return {
        ...state,
        fetchingRDPConfig: true,
        fetchingRDPConfigFailed: initialState.fetchingRDPConfigFailed,
      };
    case GET_RDP_CONFIG_SUCCESS:
      return {
        ...state,
        fetchingRDPConfig: false,
        rdpConfig: action.payload,
      };
    case GET_RDP_CONFIG_FAILURE:
      return {
        ...state,
        fetchingRDPConfig: false,
        fetchingRDPConfigFailed: true,
        isAccessDenied: action.payload,
      };
    case GET_INSTANCE_TYPES:
      return {
        ...state,
        instanceTypesData: action.payload,
      };
    case CLEAR_RDP_CONFIG_DATA:
      return {
        ...state,
        fetchingRDPConfig: initialState.fetchingRDPConfig,
        rdpConfig: initialState.rdpConfig,
        fetchingRDPConfigFailed: initialState.fetchingRDPConfigFailed,
      };
    default:
      return state;
  }
}
